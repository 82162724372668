import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppComponent } from "./app.component";
import { BheShellFeatureModule } from "@bhe/shell-feature";
import { environment } from "../environments/environment";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { getAppConfigProvider } from "@madeinlune/ngx-app-config";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslocoRootModule } from "./transloco-root.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BheShellFeatureModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    TranslocoRootModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000"
    })
  ],
  providers: [
    getAppConfigProvider(environment.config),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
