import {ChangeDetectionStrategy, Component, Input, NgModule, ViewEncapsulation,} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BarChartModule, LineChartModule, PieChartModule,} from '@swimlane/ngx-charts';

@Component({
  selector: 'bhe-pro-charts',
  template: `
    <div>
      <h2 [innerHTML]="count + ' reservations'"></h2>
      <ngx-charts-line-chart
        [xAxis]="true"
        [yAxis]="true"
        [timeline]="true"
        [results]="monthFacet"
      >
      </ngx-charts-line-chart>
    </div>

    <div>
      <h2 [innerHTML]="'Status'"></h2>
      <ngx-charts-pie-grid [results]="workflowFacet"></ngx-charts-pie-grid>
    </div>

    <div>
      <h2 [innerHTML]="'Top 5 Countries'"></h2>
      <ngx-charts-advanced-pie-chart [results]="countryFacet">
      </ngx-charts-advanced-pie-chart>
    </div>

    <div>
      <h2 [innerHTML]="'Maisons'"></h2>
      <ngx-charts-advanced-pie-chart [results]="brandsFacet">
      </ngx-charts-advanced-pie-chart>
    </div>

    <div>
      <h2 [innerHTML]="'Guest Types'"></h2>
      <ngx-charts-advanced-pie-chart [results]="guestTypesFacet">
      </ngx-charts-advanced-pie-chart>
    </div>

    <div>
      <h2 [innerHTML]="'Maisons by Visit'"></h2>
      <ngx-charts-bar-vertical
        [results]="reservationPartCountFacet"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxisLabel]="'Maisons count'"
        [yAxisLabel]="'Visit count'"
      >
      </ngx-charts-bar-vertical>
    </div>

    <ng-container *ngIf="guestExperiences">
      <h2 [innerHTML]="'Guest Experiences'"></h2>
      <ng-template ngFor [ngForOf]="guestExperiences" let-guestExperience>
        <div>
          <h3 [innerHTML]="guestExperience.brand"></h3>
          <ngx-charts-bar-vertical
            [results]="guestExperience.values"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [yAxisLabel]="'Visit count'"
          >
          </ngx-charts-bar-vertical>
        </div>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./charts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartsComponent {
  @Input()
  count!: any;

  @Input()
  monthFacet!: any;

  @Input()
  workflowFacet!: any;

  @Input()
  countryFacet!: any;

  @Input()
  brandsFacet!: any;

  @Input()
  guestTypesFacet!: any;

  @Input()
  reservationPartCountFacet!: any;

  @Input()
  guestExperiences!: any;
}

@NgModule({
  imports: [CommonModule, LineChartModule, PieChartModule, BarChartModule],
  declarations: [ChartsComponent],
  exports: [ChartsComponent],
})
export class ChartsComponentModule {}
