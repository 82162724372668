import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  InjectionToken,
  Injector,
  Input,
  NgModule,
  NgZone,
  OnChanges,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryButtonComponent } from '../primary-button/primary-button.component';
import { TextButtonComponent } from '../text-button/text-button.component';
import {
  AccentButtonComponent,
  AccentButtonComponentModule,
} from '../accent-button/accent-button.component';
import { WarnButtonComponent } from '../warn-button/warn-button.component';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonTemplate } from '../button-template';
import {
  SoftButtonComponent,
  SoftButtonComponentModule,
} from '../soft-button/soft-button.component';
import {ThemePalette} from "@angular/material/core";

export const BUTTON_DISABLED = new InjectionToken<boolean>('BUTTON_DISABLED');

export type BheButtonVariant = 'primary' | 'accent' | 'text' | 'warn' | 'soft';
export type BheButtonType = 'button' | 'submit' | 'reset';
export type BheButtonSize = 'sm' | 'default' | 'lg';

export const disabledAttributeToken = new InjectionToken<boolean>(
  'Disabled attribute token'
);

export const typeAttributeToken = new InjectionToken<boolean>(
  'Type attribute token'
);

@UntilDestroy()
@Component({
  selector: 'bhe-ui-bhe-button',
  template: `
    <ng-template #buttonContainer></ng-template>
    <div #contentWrapper>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./bhe-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BheButtonComponent implements OnChanges, AfterViewInit {
  @ViewChild('buttonContainer', { read: ViewContainerRef })
  buttonContainer!: ViewContainerRef;
  @ViewChild('contentWrapper')
  contentWrapper!: ElementRef<HTMLElement>;

  #variant: BheButtonVariant = 'text';

  componentRef!: ComponentRef<ButtonTemplate>;

  @Input()
  get variant(): BheButtonVariant {
    return this.#variant;
  }

  set variant(value: BheButtonVariant) {
    if (value == null || (value as unknown) === '') {
      value = 'text';
    }
    this.#variant = value;
    this.#buildComponent();
  }

  @Input() type: BheButtonType = 'button';

  @Input()
  disabled = false;

  @Input()
  size: BheButtonSize = 'default';

  @Input()
  icon!: string;

  @Input()
  iconPlacement!: 'left' | 'right';

  @Input()
  color!: ThemePalette;

  @Output()
  btnClick: EventEmitter<PointerEvent> = new EventEmitter<PointerEvent>();

  get buttonComponentInjector(): Injector {
    return Injector.create({
      providers: [
        {
          provide: disabledAttributeToken,
          useValue: this.disabled,
        },
        {
          provide: typeAttributeToken,
          useValue: this.type,
        },
      ],
      parent: this.injector,
    });
  }

  get buttonComponentVariant(): Type<ButtonTemplate> {
    switch (this.variant) {
      case 'primary':
        return PrimaryButtonComponent;
      case 'accent':
        return AccentButtonComponent;
      case 'warn':
        return WarnButtonComponent;
      case 'soft':
        return SoftButtonComponent;
      case 'text':
      default:
        return TextButtonComponent;
    }
  }

  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.#buildComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disabledChange: SimpleChange = changes['disabled'];
    if (disabledChange) {
      this.#validateDisabled(disabledChange.isFirstChange());
    }

    const iconChange: SimpleChange = changes['icon'];
    if (iconChange) {
      this.#validateIcon(iconChange.isFirstChange());
    }

    const { nativeElement } = this.el;

    const sizeChange: SimpleChange = changes['size'];
    if (sizeChange) {
      if (sizeChange.previousValue) {
        this.renderer.removeClass(nativeElement, sizeChange.previousValue);
      }
      if (sizeChange.currentValue) {
        this.renderer.addClass(nativeElement, sizeChange.currentValue);
      }
    }
    const iconPlacementChange: SimpleChange = changes['iconPlacement'];
    if (iconPlacementChange) {
      if (iconPlacementChange.previousValue) {
        this.renderer.removeClass(
          nativeElement,
          iconPlacementChange.previousValue
        );
      }
      if (iconPlacementChange.currentValue) {
        this.renderer.addClass(nativeElement, iconPlacementChange.currentValue);
      }
    }
  }

  #validateDisabled(firstChange: boolean) {
    if (this.componentRef?.instance) {
      this.componentRef.instance.isDisabled = this.disabled;
      const changes = {
        isDisabled: new SimpleChange('', this.disabled, firstChange),
      };
      this.componentRef.instance.ngOnChanges(changes);
    }
  }

  #validateIcon(firstChange: boolean) {
    if (this.componentRef?.instance) {
      this.componentRef.instance.icon = this.icon;
      const changes = {
        icon: new SimpleChange('', this.icon, firstChange),
      };
      this.componentRef.instance.ngOnChanges(changes);
    }
  }

  #buildComponent() {
    if (this.buttonContainer && this.#variant && !this.componentRef) {
      this.componentRef = this.buttonContainer.createComponent<ButtonTemplate>(
        this.buttonComponentVariant,
        {
          injector: this.buttonComponentInjector,
          projectableNodes: [[this.contentWrapper.nativeElement]],
        }
      );
      if (this.componentRef.instance.target) {
        if (this.icon) {
          this.componentRef.instance.icon = this.icon;
        }
        if (this.color) {
          this.componentRef.instance.color = this.color;
        }
        this.#validateDisabled(true);
        this.#validateIcon(true);
        fromEvent(this.componentRef.instance.target.nativeElement, 'click')
          .pipe(untilDestroyed(this))
          .subscribe((event: any) => {
            this.ngZone.run(() => {
              this.btnClick.emit(event);
            });
          });
      }
      this.componentRef.changeDetectorRef.detectChanges();
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    AccentButtonComponentModule,
    SoftButtonComponentModule,
  ],
  declarations: [BheButtonComponent],
  exports: [BheButtonComponent],
})
export class BheButtonComponentModule {}
