import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BheButtonComponentModule } from '@bhe/ui';
import { PwaManifestService } from '@madeinlune/ngx-pwa-manifest';
import { TranslocoModule } from '@ngneat/transloco';
import { map, Observable } from 'rxjs';
import { LetModule, PushModule } from "@ngrx/component";

@Component({
  selector: 'bhe-login',
  template: `
    <div class="content-group">
      <ng-container *transloco="let t">
        <ng-container *ngrxLet="iconUrl$; let iconUrl">
          <ng-container *ngIf="iconUrl">
            <img [src]="iconUrl" />
          </ng-container>
          <h1 [innerHTML]="appTitle$ | ngrxPush"></h1>
          <bhe-ui-bhe-button variant="soft" color="primary" (btnClick)="login()"
            ><span [innerHTML]="t('actions.login')"></span>
          </bhe-ui-bhe-button>
        </ng-container>
      </ng-container>
    </div>
  `,
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  iconUrl$: Observable<string | undefined> =
    this.pwaManifestService.icons$.pipe(
      map((icons) => {
        return icons.find((icon) => icon.sizes === '192x192')?.src;
      })
    );

  appTitle$: Observable<string | undefined> = this.pwaManifestService.name$;

  constructor(
    private oauthService: OAuthService,
    private pwaManifestService: PwaManifestService
  ) {}

  login(): void {
    this.oauthService.initImplicitFlow();
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: LoginComponent
      }
    ]),
    BheButtonComponentModule,
    TranslocoModule,
    PushModule,
    LetModule
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent],
})
export class LoginComponentModule {}
