import { map, Observable } from 'rxjs';
import {
  JsonApiPayload,
  Resource,
  ResourceIdentifier,
  ResourceRelationship,
} from '@madeinlune/ngx-json-api';

export function jsonApiResources(denormalize: boolean = false) {
  // tslint:disable-next-line:only-arrow-functions
  return function <T>(source: Observable<JsonApiPayload>) {
    return source.pipe(
      map((payload) => {
        return JSON.parse(JSON.stringify(payload));
      }),
      map((payload) => {
        const data: Resource[] = Array.isArray(payload.data)
          ? payload.data
          : [payload.data];
        const included: Resource[] = payload?.included ?? [];
        const resources: ResourceIdentifier[] = [...data, ...included].filter(
          (res) => !!res
        ) as ResourceIdentifier[];
        const requestUrl: URL = new URL(
          payload?.links?.self?.href || payload?.links?.self
        );
        let includeParams: string[] | undefined = requestUrl.searchParams
          .get('include')
          ?.split(',');
        //optional denormalization
        if (includeParams) {
          const split = includeParams.map((include) => {
            return include.split('.');
          });
          // eslint-disable-next-line prefer-spread
          includeParams = split.reduce(
            (accumulator, value) => accumulator.concat(value),
            []
          );

          if (includeParams) {
            includeParams = includeParams.filter(function (item, pos) {
              return (includeParams as string[]).indexOf(item) == pos;
            });
          }
        }
        if (denormalize && included && includeParams) {
          return denormalizeResource(data, included, includeParams, resources);
        }
        return resources;
      })
    );
  };
}

export function denormalizeResource(
  resources: Resource[],
  includedResources: Resource[],
  includes: string[],
  allResources: Resource[]
): Resource[] {
  [...resources, ...includedResources].forEach((resource) => {
    includes.forEach((field) => {
      const fiedValue: ResourceRelationship | undefined =
        resource?.relationships?.[field];
      if (fiedValue) {
        if (Array.isArray(fiedValue.data)) {
          fiedValue.data.forEach((item) => {
            item.reference = includedResources.find((resource) => {
              return resource.id === item.id;
            });
          });
        } else {
          if (fiedValue.data?.id) {
            fiedValue.data.reference = includedResources.find((resource) => {
              return resource.id === fiedValue.data.id;
            });
          }
        }
      }
    });
  });
  return allResources;
}
