import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BRANDS } from '@bhe/vocabularies-data';
import { map, Observable, take } from 'rxjs';
import { Brand, ReservationPart } from '@bhe/types';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { ResourceIdentifier, uuid } from '@madeinlune/ngx-json-api';
import { FieldArrayType } from '@ngx-formly/core';
import { ReservationFormEntitiesService } from '@bhe/reservation-data-access';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BheIconComponentModule } from '@bhe/ui';
import { LetModule } from "@ngrx/component";

@UntilDestroy()
@Component({
  selector: 'bhe-brand-selection',
  template: `
    <ng-container *ngrxLet="brands$; let brands">
      <form [formGroup]="brandForm">
        <ng-template ngFor [ngForOf]="brands" let-brand>
          <ng-container *ngIf="brand">
            <mat-checkbox [formControlName]="brand.id">
              <div class="check-box-content">
                <bhe-ui-bhe-icon [icon]="brand.code"></bhe-ui-bhe-icon>
                <span [innerHTML]="brand.name"></span>
              </div>
            </mat-checkbox>
          </ng-container>
        </ng-template>
      </form>
    </ng-container>
  `,
  styleUrls: ['./brand-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandSelectionComponent
  extends FieldArrayType
  implements AfterViewInit
{
  brandForm: UntypedFormGroup = new UntypedFormGroup({}, { updateOn: 'change' });

  constructor(
    @Inject(BRANDS) public brands$: Observable<Brand[]>,
    @Optional() private reservationFormService: ReservationFormEntitiesService
  ) {
    super();

    this.brands$.pipe(take(1)).subscribe((brands) => {
      brands.forEach((brand) => {
        this.brandForm.addControl(brand.id, new UntypedFormControl(false));
      });
    });
    this.brandForm.valueChanges
      .pipe(
        untilDestroyed(this),
        map((value) => {
          const refs: ResourceIdentifier[] = [];
          Object.keys(value).forEach((id) => {
            if (value[id]) {
              refs.push({ id, type: Brand.type });
            }
          });
          return refs;
        })
      )
      .subscribe((brandRefs) => {
        // used in New Client (this.isReservationPart===false) and new reservation form (this.isReservationPart===true)
        if (this.isReservationPart) {
          if (this.formControl.controls) {
            this.formControl.controls.forEach((control) => {
              this.reservationFormService.removeEntity(control.value.id);
            });
          }
          this.formControl.clear({ emitEvent: false });
          brandRefs.forEach((brandRef) => {
            const reservationPart = {
              id: uuid(),
              type: ReservationPart.type,
              field_brand: brandRef,
            };
            const { id, type } = reservationPart;
            this.reservationFormService.addEntity(reservationPart, 'NEW');
            this.formControl.push(new UntypedFormControl({ id, type }), {
              emitEvent: false,
            });
          });
          /*brandRefs.forEach((brandRef) => {
            this.formControl.push(new FormControl(brandRef));
          });*/
        } else {
          this.formControl.clear({ emitEvent: false });
          brandRefs.forEach((brandRef) => {
            this.formControl.push(new UntypedFormControl(brandRef), {
              emitEvent: false,
            });
          });
        }
        this.formControl.updateValueAndValidity({
          emitEvent: true,
          onlySelf: false,
        });
      });
  }

  get isReservationPart(): boolean {
    return this.field.templateOptions?.['isReservationPart'];
  }

  ngAfterViewInit(): void {
    if (this.formControl) {
      if (this.formControl.value) {
        this.formControl.value.forEach((value: ResourceIdentifier) => {
          this.brandForm.get(value.id)?.setValue(true);
        });
        this.brandForm.updateValueAndValidity({
          emitEvent: true,
          onlySelf: false,
        });
      }
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    BheIconComponentModule,
    LetModule
  ],
  declarations: [BrandSelectionComponent],
  exports: [BrandSelectionComponent],
})
export class BrandSelectionComponentModule {}
