import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  NgModule,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
  FormlyTemplateOptions
} from "@ngx-formly/core";
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  skip,
  take,
  tap,
} from 'rxjs';
import { COUNTRIES } from '@bhe/shell-state';
import { isEqual } from '@madeinlune/ngx-operators';
import { uuid } from '@madeinlune/ngx-json-api';
import { Guest, Reservation, ReservationForm } from '@bhe/types';
import { MatDialogRef } from '@angular/material/dialog';
import {
  BheButtonComponentModule,
  BheDialogComponent,
  ErrorComponentModule,
  IconLoadingComponentModule,
  LoadingComponentModule,
  ProcessComponentModule,
} from '@bhe/ui';
import { USER_FIRST_NAME } from '@bhe/user-data-access';
import {
  ReservationFormEntitiesService,
  ReservationService,
} from '@bhe/reservation-data-access';
import { RxState } from '@rx-angular/state';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import {
  bheWrapper,
  countryBlock,
  invitedByField,
  languagesBlock,
  mainGuestField,
  wishedDatesBlock,
} from '../reservation.fields';
import { DateTime } from 'luxon';
import { LetModule } from "@ngrx/component";

@UntilDestroy()
@Component({
  selector: 'bhe-new-reservation',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngrxLet="isSaving$; let isSaving">
        <ng-container *ngrxLet="error$; let error">
          <ng-container *ngrxLet="hasError$; let hasError">
            <ng-container *ngIf="isSaving || hasError">
              <bhe-ui-process
                class="b-process"
                [state]="isSaving ? 'running' : hasError ? 'error' : 'success'"
                [processData]="{
                  runningMessage: t('new-reservation.loading.message'),
                  errorMessage: t('new-reservation.error.message')
                }"
              >
                <ng-template bheUiProcessError>
                  <ng-container *ngIf="error">
                    <bhe-ui-error
                      class="b-error"
                      [message]="t('new-reservation.error.message')"
                      [code]="error.status"
                      [error]="error.error"
                      (ok)="close()"
                    >
                    </bhe-ui-error>
                  </ng-container>
                </ng-template>
              </bhe-ui-process>
            </ng-container>

            <div class="b-content" [class.invisible]="isSaving || hasError">
              <ng-container *ngrxLet="fields$; let fields">
                <form [formGroup]="form" (ngSubmit)="submit()">
                  <formly-form
                    [model]="model"
                    [fields]="fields"
                    [options]="options"
                    [form]="form"
                    (modelChange)="onModelChange($event)"
                  ></formly-form>
                </form>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./new-reservation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReservationFormEntitiesService, RxState],
})
export class NewReservationComponent {
  isSaving$ = this.state.select('isSaving');
  hasError$ = this.state.select('error').pipe(map((error) => !!error));
  error$ = this.state.select('error');

  form = new UntypedFormGroup({});

  options: FormlyFormOptions = {
    formState: {},
  };

  reservationId: string = uuid();
  mainGuestId: string = uuid();

  model: any = {};

  fields$: Observable<FormlyFieldConfig[]> = combineLatest([
    this.userFirstName$,
  ]).pipe(
    map(([userFirstName]) => {
      const welcomeTemplateOptions: FormlyTemplateOptions = {
        label: this.translocoService.translate('new-reservation.welcome.label'),
        description: 'new-reservation.welcome.description',
        userFirstName: userFirstName,
      };
      return [
        {
          key: 'id',
          defaultValue: this.reservationId,
        },
        {
          key: 'type',
          defaultValue: Reservation.type,
        },
        {
          type: 'stepper',
          className: 'form-root',
          fieldGroup: [
            {
              templateOptions: welcomeTemplateOptions,
              fieldGroupClassName: 'stepper-field-group',
              fieldGroup: [
                {
                  ...mainGuestField(
                    this.translocoService,
                    true,
                    {
                      id: this.mainGuestId,
                      type: Guest.type,
                    },
                    {
                      onGuestFormValid: (valid: boolean) => {
                        welcomeTemplateOptions['valid'] = valid;
                      },
                    }
                  ),
                },
                countryBlock(this.translocoService),
                languagesBlock(this.translocoService),
              ],
            },
            {
              templateOptions: {
                label: this.translocoService.translate(
                  'reservation.form.guest-type.label'
                ),
              },
              fieldGroupClassName: 'stepper-field-group',
              fieldGroup: [
                {
                  ...bheWrapper(
                    this.translocoService,
                    'reservation.form.guest-type.label'
                  ),
                  fieldGroup: [
                    {
                      ...this.getField(
                        'field_guest_type',
                        'reservation.form.guest-type.label',
                        {
                          required: true,
                          showDescription: true,
                        }
                      ),
                      type: 'guestTypes',
                    },
                  ],
                },
              ],
            },
            {
              templateOptions: {
                label: this.translocoService.translate(
                  'reservation.tabs.visit.label'
                ),
              },
              fieldGroupClassName: 'stepper-field-group',
              fieldGroup: [
                wishedDatesBlock(this.translocoService),
                {
                  ...bheWrapper(
                    this.translocoService,
                    'reservation.form.reservation-parts.title'
                  ),
                  fieldGroup: [
                    {
                      key: 'field_reservation_parts',
                      type: 'brands',
                      templateOptions: {
                        isReservationPart: true,
                        required: true,
                      },
                      className: 'reservation-parts',
                      fieldArray: {
                        fieldGroup: [
                          {
                            key: 'id',
                            type: 'string',
                          },
                          {
                            key: 'type',
                            type: 'string',
                          },
                        ],
                      },
                    },
                  ],
                },
                { ...invitedByField(this.translocoService) },
              ],
            },
          ],
        },
      ];
    })
  );

  constructor(
    public dialogRef: MatDialogRef<BheDialogComponent>,
    private translocoService: TranslocoService,
    @Inject(COUNTRIES)
    private countries$: Observable<{ id: string; label: string }[]>,
    @Inject(USER_FIRST_NAME) private userFirstName$: Observable<string>,
    private reservationFormEntitiesService: ReservationFormEntitiesService,
    private reservationService: ReservationService,
    private router: Router,
    private state: RxState<{
      isSaving: boolean;
      error: HttpErrorResponse | null;
    }>,
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>
  ) {
    this.state.set({
      isSaving: false,
      error: null,
    });

    this.reservationFormEntitiesService.addEntity(
      { id: this.reservationId, type: Reservation.type },
      'NEW'
    );

    this.reservationFormEntitiesService.addEntity(
      {
        id: this.mainGuestId,
        type: Guest.type,
      },
      'NEW'
    );

    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        map((value) => {
          Object.keys(value).forEach(
            (key) => value[key] === undefined && delete value[key]
          );
          return value;
        }),
        isEqual(),
        skip(1),
        tap((reservationForm: ReservationForm) => {
          const dateOnlyFields = [
            'field_new_client_date_of_last',
            'field_first_desired_date',
            'field_second_desired_date',
            'field_first_desired_date',
            'field_first_desired_end_date',
            'field_second_desired_date',
            'field_second_desired_end_date',
          ];
          Object.keys(reservationForm).forEach((key) => {
            const field: any = (reservationForm as any)[key];
            if (field instanceof DateTime) {
              const dateField: DateTime = field as DateTime;
              const isDateOnlyFields = dateOnlyFields.indexOf(key) > -1;
              const format: string = isDateOnlyFields
                ? 'yyyy-MM-dd'
                : "yyyy-MM-dd'T'HH:mm:ss'Z'";
              (reservationForm as any)[key] = dateField.toFormat(format);
            }
          });
        })
      )
      .subscribe((value) => {
        this.reservationFormEntitiesService.updateEntity(value, 'NEW');
      });

    this.isSaving$.pipe(untilDestroyed(this)).subscribe((isSaving) => {
      const { nativeElement } = this.el;
      this.renderer.removeClass(nativeElement, 'process-saving');
      if (isSaving) {
        this.renderer.addClass(nativeElement, 'process-saving');
      }
    });
  }

  filterCountries(
    countries: { id: string; label: string }[],
    currentCountryLabel: string
  ) {
    return countries.filter((country) => {
      return (
        country.label
          .toLowerCase()
          .indexOf(currentCountryLabel?.toLowerCase()) === 0
      );
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  submit() {
    this.save();
  }

  onModelChange(event: any) {}

  getField(
    property: string,
    label: string,
    templateOptions: FormlyTemplateOptions = {},
    description: string | undefined = undefined
  ): FormlyFieldConfig {
    return {
      className: property,
      key: property,
      templateOptions: {
        label: this.translocoService.translate(label),
        ...templateOptions,
        description,
      },
    };
  }

  save() {
    this.state.set({
      isSaving: true,
      error: null,
    });
    this.reservationFormEntitiesService.entities$
      .pipe(take(1))
      .subscribe((entities) => {
        this.reservationService
          .saveEntities(entities)
          .pipe(
            take(1),
            catchError((error) => of(error))
          )
          .subscribe((result) => {
            if (result instanceof HttpErrorResponse) {
              this.state.set({ error: result, isSaving: false });
            } else {
              this.router
                .navigate(['reservation', this.reservationId])
                .then(() => {
                  this.close();
                });
            }
          });
      });
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    BheButtonComponentModule,
    TranslocoModule,
    IconLoadingComponentModule,
    LoadingComponentModule,
    ErrorComponentModule,
    ProcessComponentModule,
    LetModule
  ],
  declarations: [NewReservationComponent],
  exports: [NewReservationComponent],
})
export class NewReservationComponentModule {}
