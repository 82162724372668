import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Guest } from '@bhe/types';
import { OverviewGuestComponentModule } from '../overview-guest/overview-guest.component';

@Component({
  selector: 'bhe-res-overview-reservation-guests',
  template: `
    <ng-template ngFor [ngForOf]="guests" let-guest>
      <bhe-res-overview-guest [guest]="guest"></bhe-res-overview-guest>
    </ng-template>
  `,
  styleUrls: ['./overview-reservation-guests.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewReservationGuestsComponent {
  @Input()
  guests!: Guest[];
}

@NgModule({
  imports: [CommonModule, OverviewGuestComponentModule],
  declarations: [OverviewReservationGuestsComponent],
  exports: [OverviewReservationGuestsComponent],
})
export class OverviewReservationGuestsComponentModule {}
