import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { NgxJsonApi, Resource, uuid } from '@madeinlune/ngx-json-api';
import { BheConfig, Export, Message } from '@bhe/types';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { APP_CONFIG } from '@madeinlune/ngx-app-config';
import { BheDialogService } from '@bhe/ui';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(
    private ngxJsonApi: NgxJsonApi,
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: BheConfig,
    private bheDialogService: BheDialogService
  ) {}

  createExport(exportResource: Resource): Observable<any> {
    const url = `${this.appConfig.backendUrl}/jsonapi/${Export.type
      .split('--')
      .join('/')}`;

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    });

    return this.httpClient
      .post(
        url,
        { data: exportResource },
        {
          observe: 'response',
          headers,
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.bheDialogService.openError(
            'errors.export.message',
            error.error,
            error.status
          );
          return throwError(() => error);
        })
      );
  }
}
