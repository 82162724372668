import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { select, Store } from '@ngrx/store';
import { selectBrandEntities } from './brand.selectors';
import { map, Observable } from 'rxjs';
import { State } from './brand.reducer';
import { Brand } from '@bhe/types';

@Pipe({
  name: 'brands',
})
export class BrandsPipe implements PipeTransform {
  constructor(private store: Store<State>) {}

  transform(brands: ResourceIdentifier[]): Observable<Brand[]> {
    return this.store.pipe(
      select(selectBrandEntities),
      map((brandEntities) => {
        return brands
          .filter((brand) => !!brand)
          .map((brandRef) => {
            return brandEntities?.[brandRef.id];
          })
          .filter((brand) => !!brand) as Brand[];
      })
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [BrandsPipe],
  exports: [BrandsPipe],
})
export class BrandsPipeModule {}
