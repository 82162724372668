import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WindowMessageData } from '@bhe/types';
import { BheDialogComponent } from '../bhe-dialog/bhe-dialog.component';

@Component({
  selector: 'bhe-ui-window-message',
  template: ` <div [innerHTML]="data.message"></div> `,
  styleUrls: ['./window-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WindowMessageComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WindowMessageData,
    public dialogRef: MatDialogRef<BheDialogComponent>
  ) {}
}

@NgModule({
  imports: [CommonModule],
  declarations: [WindowMessageComponent],
  exports: [WindowMessageComponent],
})
export class WindowMessageComponentModule {}
