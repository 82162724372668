import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { select, Store, StoreModule } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth.effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  AUTH_BACKEND_URL,
  AUTHENTICATED,
  AUTHENTICATION_ERROR,
  AUTHENTICATION_READY,
  AUTHENTICATION_STATE,
  AUTHENTICATION_USER_ID,
  JSON_API_END_POINT,
} from './auth.token';
import {
  selectAuthenticated,
  selectAuthenticationState,
  selectAuthenticationUserId,
} from './auth.selectors';
import {
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { map, Observable } from 'rxjs';
import { AuthErrorInterceptor } from './auth-error.interceptor';

export type AuthState = 'idle' | 'pending' | 'loaded' | 'error';

@NgModule({
  imports: [
    CommonModule,
    OAuthModule.forRoot(),
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
    HttpClientModule,
  ],
  providers: [
    { provide: OAuthStorage, useFactory: () => localStorage },
    {
      provide: AUTHENTICATED,
      deps: [Store],
      useFactory: (store: Store<fromAuth.State>) => {
        return store.pipe(select(selectAuthenticated));
      },
    },
    {
      provide: AUTHENTICATION_STATE,
      deps: [Store],
      useFactory: (store: Store<fromAuth.State>) => {
        return store.pipe(select(selectAuthenticationState));
      },
    },
    {
      provide: AUTHENTICATION_USER_ID,
      deps: [Store],
      useFactory: (store: Store<fromAuth.State>) => {
        return store.pipe(select(selectAuthenticationUserId));
      },
    },
    {
      provide: AUTHENTICATION_READY,
      deps: [AUTHENTICATION_STATE],
      useFactory: (authenticationState$: Observable<AuthState>) => {
        return authenticationState$.pipe(
          map((state) => state === 'loaded' || state === 'error')
        );
      },
    },
    {
      provide: AUTHENTICATION_ERROR,
      deps: [AUTHENTICATION_STATE],
      useFactory: (authenticationState$: Observable<AuthState>) => {
        return authenticationState$.pipe(map((state) => state === 'error'));
      },
    },
  ],
})
export class BheAuthenticationModule {
  static forRoot(): ModuleWithProviders<BheAuthenticationModule> {
    return {
      ngModule: BheAuthenticationModule,
      providers: [
        {
          provide: OAuthModuleConfig,
          deps: [AUTH_BACKEND_URL],
          useFactory: (backEndUrl: string) => {
            return {
              resourceServer: {
                allowedUrls: [backEndUrl],
                sendAccessToken: true,
              },
            };
          },
        },
        {
          provide: JSON_API_END_POINT,
          deps: [AUTH_BACKEND_URL],
          useFactory: (backEndUrl: string) => {
            return `${backEndUrl}/jsonapi`;
          },
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthErrorInterceptor,
          multi: true,
        },
      ],
    };
  }
}
