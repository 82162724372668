import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UntypedFormControl, UntypedFormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {Brand, GuestType, ReservationStatusInfos} from '@bhe/types';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DateTime} from 'luxon';
import {startWith} from 'rxjs';

export interface StatsForm {
  dateMode: 'last' | 'ever' | 'custom';
  startDate: DateTime;
  endDate: DateTime;
  brands: { code: string; uuid: string }[];
  guestTypes: { tid: string; uuid: string }[];
  status: string[];
  countries: string[];
}

@UntilDestroy()
@Component({
  selector: 'bhe-pro-stats-filters',
  template: `
    <form [formGroup]="statsFormGroup">
      <mat-button-toggle-group [formControlName]="'dateMode'">
        <mat-button-toggle value="ever">Since Ever</mat-button-toggle>
        <mat-button-toggle value="last">Last 12 months</mat-button-toggle>
        <mat-button-toggle value="custom" (change)="picker.open()">
          <div>
            Custom Period
            <mat-date-range-input [rangePicker]="picker">
              <input
                [formControlName]="'startDate'"
                matStartDate
                placeholder="Start date"
              />
              <input
                [formControlName]="'endDate'"
                matEndDate
                placeholder="End date"
              />
            </mat-date-range-input>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Brands</mat-label>
          <mat-select [formControlName]="'brands'" multiple>
            <ng-container *ngIf="brands">
              <mat-option
                *ngFor="let brand of brands"
                [value]="{ code: brand.code, uuid: brand.id }"
                >{{ brand.name }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Guest Types</mat-label>
          <mat-select [formControlName]="'guestTypes'" multiple>
            <ng-container *ngIf="guestTypes">
              <mat-option
                *ngFor="let guestType of guestTypes"
                [value]="{ tid: guestType.tid, uuid: guestType.id }"
                >{{ guestType.name }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Status</mat-label>
          <mat-select [formControlName]="'status'" multiple>
            <ng-container *ngIf="statusCollection">
              <mat-option
                *ngFor="let status of statusCollection"
                [value]="status.key"
                >{{ status.label }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Countries</mat-label>
          <mat-select [formControlName]="'countries'" multiple>
            <ng-container *ngIf="countries">
              <mat-option
                *ngFor="let country of countries"
                [value]="country.id"
                >{{ country.label }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  `,
  styleUrls: ['./stats-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsFiltersComponent implements OnInit {
  statsFormGroup: UntypedFormGroup = new UntypedFormGroup({
    dateMode: new UntypedFormControl('last'),
    startDate: new UntypedFormControl(''),
    endDate: new UntypedFormControl(''),
    brands: new UntypedFormControl(''),
    guestTypes: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    countries: new UntypedFormControl(''),
  });

  @Input()
  brands!: Brand[] | undefined;

  @Input()
  guestTypes!: GuestType[] | undefined;

  @Input()
  statusCollection!: ReservationStatusInfos[];

  @Input()
  countries!: { label: string; id: string }[] | undefined;

  @Output()
  filtersChange: EventEmitter<StatsForm> = new EventEmitter<StatsForm>();

  ngOnInit(): void {
    this.statsFormGroup.valueChanges
      .pipe(untilDestroyed(this), startWith(this.statsFormGroup.value))
      .subscribe((value) => {
        this.filtersChange.emit(value);
      });
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  declarations: [StatsFiltersComponent],
  exports: [StatsFiltersComponent],
})
export class StatsFiltersComponentModule {}
