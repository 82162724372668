import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { Role, User } from '@bhe/types';

export const userFeatureKey = 'user';

export interface State {
  infos?: User;
  roles?: Role[];
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,

  on(UserActions.loadUserSuccess, (state, { user, roles }) => {
    return {
      ...state,
      infos: user,
      roles,
    };
  })
);
