import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: 'bhe-ui-flag',
  template: `
    <span
      [class]="'fi-' + code | lowercase"
      class="country fi"
      [class.fis]="square"
    ></span>
    <ng-container *transloco="let t">
      <span [innerHTML]="t('countries.'+code)"></span>
    </ng-container>
  `,
  styleUrls: ['./flag.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagComponent {
  @Input()
  code: string | undefined;

  @Input()
  square = false;
}

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [FlagComponent],
  exports: [FlagComponent],
})
export class FlagComponentModule {}
