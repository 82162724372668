import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, HostListener, inject,
  Inject,
  NgModule,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule, DOCUMENT } from "@angular/common";
import { QuillModule } from "ngx-quill";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { ComponentStore } from "@ngrx/component-store";
import { debounceTime, filter, Observable, skip, take, tap, withLatestFrom } from "rxjs";
import { ROUTE_RESERVATION_ID } from "@bhe/router";
import "quill-emoji";
import { WINDOW } from "@ng-web-apis/common";

const localStorageMessagesKey = "bhe-messages";

@Component({
  selector: "bhe-message-box-input",
  template: `
    <div class="content">
      <quill-editor
        [modules]="modules"
        [formControl]="messageControl"
        [styles]="{ height: 'auto', maxHeight: '40vh', overflowY: 'auto' }"
      >
        <!--<div quill-editor-toolbar>
          <span class="ql-formats">
            <button class="ql-bold" [title]="'Bold'"></button>
            <button class="ql-italic" [title]="'Italic'"></button>
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
          </span>
        </div>-->
      </quill-editor>

      <button
        mat-mini-fab
        color="accent"
        class="send-btn"
        (click)="onSendMessage()"
      >
        <mat-icon svgIcon="icon-send-message"></mat-icon>
      </button>
    </div>
  `,
  styleUrls: ["./message-box-input.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBoxInputComponent extends ComponentStore<any> implements OnDestroy, AfterViewInit {

  private readonly document = inject(DOCUMENT);
  private readonly window = inject(WINDOW);

  protected messageControl: UntypedFormControl = new UntypedFormControl("");
  private allMessagesStoredMessages: { [reservation: string]: string } | undefined;

  modules = {
    "emoji-toolbar": {
      onOpen: () => {
        console.log("emoji");
      },
    },
    "toolbar": {
      container: [["emoji"], ["bold", "italic"], [{ "list": "ordered" }, { "list": "bullet" }]],
      handlers: {
        "emoji": () => {
          console.log("emoji");
        }
      }
    }
  };

  @HostListener('document:click', ['$event'])
  onClick(event: PointerEvent) {
    // Emit click event to the store
    console.log({event});
    if(this.isEmojiButton(event.target as HTMLElement) && this.window){
      const emojiPaletteHeight = 257;
      const windowHeight = this.window.innerHeight;
      const emojiPaletteBottomMargin = 60;
      const paletteTop = Math.min(event.clientY, windowHeight - emojiPaletteHeight - emojiPaletteBottomMargin );
      this.document.documentElement.style.setProperty('--emoji-palette-left', `${event.clientX}px`);
      this.document.documentElement.style.setProperty('--emoji-palette-top', `${paletteTop}px`);
    }
  }

  private observer!: ResizeObserver;

  private reservationId: string | undefined;

  @Output()
  sizeChanged: EventEmitter<{ width: number; height: number }> =
    new EventEmitter<{ width: number; height: number }>();

  @Output()
  sendMessage: EventEmitter<string> = new EventEmitter<string>();

  messageControlChanges = this.effect(() => {
    return this.messageControl.valueChanges.pipe(
      debounceTime(250),
      withLatestFrom(this.reservationId$),
      skip(1),
      tap(([value, reservationId]) => {
        console.log({ value }, { reservationId });
        if (this.allMessagesStoredMessages && localStorage) {
          this.allMessagesStoredMessages[reservationId] = value;
          localStorage.setItem(localStorageMessagesKey, JSON.stringify(this.allMessagesStoredMessages));
        }
      })
    );
  });

  reservationIdChange = this.effect(() => {
    return this.reservationId$.pipe(
      filter(reservationId => !!reservationId),
      take(1),
      tap((reservationId) => {
        this.reservationId = reservationId;
        if (reservationId) {
          if (localStorage) {
            if (!localStorage.getItem(localStorageMessagesKey)) {
              localStorage.setItem(localStorageMessagesKey, "{}");
            }
            this.allMessagesStoredMessages = JSON.parse(localStorage.getItem(localStorageMessagesKey) ?? "{}");
            if (this.allMessagesStoredMessages) {
              this.messageControl.setValue(this.allMessagesStoredMessages[reservationId]);
            }
          }
        }
      })
    );
  });

  constructor(
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    @Inject(ROUTE_RESERVATION_ID) private reservationId$: Observable<string>
  ) {
    super();
    this.observer = new ResizeObserver((entries) => {
      this.ngZone.run(() => {
        const { nativeElement } = this.el;
        const { offsetWidth, offsetHeight } = nativeElement;
        this.sizeChanged.emit({ width: offsetWidth, height: offsetHeight });
      });
    });

    this.observer.observe(this.el.nativeElement);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.observer) {
      this.observer.unobserve(this.el.nativeElement);
      this.observer.disconnect();
    }
  }

  onSendMessage() {
    this.sendMessage.emit(this.messageControl.value);
  }

  clearInput() {
    this.messageControl.reset("", { emitEvent: false });
    if (localStorage && this.reservationId) {
      if (this.allMessagesStoredMessages) {
        delete this.allMessagesStoredMessages[this.reservationId];
        localStorage.setItem(localStorageMessagesKey, JSON.stringify(this.allMessagesStoredMessages));
      }
    }
  }

  ngAfterViewInit(): void {
    const emojiButton = this.document.getElementsByClassName('ql-emoji').item(0);
    console.log('emojiButton', emojiButton);
  }

  private isEmojiButton(target: HTMLElement): boolean {
    if (target.tagName === 'BUTTON' && target.classList.contains('ql-emoji')) {
      return true;
    }
    // Check if the parent of the target is a button with the class "ql-emoji"
    const button = target.closest('button.ql-emoji');
    return !!button;
  }
}

@NgModule({
  imports: [
    CommonModule,
    QuillModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule
  ],
  declarations: [MessageBoxInputComponent],
  exports: [MessageBoxInputComponent]
})
export class MessageBoxInputComponentModule {
}
