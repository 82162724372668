import { filter, map, Observable } from 'rxjs';
import { Brand } from '@bhe/types';

export function getBrand(code: string) {
  // tslint:disable-next-line:only-arrow-functions
  return function <T>(source: Observable<Brand[]>) {
    return source.pipe(
      map((brands) => {
        return brands.find((b: any) => b.code === code);
      }),
      filter((brand) => !!brand)
    );
  };
}
