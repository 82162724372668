import { enableProdMode, ViewEncapsulation } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { VERSION_INFO } from "./environments/version";

if (environment.production) {
  enableProdMode();
}
//use process.env.npm_package_version
// https://github.com/getsentry/sentry-javascript/issues/3203#issuecomment-959823083
Sentry.init({
  enabled: environment.production,
  dsn: "https://df5c750d305a4432bebcbcc3ee491d38@o231483.ingest.sentry.io/1810270",
  release: VERSION_INFO.sentryRelease,
  ignoreErrors: ["ResizeObserver loop limit exceeded", "Non-Error exception captured", "ResizeObserver loop completed with undelivered notifications."],
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],
  normalizeDepth: 10,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2
});

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    defaultEncapsulation: ViewEncapsulation.Emulated
  })
  .catch((err) => console.error(err));
