import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RequestorsService, UserSearch, UserSearchComponentModule } from "@bhe/settings-feature";
import { RxState } from "@rx-angular/state";
import { User } from "@bhe/types";
import { LetModule } from "@rx-angular/template/let";
import { TranslocoModule } from "@ngneat/transloco";
import { BheButtonComponentModule, BheIconComponentModule, SoftButtonComponentModule } from "@bhe/ui";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "bhe-requestor-update",
  template: `
    <bhe-ui-bhe-icon icon="cross2" (click)="onClose()"></bhe-ui-bhe-icon>
    <ng-container *rxLet="vm$; let vm">
      <ng-container *transloco="let t">
        <bhe-pro-user-search [data]="inputInfos" (search)="onUserSearch($event)"></bhe-pro-user-search>
        <ng-container *ngIf="vm.foundUser as foundUser">
          <div class="b-user-found">
            <p [innerHTML]="t('reservation.replace-requestor.user-found.title', {user: foundUser.displayName})"></p>
            <bhe-ui-bhe-button variant="soft" (btnClick)="onSelectNewRequestor()" color="accent"><span [innerHTML]="t('actions.yes')"></span>
            </bhe-ui-bhe-button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ["./requestor-update.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class RequestorUpdateComponent {

  vm$ = this.state.select();

  inputInfos: UserSearch = {
    inputLabel: "New Requestor",
    placeholder: "e-mail",
    title: "Find new Requestor"
  };

  //sfraysse-nouvel@moethennessy.com

  constructor(
    private requestorsService: RequestorsService,
    private state: RxState<{ foundUser: User | undefined }>,
    private dialogRef: MatDialogRef<any, { userId: string }>
  ) {
    this.state.set({ foundUser: undefined });
  }

  onUserSearch(userMail: string) {
    this.requestorsService.searchUser(userMail).subscribe(foundUser => {
      this.state.set({ foundUser });
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onSelectNewRequestor() {
    const user = this.state.get("foundUser");
    if (user) {
      this.dialogRef.close({ userId: user.id });
    }
  }
}

@NgModule({
  imports: [CommonModule, UserSearchComponentModule, LetModule, TranslocoModule, SoftButtonComponentModule, MatIconModule, BheIconComponentModule, BheButtonComponentModule],
  declarations: [RequestorUpdateComponent],
  exports: [RequestorUpdateComponent]
})
export class RequestorUpdateComponentModule {
}
