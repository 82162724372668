import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBrand from './brand.reducer';
import { brandsFeatureKey } from './brand.reducer';

export const selectBrandState =
  createFeatureSelector<fromBrand.State>(brandsFeatureKey);

export const selectBrandEntities = createSelector(
  selectBrandState,
  fromBrand.selectBrandEntities
);
