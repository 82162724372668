import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AUTHENTICATED } from './auth.token';
import { State } from './auth.reducer';
import { Store } from '@ngrx/store';
import { logIn, logOut } from './auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    @Inject(AUTHENTICATED) public authenticated$: Observable<boolean>,
    private store: Store<State>
  ) {}

  logIn() {
    this.store.dispatch(logIn());
  }

  logOut() {
    this.store.dispatch(logOut());
  }
}
