import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject, InjectionToken,
  NgModule, Optional,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatExpansionModule } from "@angular/material/expansion";
import { FieldArrayType, FormlyModule } from "@ngx-formly/core";
import {
  AlertComponent,
  AlertComponentModule,
  BheButtonComponentModule,
  BheDialogComponent,
  BheIconComponentModule
} from "@bhe/ui";
import { ReservationFormService } from "../reservation-form/reservation-form.service";
import {
  BrandPipeModule,
  BRANDS,
  GuestExperiencePipeModule
} from "@bhe/vocabularies-data";
import {
  AddMaisonsDialogData,
  AlertDialogData,
  Brand,
  ReservationPart,
  ReservationPartForm
} from "@bhe/types";
import {
  FormEntityType,
  GetReservationPartFormPipe,
  GetReservationPartFormPipeModule,
  ReservationFormEntitiesService,
  ReservationService
} from "@bhe/reservation-data-access";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import {
  AddMaisonsComponent,
  AddMaisonsComponentModule
} from "../add-maisons/add-maisons.component";
import {
  combineLatest,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom
} from "rxjs";
import { uuid } from "@madeinlune/ngx-json-api";
import { HttpErrorResponse } from "@angular/common/http";
import { BrandPanelTitleComponentModule } from "@bhe/reservation-ui";
import { LetModule, PushModule } from "@ngrx/component";
import { CAN_UPDATE_MAISONS } from "@nx-bhe/bhe/reservation/tokens";

@Component({
  selector: "bhe-res-repeat-maison",
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngrxLet="reservationParts$; let reservationParts">
        <mat-accordion [multi]="true">
          <ng-template
            ngFor
            [ngForOf]="field.fieldGroup"
            let-field
            let-i="index"
            [ngForTrackBy]="identity"
          >
            <ng-container *ngIf="reservationParts?.[i] as reservationPartRef">
              <ng-container
                *ngrxLet="
                  reservationPartRef | getReservationPartForm;
                  let reservationPartForm
                "
              >
                <ng-container
                  *ngrxLet="reservationPartForm?.field_brand | brand; let brand"
                >
                  <mat-expansion-panel (expandedChange)="onExpandedChange()">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <bhe-res-brand-panel-title
                          [brand]="brand"
                          [guestExperience]="
                            reservationPartForm?.field_guest_experience
                              | guestExperience
                              | ngrxPush
                          "
                        ></bhe-res-brand-panel-title>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                      <formly-field
                        [field]="field"
                        class="root-reservation-part-form"
                      ></formly-field>
                      <ng-container *ngIf="!to.disabled">
                        <ng-container *ngrxLet="canUpdateMaisons$; let canUpdateMaisons">
                          <ng-container *ngIf="canUpdateMaisons">
                            <bhe-ui-bhe-button
                              class="remove-btn"
                              variant="soft"
                              icon="delete"
                              color="warn"
                              (btnClick)="onRemove(reservationPartForm, i, brand)"
                            >
                              <span [innerHTML]="t('actions.remove-maison')"></span>
                            </bhe-ui-bhe-button>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </mat-expansion-panel>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>
        </mat-accordion>
      </ng-container>
      <ng-container *ngIf="!to.disabled">
        <ng-container *ngrxLet="canUpdateMaisons$; let canUpdateMaisons">
          <ng-container *ngIf="canUpdateMaisons">
            <ng-container *ngrxLet="showAddButton$; let showAddButton">
              <ng-container *ngIf="showAddButton">
                <bhe-ui-bhe-button
                  variant="soft"
                  color="accent"
                  icon="add"
                  (click)="onAddMaisons()"
                >
                  <span [innerHTML]="t('actions.add-maisons')"></span>
                </bhe-ui-bhe-button>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ["./repeat-maison.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GetReservationPartFormPipe]
})
export class RepeatMaisonComponent extends FieldArrayType {
  reservationParts$ = this.reservationFormService.reservationParts$;

  showAddButton$: Observable<boolean> = combineLatest([
    this.reservationParts$,
    this.brands$
  ]).pipe(
    map(([reservationParts, brands]) => {
      return reservationParts.length < brands.length;
    })
  );

  constructor(
    public reservationService: ReservationService,
    public reservationFormService: ReservationFormService,
    public reservationFormEntitiesService: ReservationFormEntitiesService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,
    private transloco: TranslocoService,
    private getReservationPartFormPipe: GetReservationPartFormPipe,
    @Inject(BRANDS) public brands$: Observable<Brand[]>,
    @Optional() @Inject(CAN_UPDATE_MAISONS) protected canUpdateMaisons$: Observable<boolean>
  ) {
    super();
  }

  onExpandedChange() {
    this.cdr.detectChanges();
  }

  identity(index: number, item: any) {
    return index;
  }

  onRemove(
    reservationPart: ReservationPartForm | undefined,
    index: number,
    brand: Brand | undefined
  ) {
    if (reservationPart) {
      const removeBrandEmitter: EventEmitter<any> = new EventEmitter<any>();
      const bheDialogData: AlertDialogData = {
        message: this.transloco.translate(
          "alert.remove-reservation-part.message",
          { brand: brand?.name }
        ),
        process: null,
        actions: [
          {
            id: "yes",
            label: this.transloco.translate("actions.yes"),
            color: "primary",
            emitter: removeBrandEmitter
          },
          {
            id: "no",
            label: this.transloco.translate("actions.no"),
            color: "text",
            close: true
          }
        ]
      };
      const dialogRef: MatDialogRef<AlertComponent, "yes" | "no"> =
        this.matDialog.open(AlertComponent, {
          disableClose: false,
          panelClass: "alert",
          data: bheDialogData
        });

      const removeBrandEmitterSubscription = removeBrandEmitter
        .asObservable()
        .pipe(
          tap(() => {
            bheDialogData.process = "running";
          }),
          switchMap(() => {
            return this.reservationService.removeRelationshipsFieldMulti(
              this.form.value,
              "field_reservation_parts",
              { id: reservationPart.id, type: reservationPart.type }
            );
          })
        )
        .subscribe(
          (
            entities: { [type: string]: FormEntityType[] } | HttpErrorResponse
          ) => {
            if (!(entities instanceof HttpErrorResponse)) {
              Object.keys(entities).forEach((key) => {
                const typeData: FormEntityType[] = entities[key];
                if (typeData) {
                  typeData.forEach((data) => {
                    this.reservationFormEntitiesService.addEntity(
                      data,
                      "IN_SYNC"
                    );
                  });
                }
              });
              this.remove(index);
              dialogRef.close();
            } else {
              bheDialogData.process = "error";
            }
          }
        );

      dialogRef.afterClosed().subscribe((result) => {
        removeBrandEmitterSubscription.unsubscribe();
      });
    }
  }

  onAddMaisons() {
    this.reservationParts$
      .pipe(
        take(1),
        switchMap((reservationParts) => {
          if (reservationParts.length > 0) {
            return combineLatest(
              reservationParts.map((rp) => {
                return this.getReservationPartFormPipe
                  .transform(rp)
                  .pipe(take(1));
              })
            ).pipe(take(1));
          }
          return of([]);
        }),
        take(1)
      )
      .subscribe((existingParts) => {
        const addBrandsEmitter: EventEmitter<Brand[]> = new EventEmitter<Brand[]>();
        const dialogData: AddMaisonsDialogData = {
          component: AddMaisonsComponent,
          existingParts,
          addBrandsEmitter
        };

        const dialogRef: MatDialogRef<BheDialogComponent, Brand[] | undefined> =
          this.matDialog.open(BheDialogComponent, {
            disableClose: true,
            data: dialogData
          });

        const brandsToAdd$: Observable<Brand[]> =
          addBrandsEmitter.asObservable();
        const reservationPartsToAdd$: Observable<ReservationPartForm[]> =
          brandsToAdd$.pipe(
            map((brands) => {
              const reservationPartForms: ReservationPartForm[] = brands.map(
                (brand) => {
                  const { id, type } = brand;
                  const reservationPart: ReservationPartForm = {
                    id: uuid(),
                    type: ReservationPart.type,
                    field_brand: { id, type }
                  };
                  return reservationPart;
                }
              );
              return reservationPartForms;
            }),
            shareReplay(1)
          );
        const addBrandsEmitterSubscription = reservationPartsToAdd$
          .pipe(
            take(1),
            tap(() => {
              dialogData.process = "running";
            }),
            switchMap((reservationPartForms: ReservationPartForm[]) => {
              return this.reservationService.addRelationshipsFieldMulti(
                this.form.value,
                "field_reservation_parts",
                reservationPartForms
              );
            }),
            withLatestFrom(reservationPartsToAdd$)
          )
          .subscribe(
            ([entities, reservationPartsToAdd]: [
                { [type: string]: FormEntityType[] } | HttpErrorResponse,
              ReservationPartForm[]
            ]) => {
              if (!(entities instanceof HttpErrorResponse)) {
                Object.keys(entities).forEach((key) => {
                  const typeData: FormEntityType[] = entities[key];
                  if (typeData) {
                    typeData.forEach((data) => {
                      this.reservationFormEntitiesService.addEntity(
                        data,
                        "IN_SYNC"
                      );
                    });
                  }
                });
                reservationPartsToAdd.forEach((reservationPart) => {
                  this.add(
                    this.formControl.length,
                    {
                      id: reservationPart.id,
                      type: reservationPart.type
                    },
                    { markAsDirty: false }
                  );
                });
                this.formControl.updateValueAndValidity();
                dialogRef.close();
              } else {
                dialogData.process = "error";
              }
            }
          );

        dialogRef.afterClosed().subscribe(() => {
          addBrandsEmitterSubscription.unsubscribe();
        });
      });
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    FormlyModule,
    BheButtonComponentModule,
    BrandPipeModule,
    BheIconComponentModule,
    GetReservationPartFormPipeModule,
    AlertComponentModule,
    AddMaisonsComponentModule,
    TranslocoModule,
    BrandPanelTitleComponentModule,
    GuestExperiencePipeModule,
    PushModule,
    LetModule
  ],
  declarations: [RepeatMaisonComponent],
  exports: [RepeatMaisonComponent]
})
export class RepeatMaisonComponentModule {
}
