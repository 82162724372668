import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthState } from './bhe-authentication.module';

export const AUTH_BACKEND_URL = new InjectionToken<string>('AUTH_BACKEND_URL');

export const AUTH_CLIENT_ID = new InjectionToken<string>('AUTH_CLIENT_ID');

export const AUTH_SECRET_ID = new InjectionToken<string>('AUTH_SECRET_ID');

export const JSON_API_END_POINT = new InjectionToken<string>(
  'JSON_API_END_POINT'
);

export const AUTHENTICATED = new InjectionToken<Observable<boolean | null>>(
  'AUTHENTICATED'
);

export const AUTHENTICATION_READY = new InjectionToken<Observable<boolean>>(
  'AUTHENTICATION_READY'
);

export const AUTHENTICATION_ERROR = new InjectionToken<Observable<boolean>>(
  'AUTHENTICATION_ERROR'
);

export const AUTHENTICATION_STATE = new InjectionToken<Observable<AuthState>>(
  'AUTHENTICATION_STATE'
);

export const AUTHENTICATION_USER_ID = new InjectionToken<Observable<string>>(
  'AUTHENTICATION_USER_ID'
);
