import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { Guest } from "@bhe/types";
import { OverviewFieldWrapperComponentModule } from "../overview-field-wrapper/overview-field-wrapper.component";
import { FlagComponentModule } from "@bhe/ui";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: "bhe-res-overview-guest",
  template: `
    <ng-container *ngIf="guest">
      <ng-container *transloco="let t">
        <bhe-res-overview-field-wrapper [title]="mainLabel">
          <strong [innerHTML]="guest.fullName"></strong>
        </bhe-res-overview-field-wrapper>
        <bhe-res-overview-field-wrapper title="Mail">
          <span [innerHTML]="guest.mail ?? '--'"></span>
        </bhe-res-overview-field-wrapper>
        <bhe-res-overview-field-wrapper title="Company">
          <span [innerHTML]="guest.company ?? '--'"></span>
        </bhe-res-overview-field-wrapper>
        <bhe-res-overview-field-wrapper title="Job Title">
          <span [innerHTML]="guest.jobTitle ?? '--'"></span>
        </bhe-res-overview-field-wrapper>
        <bhe-res-overview-field-wrapper title="Country">
          <ng-container *ngIf="guest.country as country">
            <div class="b-country">
              <bhe-ui-flag [code]="country"></bhe-ui-flag>
            </div>
          </ng-container>
          <ng-container *ngIf="!guest.country">
            <span [innerHTML]="'--'"></span>
          </ng-container>
        </bhe-res-overview-field-wrapper>
        <bhe-res-overview-field-wrapper title="Phone">
          <span [innerHTML]="guest.phone ?? '--'"></span>
        </bhe-res-overview-field-wrapper>
      </ng-container>
    </ng-container>
  `,

  styleUrls: ["./overview-guest.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewGuestComponent {
  @Input()
  mainLabel!: string;

  @Input()
  guest!: Guest|undefined;
}

@NgModule({
  imports: [
    CommonModule,
    OverviewFieldWrapperComponentModule,
    FlagComponentModule,
    TranslocoModule
  ],
  declarations: [OverviewGuestComponent],
  exports: [OverviewGuestComponent]
})
export class OverviewGuestComponentModule {
}
