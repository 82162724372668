import { Expose, Transform } from 'class-transformer';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { NodeMeta } from '../drupal-node.model';

export class GuestForm implements ResourceIdentifier {
  @Expose()
  type!: string;

  @Expose()
  id!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_full_name;
  })
  @Expose()
  field_full_name!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_company;
  })
  @Expose()
  field_company!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_country;
  })
  @Expose()
  field_country!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_email;
  })
  @Expose()
  field_email!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_first_name;
  })
  @Expose()
  field_first_name!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_last_name;
  })
  @Expose()
  field_last_name!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_job_title;
  })
  @Expose()
  field_job_title!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_phone;
  })
  @Expose()
  field_phone!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_sex;
  })
  @Expose()
  field_sex!: number;

  @Transform(({ obj }) => {
    return obj?.meta;
  })
  @Expose()
  meta!: NodeMeta;
}
