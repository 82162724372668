import { Inject, NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { State } from './file.reducer';
import { select, Store } from '@ngrx/store';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { BheConfig, DrupalFile } from '@bhe/types';
import { selectFile } from './file.selectors';
import { map, Observable } from 'rxjs';
import { APP_CONFIG } from '@madeinlune/ngx-app-config';

@Pipe({
  name: 'getFile',
})
export class GetFilePipe implements PipeTransform {
  constructor(
    @Inject(APP_CONFIG) private appConfig: BheConfig,
    private store: Store<State>
  ) {}

  transform(value: ResourceIdentifier): Observable<DrupalFile | undefined> {
    return this.store.pipe(
      select(selectFile(value.id)),
      map((file) => {
        if (file) {
          file = {
            ...file,
            url: `${this.appConfig.backendUrl}/${file.url}`,
          };
          return file;
        }
        return undefined;
      })
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [GetFilePipe],
  exports: [GetFilePipe],
})
export class GetFilePipeModule {}
