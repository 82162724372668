import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { GuestExperience } from '@bhe/types';

export const loadGuestExperiences = createAction(
  '[GuestExperience/API] Load GuestExperiences',
  props<{ guestExperiences: GuestExperience[] }>()
);

export const addGuestExperience = createAction(
  '[GuestExperience/API] Add GuestExperience',
  props<{ guestExperience: GuestExperience }>()
);

export const upsertGuestExperience = createAction(
  '[GuestExperience/API] Upsert GuestExperience',
  props<{ guestExperience: GuestExperience }>()
);

export const addGuestExperiences = createAction(
  '[GuestExperience/API] Add GuestExperiences',
  props<{ guestExperiences: GuestExperience[] }>()
);

export const upsertGuestExperiences = createAction(
  '[GuestExperience/API] Upsert GuestExperiences',
  props<{ guestExperiences: GuestExperience[] }>()
);

export const updateGuestExperience = createAction(
  '[GuestExperience/API] Update GuestExperience',
  props<{ guestExperience: Update<GuestExperience> }>()
);

export const updateGuestExperiences = createAction(
  '[GuestExperience/API] Update GuestExperiences',
  props<{ guestExperiences: Update<GuestExperience>[] }>()
);

export const deleteGuestExperience = createAction(
  '[GuestExperience/API] Delete GuestExperience',
  props<{ id: string }>()
);

export const deleteGuestExperiences = createAction(
  '[GuestExperience/API] Delete GuestExperiences',
  props<{ ids: string[] }>()
);

export const clearGuestExperiences = createAction(
  '[GuestExperience/API] Clear GuestExperiences'
);
