import {ChangeDetectionStrategy, Component, Input, NgModule, ViewEncapsulation,} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'bhe-pro-settings-block',
  template: `
    <h3 class="title" [innerHTML]="title"></h3>
    <ng-container *ngIf="description">
      <div [innerHTML]="description"></div>
    </ng-container>
  `,
  styleUrls: ['./settings-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsBlockComponent {
  @Input()
  title!: string;

  @Input()
  description!: string;
}

@NgModule({
  imports: [CommonModule],
  declarations: [SettingsBlockComponent],
  exports: [SettingsBlockComponent],
})
export class SettingsBlockComponentModule {}
