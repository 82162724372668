import { ChangeDetectionStrategy, Component, Inject, NgModule, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AddMaisonsDialogData, Brand, ProcessUi } from "@bhe/types";
import { map, Observable, take } from "rxjs";
import { BRANDS } from "@bhe/vocabularies-data";
import { LetModule } from "@ngrx/component";
import { BheButtonComponentModule, BheDialogComponent, IconLoadingComponentModule, ProcessComponentModule } from "@bhe/ui";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: 'bhe-add-maisons',
  template: `
    <ng-container *transloco="let t">
      <div class="b-content" [class.process]="!!process">
        <ng-container *ngrxLet="missingBrands$; let missingBrands">
          <ng-container *ngIf="missingBrands">
            <ng-container *ngrxLet="form$; let form">
              <form [formGroup]="form">
                <ng-template ngFor [ngForOf]="missingBrands" let-brand>
                  <mat-checkbox [formControlName]="brand.id">
                    {{ brand.name }}
                  </mat-checkbox>
                </ng-template>
              </form>
              <bhe-ui-bhe-button (btnClick)="onAdd(form)"
                ><span [innerHTML]="t('actions.add-maisons')"></span
              ></bhe-ui-bhe-button>
              <bhe-ui-bhe-button (btnClick)="onCancel()"
                ><span [innerHTML]="t('actions.cancel')"></span
              ></bhe-ui-bhe-button>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="process">
        <bhe-ui-process class="b-process" [state]="process">
          <ng-template bheUiProcessRunning>
            <bhe-ui-icon-loading></bhe-ui-icon-loading>
            <p [innerHTML]="t('process.add-maison.running')"></p>
          </ng-template>
          <ng-template bheUiProcessError>
            <div class="b-process__message">
              <p [innerHTML]="t('process.add-maison.error')"></p>
            </div>
            <div class="b-process__actions">
              <bhe-ui-bhe-button variant="primary" (btnClick)="close('error')"
                ><span [innerHTML]="t('actions.ok')"></span
              ></bhe-ui-bhe-button>
            </div>
          </ng-template>
        </bhe-ui-process>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./add-maisons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMaisonsComponent {
  missingBrands$: Observable<Brand[]> = this.brands$.pipe(
    map((brands) => {
      const existingBrands: (string | undefined)[] =
        this.data.existingParts.map((rp) => rp?.field_brand?.id);
      const missingBrands: Brand[] = [];
      brands.forEach((brand) => {
        if (existingBrands.indexOf(brand.id) === -1) {
          missingBrands.push(brand);
        }
      });
      return missingBrands;
    })
  );

  form$: Observable<UntypedFormGroup> = this.missingBrands$.pipe(
    map((brands) => {
      const controls: any = {};
      brands.forEach((br) => {
        controls[br.id] = new UntypedFormControl(false);
      });
      return new UntypedFormGroup(controls);
    })
  );

  constructor(
    public dialogRef: MatDialogRef<BheDialogComponent>,
    @Inject(BRANDS) private brands$: Observable<Brand[]>,
    @Inject(MAT_DIALOG_DATA) private data: AddMaisonsDialogData
  ) {}

  onAdd(form: UntypedFormGroup) {
    this.brands$.pipe(take(1)).subscribe((brands) => {
      const selectedBrands: Brand[] = brands.filter((brand) => {
        return form.value[brand.id];
      });
      this.data.addBrandsEmitter.emit(selectedBrands);
    });
  }

  onCancel() {
    this.close(undefined);
  }

  close(id: string | undefined) {
    this.dialogRef.close(id);
  }

  get process(): ProcessUi | undefined | null {
    return this.data.process;
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    BheButtonComponentModule,
    TranslocoModule,
    ProcessComponentModule,
    IconLoadingComponentModule,
    LetModule
  ],
  declarations: [AddMaisonsComponent],
  exports: [AddMaisonsComponent],
})
export class AddMaisonsComponentModule {}
