import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { BheDialogService } from "@bhe/ui";

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {

  bheDialogService = inject(BheDialogService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse && event.status === 204) {
          // Interrupt the flow by throwing an error or completing the observable
          throw new HttpErrorResponse({ status: 204, statusText: "No Content" });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handle the error if needed, otherwise rethrow
        if (error.status === 204) {
          const bheAlertData = BheDialogService.buildAlertDialogData(
            "We are currently in maintenance,<br>please try again later.",
            [
              { id: "ok", close: true, label: "OK", color: "accent" }
            ]
          );
          this.bheDialogService.openAlert(bheAlertData);
        }
        return throwError(() => error);
      })
    );
  }
}
