import { createReducer, on } from '@ngrx/store';
import {
  applicationReady,
  setMenuMain,
  setMenuSecondary,
} from './core.actions';
import { Menu } from '@bhe/types';

export const coreFeatureKey = 'core';

export interface State {
  mainMenu?: Menu;
  secondaryMenu?: Menu;
  ready: boolean;
}

export const initialState: State = {
  ready: false,
};

export const reducer = createReducer(
  initialState,
  on(setMenuMain, (state, { mainMenu }) => ({
    ...state,
    mainMenu,
  })),
  on(setMenuSecondary, (state, { secondaryMenu }) => ({
    ...state,
    secondaryMenu,
  })),
  on(applicationReady, (state) => ({
    ...state,
    ready: true,
  }))
);
