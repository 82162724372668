import { UrlHelperService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';

@Injectable()
export class OauthUrlHelperService extends UrlHelperService {
  override getHashFragmentParams(customHashFragment?: string): object {
    const result: any = super.getHashFragmentParams(customHashFragment);
    if (!result['id_token'] && !!result['access_token']) {
      result['id_token'] = result['access_token'];
    }
    return result;
  }
}
