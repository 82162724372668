import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { select, Store, StoreModule } from '@ngrx/store';
import * as fromUser from './user.reducer';
import { State } from './user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './user.effects';
import { Role, User } from '@bhe/types';
import { getUserInfos, getUserRoles } from './user.selectors';
import { map, Observable, withLatestFrom } from 'rxjs';

export const USER_INFOS = new InjectionToken<User>('USER_INFOS');

export const USER_DISPLAY_NAME = new InjectionToken<string>(
  'USER_DISPLAY_NAME'
);

export const USER_UUID = new InjectionToken<string>('Drupal User UUID');

export const USER_ID = new InjectionToken<string>('Drupal User Id');

export const USER_ROLES = new InjectionToken<Role[]>('USER_ROLES');

export const USER_IS_ADMIN = new InjectionToken<boolean>('USER_IS_ADMIN');

export const USER_IS_BH_TEAM = new InjectionToken<boolean>('USER_IS_BH_TEAM');

export const USER_IS_SETTINGS_MANAGER = new InjectionToken<boolean>(
  'USER_IS_SETTINGS_MANAGER'
);

export const USER_FIRST_NAME = new InjectionToken<boolean>('USER_FIRST_NAME');

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.reducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [
    {
      provide: USER_INFOS,
      deps: [Store],
      useFactory: (store: Store<State>) => {
        return store.pipe(select(getUserInfos));
      },
    },
    {
      provide: USER_ROLES,
      deps: [Store],
      useFactory: (store: Store<State>) => {
        return store.pipe(select(getUserRoles));
      },
    },
    {
      provide: USER_DISPLAY_NAME,
      deps: [USER_INFOS],
      useFactory: (userInfos$: Observable<User>) => {
        return userInfos$.pipe(
          map((user) => {
            return user?.displayName;
          })
        );
      },
    },
    {
      provide: USER_FIRST_NAME,
      deps: [USER_INFOS],
      useFactory: (userInfos$: Observable<User>) => {
        return userInfos$.pipe(
          map((user) => {
            return user?.firstName || user?.displayName;
          })
        );
      },
    },
    {
      provide: USER_UUID,
      deps: [USER_INFOS],
      useFactory: (userInfos$: Observable<User>) => {
        return userInfos$.pipe(
          map((user) => {
            return user?.id;
          })
        );
      },
    },
    {
      provide: USER_ID,
      deps: [USER_INFOS],
      useFactory: (userInfos$: Observable<User>) => {
        return userInfos$.pipe(
          map((user) => {
            return user?.uid;
          })
        );
      },
    },
    {
      provide: USER_IS_ADMIN,
      deps: [USER_ROLES],
      useFactory: (userRoles$: Observable<Role[]>) => {
        return userRoles$.pipe(
          map((roles) => {
            return roles?.find((role) => role.internalId === 'administrator');
          })
        );
      },
    },
    {
      provide: USER_IS_BH_TEAM,
      deps: [USER_ROLES, USER_IS_ADMIN],
      useFactory: (
        userRoles$: Observable<Role[]>,
        isAdmin$: Observable<boolean>
      ) => {
        return userRoles$.pipe(
          withLatestFrom(isAdmin$),
          map(([roles, isAdmin]) => {
            return !!(
              roles?.find((role) => role.internalId === 'bh_team') || isAdmin
            );
          })
        );
      },
    },
    {
      provide: USER_IS_SETTINGS_MANAGER,
      deps: [USER_ROLES, USER_IS_ADMIN],
      useFactory: (
        userRoles$: Observable<Role[]>,
        isAdmin$: Observable<boolean>
      ) => {
        return userRoles$.pipe(
          withLatestFrom(isAdmin$),
          map(([roles, isAdmin]) => {
            return (
              roles?.find((role) => role.internalId === 'setting_manager') ||
              isAdmin
            );
          })
        );
      },
    },
  ],
})
export class BheUserDataAccessModule {}
