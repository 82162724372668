import { DrupalCommon, DrupalIdentifier } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';

export class UserRole extends DrupalIdentifier {}

export class User extends DrupalCommon {
  static type = 'user--user';

  @Transform(({ obj }) => {
    return obj?.attributes?.drupal_internal__uid;
  })
  @Expose()
  uid!: string;

  @Transform(({ obj }) => {
    return (
      obj?.attributes?.display_name || obj?.attributes?.field_user_full_name
    );
  })
  @Expose()
  displayName!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_user_firstname;
  })
  @Expose()
  firstName!: string|undefined;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_user_lastname;
  })
  @Expose()
  lastName!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.mail;
  })
  @Expose()
  mail!: string;
}
