import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  NgModule,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bhe-ui-bhe-icon',
  template: ` <mat-icon [svgIcon]="'icon-' + icon"></mat-icon> `,
  styleUrls: ['./bhe-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BheIconComponent {
  @Input()
  icon!: string;
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [BheIconComponent],
  exports: [BheIconComponent],
})
export class BheIconComponentModule {}
