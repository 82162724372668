import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { Expose, Transform } from 'class-transformer';

export class CorporatePageForm implements ResourceIdentifier {
  @Expose()
  type!: string;

  @Expose()
  id!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.changed;
  })
  @Expose()
  changed!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.title;
  })
  @Expose()
  title!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_brand_description?.value;
  })
  @Expose()
  field_brand_description!: string;
}
