import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  template: '',
})
export abstract class AbstractMatListComponent<T, Template> {
  @Input()
  items: T[] = [];

  @Input()
  itemTemplate!: Template;

  @Output()
  selected: EventEmitter<T> = new EventEmitter<T>();

  @Input()
  selectedItem: T | null = null;
}
