import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgModule,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationStatusInfos, ReservationWorkflow } from '@bhe/types';
import { WorkflowChoicesPipeModule } from '@bhe/reservation-data-access';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { ReservationWorkflowIconComponentModule } from '@bhe/ui';
import { RESERVATION_STATUS_INFOS } from '@bhe/shell-state';

@Component({
  selector: 'bhe-res-workflow-select',
  template: `
    <mat-select
      #select
      panelClass="overview-workflow-select"
      [value]="workflow"
      [compareWith]="compareWith"
      (selectionChange)="onSelectionChanged($event, select)"
    >
      <mat-select-trigger>
        <bhe-ui-reservation-workflow-icon
          [key]="workflow"
        ></bhe-ui-reservation-workflow-icon>
        <ng-container *ngIf="statusInfosMap.get(workflow) as statusInfo">
          <h3
            [innerHTML]="
              statusInfo.key === 'wns' ? statusInfo.key : statusInfo.label
            "
          ></h3>
        </ng-container>
      </mat-select-trigger>
      <ng-template
        ngFor
        [ngForOf]="workflow | workflowChoices"
        let-workflowChoice
      >
        <ng-container *ngIf="statusInfosMap.get(workflowChoice) as statusInfo">
          <mat-option [value]="statusInfo.key">
            <bhe-ui-reservation-workflow-icon
              [key]="statusInfo.key"
              [size]="'small'"
            ></bhe-ui-reservation-workflow-icon>
            {{ statusInfo.label }}
          </mat-option>
        </ng-container>
      </ng-template>
    </mat-select>
  `,
  styleUrls: ['./workflow-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowSelectComponent {
  @Output()
  workflowChanged: EventEmitter<ReservationWorkflow> = new EventEmitter<ReservationWorkflow>();

  @Input()
  workflow!: ReservationWorkflow;

  constructor(
    @Inject(RESERVATION_STATUS_INFOS)
    public statusInfosMap: Map<ReservationWorkflow, ReservationStatusInfos>
  ) {}

  compareWith(o1: string, o2: string): boolean {
    return o1 === o2;
  }

  onSelectionChanged($event: MatSelectChange, select: MatSelect) {
    select.value = this.workflow;
    this.workflowChanged.emit($event.value);
  }
}

@NgModule({
  imports: [
    CommonModule,
    WorkflowChoicesPipeModule,
    ReservationWorkflowIconComponentModule,
    MatSelectModule,
  ],
  declarations: [WorkflowSelectComponent],
  exports: [WorkflowSelectComponent],
})
export class WorkflowSelectComponentModule {}
