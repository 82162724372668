import { FormlyFieldConfig } from "@ngx-formly/core";


export function brandCorporateInformationFields(): FormlyFieldConfig[] {
  return [
    {
      key: 'id',
    },
    {
      key: 'type',
    },
    {
      key: 'title',
      templateOptions: {
        label: 'Title',
      },
      type: 'input',
    },
    {
      key: 'field_brand_description',
      templateOptions: {
        label: 'Description',
      },
      type: 'textArea',
    },
  ];
}
