import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BheButtonComponentModule } from '@bhe/ui';

export interface UserSearch {
  title: string;
  placeholder: string|undefined;
  inputLabel: string;
}

@Component({
  selector: 'bhe-pro-user-search',
  template: `
    <ng-container *ngIf="data">
      <h2 [innerHTML]="data.title"></h2>
      <div class="b-input-btn">
        <mat-form-field class="mr-3">
          <mat-label>{{ data.inputLabel }}</mat-label>
          <input
            [formControl]="searchInputFormItem"
            matInput
            [placeholder]="data.placeholder ?? ''"
            #requestorMailInput
          />
        </mat-form-field>
        <bhe-ui-bhe-button
          variant="soft"
          color="primary"
          [disabled]="!searchInputFormItem.valid"
          (btnClick)="search.emit(searchInputFormItem.value)"
        >
          <span>Search</span>
        </bhe-ui-bhe-button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./user-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSearchComponent {

  searchInputFormItem: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  @Input()
  data!: UserSearch;

  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();
}

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    BheButtonComponentModule,
  ],
  declarations: [UserSearchComponent],
  exports: [UserSearchComponent],
})
export class UserSearchComponentModule {}
