import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ROUTE_CORPORATE_PAGE_ID,
  ROUTE_IS_BHE_PRO_HOME,
  ROUTE_RESERVATION_CONTEXT,
  ROUTE_RESERVATION_ID,
  ROUTE_SEARCH_TEXT,
  ROUTE_SETTINGS_EDIT_CONTENT_TYPE,
  ROUTE_SETTINGS_EDIT_HOME_CONTENTS,
  ROUTE_SETTINGS_USER_FILTER_TYPE,
  ROUTER_URL,
} from './bhe-router.tokens';
import { RouterReducerState } from '@ngrx/router-store';
import { select, State, Store } from '@ngrx/store';
import {
  selectCorporatePageId,
  selectRouteIsBheProHome,
  selectRouteReservationContext,
  selectRouteReservationId,
  selectRouteSettingsEditContentType,
  selectRouteSettingsUserFilterType,
  selectRouteUrl,
  selectSearchText,
} from './bhe-router.selectors';
import { APPLICATION_READY } from '@bhe/shell-state';
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  withLatestFrom,
} from 'rxjs';
import {
  ApproversFilter,
  BhTeamFilter,
  EditContentType,
  GeoZone,
  ObserversFilter,
  UserFilterType,
} from '@bhe/types';
import { Resource } from '@madeinlune/ngx-json-api';

export interface SettingsHomeParams {
  title: string;
  description: string;
  creationLabel?: string;
  creationObject?: Resource;
}

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: ROUTE_RESERVATION_ID,
      deps: [Store, APPLICATION_READY],
      useFactory: (
        store: State<RouterReducerState>,
        applicationReady$: Observable<boolean>
      ) => {
        return store.pipe(
          withLatestFrom(applicationReady$),
          filter(([store, applicationReady]) => !!applicationReady),
          map(([store, applicationReady]) => store),
          distinctUntilChanged(),
          select(selectRouteReservationId)
        );
      },
    },
    {
      provide: ROUTE_CORPORATE_PAGE_ID,
      deps: [Store, APPLICATION_READY],
      useFactory: (
        store: State<RouterReducerState>,
        applicationReady$: Observable<boolean>
      ) => {
        return store.pipe(
          withLatestFrom(applicationReady$),
          filter(([store, applicationReady]) => !!applicationReady),
          map(([store, applicationReady]) => store),
          distinctUntilChanged(),
          select(selectCorporatePageId)
        );
      },
    },
    {
      provide: ROUTER_URL,
      deps: [Store],
      useFactory: (store: State<RouterReducerState>) => {
        return store.pipe(select(selectRouteUrl));
      },
    },
    {
      provide: ROUTE_SEARCH_TEXT,
      deps: [Store],
      useFactory: (store: State<RouterReducerState>) => {
        return store.pipe(select(selectSearchText));
      },
    },
    {
      provide: ROUTE_RESERVATION_CONTEXT,
      deps: [Store],
      useFactory: (store: State<RouterReducerState>) => {
        return store.pipe(select(selectRouteReservationContext));
      },
    },
    {
      provide: ROUTE_IS_BHE_PRO_HOME,
      deps: [Store],
      useFactory: (store: State<RouterReducerState>) => {
        return store.pipe(select(selectRouteIsBheProHome));
      },
    },
    {
      provide: ROUTE_SETTINGS_USER_FILTER_TYPE,
      deps: [Store],
      useFactory: (
        store: State<RouterReducerState>
      ): Observable<UserFilterType | null> => {
        return store.pipe(
          select(selectRouteSettingsUserFilterType),
          map((userFilterType) => {
            if (userFilterType === 'observers') {
              return 'node--observer_filter';
            } else if (userFilterType === 'approvers') {
              return 'node--approver_filter';
            } else if (userFilterType === 'bh-team') {
              return 'node--team_filter';
            }
            return null;
          })
        );
      },
    },
    {
      provide: ROUTE_SETTINGS_EDIT_CONTENT_TYPE,
      deps: [Store],
      useFactory: (
        store: State<RouterReducerState>
      ): Observable<EditContentType | null> => {
        return store.pipe(
          select(selectRouteSettingsEditContentType),
          map((editContentTypePath): EditContentType | null => {
            if (editContentTypePath === 'guest-types') {
              return 'taxonomy_term--guest_type';
            } else if (editContentTypePath === 'brand-pages') {
              return 'node--brand_corporate_information';
            } else if (editContentTypePath === 'brand-bhe-infos') {
              return 'taxonomy_term--brand';
            } else if (editContentTypePath === 'guest-experiences') {
              return 'taxonomy_term--guest_experience';
            } else if (editContentTypePath === 'regions') {
              return 'node--geo_zone';
            } else if (editContentTypePath === 'observers') {
              return 'node--observer_filter';
            } else if (editContentTypePath === 'approvers') {
              return 'node--approver_filter';
            } else if (editContentTypePath === 'bh-team') {
              return 'node--team_filter';
            }
            return null;
          })
        );
      },
    },
    {
      provide: ROUTE_SETTINGS_EDIT_HOME_CONTENTS,
      deps: [Store],
      useFactory: (
        store: State<RouterReducerState>
      ): Observable<SettingsHomeParams | null> => {
        return store.pipe(
          select(selectRouteSettingsEditContentType),
          map((editContentTypePath): SettingsHomeParams | null => {
            if (editContentTypePath === 'guest-types') {
              return {
                title: 'bhe-pro.settings.guest-types.title',
                description: 'bhe-pro.settings.guest-types.description',
              };
            } else if (editContentTypePath === 'brand-pages') {
              return {
                title: 'bhe-pro.settings.brand-pages.title',
                description: 'bhe-pro.settings.brand-pages.description',
              };
            } else if (editContentTypePath === 'brand-bhe-infos') {
              return {
                title: 'bhe-pro.settings.brand-bhe-infos.title',
                description: 'bhe-pro.settings.brand-bhe-infos.description',
              };
            } else if (editContentTypePath === 'guest-experiences') {
              return {
                title: 'bhe-pro.settings.guest-experiences.title',
                description: 'bhe-pro.settings.guest-experiences.description',
              };
            } else if (editContentTypePath === 'regions') {
              return {
                title: 'bhe-pro.settings.region-builder.title',
                description: 'bhe-pro.settings.region-builder.description',
                creationLabel: 'bhe-pro.settings.region-builder.create',
                creationObject: {
                  id: 'to be defined',
                  type: GeoZone.type,
                  attributes: {
                    title: 'New GeoZone',
                  },
                },
              };
            } else if (editContentTypePath === 'observers') {
              return {
                title: 'bhe-pro.settings.observers.title',
                description: 'bhe-pro.settings.observers.description',
                creationLabel: 'bhe-pro.settings.observers.create',
                creationObject: {
                  id: 'to be defined',
                  type: ObserversFilter.type,
                  attributes: {
                    title: 'New Observer Filter',
                  },
                },
              };
            } else if (editContentTypePath === 'approvers') {
              return {
                title: 'bhe-pro.settings.approvers.title',
                description: 'bhe-pro.settings.approvers.description',
                creationLabel: 'bhe-pro.settings.approvers.create',
                creationObject: {
                  id: 'to be defined',
                  type: ApproversFilter.type,
                  attributes: {
                    title: 'New Approver Filter',
                  },
                },
              };
            } else if (editContentTypePath === 'bh-team') {
              return {
                title: 'bhe-pro.settings.bh-team.title',
                description: 'bhe-pro.settings.bh-team.description',
                creationLabel: 'bhe-pro.settings.bh-team.create',
                creationObject: {
                  id: 'to be defined',
                  type: BhTeamFilter.type,
                  attributes: {
                    title: 'New BH Team Filter',
                  },
                },
              };
            }
            return null;
          })
        );
      },
    },
  ],
})
export class BheRouterModule {}
