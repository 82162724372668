import { Inject, Injectable } from "@angular/core";
import {
  NgxJsonApi,
  QueryParams,
  Resource,
  ResourceIdentifier
} from "@madeinlune/ngx-json-api";
import {
  catchError, finalize,
  map,
  Observable,
  of, retry,
  switchMap, take,
  tap,
  throwError,
  withLatestFrom
} from "rxjs";
import {
  ApprobationConfirmation,
  BheConfig,
  Guest,
  GuestType,
  JsonApiOperation,
  JsonApiOperationType,
  MhProfile,
  Reservation,
  ReservationForm,
  ReservationPart,
  ReservationWorkflow,
  User
} from "@bhe/types";
import { bheClasses, jsonApiResources } from "@nx-agency/bhe/operators";
import { APP_CONFIG } from "@madeinlune/ngx-app-config";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from "@angular/common/http";
import { State } from "./reservation.reducer";
import { Store } from "@ngrx/store";
import { loadReservation, loadReservationSuccess } from "./reservation.actions";
import {
  FormEntity,
  FormEntityType
} from "./reservation-form-entities.service";
import { ENTITIES_RELATIONS_SHIPS, RESERVATION } from "./reservation.tokens";
import { diff } from "deep-object-diff";
import { GUEST_TYPE_PRESS_UUID } from "@bhe/vocabularies-data";
import { buildResourceFromEntity } from "@bhe/utils";
import { BheDialogService } from "@bhe/ui";

const reservationQueryParams: QueryParams = {
  fields: {
    [User.type]: ["display_name"],
    [GuestType.type]: ["machine_name", "name"],
    [Guest.type]: [
      "field_full_name",
      "field_company",
      "field_country",
      "field_email",
      "field_first_name",
      "field_last_name",
      "field_job_title",
      "field_phone",
      "field_sex"
    ],
    [MhProfile.type]: [
      "title",
      "field_ad_profile_first_name",
      "field_ad_profile_last_name",
      "field_ad_profile_email",
      "field_ad_profile_area_service",
      "field_ad_profile_entity",
      "field_ad_profile_department",
      "field_ad_profile_position"
    ],
    [ReservationPart.type]: [
      "field_approval_deadline",
      "field_bottles_sold",
      "field_bottles_sold_other",
      "field_contact_info",
      "field_contact_phone",
      "field_dress_code",
      "field_other_remarks",
      "field_program_description_long",
      "field_program_description_short",
      "field_recommendations",
      "field_transport_details",
      "field_transport_dropoff",
      "field_transport_needed",
      "field_transport_pickup",
      "field_visit_theme",
      "field_workflow_reservation_part",
      "field_approval_approver",
      "field_approvers",
      "field_brand",
      "field_guest_experience"
    ]
  },
  include: [
    "field_guest_type",
    "field_invited_by",
    "field_main_guest",
    "field_mh_accompanying_people",
    "field_reservation_parts.field_approvers",
    "field_reservation_parts.field_approval_approver",
    "uid",
    "field_reservation_guests"
  ]
};

@Injectable({
  providedIn: "root"
})
export class ReservationService {

  saving = false;

  static getUpdateReservationOperation(
    reservation: ResourceIdentifier
  ): JsonApiOperation {
    const { id, type } = reservation;
    return {
      op: "update",
      data: {
        id,
        type,
        meta: {
          appUpdate: Math.random() * 0xffffff
        }
      },
      ref: {
        id,
        type
      }
    };
  }

  constructor(
    private ngxJsonApi: NgxJsonApi,
    @Inject(APP_CONFIG) private appConfig: BheConfig,
    @Inject(ENTITIES_RELATIONS_SHIPS)
    private entitiesRelationships: Map<string, string[]>,
    @Inject(GUEST_TYPE_PRESS_UUID)
    private guestTypePressId$: Observable<string>,
    @Inject(RESERVATION)
    private reservation$: Observable<Reservation>,
    private httpClient: HttpClient,
    private store: Store<State>,
    private bheDialogService: BheDialogService
  ) {
  }

  loadReservation(id: string): Observable<Reservation> {
    return this.ngxJsonApi
      .find({
        type: Reservation.type,
        id,
        params: reservationQueryParams
      })
      .pipe(
        tap(result => {
          const omittedWarning = (result.meta as any)?.omitted;
          if (omittedWarning) {
            const alertData = BheDialogService.buildAlertDialogData(
              "Sorry, we encountered a problem while loading this reservation.<br>An automatic operation is being triggered.<br><br><small>Please come back in 5 minutes.</small>",
              [
                {
                  label: "OK",
                  close: true,
                  id: "ok",
                  color: "accent"
                }
              ]
            );
            if ((result?.data as Resource)?.id) {
              const badReservationRequest$ = this.setBadReservation((result?.data as Resource)?.id);
              badReservationRequest$.pipe(take(1)).subscribe({
                next: (result) => {
                  this.bheDialogService.openAlert(alertData);
                },
                error: (error) => {
                  this.bheDialogService.openAlert(alertData);
                }
              });
            }
          }
        }),
        jsonApiResources(true),
        bheClasses(),
        map((resultMap) => {
          return resultMap[Reservation.type][0];
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status !== 403 && error.status !== 401) {
              this.bheDialogService.openError(
                "errors.reservation.get.message",
                error.error,
                error.status
              );
            }
          }
          return throwError(
            () => error
          );
        })
      );
  }

  loadReservationFormModel(id: string): Observable<{ [type: string]: any[] }> {
    return this.guestTypePressId$.pipe(
      switchMap((pressId: string) => {
        return this.ngxJsonApi
          .find({
            type: Reservation.type,
            id,
            params: reservationQueryParams
          })
          .pipe(
            jsonApiResources(),
            bheClasses(true, pressId),
            catchError((error) => {
              if (error instanceof HttpErrorResponse) {
                this.bheDialogService.openError(
                  "errors.reservation.get.message",
                  error.error,
                  error.status
                );
              }
              return throwError(
                () => error
              );
            })
          );
      })
    );
  }

  updateReservationPartStatus(
    approbationConfirmation: ApprobationConfirmation
  ): Observable<any> {
    const url = `${this.appConfig.backendUrl}/jsonapi/operations`;
    const { reservationPartRef } = approbationConfirmation.approbation;
    const { reservation } = approbationConfirmation;
    const reservationPartResource: Resource = {
      id: reservationPartRef.id,
      type: reservationPartRef.type,
      attributes: {
        field_workflow_reservation_part: approbationConfirmation.targetStatus
      }
    };

    if (approbationConfirmation.userGuestExperience) {
      reservationPartResource.relationships = {
        field_guest_experience: {
          data: approbationConfirmation.userGuestExperience
        }
      };
    }

    const operations: JsonApiOperation[] = [
      {
        op: "update",
        data: reservationPartResource,
        ref: {
          id: reservationPartRef.id,
          type: reservationPartRef.type
        }
      }
    ];

    if (approbationConfirmation.messageBoxMessage) {
      const { body, id, type } = approbationConfirmation.messageBoxMessage;
      const messageOperation: JsonApiOperation = {
        op: "add",
        data: {
          id,
          type,
          attributes: {
            field_name: "field_comments",
            entity_type: "node",
            comment_body: {
              value: body
            }
          },
          relationships: {
            entity_id: {
              data: {
                id: reservation.id,
                type: reservation.type
              }
            }
          }
        },
        ref: {
          id,
          type
        }
      };
      operations.push(messageOperation);
    }

    operations.push(
      ReservationService.getUpdateReservationOperation(reservation)
    );

    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json"
    });

    return this.httpClient
      .patch(
        url,
        {
          operations
        },
        {
          observe: "response",
          headers
        }
      )
      .pipe(
        switchMap((result: HttpResponse<object>) => {
          return this.loadReservation(reservation.id).pipe(
            tap((reservation) => {
              this.store.dispatch(loadReservationSuccess({ reservation }));
            }),
            map((reservation) => {
              return reservation;
            }),
            catchError((error) => throwError(error))
          );
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  saveEntity(
    entities: {
      [id: string]: FormEntity;
    },
    entityId: string,
    guestTypePressId: string | null = null
  ) {
    console.log('saveEntity');
    const formEntity: FormEntity = entities[entityId];
    const resource = this.#buildResourceFromEntityStore(formEntity);
    if(resource){
      const resources = [resource];
      const operations: JsonApiOperation[] = resources.map((resource) => {
        const { id, type } = resource;
        const op: JsonApiOperationType =
          entities[id]?.state === "NEW" ? "add" : "update";
        return {
          op,
          data: resource,
          ref: {
            id,
            type
          }
        };
      });
      const headers: HttpHeaders = new HttpHeaders({
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json"
      });
      const url = `${this.appConfig.backendUrl}/jsonapi/operations`;
      this.saving = true;
      return this.httpClient
        .patch(
          url,
          {
            operations
          },
          {
            observe: "response",
            headers
          }
        )
        .pipe(
          map((operationsResult: HttpResponse<any>) => {
            const operations = operationsResult?.body?.operations;
            const data: Resource[] = operations
              .map((op: any) => {
                return op?.data;
              })
              .filter((d: Resource) => !!d);
            return data;
          }),
          bheClasses(true, guestTypePressId),
          withLatestFrom(this.reservation$),
          tap(([resources, reservation]) => {
            const reservationId = reservation?.id;
            if (reservationId) {
              this.store.dispatch(loadReservation({ reservationId }));
            }
          }),
          map(([resources, reservation]) => {
            return resources;
          }),
          catchError((error) => {
            if (error instanceof HttpErrorResponse) {
              this.bheDialogService.openError(
                "errors.reservation.message",
                error.error,
                error.status
              );
            }
            return of(error);
          }),
          finalize(() => {
            this.saving = false;
          })
        );
    }
    return of('no request needed');
  }

  saveEntities(
    entities: {
      [id: string]: FormEntity;
    },
    guestTypePressId: string | null = null
  ): Observable<| { [type: string]: FormEntityType[] }
    | HttpErrorResponse
    | "no request needed"> {
    let resources: Resource[] = [];
    Object.keys(entities).forEach((uuid: string) => {
      const formEntity: FormEntity = entities[uuid];
      if (formEntity.state !== "IN_SYNC") {
        const resource = this.#buildResourceFromEntityStore(formEntity);
        if (resource) {
          resources.push(resource);
        }
      }
    });

    resources = resources.filter((r) => !!r);

    if (resources.length === 0) {
      return of("no request needed");
    }

    let reservationResource: Resource | undefined | null = resources.find(
      (res) => res.type === Reservation.type
    );
    if (!reservationResource) {
      const reservationId: string | undefined = Object.keys(entities).find(
        (id) => entities[id].entity.type === Reservation.type
      );
      if (reservationId) {
        const reservationFormEntity: FormEntity = entities[reservationId];
        if (reservationFormEntity) {
          reservationResource = {
            type: Reservation.type,
            id: reservationId,
            meta: {
              appTime: Date.now()
            }
          }
          resources.push(reservationResource);
        }
      }
    }

    let operations: JsonApiOperation[] = resources.map((resource) => {
      const { id, type } = resource;
      const op: JsonApiOperationType =
        entities[id]?.state === "NEW" ? "add" : "update";
      return {
        op,
        data: resource,
        ref: {
          id,
          type
        }
      };
    });
    const reservationOperation: JsonApiOperation | undefined = operations.find(
      (operation) => operation.data.type === Reservation.type
    );
    /* we remove reservation Operation to add it at the end of the array as it is the latest referencable data*/
    if (reservationOperation) {
      operations = operations.filter(
        (operation) => operation.data.type !== Reservation.type
      );
      operations.push(reservationOperation);
    }

    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json"
    });
    const url = `${this.appConfig.backendUrl}/jsonapi/operations`;
    this.saving = true;
    return this.httpClient
      .patch(
        url,
        {
          operations
        },
        {
          observe: "response",
          headers
        }
      )
      .pipe(
        map((operationsResult: HttpResponse<any>) => {
          const operations = operationsResult?.body?.operations;
          const data: Resource[] = operations
            .map((op: any) => {
              return op?.data;
            })
            .filter((d: Resource) => !!d);
          return data;
        }),
        bheClasses(true, guestTypePressId),
        withLatestFrom(this.reservation$),
        tap(([resources, reservation]) => {
          const reservationId = reservation?.id;
          if (reservationId) {
            this.store.dispatch(loadReservation({ reservationId }));
          }
        }),
        map(([resources, reservation]) => {
          return resources;
        }),
        retry(5),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            this.bheDialogService.openError(
              "errors.reservation.message",
              error.error,
              error.status
            );
          }
          return of(error);
        }),
        finalize(() => {
          this.saving = false;
        })
      );
  }

  addRelationshipsFieldMulti(
    reservation: ReservationForm,
    fieldName: string,
    newRelations: ResourceIdentifier[]
  ): Observable<{ [type: string]: FormEntityType[] } | HttpErrorResponse> {
    const reservationResource: Resource = {
      id: reservation.id,
      type: reservation.type,
      relationships: {
        [fieldName]: {
          data: [
            ...(reservation as any)[fieldName],
            ...newRelations.map((r) => {
              return {
                id: r.id,
                type: r.type
              };
            })
          ]
        }
      }
    };
    const newRelationResources: Resource[] = newRelations
      .map((r) => {
        return buildResourceFromEntity(r, this.entitiesRelationships);
      })
      .filter((r) => !!r) as Resource[];
    const operations: JsonApiOperation[] = [];
    if (newRelationResources) {
      newRelationResources.forEach((newRelationResource) => {
        operations.push({
          op: "add",
          data: newRelationResource,
          ref: { id: newRelationResource.id, type: newRelationResource.type }
        });
      });
    }
    operations.push({
      op: "update",
      data: reservationResource,
      ref: { id: reservationResource.id, type: reservationResource.type }
    });
    return this.postOperations(operations);
  }

  removeRelationshipsFieldMulti(
    reservation: ReservationForm,
    fieldName: string,
    removedRelation: ResourceIdentifier
  ): Observable<{ [type: string]: FormEntityType[] } | HttpErrorResponse> {
    const reservationResource: Resource = {
      id: reservation.id,
      type: reservation.type,
      relationships: {
        [fieldName]: {
          data: (reservation as any)[fieldName].filter(
            (rid: ResourceIdentifier) => rid.id !== removedRelation.id
          )
        }
      }
    };
    const operations: JsonApiOperation[] = [];
    operations.push({
      op: "update",
      data: reservationResource,
      ref: { id: reservationResource.id, type: reservationResource.type }
    });
    return this.postOperations(operations);
  }

  postOperations(
    operations: JsonApiOperation[]
  ): Observable<{ [type: string]: FormEntityType[] } | HttpErrorResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json"
    });
    const url = `${this.appConfig.backendUrl}/jsonapi/operations`;
    return this.guestTypePressId$.pipe(
      switchMap((guestTypePressId) => {
        return this.httpClient
          .patch(
            url,
            {
              operations
            },
            {
              observe: "response",
              headers
            }
          )
          .pipe(
            map((operationsResult: HttpResponse<any>) => {
              const operations = operationsResult?.body?.operations;
              const data: Resource[] = operations
                .map((op: any) => {
                  return op?.data;
                })
                .filter((d: Resource) => !!d);
              return data;
            }),
            bheClasses(true, guestTypePressId),
            catchError((error) => {
              return of(error);
            })
          );
      })
    );
  }

  #buildResourceFromEntityStore(formEntityStore: FormEntity): Resource | null {
    let difference: any;
    const clonedEntity: any = { ...formEntityStore.entity };
    if (formEntityStore.persistedResource) {
      difference = diff(
        formEntityStore.entity,
        formEntityStore.persistedResource
      );
      if (Object.keys(difference).length === 0) {
        return null;
      }
      Object.keys(formEntityStore.entity).forEach((key) => {
        if (!(key in difference) && key !== "id" && key !== "type") {
          delete (clonedEntity as any)[key];
        }
      });
      //manage null values. When a property is null it is removed from the form... it is not in formEntityStore.entity
      /*Object.keys(difference).forEach((key) => {
        if (!(key in clonedEntity)) {
          clonedEntity[key] = null;
        }
      });*/
    }
    return buildResourceFromEntity(clonedEntity, this.entitiesRelationships);
  }

  setBadReservation(
    id: string): Observable<any> {
    const reservationResource: Resource = {
      id,
      type: Reservation.type,
      attributes: {
        field_bad_reservation: true
      }
    };

    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json"
    });

    const url = `${this.appConfig.backendUrl}/jsonapi/node/reservation/${id}`;
    return this.httpClient
      .patch(
        url,
        { data: reservationResource },
        {
          observe: "response",
          headers
        }
      ).pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  updateRequestor(
    reservation: Reservation,
    uid: string): Observable<any> {
    const { id, type } = reservation;
    const reservationResource: Resource = {
      id,
      type,
      relationships: {
        uid: {
          data: {
            type: User.type,
            id: uid
          }
        }
      }
    };

    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json"
    });

    const url = `${this.appConfig.backendUrl}/jsonapi/node/reservation/${id}`;
    return this.httpClient
      .patch(
        url,
        { data: reservationResource },
        {
          observe: "response",
          headers
        }
      ).pipe(
        tap((result) => {
          this.store.dispatch(loadReservation({ reservationId: id }));
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  updateWorkflow(
    reservation: Reservation,
    workflow: ReservationWorkflow
  ): Observable<any | HttpErrorResponse> {
    const { id, type } = reservation;
    const reservationResource: Resource = {
      id,
      type,
      attributes: {
        field_workflow_reservation: workflow
      }
    };

    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json"
    });

    const url = `${this.appConfig.backendUrl}/jsonapi/node/reservation/${id}`;
    return this.httpClient
      .patch(
        url,
        { data: reservationResource },
        {
          observe: "response",
          headers
        }
      ).pipe(
        tap((result) => {
          this.store.dispatch(loadReservation({ reservationId: id }));
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
}
