import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { Reservation } from '@bhe/types';
import {
  BrandIconsComponentModule,
  FlagComponentModule,
  ReservationWorkflowIconComponentModule,
} from '@bhe/ui';

@Component({
  selector: 'bhe-pro-table',
  template: `
    <table mat-table #table [dataSource]="dataSource" recycleRows>
      <!-- Id -->
      <ng-container matColumnDef="searchBheId">
        <th mat-header-cell *matHeaderCellDef [style.top.px]="0">Id</th>
        <td mat-cell *matCellDef="let reservation">
          {{ reservation?.searchBheId }}
        </td>
      </ng-container>

      <!-- Country -->
      <ng-container matColumnDef="field_country">
        <th mat-header-cell *matHeaderCellDef [style.top.px]="0">Country</th>
        <td mat-cell *matCellDef="let reservation">
          <bhe-ui-flag
            [code]="reservation?.searchCountry"
            [square]="true"
          ></bhe-ui-flag>
        </td>
      </ng-container>

      <!-- Requestor -->
      <ng-container matColumnDef="requestor">
        <th mat-header-cell *matHeaderCellDef [style.top.px]="0">Requestor</th>
        <td mat-cell *matCellDef="let reservation">
          <span [innerHTML]="reservation?.searchRequestor"></span>
        </td>
      </ng-container>

      <!-- Maisons -->
      <ng-container matColumnDef="maisons">
        <th mat-header-cell *matHeaderCellDef [style.top.px]="0">Maisons</th>
        <td mat-cell *matCellDef="let reservation">
          <bhe-ui-brand-icons
            [size]="'small'"
            [codes]="reservation.searchBrands"
          ></bhe-ui-brand-icons>
        </td>
      </ng-container>

      <!-- Main Guest -->
      <ng-container matColumnDef="field_main_guest_full_name">
        <th mat-header-cell *matHeaderCellDef [style.top.px]="0">Main Guest</th>
        <td mat-cell *matCellDef="let reservation">
          {{ reservation?.searchMainGuest }}
        </td>
      </ng-container>

      <!-- Visit Date -->
      <ng-container matColumnDef="field_reservation_date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [style.top.px]="0"
        >
          Visit Date
        </th>
        <td mat-cell *matCellDef="let reservation">
          {{ reservation?.searchReservationDate | date: 'MMM d y':'+00:00' }}
        </td>
      </ng-container>

      <!-- Guest Type -->
      <ng-container matColumnDef="field_guest_type_name">
        <th mat-header-cell *matHeaderCellDef [style.top.px]="0">Guest Type</th>
        <td mat-cell *matCellDef="let reservation">
          {{ reservation?.searchGuestType }}
        </td>
      </ng-container>

      <!-- Created -->
      <ng-container matColumnDef="created">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [style.top.px]="0"
        >
          Created on
        </th>
        <td mat-cell *matCellDef="let reservation">
          {{ reservation?.searchCreated | date: 'MMM d y':'+00:00' }}
        </td>
      </ng-container>

      <!-- Modified -->
      <ng-container matColumnDef="changed">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [style.top.px]="0"
        >
          Modified on
        </th>
        <td mat-cell *matCellDef="let reservation">
          {{ reservation?.searchChanged | date: 'MMM d y':'+00:00' }}
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef [style.top.px]="0">Status</th>
        <td mat-cell *matCellDef="let reservation">
          <bhe-ui-reservation-workflow-icon
            [key]="reservation?.searchWorkflow"
            size="small"
          ></bhe-ui-reservation-workflow-icon>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="visibleColumns; sticky: true"
        [style.top.px]="0"
      ></tr>

      <tr mat-row *matRowDef="let row; columns: visibleColumns"></tr>
    </table>
  `,
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent {
  visibleColumns = [
    'searchBheId',
    'requestor',
    'maisons',
    'field_main_guest_full_name',
    'field_reservation_date',
    'field_guest_type_name',
    'field_country',
    'created',
    'changed',
    'status',
  ];

  @Input()
  dataSource!: Reservation[];
}

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    ReservationWorkflowIconComponentModule,
    FlagComponentModule,
    BrandIconsComponentModule,
  ],
  declarations: [TableComponent],
  exports: [TableComponent],
})
export class TableComponentModule {}
