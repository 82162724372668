import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldWrapper} from '@ngx-formly/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {UntypedFormControl, ReactiveFormsModule} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {DateTime} from 'luxon';

@UntilDestroy()
@Component({
  selector: 'bhe-res-date-range',
  template: `
    <ng-container *ngIf="startDateControl && endDateControl">
      <ng-container *ngIf="props.label as label">
        <h3 [innerHTML]="label"></h3>
      </ng-container>
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input
          [disabled]="field.fieldGroup && field.fieldGroup.length>0?!!field.fieldGroup[0].props?.readonly:true"
          [rangePicker]="picker"
          [min]="props['minDate']"
          [dateFilter]="weekendFilter"
        >
          <input
            matStartDate
            [formControl]="startDateControl"
            placeholder="Start date"
          />
          <input
            matEndDate
            [formControl]="endDateControl"
            placeholder="End date"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker color="accent"></mat-date-range-picker>
      </mat-form-field>
    </ng-container>
  `,
  styleUrls: ['./date-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent extends FieldWrapper implements AfterViewInit {
  startDateControl!: UntypedFormControl;
  endDateControl!: UntypedFormControl;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit(): void {
    const fields: string[] = this.field.props?.['fields'];
    this.startDateControl = this.form.get(fields[0]) as UntypedFormControl;
    this.endDateControl = this.form.get(fields[1]) as UntypedFormControl;
    this.cdr.detectChanges();
  }

  weekendFilter = (d: DateTime): boolean => {
    const day = d.get('weekday');
    return day !== 6 && day !== 7;
  };
}

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
  ],
  declarations: [DateRangeComponent],
  exports: [DateRangeComponent],
})
export class DateRangeComponentModule {
}
