import { Injectable, Type } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  AlertAction,
  AlertDialogData,
  BheDialogData,
  ErrorDialogData,
  WindowMessageData
} from "@bhe/types";
import { AlertComponent } from "../alert/alert.component";
import { BheDialogComponent } from "./bhe-dialog.component";
import { WindowMessageComponent } from "../window-message/window-message.component";
import { TranslocoService } from "@ngneat/transloco";
import { ErrorComponent } from "../error/error.component";
import { WindowConnectComponent } from "../window-connect/window-connect.component";

// import { RequestorUpdateComponent } from "@bhe/reservation-feature";

@Injectable({
  providedIn: "root"
})
export class BheDialogService {
  constructor(
    private matDialog: MatDialog,
    private translocoService: TranslocoService
  ) {
  }

  static buildAlertDialogData(
    message: string,
    actions: AlertAction[]
  ): AlertDialogData {
    const bheDialogData: AlertDialogData = {
      component: AlertComponent,
      message,
      process: null,
      actions
    };
    return bheDialogData;
  }

  openAlert<T>(
    bheDialogData: AlertDialogData
  ): MatDialogRef<BheDialogComponent, T> {
    return this.matDialog.open(BheDialogComponent, {
      disableClose: true,
      panelClass: "alert",
      data: bheDialogData
    });
  }

  openMessageWindow(title: string, message: string) {
    const windowMessageData: WindowMessageData = {
      component: WindowMessageComponent,
      message: this.translocoService.translate(message),
      title: title,
      showHeader: true
    };
    this.matDialog.open(BheDialogComponent, {
      data: windowMessageData,
      disableClose: true,
      width: "100vw",
      maxWidth: "600px",
      panelClass: "content"
    });
  }

  openError(
    message: string,
    error: any,
    status: number
  ): MatDialogRef<BheDialogComponent> {
    const errorDialogData: ErrorDialogData = {
      component: ErrorComponent,
      error,
      code: status,
      message: this.translocoService.translate(message),
      actions: []
    };
    return this.matDialog.open(BheDialogComponent, {
      disableClose: true,
      width: "100vw",
      maxWidth: 400,
      data: errorDialogData
    });
  }

  openConnectWindow() {
    if (!this.matDialog.getDialogById("connect")) {
      const windowMessageData: BheDialogData = {
        component: WindowConnectComponent,
        showHeader: false
      };
      this.matDialog.open(BheDialogComponent, {
        id: "connect",
        data: windowMessageData,
        disableClose: true,
        width: "100vw",
        maxWidth: "480px",
        panelClass: "content"
      });
    }
  }

  openRequestorUpdateWindow(component: Type<any>): MatDialogRef<BheDialogComponent, { userId: string }>|null {
    if (!this.matDialog.getDialogById("requestorUpdate")) {
      const windowMessageData: BheDialogData = {
        component: component,
        showHeader: false
      };
      return this.matDialog.open<BheDialogComponent, BheDialogData, { userId: string }>(BheDialogComponent, {
        id: "requestorUpdate",
        data: windowMessageData,
        disableClose: true,
        width: "100vw",
        maxWidth: "480px",
        panelClass: "content"
      });
    }
    return null;
  }

  openMaintenanceWindow() {
    if (!this.matDialog.getDialogById("maintenance")) {
      const alertDialogData: AlertDialogData = BheDialogService.buildAlertDialogData("Sorry, BHE is currently under maintenance.<br>Please try to connect later.", [{
        id: "ok",
        label: "OK",
        color: "accent",
        close: true
      }]);
      this.matDialog.open(BheDialogComponent, {
        id: "maintenance",
        data: alertDialogData,
        disableClose: true,
        width: "100vw",
        maxWidth: "480px",
        panelClass: "content"
      });
    }
  }

}
