import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'bhe-ui-icon-loading',
  template: ` <mat-spinner [diameter]="24" mode="indeterminate"></mat-spinner>`,
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLoadingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [IconLoadingComponent],
  exports: [IconLoadingComponent],
})
export class IconLoadingComponentModule {}
