import { Inject, Injectable } from '@angular/core';
import {
  catchError,
  filter,
  map,
  Observable,
  startWith,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { NgxJsonApi, QueryParams } from '@madeinlune/ngx-json-api';
import { CorporatePage, DrupalFile } from '@bhe/types';
import { bheClasses, jsonApiResources } from '@nx-agency/bhe/operators';
import { State } from './corporate-page.reducer';
import { select, Store } from '@ngrx/store';
import { addCorporatePage, addCorporatePages } from './corporate-page.actions';
import { CORPORATE_PAGES } from './bhe-corporate-data-access.module';
import { selectCorporatePageEntities } from './corporate-page.selectors';

const corporatePageQueryParams: QueryParams = {
  filtering: [
    {
      path: 'status',
      value: 1,
    },
  ],
  include: [
    'field_brand_banner',
    'field_brand_downloads.field_media_file',
    'field_brand_images',
    'field_brand_video',
  ],
  fields: {
    [CorporatePage.type]: [
      'title',
      'field_brand_description',
      'field_brand_banner',
      'field_brand_downloads',
      'field_brand_images',
      'field_brand_reference',
      'field_brand_video',
    ],
    [DrupalFile.type]: ['filemime', 'filename', 'filesize', 'uri'],
  },
};

@Injectable({
  providedIn: 'root',
})
export class CorporateService {
  constructor(
    private ngxJsonApi: NgxJsonApi,
    private store: Store<State>,
    @Inject(CORPORATE_PAGES)
    private corporatePages$: Observable<CorporatePage[]>
  ) {}

  loadHomeCorporatePages(): Observable<CorporatePage[]> {
    return this.corporatePages$.pipe(
      switchMap((corporatePages) => {
        return this.ngxJsonApi
          .find({
            type: CorporatePage.type,
            params: corporatePageQueryParams,
          })
          .pipe(
            jsonApiResources(true),
            bheClasses(),
            map((resultMap) => {
              return resultMap[CorporatePage.type];
            }),
            filter((corporatePages) => !!corporatePages),
            tap((corporatePages) => {
              this.store.dispatch(addCorporatePages({ corporatePages }));
            }),
            startWith(corporatePages),
            catchError((error) => {
              return throwError(
                () => `an error occured while loading reservation ${error}`
              );
            })
          );
      })
    );
  }

  loadHomeCorporatePage(id: string): Observable<CorporatePage> {
    const corporatePage$: Observable<CorporatePage | undefined> =
      this.store.pipe(
        select(selectCorporatePageEntities),
        map((brandEntities) => {
          return brandEntities?.[id];
        })
      );
    return corporatePage$.pipe(
      switchMap((corporatePage) => {
        return this.ngxJsonApi
          .find({
            type: CorporatePage.type,
            id,
            params: corporatePageQueryParams,
          })
          .pipe(
            jsonApiResources(true),
            bheClasses(),
            map((resultMap) => {
              return resultMap[CorporatePage.type][0];
            }),
            tap((corporatePage) => {
              this.store.dispatch(addCorporatePage({ corporatePage }));
            }),
            startWith(corporatePage),
            catchError((error) => {
              return throwError(
                () => `an error occured while loading reservation ${error}`
              );
            })
          );
      })
    );
  }
}
