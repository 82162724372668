import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  BheButtonType,
  typeAttributeToken,
} from '../bhe-button/bhe-button.component';
import { ButtonTemplate } from '../button-template';

@Component({
  selector: 'bhe-ui-warn-button',
  template: `
    <button
      #target
      mat-flat-button
      color="warn"
      [type]="type"
      [disabled]="isDisabled"
    >
      <ng-content></ng-content>
    </button>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarnButtonComponent implements ButtonTemplate, OnChanges {
  @Input()
  isDisabled = false;

  @ViewChild('target', { static: true, read: ElementRef })
  target!: ElementRef<HTMLButtonElement>;

  constructor(
    @Inject(typeAttributeToken) public type: BheButtonType,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }
}

@NgModule({
  imports: [CommonModule, MatButtonModule],
  declarations: [WarnButtonComponent],
  exports: [WarnButtonComponent],
})
export class WarnButtonComponentModule {}
