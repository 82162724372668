import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldType } from '@ngx-formly/material';
import { MhProfileAutocompleteComponentModule } from '../mh-profile-autocomplete/mh-profile-autocomplete.component';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { FieldTypeConfig, FormlyModule } from "@ngx-formly/core";

@Component({
  selector: 'bhe-mh-field-single',
  template: `
    <ng-container *ngIf="field.props.description as description">
      <div [innerHTML]="description"></div>
    </ng-container>
    <bhe-res-mh-profile-autocomplete
      [formlyAttributes]="field"
      [readonly]="!!field.props.disabled"
      [label]="field.props.label"
      [value]="formControl.value"
      (valueChanges)="onValueChanges($event)"
    ></bhe-res-mh-profile-autocomplete>
  `,
  styleUrls: ['./mh-field-single.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MhFieldSingleComponent extends FieldType<FieldTypeConfig> {
  constructor() {
    super();
  }

  onValueChanges(profiles: ResourceIdentifier[]) {
    if (this.formControl) {
      if (profiles.length === 0) {
        this.formControl.setValue(null);
      } else if (profiles.length > 0) {
        this.formControl.setValue(profiles[0]);
      }
      // this.formControl.updateValueAndValidity();
    }
  }
}

@NgModule({
  imports: [CommonModule, MhProfileAutocompleteComponentModule, FormlyModule],
  declarations: [MhFieldSingleComponent],
  exports: [MhFieldSingleComponent],
})
export class MhFieldSingleComponentModule {}
