import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Inject,
  NgModule,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ROUTE_RESERVATION_ID } from "@bhe/router";
import { distinctUntilChanged, filter, Observable } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ReservationFormComponent, ReservationFormComponentModule } from "./reservation-form/reservation-form.component";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { FormlyModule } from "@ngx-formly/core";
import { DateRangeComponent, FormItemsWrapperComponent, GuestTypesComponent, TabsComponent, TextAreaComponent } from "@bhe/reservation-ui";
import { BottleSoldWrapperComponent } from "./bottle-sold-wrapper/bottle-sold-wrapper.component";
import { AutocompleteComponent, AutocompleteComponentModule, BheButtonComponentModule } from "@bhe/ui";
import { GuestExperiencesComponent } from "./guest-experiences/guest-experiences.component";
import { GuestFormComponent } from "./guest-form/guest-form.component";
import { ReservationPartFormComponent } from "./reservation-part-form/reservation-part-form.component";
import { RepeatGuestComponent } from "./repeat-guest/repeat-guest.component";
import { RepeatMaisonComponent } from "./repeat-maison/repeat-maison.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { PushModule } from "@rx-angular/template/push";
import { FormlyMatDatepickerModule } from "@ngx-formly/material/datepicker";
import { MatLuxonDateModule } from "@angular/material-luxon-adapter";
import { GuestFormRefComponent, GuestFormRefComponentModule } from "./guest-form-ref/guest-form-ref.component";
import { MhFieldMultiComponent, MhFieldMultiComponentModule } from "../mh-field-multi/mh-field-multi.component";
import { DefaultBrandDataInjectorComponent } from "./default-brand-data-injector/default-brand-data-injector.component";

@UntilDestroy()
@Component({
  selector: 'bhe-edit',
  template: ` <ng-template #formContainer></ng-template> `,
  styleUrls: ['edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class EditComponent implements AfterViewInit {
  @ViewChild('formContainer', { read: ViewContainerRef })
  formContainer!: ViewContainerRef;

  constructor(
    @Inject(ROUTE_RESERVATION_ID)
    private routeReservationId$: Observable<string>
  ) {}

  ngAfterViewInit(): void {
    this.routeReservationId$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter((routeReservationId) => !!routeReservationId)
      )
      .subscribe((routeReservationId) => {
        this.createReservationForm();
      });
  }

  private createReservationForm() {
    if (this.formContainer) {
      this.formContainer.clear();
    }
    const componentRef: ComponentRef<ReservationFormComponent> =
      this.formContainer.createComponent<ReservationFormComponent>(
        ReservationFormComponent
      );
    componentRef.changeDetectorRef.detectChanges();
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: EditComponent,
      },
    ]),
    FormlyMaterialModule,
    FormlyModule.forChild({
      extras: { resetFieldOnHide: true },
      wrappers: [
        {
          name: 'dateRange',
          component: DateRangeComponent,
        },
        {
          name: 'bheWrapper',
          component: FormItemsWrapperComponent,
        },
        {
          name: 'bottleSold',
          component: BottleSoldWrapperComponent,
        },
      ],
      types: [
        { name: 'string', extends: 'input' },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        { name: 'boolean', extends: 'checkbox' },
        { name: 'enum', extends: 'select' },
        { name: 'language_reference', extends: 'string' },
        {
          name: 'autocomplete',
          component: AutocompleteComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'guestTypes',
          component: GuestTypesComponent,
        },
        {
          name: 'guestExperiences',
          component: GuestExperiencesComponent,
        },
        {
          name: 'guestForm',
          component: GuestFormComponent,
        },
        {
          name: 'guestFormRef',
          component: GuestFormRefComponent,
        },
        {
          name: 'reservationPartForm',
          component: ReservationPartFormComponent,
        },
        {
          name: 'repeatGuest',
          component: RepeatGuestComponent,
        },
        {
          name: 'repeatMaison',
          component: RepeatMaisonComponent,
        },
        {
          name: 'textArea',
          component: TextAreaComponent,
        },
        {
          name: 'mhProfileMulti',
          component: MhFieldMultiComponent,
        },
        {
          name: 'tabs',
          component: TabsComponent,
        },
        {
          name: 'brandDataInjectorButton',
          component: DefaultBrandDataInjectorComponent,
        },
      ],
    }),
    ReactiveFormsModule,
    BheButtonComponentModule,
    AutocompleteComponentModule,
    MatFormFieldModule,
    MatButtonModule,
    PushModule,
    FormlyMatDatepickerModule,
    MatLuxonDateModule,
    ReservationFormComponentModule,
    GuestFormRefComponentModule,
    MhFieldMultiComponentModule,
  ],
  declarations: [EditComponent],
  exports: [EditComponent],
  providers: [],
})
export class EditComponentModule {}
