import { map, Observable, switchMap } from 'rxjs';
import { GetBrandGuestExperiencesPipe } from './get-brand-guest-experiences.pipe';
import { Brand } from '@bhe/types';

export function guestExperienceFacet(
  getBrandGuestExperiencesPipe: GetBrandGuestExperiencesPipe
) {
  // tslint:disable-next-line:only-arrow-functions
  return function <T>(source: Observable<any>) {
    return source.pipe(
      switchMap(([facet, brand]: [any, Brand]) => {
        return getBrandGuestExperiencesPipe.transform(brand).pipe(
          map((guestExperiences) => {
            const filter: { brand: string; values: any[] } = {
              brand: brand.name,
              values: facet?.values
                .filter((val: any) => {
                  return (
                    guestExperiences.findIndex(
                      (ge) => ge.tid === parseInt(val.filter)
                    ) > -1
                  );
                })
                .map((val: any) => {
                  return {
                    name:
                      guestExperiences.find(
                        (ge) => ge.tid === parseInt(val.filter)
                      )?.name ?? val.filter,
                    value: val.count,
                  };
                }),
            };
            return filter;
          })
        );
      })
    );
  };
}
