import { TranslocoService } from '@ngneat/transloco';

export function getDirtyValues(form: any, keys: string[]) {
  Object.keys(form.controls).forEach((key) => {
    const currentControl = form.controls[key];
    if (currentControl.dirty) {
      if (currentControl.controls) {
        keys.push(key);
        getDirtyValues(currentControl, keys);
      } else {
        keys.push(key);
      }
    }
  });

  return keys;
}

export function yesNoIdkOptions(translocoService: TranslocoService): any[] {
  return [
    {
      value: 1,
      label: translocoService.translate('actions.yes'),
    },
    {
      value: 2,
      label: translocoService.translate('actions.no'),
    },
    {
      value: 3,
      label: translocoService.translate('actions.i-dont-know'),
    },
  ];
}
