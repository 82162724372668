import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MhProfile } from '@bhe/types';
import { MhProfileRendererComponentModule } from '../../form/mh-profile-renderer/mh-profile-renderer.component';

@Component({
  selector: 'bhe-res-overview-accompanyning',
  template: `
    <ng-container *ngIf="mhProfiles">
      <ng-template ngFor [ngForOf]="mhProfiles" let-mhProfile>
        <bhe-res-mh-profile-renderer
          [mhProfile]="mhProfile"
        ></bhe-res-mh-profile-renderer>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./overview-accompanyning.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewAccompanyningComponent {
  @Input()
  mhProfiles!: MhProfile[];
}

@NgModule({
  imports: [CommonModule, MhProfileRendererComponentModule],
  declarations: [OverviewAccompanyningComponent],
  exports: [OverviewAccompanyningComponent],
})
export class OverviewAccompanyningComponentModule {}
