import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { select, Store, StoreModule } from '@ngrx/store';
import * as fromCorporate from './corporate-page.reducer';
import { State } from './corporate-page.reducer';
import { map, Observable } from 'rxjs';
import { CorporatePage, DrupalFile } from '@bhe/types';
import { selectCorporatePageEntities } from './corporate-page.selectors';

export const CORPORATE_PAGES_MAP = new InjectionToken<
  Observable<{ [id: string]: CorporatePage }>
>('CORPORATE_PAGES_MAP');

export const CORPORATE_PAGES = new InjectionToken<Observable<CorporatePage[]>>(
  'CORPORATE_PAGES'
);

export const CORPORATE_HOME_PAGE = new InjectionToken<
  Observable<CorporatePage>
>('CORPORATE_HOME_PAGE');

export const BRAND_FILES_MAP = new InjectionToken<
  Observable<Map<string, DrupalFile[]>>
>('BRAND_FILES_MAP');

export const BRAND_FILES = new InjectionToken<Observable<DrupalFile[]>>(
  'BRAND_FILES'
);

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCorporate.corporatePagesFeatureKey,
      fromCorporate.reducer
    ),
  ],
  providers: [
    {
      provide: CORPORATE_PAGES_MAP,
      deps: [Store],
      useFactory: (store: Store<State>) => {
        return store.pipe(select(selectCorporatePageEntities));
      },
    },
    {
      provide: CORPORATE_PAGES,
      deps: [CORPORATE_PAGES_MAP],
      useFactory: (
        corporatePageMap$: Observable<{ [id: string]: CorporatePage }>
      ) => {
        return corporatePageMap$.pipe(
          map((corporatePageEntities) => {
            return Object.keys(corporatePageEntities).map(
              (key) => corporatePageEntities[key]
            );
          })
        );
      },
    },
    {
      provide: CORPORATE_HOME_PAGE,
      deps: [CORPORATE_PAGES],
      useFactory: (corporatePages$: Observable<CorporatePage[]>) => {
        return corporatePages$.pipe(
          map((corporatePages) => {
            return corporatePages.find((page) => !page.brand);
          })
        );
      },
    },
    {
      provide: BRAND_FILES_MAP,
      deps: [CORPORATE_PAGES],
      useFactory: (corporatePages$: Observable<CorporatePage[]>) => {
        return corporatePages$.pipe(
          map((corporatePages) => {
            const brandFiles: Map<string, DrupalFile[]> = new Map();
            corporatePages.forEach((corporatePage) => {
              if (corporatePage.brand && corporatePage.downloads?.length > 0) {
                brandFiles.set(corporatePage.brand.id, corporatePage.downloads);
              }
            });
            return brandFiles;
          })
        );
      },
    },
    {
      provide: BRAND_FILES,
      deps: [CORPORATE_PAGES],
      useFactory: (corporatePages$: Observable<CorporatePage[]>) => {
        return corporatePages$.pipe(
          map((corporatePages) => {
            const brandFiles: DrupalFile[] = [];
            corporatePages.forEach((corporatePage) => {
              if (corporatePage.brand && corporatePage.downloads?.length > 0) {
                brandFiles.push(...corporatePage.downloads);
              }
            });
            return brandFiles;
          })
        );
      },
    },
  ],
})
export class BheCorporateDataAccessModule {}
