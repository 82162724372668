import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { MatSelectModule } from '@angular/material/select';
import { map, Observable } from 'rxjs';
import { FieldType } from '@ngx-formly/material';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { LetModule } from "@ngrx/component";
import { FieldTypeConfig } from "@ngx-formly/core";

export interface LanguageItem {
  code: string;
  label: string;
}

@Component({
  selector: 'bhe-res-languages',
  template: `
    <ng-container *ngrxLet="languages$; let languages">
      <ng-container *ngIf="languages">
        <mat-select
          [formControl]="control"
          [compareWith]="compareWith"
          [disabled]="!!field.props.readonly"
        >
          <mat-option [value]="null">N/A</mat-option>
          <mat-option
            *ngFor="let language of languages"
            [value]="language.code"
          >
            {{ language.label }}
          </mat-option>
        </mat-select>
      </ng-container>
    </ng-container>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesComponent extends FieldType<FieldTypeConfig> {
  public get control() {
    return this.formControl as UntypedFormControl;
  }

  languages$: Observable<LanguageItem[]> = this.translateLoco
    .selectTranslateObject('languages')
    .pipe(
      map((languages) => {
        return Object.keys(languages).map((key) => {
          return languages[key];
        });
      })
    );

  constructor(private translateLoco: TranslocoService) {
    super();
  }

  compareWith(o1: string, o2: string): boolean {
    return o1 === o2;
  }

  trackBy(index: number, item: LanguageItem) {
    return item.code;
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    LetModule
  ],
  declarations: [LanguagesComponent],
  exports: [LanguagesComponent],
})
export class LanguagesComponentModule {}
