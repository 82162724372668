import { Expose, Transform } from 'class-transformer';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';

export class GuestTypeForm implements ResourceIdentifier {
  @Expose()
  type!: string;

  @Expose()
  id!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.changed;
  })
  @Expose()
  changed!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.name;
  })
  @Expose()
  name!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_description;
  })
  @Expose()
  field_description!: string;
}
