import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgModule,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Reservation, ReservationWorkflow } from '@bhe/types';
import { OverviewApprobationComponentModule } from '../overview-approbation/overview-approbation.component';
import { ReservationWorkflowIconComponentModule } from '@bhe/ui';
import { MatSelectModule } from '@angular/material/select';
import {
  ShowApprobationBlocksPipeModule,
  WorkflowChoicesPipeModule,
} from '@bhe/reservation-data-access';
import { USER_IS_BH_TEAM } from '@bhe/user-data-access';
import { Observable } from 'rxjs';
import { LetModule } from "@ngrx/component";
import { WorkflowSelectComponentModule } from '../../workflow-select/workflow-select.component';

@Component({
  selector: 'bhe-res-overview-workflow',
  template: `
    <ng-container *ngrxLet="isBhTeam$; let isBhTeam">
      <ng-container *ngIf="isBhTeam">
        <bhe-res-workflow-select
          [workflow]="reservation.workflow"
          (workflowChanged)="workflowChanged.emit($event)"
        ></bhe-res-workflow-select>
      </ng-container>
    </ng-container>
    <ng-container>
      <ng-container *ngIf="reservation.reservationParts as reservationParts">
        <ng-container
          *ngIf="reservation.reservationParts | showApprobationBlocks"
        >
          <div class="b-approbations">
            <ng-template ngFor [ngForOf]="reservationParts" let-reservationPart>
              <ng-container *ngIf="reservationPart">
                <bhe-res-overview-approbation
                  [reservation]="reservation"
                  [approbation]="reservationPart.approbation"
                ></bhe-res-overview-approbation>
              </ng-container>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./overview-workflow.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewWorkflowComponent implements OnChanges {
  @Input()
  reservation!: Reservation;

  @Input()
  workflow!: ReservationWorkflow;

  @Output()
  workflowChanged: EventEmitter<ReservationWorkflow> = new EventEmitter<ReservationWorkflow>();

  constructor(
    @Inject(USER_IS_BH_TEAM) public isBhTeam$: Observable<boolean>,
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { nativeElement } = this.el;
    const previousWorkflow: ReservationWorkflow =
      changes['workflow']?.previousValue;
    const currentWorkflow: ReservationWorkflow =
      changes['workflow']?.currentValue;
    if (previousWorkflow) {
      this.renderer.removeClass(nativeElement, previousWorkflow);
    }
    if (currentWorkflow) {
      this.renderer.addClass(nativeElement, currentWorkflow);
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    OverviewApprobationComponentModule,
    ReservationWorkflowIconComponentModule,
    MatSelectModule,
    ShowApprobationBlocksPipeModule,
    WorkflowChoicesPipeModule,
    WorkflowSelectComponentModule,
    LetModule
  ],
  declarations: [OverviewWorkflowComponent],
  exports: [OverviewWorkflowComponent],
})
export class OverviewWorkflowComponentModule {}
