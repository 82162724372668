import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationFormEntitiesService } from '@bhe/reservation-data-access';
import { EMPTY, map, Observable, take } from 'rxjs';
import { MhProfile } from '@bhe/types';

@Pipe({
  name: 'getMhProfile',
})
export class GetMhProfilePipe implements PipeTransform {
  constructor(private reservationFormService: ReservationFormEntitiesService) {}

  transform(mhProfileId: string | undefined): Observable<MhProfile> {
    if (mhProfileId) {
      return this.reservationFormService.entities$.pipe(
        map((entities) => {
          return entities[mhProfileId].entity as MhProfile;
        }),
        take(1)
      );
    }
    return EMPTY;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [GetMhProfilePipe],
  exports: [GetMhProfilePipe],
})
export class GetMhProfilePipeModule {}
