import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  NgModule,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationStatusInfos, ReservationWorkflow } from '@bhe/types';

export const UI_ICON_STATUS_INFOS = new InjectionToken<
  Map<ReservationWorkflow, ReservationStatusInfos>
>('UI_ICON_STATUS_INFOS');

export type StatusIconSize = 'small' | 'default';

@Component({
  selector: 'bhe-ui-reservation-workflow-icon',
  template: `
    <span [innerHTML]="reservationStatusInfos.get(key)?.code"></span>
  `,
  styleUrls: ['./reservation-workflow-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationWorkflowIconComponent implements OnChanges {
  constructor(
    @Inject(UI_ICON_STATUS_INFOS)
    public reservationStatusInfos: Map<
      ReservationWorkflow,
      ReservationStatusInfos
    >,
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>
  ) {}

  @Input()
  key!: ReservationWorkflow;

  @Input()
  size!: StatusIconSize;

  @Input()
  statusMap!: Map<ReservationWorkflow, ReservationStatusInfos>;

  ngOnChanges(changes: SimpleChanges): void {
    const { nativeElement } = this.el;
    const currentClasses: string[] = nativeElement.className
      .split(' ')
      .filter((className) => !!className);
    currentClasses.forEach((className) => {
      if (this.reservationStatusInfos.get(className as ReservationWorkflow)) {
        this.renderer.removeClass(nativeElement, className);
      }
    });
    this.renderer.addClass(nativeElement, this.key);
    this.renderer.addClass(nativeElement, this.size);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ReservationWorkflowIconComponent],
  exports: [ReservationWorkflowIconComponent],
})
export class ReservationWorkflowIconComponentModule {}
