import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgModule,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BheIconComponentModule } from '../bhe-icon/bhe-icon.component';

export type BrandIconSize = 'small' | 'default';

@Component({
  selector: 'bhe-ui-brand-icons',
  template: ` <ng-container *ngIf="codes">
    <ng-template ngFor [ngForOf]="codes" let-code [ngForTrackBy]="identity">
      <bhe-ui-bhe-icon [icon]="code"></bhe-ui-bhe-icon>
    </ng-template>
  </ng-container>`,
  styleUrls: ['./brand-icons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandIconsComponent implements OnChanges {
  @Input()
  codes!: string[];

  @Input()
  size: BrandIconSize = 'default';

  constructor(
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>
  ) {}

  identity(index: number, code: string) {
    return code;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['size']) {
      if (changes['size']?.previousValue) {
        this.renderer.removeClass(
          this.el.nativeElement,
          changes['size']?.previousValue
        );
      }
      this.renderer.addClass(
        this.el.nativeElement,
        changes['size']?.currentValue
      );
    }
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, BheIconComponentModule],
  declarations: [BrandIconsComponent],
  exports: [BrandIconsComponent],
})
export class BrandIconsComponentModule {}
