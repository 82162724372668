import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationWorkflow } from '@bhe/types';

@Pipe({
  name: 'workflowChoices',
})
export class WorkflowChoicesPipe implements PipeTransform {
  transform(workflow: ReservationWorkflow): ReservationWorkflow[] {
    let status: ReservationWorkflow[] = [];
    switch (workflow) {
      case 'wns':
        status = [workflow, 'submitted', 'cancelled'];
        break;

      case 'draft':
        status = [workflow, 'submitted', 'cancelled'];
        break;

      case 'submitted':
        status.push(workflow, 'approved', 'wns', 'cancelled');

        break;

      case 'approved':
        status.push(workflow, 'ready', 'cancelled', 'wns');

        break;

      case 'ready':
        status.push(workflow, 'confirmed', 'cancelled', 'wns');

        break;

      case 'confirmed':
        status.push(workflow, 'closed', 'cancelled');

        break;

      case 'cancelled':
        status.push(
          workflow,
          'draft',
          'submitted',
          'ready',
          'approved',
          'confirmed',
          'wns',
          'closed'
        );
        break;

      case 'closed':
        status.push(workflow, 'wns');

        break;
    }

    return status;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [WorkflowChoicesPipe],
  exports: [WorkflowChoicesPipe],
})
export class WorkflowChoicesPipeModule {}
