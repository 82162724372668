import { ChangeDetectionStrategy, Component, Inject, Injectable, NgModule, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterModule, RouterStateSnapshot, UrlTree } from "@angular/router";
import { MatTabsModule } from "@angular/material/tabs";
import { BheReservationDataAccessModule, RESERVATION, ReservationService, SHOW_EDIT_TAB } from "@bhe/reservation-data-access";
import { TranslocoModule } from "@ngneat/transloco";
import { HeaderComponentModule } from "@bhe/reservation-ui";
import { Observable, take, tap, withLatestFrom } from "rxjs";
import { Reservation, ReservationWorkflow } from "@bhe/types";
import { BheDialogService, IconLoadingComponentModule } from "@bhe/ui";
import { USER_IS_BH_TEAM } from "@bhe/user-data-access";
import { WorkflowService } from "../workflow.service";
import { RequestorUpdateComponent, RequestorUpdateComponentModule } from "../edit/requestor-update/requestor-update.component";
import { AppCursorService } from "@bhe/utils";
import { LetModule, PushModule } from "@ngrx/component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ComponentStore } from "@ngrx/component-store";
import { MessageBoxComponent } from "../message-box/message-box.component";

interface ReservationComponentState {
  isXl?: boolean;
}

@Component({
  selector: "bhe-reservation",
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngrxLet="reservation$; let reservation">
        <div>
          <bhe-res-header
            [reservation]="reservation"
            [isBHTeam]="isBhTeam$ | ngrxPush"
            (workflowChanged)="onWorkflowChanged($event)"
            (updateRequestor)="onUpdateRequestor()"
          ></bhe-res-header>

          <ng-container *ngrxLet="showEditTab$; let showEditTab">
            <nav mat-tab-nav-bar mat-align-tabs="center">
              <a
                mat-tab-link
                [routerLink]="['overview']"
                routerLinkActive
                #rlaOverview="routerLinkActive"
                [active]="rlaOverview.isActive"
                [innerHTML]="t('reservation.menu.overview')"
              >
              </a>
              <ng-container *ngIf="showEditTab">
                <a
                  mat-tab-link
                  #rla="routerLinkActive"
                  [routerLink]="['edit']"
                  routerLinkActive
                  #rlaEdit="routerLinkActive"
                  [active]="rlaEdit.isActive"
                  [innerHTML]="t('reservation.menu.edit')"
                ></a>
              </ng-container>
              <a
                mat-tab-link
                [routerLink]="['message-box']"
                class="message-box-link"
                routerLinkActive
                #rlaMessage="routerLinkActive"
                [active]="rlaMessage.isActive"
                [innerHTML]="t('reservation.menu.message-box')"
              ></a>
            </nav>
          </ng-container>
          <div class="routed-content">
            <!--<ng-container *ngIf="!reservation">
              <div class="loading-group">
                <bhe-ui-icon-loading
                  class="reservation-loading"
                ></bhe-ui-icon-loading>
              </div>
            </ng-container>-->
            <router-outlet></router-outlet>
          </div>
        </div>
        <ng-container *ngrxLet="isXl$; let isXl">
          <ng-container *ngIf="isXl">
            <bhe-message-box class="embed"></bhe-message-box>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ["./reservation.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReservationComponent extends ComponentStore<ReservationComponentState> {

  private onXLBreakpoint = this.effect(() => {
    return this.breakpointObserver.observe([Breakpoints.XLarge]).pipe(
      tap(result => {
        this.patchState(state => {
          return { isXl: result.matches };
        });
      })
    );
  });

  protected isXl$ = this.select(s => s.isXl);

  constructor(
    @Inject(RESERVATION) public reservation$: Observable<Reservation>,
    @Inject(SHOW_EDIT_TAB) public showEditTab$: Observable<boolean>,
    @Inject(USER_IS_BH_TEAM) public isBhTeam$: Observable<boolean>,
    private workflowService: WorkflowService,
    private dialogService: BheDialogService,
    private reservationService: ReservationService,
    private appCursorService: AppCursorService,
    private breakpointObserver: BreakpointObserver
  ) {
    super({});
  }

  onWorkflowChanged(reservationWorkflow: ReservationWorkflow) {
    this.reservation$.pipe(take(1)).subscribe((reservation) => {
      this.workflowService.update(reservationWorkflow, reservation);
    });
  }

  onUpdateRequestor() {
    this.isBhTeam$.pipe(take(1)).subscribe(isBhTeam => {
      if (isBhTeam) {
        const dialogRef = this.dialogService.openRequestorUpdateWindow(RequestorUpdateComponent);
        if (dialogRef) {
          dialogRef.afterClosed()
            .pipe(
              withLatestFrom(this.reservation$)
            )
            .subscribe(([dialogResult, reservation]) => {
              if (dialogResult?.userId) {
                this.appCursorService.setWaitingCursor();
                this.reservationService
                  .updateRequestor(reservation, dialogResult.userId)
                  .subscribe(requestorUpdateResult => {
                    this.appCursorService.setDefaultCursor();
                  });
              }
            });
        }
      }
    });
  }
}

@Injectable({
  providedIn: "root"
})
export class ViewportGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Check viewport width
    if (window.innerWidth >= 1920) {
      // Deny access and redirect to another route
      const segments = state.url.split("/");
      const indexToRemove = segments.indexOf("message-box");
      if (indexToRemove !== -1) {
        segments[indexToRemove] = "overview"; // Replace the segment with the new one
      }
      return this.router.parseUrl(segments.join("/"));
    } else {
      // Allow access
      return true;
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    BheReservationDataAccessModule,
    RouterModule.forChild([
      {
        path: "",
        component: ReservationComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "overview"
          },
          {
            path: "overview",
            data: {
              reservationContext: "overview"
            },
            loadChildren: () =>
              import("../overview/overview.component").then(
                (m) => m.OverviewComponentModule
              )
          },
          {
            path: "edit",
            data: {
              reservationContext: "edit"
            },
            loadChildren: () =>
              import("../edit/edit.component").then(
                (m) => m.EditComponentModule
              )
          },
          {
            path: "message-box",
            data: {
              reservationContext: "message-box"
            },
            canActivate: [ViewportGuard],
            loadChildren: () =>
              import("../message-box/message-box.component").then(
                (m) => m.MESSAGE_BOX_ROUTES
              )
          }
        ]
      }
    ]),
    PushModule,
    MatTabsModule,
    TranslocoModule,
    HeaderComponentModule,
    IconLoadingComponentModule,
    RequestorUpdateComponentModule,
    LetModule,
    PushModule,
    MessageBoxComponent
  ],
  declarations: [ReservationComponent],
  exports: [ReservationComponent]
})
export class ReservationComponentModule {
}
