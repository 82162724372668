import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  NgModule,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FieldArrayType, FormlyFieldConfig,
  FormlyFormBuilder,
  FormlyModule
} from "@ngx-formly/core";
import { GuestFormComponentModule } from '../guest-form/guest-form.component';
import {
  AlertComponent,
  AlertComponentModule,
  BheButtonComponentModule,
  BheDialogComponent,
  BheDialogComponentModule,
} from '@bhe/ui';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddGuestDialogData, AlertDialogData, GuestForm } from '@bhe/types';
import {
  AddGuestComponent,
  AddGuestComponentModule,
} from '../add-guest/add-guest.component';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  FormEntityType,
  ReservationFormEntitiesService,
  ReservationService,
} from '@bhe/reservation-data-access';
import { switchMap, take, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GuestFormRefComponentModule } from '../guest-form-ref/guest-form-ref.component';

@Component({
  selector: 'bhe-res-repeat-guest',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngIf="formArray">
        <div class="b-guest-forms">
          <ng-template
            ngFor
            [ngForOf]="formArray.controls"
            let-guestRefControl
            let-i="index"
          >
            <div class="b-guest">
              <formly-field
                [field]="
                  getFieldConfig({
                    type: 'guestFormRef',
                    formControl: guestRefControl,
                    form: form,
                    modelOptions: {
                      updateOn: 'change'
                    }
                  })
                "
              ></formly-field>
              <ng-container *ngIf="!to?.disabled">
                <bhe-ui-bhe-button
                  class="remove-guest-btn"
                  variant="soft"
                  color="warn"
                  icon="cancel-circle2"
                  (btnClick)="onRemoveGuest(i)"
                >
                  <span [innerHTML]="t('actions.remove-guest')"></span>
                </bhe-ui-bhe-button>
              </ng-container>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="!to?.disabled">
          <bhe-ui-bhe-button
            class="add-guest-btn"
            variant="soft"
            color="accent"
            icon="add"
            (btnClick)="onAddGuest()"
          >
            <span [innerHTML]="t('actions.add-guest')"></span>
          </bhe-ui-bhe-button>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./repeat-guest.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatGuestComponent extends FieldArrayType {
  constructor(
    private matDialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private reservationService: ReservationService,
    private reservationFormEntitiesService: ReservationFormEntitiesService,
    private transloco: TranslocoService,
    public formlyFormBuilder: FormlyFormBuilder
  ) {
    super();
  }

  onAddGuest() {
    const addGuestEmitter: EventEmitter<ResourceIdentifier> =
      new EventEmitter<ResourceIdentifier>();

    const dialogData: AddGuestDialogData = {
      component: AddGuestComponent,
      reservationId: this.form.value.id,
      addGuestEmitter,
    };
    const addGuestSubscription = addGuestEmitter
      .asObservable()
      .subscribe((guestIdentifier) => {
        this.add(undefined, guestIdentifier);
      });
    this.matDialog
      .open(BheDialogComponent, {
        disableClose: true,
        data: dialogData,
        viewContainerRef: this.viewContainerRef,
      })
      .afterClosed()
      .subscribe(() => {
        addGuestSubscription.unsubscribe();
      });
  }

  onRemoveGuest(index: number) {
    const guestRefFormControl: UntypedFormGroup = this.formControl.at(
      index
    ) as UntypedFormGroup;
    if (guestRefFormControl) {
      const guestRef: ResourceIdentifier = guestRefFormControl?.value;
      const guestId: string = guestRef?.id;
      if (guestId) {
        this.reservationFormEntitiesService
          .getEntity(guestId)
          .pipe(take(1))
          .subscribe((guestFormEntity) => {
            if (guestFormEntity) {
              const guestToRemove: GuestForm =
                guestFormEntity.entity as GuestForm;

              const removeEmitter: EventEmitter<any> = new EventEmitter<any>();

              const bheDialogData: AlertDialogData = {
                component: AlertComponent,
                message: this.transloco.translate(
                  'alert.remove.guest.message',
                  {
                    user: guestToRemove.field_full_name,
                  }
                ),
                process: null,
                actions: [
                  {
                    id: 'yes',
                    label: this.transloco.translate('actions.yes'),
                    color: 'primary',
                    emitter: removeEmitter,
                  },
                  {
                    id: 'no',
                    label: this.transloco.translate('actions.no'),
                    color: 'text',
                    close: true,
                  },
                ],
              };

              const dialogRef: MatDialogRef<
                BheDialogComponent,
                'error' | 'no'
              > = this.matDialog.open(BheDialogComponent, {
                disableClose: true,
                panelClass: 'alert',
                data: bheDialogData,
              });

              const removeEmitterSubscription = removeEmitter
                .asObservable()
                .pipe(
                  take(1),
                  tap(() => {
                    bheDialogData.process = 'running';
                  }),
                  switchMap(() => {
                    return this.reservationService.removeRelationshipsFieldMulti(
                      this.form.value,
                      'field_reservation_guests',
                      guestRef
                    );
                  })
                )
                .subscribe(
                  (
                    entities:
                      | { [type: string]: FormEntityType[] }
                      | HttpErrorResponse
                  ) => {
                    if (!(entities instanceof HttpErrorResponse)) {
                      Object.keys(entities).forEach((key) => {
                        const typeData: FormEntityType[] = entities[key];
                        if (typeData) {
                          typeData.forEach((data) => {
                            this.reservationFormEntitiesService.addEntity(
                              data,
                              'IN_SYNC'
                            );
                          });
                        }
                      });
                      this.remove(index);
                      dialogRef.close();
                    } else {
                      bheDialogData.process = 'error';
                    }
                  }
                );

              dialogRef.afterClosed().subscribe(() => {
                removeEmitterSubscription.unsubscribe();
              });
            }
          });
      }
    }
  }

  public get formArray(): UntypedFormArray {
    return this.formControl as UntypedFormArray;
  }

  public getFieldConfig(field: FormlyFieldConfig): FormlyFieldConfig {
    this.formlyFormBuilder.build(field);
    field.templateOptions = {
      ...field.templateOptions,
      restricted: true
    }
    return field;
  }

  identify(index: number, item: UntypedFormControl) {
    return item?.value?.id | index;
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormlyModule,
    GuestFormComponentModule,
    BheDialogComponentModule,
    BheButtonComponentModule,
    AddGuestComponentModule,
    TranslocoModule,
    AlertComponentModule,
    GuestFormRefComponentModule,
    ReactiveFormsModule,
  ],
  declarations: [RepeatGuestComponent],
  exports: [RepeatGuestComponent],
})
export class RepeatGuestComponentModule {}
