import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as GeozoneActions from './geozone.actions';
import { GeoZone } from '@bhe/types';

export const geozonesFeatureKey = 'geozones';

export interface State extends EntityState<GeoZone> {
  // additional entities state properties
}

export const adapter: EntityAdapter<GeoZone> = createEntityAdapter<GeoZone>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(GeozoneActions.addGeozone, (state, action) =>
    adapter.addOne(action.geozone, state)
  ),
  on(GeozoneActions.upsertGeozone, (state, action) =>
    adapter.upsertOne(action.geozone, state)
  ),
  on(GeozoneActions.addGeozones, (state, action) =>
    adapter.addMany(action.geozones, state)
  ),
  on(GeozoneActions.upsertGeozones, (state, action) =>
    adapter.upsertMany(action.geozones, state)
  ),
  on(GeozoneActions.updateGeozone, (state, action) =>
    adapter.updateOne(action.geozone, state)
  ),
  on(GeozoneActions.updateGeozones, (state, action) =>
    adapter.updateMany(action.geozones, state)
  ),
  on(GeozoneActions.deleteGeozone, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(GeozoneActions.deleteGeozones, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(GeozoneActions.loadGeozones, (state, action) =>
    adapter.setAll(action.geozones, state)
  ),
  on(GeozoneActions.clearGeozones, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectGeozoneEntities = selectEntities;
