import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as BrandActions from './brand.actions';
import { Brand } from '@bhe/types';

export const brandsFeatureKey = 'brands';

export interface State extends EntityState<Brand> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Brand> = createEntityAdapter<Brand>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(BrandActions.addBrand, (state, action) =>
    adapter.addOne(action.brand, state)
  ),
  on(BrandActions.upsertBrand, (state, action) =>
    adapter.upsertOne(action.brand, state)
  ),
  on(BrandActions.addBrands, (state, action) =>
    adapter.addMany(action.brands, state)
  ),
  on(BrandActions.upsertBrands, (state, action) =>
    adapter.upsertMany(action.brands, state)
  ),
  on(BrandActions.updateBrand, (state, action) =>
    adapter.updateOne(action.brand, state)
  ),
  on(BrandActions.updateBrands, (state, action) =>
    adapter.updateMany(action.brands, state)
  ),
  on(BrandActions.deleteBrand, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(BrandActions.deleteBrands, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(BrandActions.loadBrands, (state, action) =>
    adapter.setAll(action.brands, state)
  ),
  on(BrandActions.clearBrands, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectBrandsIds = selectIds;

// select the dictionary of user entities
export const selectBrandEntities = selectEntities;
