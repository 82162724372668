import {Inject, Injectable, Optional} from '@angular/core';
import {ReservationPartForm} from '@bhe/types';
import {UntypedFormGroup} from '@angular/forms';
import {
  catchError,
  debounceTime,
  filter,
  map,
  Observable,
  ReplaySubject,
  skip,
  startWith,
  switchMap,
  take,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';
import {BOTTLES_SOLD_FORM} from '@bhe/shell-state';
import {
  FormEntity,
  FormEntityType,
  ReservationFormEntitiesService,
  ReservationService,
  USER_HAS_REQUESTOR_ROLE,
} from '@bhe/reservation-data-access';
import {isEqual} from '@madeinlune/ngx-operators';
import {HttpErrorResponse} from '@angular/common/http';
import {RxState, selectSlice} from '@rx-angular/state';
import {AppCursorService} from '@bhe/utils';
import {getField} from '../../reservation.fields';
import {combineLatest} from 'rxjs';
import {ReservationFormService} from "../reservation-form/reservation-form.service";
import { FormlyFieldConfig } from "@ngx-formly/core";

@Injectable()
export class ReservationPartService extends RxState<{
  saving: boolean;
}> {
  reservationPartForm = new UntypedFormGroup({});
  formValid$: Observable<boolean> = this.reservationPartForm.valueChanges.pipe(
    startWith(false),
    map((value) => this.reservationPartForm.valid)
  );
  fields$: Observable<FormlyFieldConfig[]> = this.bottlesSoldForm$.pipe(
    filter((bottlesSoldForm) => !!bottlesSoldForm),
    withLatestFrom(this.userHasRequestorRole$),
    map(([bottlesSoldForm, userHasRequestorRole]) => {
      return [
        {
          key: 'id',
          className: 'd-none',
        },
        {
          key: 'type',
          className: 'd-none',
        },
        {
          key: 'field_brand',
          className: 'd-none',
        },
        {
          wrappers: ['bheWrapper'],
          props: {
            className: 'fields-block',
            label: this.translocoService.translate(
              'reservation-part.form.guest-experience.label'
            ),
          },
          fieldGroup: [
            {
              ...getField(this.translocoService, 'field_guest_experience', ''),
              type: 'guestExperiences',
            },
          ],
        },
        {
          wrappers: ['bottleSold'],
          props: {
            className: 'fields-block',
            label: this.translocoService.translate(
              'reservation-part.form.bottle-sold.block-title'
            ),
          },
          fieldGroup: [
            {
              ...getField(
                this.translocoService,
                'field_bottles_sold',
                'reservation-part.form.bottle-sold.quantity.label',
                {
                  options: bottlesSoldForm,
                }
              ),
              type: 'select',
            },
            {
              ...getField(
                this.translocoService,
                'field_bottles_sold_other',
                'reservation-part.form.bottle-sold.other.label'
              ),
              hideExpression: (model:any, formState:any, field:any) => {
                return model.field_bottles_sold !== 'other';
              },
              type: 'input',
            },
          ],
        },
        {
          wrappers: ['bheWrapper'],
          props: {
            className: 'fields-block',
            label: this.translocoService.translate(
              'reservation-part.form.objectives-of-the-visit.block-title'
            ),
          },
          fieldGroup: [
            {
              ...getField(
                this.translocoService,
                'field_visit_theme',
                'reservation-part.form.objectives-of-the-visit.visit-theme.label'
              ),
              type: 'textArea',
            },
            {
              ...getField(
                this.translocoService,
                'field_other_remarks',
                'reservation-part.form.objectives-of-the-visit.other-remarks.label'
              ),
              type: 'textArea',
            },
          ],
        },
        ...(userHasRequestorRole
          ? []
          : [
            {
              type: 'brandDataInjectorButton',
              className: 'brand-data-injector-button',
            },
          ]),
        ...(userHasRequestorRole
          ? []
          : [
            {
              wrappers: ['bheWrapper'],
              props: {
                className: 'fields-block',
                label: this.translocoService.translate(
                  'reservation-part.form.contact.block-title'
                ),
              },
              fieldGroup: [
                {
                  ...getField(
                    this.translocoService,
                    'field_contact_info',
                    'reservation-part.form.contact.info.label'
                  ),
                  type: 'textArea',
                },
                {
                  ...getField(
                    this.translocoService,
                    'field_contact_phone',
                    'reservation-part.form.contact.phone.label'
                  ),
                  type: 'input',
                },
              ],
            },
          ]),
        ...(userHasRequestorRole
          ? []
          : [
            {
              wrappers: ['bheWrapper'],
              props: {
                className: 'fields-block',
                label: this.translocoService.translate(
                  'reservation-part.form.program.block-title'
                ),
              },
              fieldGroup: [
                {
                  ...getField(
                    this.translocoService,
                    'field_program_description_short',
                    'reservation-part.form.program.short-description.label'
                  ),
                  type: 'textArea',
                },
                {
                  ...getField(
                    this.translocoService,
                    'field_program_description_long',
                    'reservation-part.form.program.long-description.label'
                  ),
                  type: 'textArea',
                },
                {
                  ...getField(
                    this.translocoService,
                    'field_recommendations',
                    'reservation-part.form.program.recommendations.label'
                  ),
                  type: 'textArea',
                },
                {
                  ...getField(
                    this.translocoService,
                    'field_dress_code',
                    'reservation-part.form.program.dress-code.label'
                  ),
                  type: 'input',
                },
              ],
            },
          ]),
        {
          wrappers: ['bheWrapper'],
          props: {
            className: 'fields-block',
            label: this.translocoService.translate(
              'reservation-part.form.transport.block-title'
            ),
          },
          fieldGroup: [
            {
              ...getField(
                this.translocoService,
                'field_transport_needed',
                'reservation-part.form.transport.needed.label'
              ),
              type: 'checkbox',
            },
            {
              ...getField(
                this.translocoService,
                'field_transport_pickup',
                'reservation-part.form.transport.pick-up.label',
                {
                  rows: 3,
                }
              ),
              type: 'textarea',
              hideExpression: (model, formState, field) => {
                return model.field_transport_needed !== true;
              },
            },
            {
              ...getField(
                this.translocoService,
                'field_transport_dropoff',
                'reservation-part.form.transport.drop-off.label',
                {
                  rows: 3,
                }
              ),
              type: 'textarea',
              hideExpression: (model, formState, field) => {
                return model.field_transport_needed !== true;
              },
            },
            {
              ...getField(
                this.translocoService,
                'field_transport_details',
                'reservation-part.form.transport.details.label',
                {
                  rows: 5,
                }
              ),
              type: 'textarea',
              hideExpression: (model, formState, field) => {
                return model.field_transport_needed !== true;
              },
            },
          ],
        },
      ];
    })
  );

  reservationPartModel$: ReplaySubject<ReservationPartForm | undefined> =
    new ReplaySubject<ReservationPartForm | undefined>(1);

  readonly saving$: Observable<boolean> = this.select('saving');

  constructor(
    @Inject(BOTTLES_SOLD_FORM)
    private bottlesSoldForm$: Observable<{ value: string; label: string }[]>,
    @Inject(USER_HAS_REQUESTOR_ROLE)
    private userHasRequestorRole$: Observable<boolean>,
    private translocoService: TranslocoService,
    private reservationService: ReservationService,
    private reservationFormEntitiesService: ReservationFormEntitiesService,
    private appCursorService: AppCursorService,
    @Optional() private reservationFormService?: ReservationFormService,
  ) {
    super();
    this.set({saving: false});

    this.hold(this.select(selectSlice(['saving'])), ({saving}) => {
      if (this.reservationFormService) {
        this.reservationFormService.set({saving});
      }
    });

    combineLatest([this.reservationPartForm.valueChanges, this.saving$])
      .pipe(
        filter(([value, saving]) => {
          return value.id && value.type && !saving;
        }),
        map(([value]) => {
          return value;
        }),
        debounceTime(200),
        isEqual(),
        skip(1),
        tap((reservationPartForm: ReservationPartForm) => {
          this.reservationFormEntitiesService.updateEntity(
            reservationPartForm,
            'UPDATED'
          );
        }),
        withLatestFrom(this.reservationFormEntitiesService.entities$),
        switchMap(
          ([value, entities]: [
            any,
            {
              [id: string]: FormEntity;
            }
          ]) => {
            this.set({saving: true});
            return this.reservationService.saveEntities(entities);
          }
        )
      )
      .subscribe((result) => {
        this.set({saving: false});
        if (
          !(result instanceof HttpErrorResponse) &&
          result !== 'no request needed'
        ) {
          Object.keys(result).forEach((key) => {
            const typeData: FormEntityType[] = result[key];
            if (Array.isArray(typeData)) {
              typeData.forEach((data) => {
                this.reservationFormEntitiesService.addEntity(data, 'IN_SYNC');
              });
            }
          });
        } else if (result instanceof HttpErrorResponse) {
          //TODO manage error
          console.error(
            '[RESERVATION PART] an error occured while saving reservation Part',
            {
              result,
            }
          );
        }
      });
  }

  public loadModel(id: string) {
    //TODO Unsubscribe
    this.reservationFormEntitiesService
      .getEntity(id)
      .pipe(
        map((reservationPartResource) => {
          return reservationPartResource?.entity;
        }),
        take(1),
        catchError((error) => {
          return throwError(
            () =>
              `an error occured while computing reservation part form, ${error}`
          );
        })
      )
      .subscribe((formModel: FormEntityType | undefined) => {
        this.reservationPartModel$.next(formModel as ReservationPartForm);
      });
  }
}
