export function isJsonApiError(error: any): boolean {
  return !!error.jsonapi;
}

export function jsonApiErrors(error: any): any[] {
  return error.errors;
}

export function isWorkingCopyError(error: any): boolean {
  const isJApiError: boolean = isJsonApiError(error);
  return isJApiError && jsonApiErrors(error).length > 0;
}

export function isSQLError(error: any): boolean {
  return (
    isJsonApiError(error) &&
    jsonApiErrors(error).filter((e) => {
      return e.status === '500' && e.detail.indexOf('SQLSTATE') > -1;
    }).length > 0
  );
}
