import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MhProfileAutocompleteComponentModule } from '../mh-profile-autocomplete/mh-profile-autocomplete.component';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { FieldArrayType, FieldTypeConfig, FormlyModule } from "@ngx-formly/core";

@Component({
  selector: 'bhe-mh-field-multi',
  template: `
    <bhe-res-mh-profile-autocomplete
      [formlyAttributes]="field"
      [readonly]="!!field.props.disabled"
      [addMax]="2"
      [label]="field.props.label"
      [value]="formControl.value"
      (valueChanges)="onValueChanges($event)"
    ></bhe-res-mh-profile-autocomplete>
  `,
  styleUrls: ['./mh-field-multi.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MhFieldMultiComponent extends FieldArrayType<FieldTypeConfig> {
  constructor() {
    super();
  }

  onValueChanges(profiles: ResourceIdentifier[]) {
    if (this.formControl) {
      const currentValue: ResourceIdentifier[] = this.formControl.value;
      let mode: 'add' | 'remove' | undefined;
      if (profiles.length > currentValue.length) {
        mode = 'add';
      } else if (profiles.length < currentValue.length) {
        mode = 'remove';
      }
      if (mode) {
        if (mode === 'add') {
          profiles.forEach((profile, i) => {
            const hasProfileInCurrentValue = !!currentValue.find(
              (r: ResourceIdentifier) => r.id === profile.id
            );
            if (!hasProfileInCurrentValue) {
              this.add(i, profile, { markAsDirty: false });
            }
          });
        } else if (mode === 'remove') {
          currentValue.forEach((profile, i) => {
            const hasProfileInFutureValue = !!profiles.find(
              (r: ResourceIdentifier) => r.id === profile.id
            );
            if (!hasProfileInFutureValue) {
              this.remove(i, { markAsDirty: false });
            }
          });
        }
      }
    }
  }
}

@NgModule({
  imports: [CommonModule, MhProfileAutocompleteComponentModule, FormlyModule],
  declarations: [MhFieldMultiComponent],
  exports: [MhFieldMultiComponent],
})
export class MhFieldMultiComponentModule {}
