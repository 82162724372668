import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGeoZone from './geozone.reducer';
import { geozonesFeatureKey } from './geozone.reducer';

export const selectBrandState =
  createFeatureSelector<fromGeoZone.State>(geozonesFeatureKey);

export const selectGeozoneEntities = createSelector(
  selectBrandState,
  fromGeoZone.selectGeozoneEntities
);
