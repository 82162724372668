import { AuthConfig } from 'angular-oauth2-oidc';

export function getAuthConfig(
  backEndUrl: string,
  clientId: string
): AuthConfig {
  return {
    requireHttps: false,
    loginUrl: `${backEndUrl}/oauth/authorize`,
    redirectUri: window.location.origin,
    tokenEndpoint: `${backEndUrl}/oauth/token`,
    userinfoEndpoint: `${backEndUrl}/oauth/UserInfo`,
    issuer: `${backEndUrl}`,
    clientId,
    scope: 'openid',
    oidc: true,
    responseType: 'code',
    skipIssuerCheck: true,
    disableAtHashCheck: true,
    skipSubjectCheck: true,
    clearHashAfterLogin: true,
    showDebugInformation: true,
  };
}
