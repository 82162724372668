import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Brand } from '@bhe/types';

export const loadBrands = createAction(
  '[Brand/API] Load Brands',
  props<{ brands: Brand[] }>()
);

export const addBrand = createAction(
  '[Brand/API] Add Brand',
  props<{ brand: Brand }>()
);

export const upsertBrand = createAction(
  '[Brand/API] Upsert Brand',
  props<{ brand: Brand }>()
);

export const addBrands = createAction(
  '[Brand/API] Add Brands',
  props<{ brands: Brand[] }>()
);

export const upsertBrands = createAction(
  '[Brand/API] Upsert Brands',
  props<{ brands: Brand[] }>()
);

export const updateBrand = createAction(
  '[Brand/API] Update Brand',
  props<{ brand: Update<Brand> }>()
);

export const updateBrands = createAction(
  '[Brand/API] Update Brands',
  props<{ brands: Update<Brand>[] }>()
);

export const deleteBrand = createAction(
  '[Brand/API] Delete Brand',
  props<{ id: string }>()
);

export const deleteBrands = createAction(
  '[Brand/API] Delete Brands',
  props<{ ids: string[] }>()
);

export const clearBrands = createAction('[Brand/API] Clear Brands');
