import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldType } from '@ngx-formly/material';
import { Observable, startWith, switchMap } from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldTypeConfig, FormlyModule } from "@ngx-formly/core";
import { MatInputModule } from '@angular/material/input';
import { PushModule } from "@ngrx/component";
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'bhe-ui-autocomplete',
  template: `
    <input
      matInput
      [matAutocomplete]="auto"
      [formControl]="control"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder!"
      [errorStateMatcher]="errorStateMatcher"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith">
      <mat-option *ngFor="let value of filter$ | ngrxPush" [value]="value.id">
        {{ value.label }}
      </mat-option>
    </mat-autocomplete>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent extends FieldType<FieldTypeConfig> implements OnInit {
  public get control() {
    return this.formControl as UntypedFormControl;
  }

  filter$!: Observable<any>;

  constructor(private translocoService: TranslocoService) {
    super();
  }

  ngOnInit() {
    this.filter$ = this.control.valueChanges.pipe(
      startWith(''),
      switchMap((term) => {
        return this.to['filter'](term);
      })
    );
  }

  displayWith = (countryCode: string) => {
    if (countryCode) {
      return this.translocoService.translate(`countries.${countryCode}`);
    }
    return '';
  };
}

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormlyModule,
    MatInputModule,
    PushModule
  ],
  declarations: [AutocompleteComponent],
  exports: [AutocompleteComponent],
})
export class AutocompleteComponentModule {}
