import { catchError, map, Observable, throwError } from 'rxjs';
import { Resource } from '@madeinlune/ngx-json-api';
import {
  ApproversFilter,
  BhTeamFilter,
  Brand,
  BrandForm,
  CorporatePage,
  CorporatePageForm,
  DrupalFile,
  Export,
  GeoZone,
  Guest,
  GuestExperience,
  GuestExperienceForm,
  GuestForm,
  GuestType,
  GuestTypeForm,
  Message,
  MhProfile,
  ObserversFilter,
  Reservation,
  ReservationForm,
  ReservationPart,
  ReservationPartForm,
  Role,
  User,
} from '@bhe/types';
import { plainToClass } from 'class-transformer';

export function bheClasses(
  isForm: boolean = false,
  pressId: string | null = null
) {
  // tslint:disable-next-line:only-arrow-functions
  return function <T>(source: Observable<Resource[]>) {
    return source.pipe(
      map((resources) => {
        return resources
          .map((resource) => {
            if (resource?.type === Reservation.type && !isForm) {
              return plainToClass(Reservation, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            }
            if (resource?.type === Reservation.type && isForm) {
              const isPress: boolean =
                resource.relationships?.['field_guest_type']?.data?.id ===
                pressId;
              const reservationForm = plainToClass(ReservationForm, resource, {
                groups: isPress ? ['press'] : [],
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
              return reservationForm;
            } else if (resource?.type === Brand.type && !isForm) {
              return plainToClass(Brand, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === Brand.type && isForm) {
              return plainToClass(BrandForm, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === DrupalFile.type) {
              return plainToClass(DrupalFile, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === GuestExperience.type && !isForm) {
              return plainToClass(GuestExperience, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === GuestExperience.type && isForm) {
              return plainToClass(GuestExperienceForm, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === GuestType.type && !isForm) {
              return plainToClass(GuestType, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === GuestType.type && isForm) {
              return plainToClass(GuestTypeForm, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === User.type) {
              return plainToClass(User, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === Role.type) {
              return plainToClass(Role, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === Message.type) {
              return plainToClass(Message, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === Guest.type && !isForm) {
              return plainToClass(Guest, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === Guest.type && isForm) {
              return plainToClass(GuestForm, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === ReservationPart.type && !isForm) {
              return plainToClass(ReservationPart, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === ReservationPart.type && isForm) {
              return plainToClass(ReservationPartForm, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === CorporatePage.type && !isForm) {
              return plainToClass(CorporatePage, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === CorporatePage.type && isForm) {
              return plainToClass(CorporatePageForm, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === MhProfile.type) {
              return plainToClass(MhProfile, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === GeoZone.type) {
              return plainToClass(GeoZone, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === ApproversFilter.type) {
              return plainToClass(ApproversFilter, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === BhTeamFilter.type) {
              return plainToClass(BhTeamFilter, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === ObserversFilter.type) {
              return plainToClass(ObserversFilter, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else if (resource?.type === Export.type) {
              return plainToClass(Export, resource, {
                excludeExtraneousValues: true,
                exposeUnsetFields: false,
              });
            } else {
              // console.warn('content not processed', resource?.type);
              return resource;
            }
          })
          .filter((model) => model !== null);
      }),
      map((resources) => {
        return resources.reduce((r: any, a) => {
          if (a) {
            r[a.type] = [...(r[a.type] || []), a];
          }
          return r;
        }, {});
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  };
}
