import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { GetBrandGuestExperiencesPipeModule } from '@bhe/vocabularies-data';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-res-approbation-guest-experiences',
  template: `
    <ng-container *transloco="let t">
      <h4
        class="title"
        [innerHTML]="t('reservation-part.approbation.guest-experience.title')"
      ></h4>
      <mat-radio-group [formControl]="formControl">
        <ng-container
          *ngrxLet="brandRef | getBrandGuestExperiences; let guestExperiences"
        >
          <ng-template ngFor [ngForOf]="guestExperiences" let-guestExperience>
            <ng-container *ngIf="guestExperience.enabled">
              <mat-radio-button [value]="guestExperience.id">{{
                guestExperience.name
              }}</mat-radio-button>
            </ng-container>
          </ng-template>
        </ng-container>
      </mat-radio-group>
      <div
        class="caption"
        [innerHTML]="t('reservation-part.approbation.guest-experience.hints')"
      ></div>
    </ng-container>
  `,
  styleUrls: ['./approbation-guest-experiences.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApprobationGuestExperiencesComponent implements OnChanges {
  formControl: UntypedFormControl = new UntypedFormControl();

  @Input()
  brandRef!: ResourceIdentifier;

  @Input()
  guestExperienceRef!: ResourceIdentifier;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['guestExperienceRef'].firstChange) {
      this.formControl.setValue(this.guestExperienceRef.id);
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    GetBrandGuestExperiencesPipeModule,
    ReactiveFormsModule,
    TranslocoModule,
    LetModule
  ],
  declarations: [ApprobationGuestExperiencesComponent],
  exports: [ApprobationGuestExperiencesComponent],
})
export class ApprobationGuestExperiencesComponentModule {}
