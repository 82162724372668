import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  NgModule,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PortalModule} from '@angular/cdk/portal';
import {PortalService} from '../portal.service';
import {Observable, pairwise, startWith} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BheProMenuComponentModule} from '@bhe/ui';
import {Menu} from '@bhe/types';
import { LetModule, PushModule } from "@ngrx/component";

export const BHE_PRO_LAYOUT_MODE = new InjectionToken<
  Observable<'home' | 'detail'>
>('BHE_PRO_LAYOUT_MODE');

export const BHE_PRO_MENU = new InjectionToken<Menu>('BHE_PRO_MENU');

@UntilDestroy()
@Component({
  selector: 'bhe-pro-layout',
  template: `
    <ng-container *ngrxLet="layoutMode$; let layoutMode">
      <aside>
        <ng-container *ngIf="layoutMode === 'detail'">
          <div class="left-container">
            <ng-container *ngIf="listPortal$ | ngrxPush as listPortal">
              <ng-template [cdkPortalOutlet]="listPortal"></ng-template>
            </ng-container>
          </div>
        </ng-container>
      </aside>
      <main>
        <router-outlet></router-outlet>
      </main>
      <aside>
        <ng-container *ngIf="layoutMode === 'detail'">
          <bhe-ui-bhe-pro-menu [menu]="menu"></bhe-ui-bhe-pro-menu>
        </ng-container>
      </aside>
    </ng-container>
  `,
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  listPortal$ = this.portalService.listPortal$;

  constructor(
    @Inject(BHE_PRO_LAYOUT_MODE)
    public layoutMode$: Observable<'home' | 'detail'>,
    @Inject(BHE_PRO_MENU)
    public menu: Menu,
    private portalService: PortalService,
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef
  ) {
    this.layoutMode$
      .pipe(untilDestroyed(this), startWith(null), pairwise())
      .subscribe(([previousLayoutMode, nextLayoutMode]) => {
        if (previousLayoutMode) {
          this.renderer.removeClass(this.el.nativeElement, previousLayoutMode);
        }
        if (nextLayoutMode) {
          this.renderer.addClass(this.el.nativeElement, nextLayoutMode);
        }
      });

    this.listPortal$.pipe(untilDestroyed(this)).subscribe(() => {
      setTimeout(() => {
        this.cdr.markForCheck();
      });
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PortalModule,
    BheProMenuComponentModule,
    PushModule,
    LetModule
  ],
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
})
export class LayoutComponentModule {}
