import {
  ChangeDetectionStrategy,
  Component, Inject,
  Input,
  NgModule,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReservationPart } from "@bhe/types";
import { MatExpansionModule } from "@angular/material/expansion";
import { OverviewReservationPartComponentModule } from "../overview-reservation-part/overview-reservation-part.component";
import { ReactiveComponentModule } from "@ngrx/component";
import {
  BrandPipeModule,
  GuestExperiencePipeModule
} from "@bhe/vocabularies-data";
import { BheIconComponentModule, BrandIconsComponentModule } from "@bhe/ui";
import { BrandPanelTitleComponentModule } from "../../form/brand-panel-title/brand-panel-title.component";
import { NgArrayPipesModule } from "ngx-pipes";
import { RESERVATION_HAS_GUEST_EXPERIENCES, RESERVATION_MISSING_GUEST_EXPERIENCES } from "@bhe/reservation-data-access";
import { Observable } from "rxjs";
import { ResourceIdentifier } from "@madeinlune/ngx-json-api";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: "bhe-res-overview-reservation-parts",
  template: `
    <ng-container *ngIf="reservationParts">
      <mat-accordion [multi]="true">
        <ng-template
          ngFor
          [ngForOf]="reservationParts | truthify"
          let-reservationPart
          [ngForTrackBy]="identify"
        >
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <ng-container
                  *ngrxLet="reservationPart.brandRef | brand; let brand"
                >
                  <bhe-res-brand-panel-title
                    [brand]="brand"
                    [guestExperience]="
                      reservationPart.guestExperienceRef
                        | guestExperience
                        | ngrxPush
                    "
                  ></bhe-res-brand-panel-title>
                </ng-container>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <bhe-res-overview-reservation-part
                [reservationPart]="reservationPart"
              ></bhe-res-overview-reservation-part>
            </ng-template>
          </mat-expansion-panel>
        </ng-template>
      </mat-accordion>
    </ng-container>
    <ng-container *ngrxLet="hasGuestExperiences$; let hasGuestExperiences">
      <ng-container *ngIf="!hasGuestExperiences">
        <ng-container *transloco="let t">
          <div class="missing-guest-experiences">
            <p [innerHTML]="t('reservation.missing-guest-experiences.message')"></p>
            <ng-container *ngrxLet="missingGuestExperiences$; let brandRefs">
              <ng-container *ngIf="brandRefs">
                <ng-template ngFor [ngForOf]="brandRefs" let-brandRef>
                  <ng-container *ngrxLet="brandRef|brand; let brand">
                    <ng-container *ngIf="brand">
                      <div class="brand-error">
                        <bhe-ui-bhe-icon [icon]="brand.code"></bhe-ui-bhe-icon>
                        <span [innerHTML]="brand.name"></span>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ["./overview-reservation-parts.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewReservationPartsComponent {
  @Input()
  reservationParts!: ReservationPart[];

  identify(index: number, item: ReservationPart) {
    return item.id;
  }

  constructor(
    @Inject(RESERVATION_HAS_GUEST_EXPERIENCES) protected hasGuestExperiences$: Observable<boolean>,
    @Inject(RESERVATION_MISSING_GUEST_EXPERIENCES) protected missingGuestExperiences$: Observable<ResourceIdentifier[]>
  ) {
  }

}

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    OverviewReservationPartComponentModule,
    ReactiveComponentModule,
    BrandPipeModule,
    BheIconComponentModule,
    BrandPanelTitleComponentModule,
    GuestExperiencePipeModule,
    NgArrayPipesModule,
    TranslocoModule
  ],
  declarations: [OverviewReservationPartsComponent],
  exports: [OverviewReservationPartsComponent]
})
export class OverviewReservationPartsComponentModule {
}
