import { Inject, Injectable } from '@angular/core';
import { RxState, selectSlice } from '@rx-angular/state';
import {
  catchError,
  combineLatest,
  filter,
  map,
  Observable,
  startWith,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { ApolloQueryResult } from '@apollo/client';
import { plainToInstance } from 'class-transformer';
import { Brand, GuestExperience, GuestType, Reservation } from '@bhe/types';
import {
  ConditionGroup,
  ConditionGroupInput,
} from '@bhe/reservation-list-data-access';
import { Apollo, QueryRef } from 'apollo-angular';
import { statisticsGql } from './statistics.gql';
import {
  BRANDS,
  GetBrandGuestExperiencesPipe,
  GUEST_EXPERIENCES_MAP,
  GUEST_TYPES,
  guestExperienceFacet,
} from '@bhe/vocabularies-data';
import { getBrand } from '@nx-agency/bhe/operators';
import { OrderByPipe } from 'ngx-pipes';

export type StatisticsFacetField =
  | 'field_guest_type'
  | 'field_workflow_reservation'
  | 'field_country'
  | 'field_brands_codes_array'
  | 'reservation_month'
  | 'reservation_year'
  | 'count_field_reservation_parts'
  | 'field_guest_experience';

export interface StatisticsFacet {
  name: StatisticsFacetField;
  values: StatisticsFacetValue[];
}

export interface StatisticsFacetValue {
  count: number;
  filter: string;
}

@Injectable()
export class StatisticsService extends RxState<{
  isLoading: boolean;
  count: number;
  facets: StatisticsFacet[];
  fromDate: string;
  toDate: string;
  brands: string[];
  guestTypes: string[];
  status: string[];
  countries: string[];
}> {
  readonly facets$ = this.select('facets');
  readonly count$ = this.select('count');
  readonly monthFacet$ = this.facets$.pipe(
    map((facets) => {
      return facets.find((facet) => facet.name === 'reservation_month');
    }),
    filter((facet) => !!facet),
    map((facet) => {
      return [
        {
          name: 'reservations',
          series: this.orderByPipe.transform(
            facet?.values.map((val) => {
              return { name: val.filter, value: val.count };
            }),
            'name'
          ),
        },
      ];
    })
  );
  readonly workflowFacet$ = this.facets$.pipe(
    map((facets: any) => {
      return facets.find(
        (facet: any) => facet.name === 'field_workflow_reservation'
      );
    }),
    map((facet) => {
      return facet?.values.map((val: any) => {
        return { name: val.filter, value: val.count };
      });
    })
  );
  readonly countryFacet$: Observable<StatisticsFacet> = this.facets$.pipe(
    map((facets: any) => {
      return facets.find((facet: any) => facet.name === 'field_country');
    }),
    filter((facet) => !!facet)
  ) as Observable<StatisticsFacet>;

  readonly brandsFacet$: Observable<StatisticsFacet> = combineLatest([
    this.brands$,
    this.facets$,
  ]).pipe(
    map(([brands, facets]) => {
      const brandFacet = facets.find(
        (facet) => facet.name === 'field_brands_codes_array'
      );
      return brandFacet?.values
        .filter((f: any) => f.filter !== 'pa')
        .map((val: any) => {
          const brandName = brands.find(
            (brand) => brand.code === val.filter
          )?.name;
          return {
            name: brandName,
            value: val.count,
          };
        });
    }),
    filter((facet) => !!facet)
  ) as Observable<any>;

  readonly guestTypesFacet$: Observable<StatisticsFacet> = combineLatest([
    this.guestTypes$,
    this.facets$,
  ]).pipe(
    map(([guestTypes, facets]) => {
      const guestTypeFacet = facets.find(
        (facet) => facet.name === 'field_guest_type'
      );
      return guestTypeFacet?.values.map((val: any) => {
        const guestTypeName =
          guestTypes.find(
            (guestType: any) => guestType.tid === parseInt(val.filter)
          )?.name ?? '';
        return {
          name: guestTypeName,
          value: val.count,
        };
      });
    }),
    filter((facet) => !!facet)
  ) as Observable<any>;

  readonly reservationPartCountFacet$: Observable<StatisticsFacet> =
    this.facets$.pipe(
      map((facets) => {
        return facets.find(
          (facet) => facet.name === 'count_field_reservation_parts'
        );
      }),
      filter((facet) => !!facet),
      map((facet) => {
        return this.orderByPipe.transform(
          facet?.values
            .map((val: any) => {
              return { name: val.filter, value: val.count };
            })
            .filter((f: any) => f.name !== '0'),
          'name'
        );
      })
    ) as Observable<any>;

  readonly guestExperiencesFacet$: Observable<any> = this.facets$.pipe(
    map((facets) => {
      return facets.find(
        (facet: any) => facet.name === 'field_guest_experience'
      );
    }),
    filter((facet) => !!facet)
  );

  readonly mcGuestExperiencesFacet$: Observable<{
    brand: string;
    values: any[];
  }> = this.guestExperiencesFacet$.pipe(
    withLatestFrom(this.brands$.pipe(getBrand('mc'))),
    guestExperienceFacet(this.getBrandGuestExperiencesPipe)
  );

  readonly dpGuestExperiencesFacet$: Observable<{
    brand: string;
    values: any[];
  }> = this.guestExperiencesFacet$.pipe(
    withLatestFrom(this.brands$.pipe(getBrand('dp'))),
    guestExperienceFacet(this.getBrandGuestExperiencesPipe)
  );

  readonly ruGuestExperiencesFacet$: Observable<{
    brand: string;
    values: any[];
  }> = this.guestExperiencesFacet$.pipe(
    withLatestFrom(this.brands$.pipe(getBrand('ru'))),
    guestExperienceFacet(this.getBrandGuestExperiencesPipe)
  );

  readonly vcGuestExperiencesFacet$: Observable<{
    brand: string;
    values: any[];
  }> = this.guestExperiencesFacet$.pipe(
    withLatestFrom(this.brands$.pipe(getBrand('vc'))),
    guestExperienceFacet(this.getBrandGuestExperiencesPipe)
  );

  readonly krGuestExperiencesFacet$: Observable<{
    brand: string;
    values: any[];
  }> = this.guestExperiencesFacet$.pipe(
    withLatestFrom(this.brands$.pipe(getBrand('kr'))),
    guestExperienceFacet(this.getBrandGuestExperiencesPipe)
  );

  guestExperiences$: Observable<{ brand: string; values: any[] }[]> =
    combineLatest([
      this.mcGuestExperiencesFacet$,
      this.dpGuestExperiencesFacet$,
      this.ruGuestExperiencesFacet$,
      this.vcGuestExperiencesFacet$,
      this.krGuestExperiencesFacet$,
    ]);

  private statisticsQuery!: QueryRef<any> | null;

  public initialized = false;

  constructor(
    @Inject(GUEST_EXPERIENCES_MAP)
    private guestExperiencesMap$: Observable<{ [id: string]: GuestExperience }>,
    @Inject(BRANDS) private brands$: Observable<Brand[]>,
    @Inject(GUEST_TYPES) public guestTypes$: Observable<GuestType[]>,
    private getBrandGuestExperiencesPipe: GetBrandGuestExperiencesPipe,
    private orderByPipe: OrderByPipe,
    private apollo: Apollo
  ) {
    super();

    this.hold(
      this.select(
        selectSlice([
          'fromDate',
          'toDate',
          'brands',
          'guestTypes',
          'status',
          'countries',
        ])
      ),
      ({ fromDate, toDate, brands, guestTypes, status, countries }) => {
        const conditions: ConditionGroupInput = {
          groups: [
            {
              conjunction: 'AND',
              conditions: [
                {
                  name: 'field_reservation_date',
                  value: fromDate,
                  operator: '>=',
                },
                {
                  name: 'field_reservation_date',
                  value: toDate,
                  operator: '<=',
                },
              ],
            },
          ],
        };

        if (brands?.length > 0) {
          const brandGroup: ConditionGroup = {
            conjunction: 'OR',
            conditions: brands.map((code) => {
              return {
                name: 'field_brands_codes_array',
                value: code,
                operator: '=',
              };
            }),
          };
          conditions.groups.push(brandGroup);
        }

        if (guestTypes?.length > 0) {
          const guestTypeGroup: ConditionGroup = {
            conjunction: 'OR',
            conditions: guestTypes.map((id) => {
              return {
                name: 'field_guest_type',
                value: id.toString(),
                operator: '=',
              };
            }),
          };
          conditions.groups.push(guestTypeGroup);
        }

        if (status?.length > 0) {
          const statusGroup: ConditionGroup = {
            conjunction: 'OR',
            conditions: status.map((key) => {
              return {
                name: 'field_workflow_reservation',
                value: key,
                operator: '=',
              };
            }),
          };
          conditions.groups.push(statusGroup);
        }

        if (countries?.length > 0) {
          const countriesGroup: ConditionGroup = {
            conjunction: 'OR',
            conditions: countries.map((key) => {
              return {
                name: 'field_country',
                value: key,
                operator: '=',
              };
            }),
          };
          conditions.groups.push(countriesGroup);
        }

        if (!this.statisticsQuery) {
          this.#initializeState(0, conditions);
        } else {
          this.statisticsQuery.setVariables({
            offset: 0,
            limit: 0,
            conditions:
              conditions && conditions?.groups?.length > 0 ? conditions : null,
          });
        }
      }
    );
  }

  setFilters(
    fromDate: string,
    toDate: string,
    brands: string[] = [],
    guestTypes: string[] = [],
    status: string[] = [],
    countries: string[] = []
  ): void {
    this.set({
      fromDate,
      toDate,
      brands,
      guestTypes,
      status,
      countries,
    });
  }

  #initializeState(
    limit: number,
    conditions: ConditionGroupInput | null = null
  ): void {
    this.statisticsQuery = this.apollo.watchQuery<any>({
      query: statisticsGql,
      variables: {
        offset: 0,
        limit: limit,
        conditions:
          conditions && conditions?.groups?.length > 0 ? conditions : null,
      },
    });

    const apolloSearch$: Observable<ApolloQueryResult<unknown>> =
      this.statisticsQuery.valueChanges;

    const searchReservations$ = apolloSearch$.pipe(
      tap((queryResult: any) => {
        const resultCount: number =
          queryResult?.data?.searchAPISearch?.result_count;
        if (!isNaN(resultCount)) {
          this.set({
            count: queryResult?.data?.searchAPISearch?.result_count,
            facets: queryResult?.data?.searchAPISearch?.facets,
          });
        }
      }),
      map((queryResult: any) => {
        return queryResult?.data?.searchAPISearch?.documents;
      }),
      /*tap((documents) => console.log({ documents })),*/
      map((documents) => {
        return documents.map((doc: any) => {
          return plainToInstance(Reservation, doc, {
            excludeExtraneousValues: true,
            exposeUnsetFields: false,
          });
        });
      }),
      map((reservations) => ({
        isLoading: false,
      })),
      startWith({ isLoading: true }),
      catchError((error) => {
        return throwError(
          () =>
            `[STATISTICS] an error occured while searching statistics, ${error} `
        );
      })
    );
    this.connect(searchReservations$);
  }
}
