<mat-selection-list
  [multiple]="false"
  (selectionChange)="selected.emit($event.options[0].value)"
>
  <mat-list-option *ngFor="let item of items" [value]="item" [selected]="item.id === selectedItem?.id">
    <ng-template
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-template>
  </mat-list-option>
</mat-selection-list>
