import { FormlyFieldConfig } from "@ngx-formly/core";

export function geozoneFields(): FormlyFieldConfig[] {
  return [
    {
      key: "id"
    },
    {
      key: "type"
    },
    {
      key: "title",
      templateOptions: {
        label: "Filter title"
      },
      type: "input"
    },
    {
      key: "field_filter_countries",
      templateOptions: {
        label: "Countries"
      },
      type: "countries",
      wrappers: ["form-field"]
    }
  ];
}
