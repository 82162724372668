import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCore from './core.reducer';

export const selectCoreState = createFeatureSelector<fromCore.State>(
  fromCore.coreFeatureKey
);

export const selectMainMenu = createSelector(
  selectCoreState,
  (state: fromCore.State) => state.mainMenu
);

export const selectSecondaryMenu = createSelector(
  selectCoreState,
  (state: fromCore.State) => state.secondaryMenu
);

export const selectApplicationReady = createSelector(
  selectCoreState,
  (state: fromCore.State) => state.ready
);
