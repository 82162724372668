import { FormlyFieldConfig } from "@ngx-formly/core";


export function guestTypeFields(): FormlyFieldConfig[] {
  return [
    {
      key: 'id',
    },
    {
      key: 'type',
    },
    {
      key: 'name',
      props: {
        label: 'Title',
      },
      type: 'input',
    },
    {
      key: 'field_description',
      props: {
        label: 'Description',
        rows: 10,
      },
      type: 'textarea',
    },
  ];
}
