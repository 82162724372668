import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  NgModule,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldWrapper } from '@ngx-formly/core';
import { GUEST_TYPE_TRADE_UUID } from '@bhe/vocabularies-data';
import { map, Observable, tap, withLatestFrom } from 'rxjs';
import { ReservationFormService } from '../reservation-form/reservation-form.service';
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-res-bottle-sold-wrapper',
  template: `
    <ng-container *ngrxLet="show$; let show">
      <ng-container *ngIf="show">
        <h3>{{ to.label }}</h3>
        <div class="wrapper-fields">
          <ng-container #fieldComponent></ng-container>
        </div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./bottle-sold-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottleSoldWrapperComponent extends FieldWrapper {
  show$: Observable<boolean> = this.reservationFormService.guestType$.pipe(
    withLatestFrom(this.tradeUuid$),
    map(([guestType, tradeUuid]) => {
      return guestType?.id === tradeUuid;
    }),
    tap((show) => {
      const { nativeElement } = this.el;
      this.renderer.removeClass(nativeElement, 'hidden');
      if (!show) {
        this.renderer.addClass(nativeElement, 'hidden');
      }
    })
  );

  constructor(
    @Inject(GUEST_TYPE_TRADE_UUID) private tradeUuid$: Observable<string>,
    private readonly reservationFormService: ReservationFormService,
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>
  ) {
    super();
  }
}

@NgModule({
  imports: [CommonModule, LetModule],
  declarations: [BottleSoldWrapperComponent],
  exports: [BottleSoldWrapperComponent],
})
export class BottleSoldWrapperComponentModule {}
