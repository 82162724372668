import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgModule,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessageBoxItem } from "@bhe/types";
import { FlagComponentModule } from "@bhe/ui";

@Component({
  selector: "bhe-home-message",
  template: `
    <ng-container *ngIf="message">
      <div class="header">
        <ng-container *ngIf="message.changed">
          <small [innerHTML]="message.changed | date: 'MMM d, y, HH:mm'"></small>
        </ng-container>
        <ng-container *ngIf="message.reservationId as reservationId">
          <strong [innerHTML]="'#' + reservationId"></strong>
        </ng-container>
      </div>
      <div class="content">
        <div class="message-header">
          <strong [innerHTML]="message.userName"></strong>
          <ng-container *ngIf="message.reservationCountry as reservationCountry">
            <bhe-ui-flag [code]="reservationCountry"></bhe-ui-flag>
          </ng-container>
        </div>
        <div class="message" [innerHTML]="message.body"></div>
      </div>
    </ng-container>
  `,
  styleUrls: ["./home-message.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeMessageComponent implements OnDestroy {
  observer!: ResizeObserver;

  @Input()
  message?: MessageBoxItem;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>,
    private ngZone: NgZone
  ) {
    this.observer = new ResizeObserver((entries) => {
      this.ngZone.run(() => {
        const { nativeElement } = this.elementRef;
        const { offsetHeight, scrollHeight } = nativeElement;
        this.renderer.removeClass(nativeElement, "need-scroll");
        if (scrollHeight > offsetHeight) {
          this.renderer.addClass(nativeElement, "need-scroll");
        }
      });
    });

    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.unobserve(this.elementRef.nativeElement);
    }
  }
}

@NgModule({
  imports: [CommonModule, FlagComponentModule],
  declarations: [HomeMessageComponent],
  exports: [HomeMessageComponent]
})
export class HomeMessageComponentModule {
}
