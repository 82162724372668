import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  BheButtonType,
  disabledAttributeToken,
  typeAttributeToken,
} from '../bhe-button/bhe-button.component';
import { ButtonTemplate } from '../button-template';
import { BheIconComponentModule } from '../../bhe-icon/bhe-icon.component';

@Component({
  selector: 'bhe-ui-text-button',
  template: `
    <button
      #target
      mat-button
      color="primary"
      [type]="type"
      [disabled]="isDisabled"
    >
      <ng-container *ngIf="icon">
        <bhe-ui-bhe-icon [icon]="icon"></bhe-ui-bhe-icon>
      </ng-container>
      <ng-content></ng-content>
    </button>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextButtonComponent implements ButtonTemplate, OnChanges {
  @Input()
  icon!: string;

  @ViewChild('target', { static: true, read: ElementRef })
  target!: ElementRef<HTMLButtonElement>;

  constructor(
    @Inject(disabledAttributeToken) public isDisabled: boolean,
    @Inject(typeAttributeToken) public type: BheButtonType
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}
}

@NgModule({
  imports: [CommonModule, MatButtonModule, BheIconComponentModule],
  declarations: [TextButtonComponent],
  exports: [TextButtonComponent],
})
export class TextButtonComponentModule {}
