import { createAction, props } from '@ngrx/store';
import { Role, User } from '@bhe/types';

export const loadUser = createAction(
  '[User] Load User',
  props<{ uuid: string }>()
);

export const loadUserSuccess = createAction(
  '[User] Load User Success',
  props<{ user: User; roles: Role[] }>()
);
