import { InjectionToken, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { Brand, EditContentType, GeoZone, GuestType, Menu, User } from "@bhe/types";
import { map, Observable } from "rxjs";
import { ROUTE_IS_BHE_PRO_HOME, ROUTE_SETTINGS_EDIT_CONTENT_TYPE } from "@bhe/router";
import { BHE_PRO_LAYOUT_MODE, BHE_PRO_MENU, LayoutComponent } from "@bhe/settings-ui";
import { QueryParams } from "@madeinlune/ngx-json-api";
import { BRANDS, GUEST_TYPES } from "@bhe/vocabularies-data";
import { GEO_ZONES } from "@bhe/settings-data-access";
import { approverFilters, teamFilters } from "./form/user-filters.fields";
import { guestExperience } from "./form/guest-experience.fields";
import { guestTypeFields } from "./form/guest-type.fields";
import { geozoneFields } from "./form/geozone.fields";
import { brandFields } from "./form/brand.fields";
import { brandCorporateInformationFields } from "./form/brand-corporate-informations.fields";
import { FormlyFieldConfig } from "@ngx-formly/core";

export const BHE_SETTINGS_MENU = new InjectionToken<Menu>("BHE_SETTINGS_MENU");

export const BRAND_PAGES_FIELDS = new InjectionToken<
  Observable<FormlyFieldConfig[] | undefined>
>("BRAND_PAGES_FIELDS");

export const EDIT_CONTENT_QUERY_PARAMS = new InjectionToken<
  Observable<QueryParams | undefined>
>("EDIT_CONTENT_QUERY_PARAMS");

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: LayoutComponent,
        children: [
          {
            path: "",
            data: {
              isBheProHome: true
            },
            loadChildren: () =>
              import("./settings/settings.component").then(
                (m) => m.SettingsComponentModule
              )
          },
          {
            path: "settings/requestors/search-and-replace",
            loadChildren: () =>
              import(
                "./requestors/search-and-replace/search-and-replace.component"
                ).then((m) => m.SearchAndReplaceComponentModule)
          },
          {
            path: "settings/edit-content/:editContentType",
            loadChildren: () =>
              import("./edit-content/edit-content.component").then(
                (m) => m.EditContentComponentModule
              )
          },
          {
            path: "data-center",
            loadChildren: () =>
              import("./data-center/data-center.component").then(
                (m) => m.DataCenterComponentModule
              )
          }
        ]
      }
    ])
  ],
  providers: [
    {
      provide: BHE_PRO_LAYOUT_MODE,
      deps: [ROUTE_IS_BHE_PRO_HOME],
      useFactory: (isBheProHome$: Observable<boolean>) => {
        return isBheProHome$.pipe(
          map((isBheProHome) => {
            return isBheProHome ? "home" : "detail";
          })
        );
      }
    },
    {
      provide: BHE_SETTINGS_MENU,
      useFactory: () => {
        /*const rolesPath: Map<string, string> = new Map<string, string>();
        rolesPath.set('region-builder', 'edit-content/regions');
        rolesPath.set('bh-team', 'edit-content/bh-team');
        rolesPath.set('observers', 'edit-content/observers');
        rolesPath.set('approvers', 'edit-content/approvers');
        rolesPath.set('brand-bhe-infos', 'edit-content/brand-bhe-infos');
        rolesPath.set('guest-types', 'edit-content/guest-types');
        rolesPath.set('guest-experiences', 'edit-content/guest-experiences');
        rolesPath.set('brand-pages', 'edit-content/brand-pages');
        rolesPath.set('requestors', 'requestors');

        const roleMenuKeys: string[] = [
          'region-builder',
          'bh-team',
          'observers',
          'approvers',
          'brand-bhe-infos',
          'guest-types',
          'brand-pages',
          'guest-experiences',
          'requestors',
        ];*/

        const rolesPaths: Map<string, string> = new Map<string, string>();
        rolesPaths.set("bh-team", "edit-content/bh-team");
        rolesPaths.set("observers", "edit-content/observers");
        rolesPaths.set("approvers", "edit-content/approvers");

        const roleMenuKeys: string[] = ["bh-team", "observers", "approvers"];

        const contentEditingPaths: Map<string, string> = new Map<
          string,
          string
        >();
        contentEditingPaths.set("region-builder", "edit-content/regions");
        contentEditingPaths.set(
          "brand-bhe-infos",
          "edit-content/brand-bhe-infos"
        );
        contentEditingPaths.set("guest-types", "edit-content/guest-types");
        contentEditingPaths.set(
          "guest-experiences",
          "edit-content/guest-experiences"
        );
        contentEditingPaths.set("brand-pages", "edit-content/brand-pages");

        const contentEditingKeys: string[] = [
          "region-builder",
          "brand-bhe-infos",
          "guest-types",
          "brand-pages",
          "guest-experiences"
        ];

        const requestorsPaths: Map<string, string> = new Map<string, string>();

        requestorsPaths.set("requestors", "requestors/search-and-replace");

        const requestorsKeys: string[] = ["requestors"];

        /*const menu: Menu = {
          items: menuKeys.map((key) => {
            return {
              label: `bhe-pro.settings.${key}.title`,
              path: `/bhe-pro/settings/${paths.get(key)}`,
              description: `bhe-pro.settings.${key}.description`,
            };
          }),
        };*/
        const menu: Menu = {
          items: [
            {
              label: "Welcome",
              path: "/bhe-pro",
              id: "bhe-pro"
            },
            {
              label: "Statistics and Exports",
              path: "/bhe-pro/data-center",
              id: "data-center"
            },
            {
              label: "Requestors Management",
              items: requestorsKeys.map((key) => {
                return {
                  label: `bhe-pro.settings.${key}.title`,
                  path: `/bhe-pro/settings/${requestorsPaths.get(key)}`,
                  description: `bhe-pro.settings.${key}.description`
                };
              })
            },
            {
              label: "Roles Management",
              items: roleMenuKeys.map((key) => {
                return {
                  label: `bhe-pro.settings.${key}.title`,
                  path: `/bhe-pro/settings/${rolesPaths.get(key)}`,
                  description: `bhe-pro.settings.${key}.description`
                };
              })
            },
            {
              label: "Content Editing",
              items: contentEditingKeys.map((key) => {
                return {
                  label: `bhe-pro.settings.${key}.title`,
                  path: `/bhe-pro/settings/${contentEditingPaths.get(key)}`,
                  description: `bhe-pro.settings.${key}.description`
                };
              })
            }
          ]
        };
        return menu;
      }
    },
    {
      provide: BHE_PRO_MENU,
      deps: [BHE_SETTINGS_MENU],
      useFactory: (menu: Menu) => menu
    },
    {
      provide: BRAND_PAGES_FIELDS,
      deps: [ROUTE_SETTINGS_EDIT_CONTENT_TYPE, GUEST_TYPES, BRANDS, GEO_ZONES],
      useFactory: (
        editContentType$: Observable<EditContentType>,
        guestTypes$: Observable<GuestType[]>,
        brands$: Observable<Brand[]>,
        geoZones$: Observable<GeoZone[]>
      ) => {
        return editContentType$.pipe(
          map((editContentType): FormlyFieldConfig[] | undefined => {
            if (editContentType === "node--brand_corporate_information") {
              return brandCorporateInformationFields();
            } else if (editContentType === "taxonomy_term--brand") {
              return brandFields();
            } else if (editContentType === "taxonomy_term--guest_experience") {
              return guestExperience();
            } else if (editContentType === "taxonomy_term--guest_type") {
              return guestTypeFields();
            } else if (editContentType === "node--geo_zone") {
              return geozoneFields();
            } else if (
              editContentType === "node--observer_filter" ||
              editContentType === "node--approver_filter"
            ) {
              return approverFilters(brands$, guestTypes$, geoZones$);
            } else if (editContentType === "node--team_filter") {
              return teamFilters(brands$, guestTypes$, geoZones$);
            }
            return undefined;
          })
        );
      }
    },
    {
      provide: EDIT_CONTENT_QUERY_PARAMS,
      deps: [ROUTE_SETTINGS_EDIT_CONTENT_TYPE],
      useFactory: (editContentType$: Observable<EditContentType>) => {
        return editContentType$.pipe(
          map((editContentType): QueryParams | undefined => {
            if (editContentType === "node--brand_corporate_information") {
              return {};
            } else if (editContentType === "taxonomy_term--brand") {
              return {};
            } else if (editContentType === "taxonomy_term--guest_experience") {
              return {};
            } else if (editContentType === "taxonomy_term--guest_type") {
              return {};
            } else if (editContentType === "node--geo_zone") {
              return {};
            } else if (
              editContentType === "node--observer_filter" ||
              editContentType === "node--approver_filter" ||
              editContentType === "node--team_filter"
            ) {
              return {
                include: ["field_filter_users"],
                fields: {
                  [editContentType]: [
                    "title",
                    "changed",
                    "drupal_internal__nid",
                    "field_filter_users",
                    "field_filter_brands",
                    "field_filter_geo_zones",
                    "field_guest_experiences",
                    "field_filter_guest_types"
                  ],
                  [User.type]: ["display_name"]
                }
              };
            }
            return undefined;
          })
        );
      }
    }
  ]
})
export class BheSettingsFeatureModule {
}
