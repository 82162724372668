import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ApprobationConfirmation } from '@bhe/types';

@Injectable()
export class OverviewEventsService {
  public confirmApprobationChoice$: ReplaySubject<ApprobationConfirmation> =
    new ReplaySubject<ApprobationConfirmation>(1);

  confirmApprobationChoice(approbationConfirmation: ApprobationConfirmation) {
    this.confirmApprobationChoice$.next(approbationConfirmation);
  }
}
