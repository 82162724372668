import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BheButtonComponentModule, IconLoadingComponentModule } from '@bhe/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { Observable, take } from 'rxjs';
import { RequestorsService } from '../requestors.service';
import { RxState } from '@rx-angular/state';
import { Reservation, User } from '@bhe/types';
import { LetModule } from '@rx-angular/template/let';
import { TableComponentModule } from '../table/table.component';
import { UserSearchComponentModule } from './user-search/user-search.component';
import { PortalService } from '@bhe/settings-ui';

@Component({
  selector: 'bhe-pro-search-and-replace',
  template: `
    <ng-container *rxLet="vm$; let vm">
      <ng-container *ngIf="!vm.isReplacing">
        <bhe-pro-user-search
          [data]="{
            title: '1. Search the requestor you want to replace',
            placeholder: 'placeholder',
            inputLabel: 'Requestor e-mail'
          }"
          (search)="onRequestorSearch($event)"
        >
        </bhe-pro-user-search>

        <ng-container *ngIf="vm.requestorInfosLoading">
          <bhe-ui-icon-loading></bhe-ui-icon-loading>
        </ng-container>

        <ng-container *ngIf="vm.noUserFound">
          <span>Sorry, we didn't found any user matching this e-mail</span>
        </ng-container>

        <ng-container *ngIf="vm.noReservationFound">
          <span>Sorry, we didn't found any reservations for this user</span>
        </ng-container>

        <ng-container *ngIf="vm.reservations.length > 0">
          <span class="accent"
            >We found {{ vm.reservations.length }} reservations for this user :
            <strong>{{ vm.requestorMail }}</strong></span
          >

          <bhe-pro-table [dataSource]="vm.reservations"></bhe-pro-table>
          <ng-container *ngIf="vm.newRequestor as newRequestor">
            <ng-container *ngIf="vm.isReplacing">
              <bhe-ui-icon-loading></bhe-ui-icon-loading>
            </ng-container>
          </ng-container>

          <bhe-pro-user-search
            [data]="{
              title: '2. Search the user you want to be the new requestor',
              placeholder: 'placeholder',
              inputLabel: 'User e-mail'
            }"
            (search)="onUserSearch($event)"
          >
          </bhe-pro-user-search>

          <ng-container *ngIf="vm.newRequestorInfosLoading">
            <bhe-ui-icon-loading></bhe-ui-icon-loading>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="vm.newRequestor as newRequestor">
        <div class="b-confirmation">
          <ng-container *ngIf="!vm.isReplacing">
            <span class="b-confirmation--message"
              >We found <strong>{{ newRequestor.displayName }}</strong
              >.<br />Do you want this User to be the new requestor of the above
              reservations ?</span
            >
            <div class="b-confirmation--btns">
              <bhe-ui-bhe-button variant="text" (btnClick)="onCancel()">
                <span>Cancel</span>
              </bhe-ui-bhe-button>
              <bhe-ui-bhe-button
                variant="accent"
                (btnClick)="onReplace(vm.reservations, newRequestor)"
              >
                <span>Replace</span>
              </bhe-ui-bhe-button>
            </div>
          </ng-container>
          <ng-container *ngIf="vm.isReplacing">
            <bhe-ui-icon-loading></bhe-ui-icon-loading>
            <p>Please wait while we're processing reservations</p>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./search-and-replace.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class SearchAndReplaceComponent {
  readonly vm$ = this.state.select();

  noUserFound$: Observable<boolean> = this.state.select('noUserFound');

  noReservationFound$: Observable<boolean> =
    this.state.select('noReservationFound');

  reservations$: Observable<Reservation[]> = this.state.select('reservations');

  constructor(
    private state: RxState<{
      noReservationFound: boolean;
      noUserFound: boolean;
      reservations: Reservation[];
      requestorMail: string | null;
      newRequestor: User | null;
      requestorInfosLoading: boolean;
      newRequestorInfosLoading: boolean;
      isReplacing: boolean;
    }>,
    private requestorsService: RequestorsService,
    private portalService: PortalService
  ) {
    this.#initState();
    this.portalService.setListPortal(null);
  }

  #initState() {
    this.state.set({
      reservations: [],
      noReservationFound: false,
      noUserFound: false,
      isReplacing: false,
      requestorInfosLoading: false,
      newRequestorInfosLoading: false,
      requestorMail: null,
      newRequestor: null,
    });
  }

  onReplace(reservations: Reservation[], newRequestor: User) {
    this.state.set({
      isReplacing: true,
    });
    this.requestorsService
      .replaceRequestor(reservations, newRequestor)
      .subscribe((done) => {
        this.#initState();
      });
  }

  onCancel() {
    this.#initState();
  }

  onRequestorSearch(mail: string) {
    this.state.set({
      requestorMail: mail,
    });
    this.searchRequestorReservations(mail);
  }

  onUserSearch(mail: string) {
    this.state.set({
      newRequestorInfosLoading: true,
    });
    this.requestorsService.searchUser(mail).subscribe((user) => {
      this.state.set({
        newRequestor: user,
        newRequestorInfosLoading: false,
      });
    });
  }

  searchRequestorReservations(mail: string) {
    this.state.set({
      reservations: [],
      noReservationFound: false,
      noUserFound: false,
      requestorInfosLoading: true,
    });
    this.requestorsService
      .loadRequestorReservations(mail)
      .pipe(take(1))
      .subscribe((reservations) => {
        if (reservations.length === 0) {
          this.state.set({ reservations: [], noReservationFound: true });
        } else if (reservations === 'no user found') {
          this.state.set({ reservations: [], noUserFound: true });
        } else {
          this.state.set({ reservations, noUserFound: false });
        }
        this.state.set({ requestorInfosLoading: false });
      });
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: '', component: SearchAndReplaceComponent }]),
    MatFormFieldModule,
    MatInputModule,
    BheButtonComponentModule,
    ReactiveFormsModule,
    LetModule,
    TableComponentModule,
    UserSearchComponentModule,
    IconLoadingComponentModule,
  ],
  declarations: [SearchAndReplaceComponent],
  exports: [SearchAndReplaceComponent],
})
export class SearchAndReplaceComponentModule {}
