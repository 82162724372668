import { createReducer, on } from '@ngrx/store';
import * as ReservationActions from './reservation.actions';
import { Reservation } from '@bhe/types';

export const reservationFeatureKey = 'reservation';

export interface State {
  reservation?: Reservation | null;
  loadingReservation?: boolean;
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,

  on(ReservationActions.loadReservation, (state) => {
    return {
      ...state,
      loadingReservation: true,
    };
  }),
  on(ReservationActions.loadReservationSuccess, (state, { reservation }) => {
    return {
      ...state,
      reservation,
      loadingReservation: false,
    };
  }),
  on(ReservationActions.clearReservation, (state) => {
    return {
      ...state,
      reservation: null,
      loadingReservation: false,
    };
  })
);
