import { DrupalIdentifier } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';

export class Role extends DrupalIdentifier {
  static type = 'user_role--user_role';
  @Transform(({ obj }) => {
    return obj?.attributes?.label;
  })
  @Expose()
  label!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.drupal_internal__id;
  })
  @Expose()
  internalId!: 'administrator' | 'setting_manager' | 'bh_team';
}
