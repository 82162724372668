import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as GuestExperienceActions from './guest-experience.actions';
import { GuestExperience } from '@bhe/types';

export const guestExperiencesFeatureKey = 'guestExperiences';

export interface State extends EntityState<GuestExperience> {
  // additional entities state properties
}

export const adapter: EntityAdapter<GuestExperience> =
  createEntityAdapter<GuestExperience>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(GuestExperienceActions.addGuestExperience, (state, action) =>
    adapter.addOne(action.guestExperience, state)
  ),
  on(GuestExperienceActions.upsertGuestExperience, (state, action) =>
    adapter.upsertOne(action.guestExperience, state)
  ),
  on(GuestExperienceActions.addGuestExperiences, (state, action) =>
    adapter.addMany(action.guestExperiences, state)
  ),
  on(GuestExperienceActions.upsertGuestExperiences, (state, action) =>
    adapter.upsertMany(action.guestExperiences, state)
  ),
  on(GuestExperienceActions.updateGuestExperience, (state, action) =>
    adapter.updateOne(action.guestExperience, state)
  ),
  on(GuestExperienceActions.updateGuestExperiences, (state, action) =>
    adapter.updateMany(action.guestExperiences, state)
  ),
  on(GuestExperienceActions.deleteGuestExperience, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(GuestExperienceActions.deleteGuestExperiences, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(GuestExperienceActions.loadGuestExperiences, (state, action) =>
    adapter.setAll(action.guestExperiences, state)
  ),
  on(GuestExperienceActions.clearGuestExperiences, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectGuestExperienceEntities = selectEntities;
