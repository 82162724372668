import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFiles from './file.reducer';
import { filesFeatureKey } from './file.reducer';

export const selectFilesState =
  createFeatureSelector<fromFiles.State>(filesFeatureKey);

export const selectFilesEntities = createSelector(
  selectFilesState,
  fromFiles.selectFileEntities
);

export const selectFile = (id: string) =>
  createSelector(selectFilesEntities, (files) => {
    return files[id];
  });
