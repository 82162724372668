import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessRunningDirective } from './process-running.directive';
import { ProcessSuccessDirective } from './process-success.directive';
import { ProcessErrorDirective } from './process-error.directive';
import { ProcessUi } from '@bhe/types';
import { IconLoadingComponentModule } from '../icon-loading/icon-loading.component';
import { BheButtonComponentModule } from '../buttons/bhe-button/bhe-button.component';
import { TranslocoModule } from '@ngneat/transloco';

export interface ProcessData {
  runningMessage?: string;
  successMessage?: string;
  errorMessage?: string;
}

@Component({
  selector: 'bhe-ui-process',
  template: `
    <ng-container *transloco="let t">
      <ng-container [ngSwitch]="state">
        <ng-container *ngSwitchCase="'running'">
          <div class="b-process__body running">
            <ng-container
              *ngIf="processRunning?.template; else runningTemplate"
            >
              <ng-container
                [ngTemplateOutlet]="processRunning.template"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'success'">
          <div class="b-process__body success">
            <ng-container
              *ngIf="processSuccess?.template; else successTemplate"
            >
              <ng-container
                [ngTemplateOutlet]="processSuccess.template"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'error'">
          <div class="b-process__body error">
            <ng-container *ngIf="processError.template; else errorTemplate">
              <ng-container
                [ngTemplateOutlet]="processError.template"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #runningTemplate>
        <bhe-ui-icon-loading></bhe-ui-icon-loading>
        <div class="b-process__message">
          <p [innerHTML]="processData.runningMessage"></p>
        </div>
      </ng-template>
      <ng-template #successTemplate>
        <div class="b-process__message">
          <p [innerHTML]="processData.successMessage"></p>
        </div>
      </ng-template>
      <ng-template #errorTemplate>
        <div class="b-process__message">
          <p [innerHTML]="processData.errorMessage"></p>
        </div>
        <div class="b-process__actions">
          <bhe-ui-bhe-button variant="primary" (btnClick)="okError.emit()"
            ><span [innerHTML]="t('actions.ok')"></span
          ></bhe-ui-bhe-button>
        </div>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./process.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessComponent {
  /** Content for step label given by `<ng-container *bheUiProcessRunning>`. */
  @ContentChild(ProcessRunningDirective)
  processRunning!: ProcessRunningDirective;

  /** Content for step label given by `<ng-container *bheUiProcessSuccess>`. */
  @ContentChild(ProcessSuccessDirective)
  processSuccess!: ProcessSuccessDirective;

  /** Content for step label given by `<ng-container *bheUiProcessError>`. */
  @ContentChild(ProcessErrorDirective)
  processError!: ProcessErrorDirective;

  @Input()
  state!: ProcessUi;

  @Input()
  processData!: ProcessData;

  @Output()
  okError: EventEmitter<any> = new EventEmitter<any>();
}

@NgModule({
  imports: [
    CommonModule,
    IconLoadingComponentModule,
    BheButtonComponentModule,
    TranslocoModule,
  ],
  declarations: [
    ProcessComponent,
    ProcessRunningDirective,
    ProcessSuccessDirective,
    ProcessErrorDirective,
  ],
  exports: [
    ProcessComponent,
    ProcessRunningDirective,
    ProcessSuccessDirective,
    ProcessErrorDirective,
  ],
})
export class ProcessComponentModule {}
