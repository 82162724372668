import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldType } from '@ngx-formly/material';
import { BheButtonComponentModule } from '@bhe/ui';
import { TranslocoModule } from '@ngneat/transloco';
import {
  combineLatest,
  Observable,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { Brand, GuestExperience, ReservationPartForm } from '@bhe/types';
import {
  BrandPipe,
  BrandPipeModule,
  GuestExperiencePipe,
  GuestExperiencePipeModule,
} from '@bhe/vocabularies-data';
import { FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: 'bhe-default-brand-data-injector',
  template: `
    <ng-container *transloco="let t">
      <div [innerHTML]="t('reservation-part.form.inject-default-data')"></div>
      <bhe-ui-bhe-button
        [variant]="'soft'"
        (click)="onInjectData()"
        [icon]="'inject-data'"
        [color]="'accent'"
        ><span [innerHTML]="t('actions.inject-default-data')"></span
      ></bhe-ui-bhe-button>
    </ng-container>
  `,
  styleUrls: ['./default-brand-data-injector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GuestExperiencePipe, BrandPipe],
})
export class DefaultBrandDataInjectorComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit
{
  guestExperienceData$!: Observable<GuestExperience | undefined>;
  brandData$!: Observable<Brand | undefined>;

  constructor(
    private guestExperiencePipe: GuestExperiencePipe,
    private brandPipe: BrandPipe
  ) {
    super();
  }

  ngAfterViewInit(): void {
    const formValue$: Observable<ReservationPartForm> =
      this.form.valueChanges.pipe(startWith(this.form.value), shareReplay(1));

    this.guestExperienceData$ = formValue$.pipe(
      switchMap((value: ReservationPartForm) => {
        return this.guestExperiencePipe.transform(value.field_guest_experience);
      })
    );

    this.brandData$ = formValue$.pipe(
      switchMap((value: ReservationPartForm) => {
        return this.brandPipe.transform(value.field_brand);
      })
    );
  }

  onInjectData() {
    combineLatest([this.guestExperienceData$, this.brandData$])
      .pipe(take(1))
      .subscribe(([guestExperienceData, brandData]) => {
        const options = {
          onlySelf: true,
          emitEvent: false,
        };
        this.form
          .get('field_program_description_short')
          ?.setValue(guestExperienceData?.programShortDescription, options);
        this.form
          .get('field_program_description_long')
          ?.setValue(guestExperienceData?.programLongDescription, options);
        this.form
          .get('field_dress_code')
          ?.setValue(guestExperienceData?.dressCode, options);
        this.form
          .get('field_recommendations')
          ?.setValue(guestExperienceData?.recommendations, options);
        //
        this.form
          .get('field_contact_info')
          ?.setValue(brandData?.contactInfos, options);
        this.form
          .get('field_contact_phone')
          ?.setValue(brandData?.contactPhone, options);
        //
        this.form.updateValueAndValidity({
          onlySelf: false,
          emitEvent: true,
        });
      });
  }
}

@NgModule({
  imports: [
    CommonModule,
    BheButtonComponentModule,
    TranslocoModule,
    GuestExperiencePipeModule,
    BrandPipeModule,
  ],
  declarations: [DefaultBrandDataInjectorComponent],
  exports: [DefaultBrandDataInjectorComponent],
})
export class DefaultBrandDataInjectorComponentModule {}
