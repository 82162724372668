import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldTypeConfig, FormlyModule } from "@ngx-formly/core";
import { FieldType } from '@ngx-formly/material';
import {
  map,
  Observable,
  of,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { COUNTRIES } from '@bhe/shell-state';
import { FlagComponentModule } from '@bhe/ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PushModule } from "@ngrx/component";

@UntilDestroy()
@Component({
  selector: 'bhe-res-country-autocomplete',
  template: `
    <input
      matInput
      [matAutocomplete]="auto"
      [formControl]="countryControl"
      [placeholder]="props.placeholder!"
      [errorStateMatcher]="errorStateMatcher"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayWith"
      (optionSelected)="onOptionSelected($event)"
    >
      <mat-option
        class="country-option"
        *ngFor="let value of filteredOptions$ | ngrxPush"
        [value]="value.id"
      >
        <bhe-ui-flag [code]="value.id"></bhe-ui-flag>
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrls: ['./country-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryAutocompleteComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit
{
  countryControl: UntypedFormControl = new UntypedFormControl();

  filteredOptions$: Observable<{ id: string; label: string }[]> =
    this.countryControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value?.label)),
      withLatestFrom(this.countries$),
      switchMap(([name, countries]) => {
        if (name) {
          return this._filter(name);
        }
        return of(countries.slice());
      })
    );

  constructor(
    @Inject(COUNTRIES)
    private countries$: Observable<{ id: string; label: string }[]>,
    private translocoService: TranslocoService
  ) {
    super();
  }

  ngAfterViewInit() {
    if (this.formControl) {
      setTimeout(() => {
        if (this.field?.templateOptions?.disabled) {
          this.countryControl.disable();
        }
      });
      this.formControl.valueChanges
        .pipe(startWith(this.formControl.value), untilDestroyed(this))
        .subscribe((value) => {
          if (value !== this.countryControl.value) {
            this.countryControl.setValue(value);
          }
        });
    }
  }

  displayWith = (countryCode: string) => {
    if (countryCode) {
      return this.translocoService.translate(`countries.${countryCode}`);
    }
    return '';
  };

  private _filter(name: string): Observable<{ id: string; label: string }[]> {
    const filterValue = name.toLowerCase();

    return this.countries$.pipe(
      map((countries) => {
        return countries.filter((country) =>
          country.label.toLowerCase().includes(filterValue)
        );
      })
    );
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    this.formControl?.setValue($event.option.value);
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    FormlyModule,
    FlagComponentModule,
    PushModule
  ],
  declarations: [CountryAutocompleteComponent],
  exports: [CountryAutocompleteComponent],
})
export class CountryAutocompleteComponentModule {}
