import { gql } from '@apollo/client';

export const statisticsGql = gql`
  query Statistics(
    $offset: Int!
    $limit: Int!
    $conditions: ConditionGroupInput
    $fullText: FulltextInput
    $sortField: String = "field_reservation_date"
    $sortDirection: String = "DESC"
  ) {
    searchAPISearch(
      index_id: "reservations"
      range: { offset: $offset, limit: $limit }
      sort: { field: $sortField, value: $sortDirection }
      condition_group: $conditions
      fulltext: $fullText
      facets: [
        {
          field: "field_guest_type"
          limit: 0
          min_count: 1
          operator: "="
          missing: false
        }
        {
          field: "field_workflow_reservation"
          limit: 0
          min_count: 0
          operator: "="
          missing: false
        }
        {
          field: "field_country"
          limit: 0
          min_count: 1
          operator: "="
          missing: false
        }
        {
          field: "field_brands_codes_array"
          limit: 0
          min_count: 1
          operator: "="
          missing: false
        }
        {
          field: "reservation_month"
          limit: 0
          min_count: 1
          operator: "="
          missing: false
        }
        {
          field: "reservation_year"
          limit: 0
          min_count: 1
          operator: "="
          missing: false
        }
        {
          field: "count_field_reservation_parts"
          limit: 0
          min_count: 1
          operator: "="
          missing: false
        }
        {
          field: "field_guest_experience"
          limit: 0
          min_count: 1
          operator: "="
          missing: false
        }
      ]
    ) {
      result_count
      documents {
        index_id
        ... on ReservationsDoc {
          field_guest_experience
          field_brands_codes_array
          field_user_full_name
          field_guest_type_name
          field_invited_by_full_name
          field_main_guest_full_name
          field_country
          field_reservation_id
          name
          field_reservation_date
          changed
          uuid
          field_workflow_reservation
          field_guest_type_short_label
        }
      }
      facets {
        name
        values {
          count
          filter
        }
      }
    }
  }
`;
