import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DrupalFile } from '@bhe/types';

export const loadFiles = createAction(
  '[File/API] Load Files',
  props<{ files: DrupalFile[] }>()
);

export const addFile = createAction(
  '[File/API] Add File',
  props<{ file: DrupalFile }>()
);

export const upsertFile = createAction(
  '[File/API] Upsert File',
  props<{ file: DrupalFile }>()
);

export const addFiles = createAction(
  '[File/API] Add Files',
  props<{ files: DrupalFile[] }>()
);

export const upsertFiles = createAction(
  '[File/API] Upsert Files',
  props<{ files: DrupalFile[] }>()
);

export const updateFile = createAction(
  '[File/API] Update File',
  props<{ file: Update<DrupalFile> }>()
);

export const updateFiles = createAction(
  '[File/API] Update Files',
  props<{ files: Update<DrupalFile>[] }>()
);

export const deleteFile = createAction(
  '[File/API] Delete File',
  props<{ id: string }>()
);

export const deleteFiles = createAction(
  '[File/API] Delete Files',
  props<{ ids: string[] }>()
);

export const clearFiles = createAction('[File/API] Clear Files');
