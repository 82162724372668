import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { select, State, Store, StoreModule } from '@ngrx/store';
import * as fromBrand from './brand/brand.reducer';
import * as fromGuestExperience from './guest-experience/guest-experience.reducer';
import * as fromGuestType from './guest-type/guest-type.reducer';
import { filter, map, Observable } from 'rxjs';
import { Brand, GuestExperience, GuestType } from '@bhe/types';
import { selectGuestTypeEntities } from './guest-type/guest-type.selectors';
import { selectGuestExperienceEntities } from './guest-experience/guest-experience.selectors';
import { selectBrandEntities } from './brand/brand.selectors';

export const BRANDS_MAP = new InjectionToken<
  Observable<{ [id: string]: Brand }>
>('BRANDS_MAP');

export const BRANDS = new InjectionToken<Observable<Brand[]>>('BRANDS');

export const GUEST_TYPES = new InjectionToken<Observable<GuestType[]>>(
  'GUEST_TYPES'
);

export const GUEST_TYPES_MAP = new InjectionToken<
  Observable<Map<string, GuestType>>
>('GUEST_TYPES_MAP');

export const GUEST_TYPE_PRESS_UUID = new InjectionToken<Observable<string>>(
  'GUEST_TYPE_PRESS_UUID'
);

export const GUEST_TYPE_TRADE_UUID = new InjectionToken<Observable<string>>(
  'GUEST_TYPE_TRADE_UUID'
);

export const GUEST_TYPE_PRIVATE_CLIENT_UUID = new InjectionToken<
  Observable<string>
>('GUEST_TYPE_PRIVATE_CLIENT_UUID');

export const GUEST_EXPERIENCES_MAP = new InjectionToken<
  Observable<{ [id: string]: GuestExperience }>
>('GUEST_EXPERIENCES_MAP');

export const GUEST_EXPERIENCES = new InjectionToken<
  Observable<GuestExperience[]>
>('GUEST_EXPERIENCES');

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBrand.brandsFeatureKey, fromBrand.reducer),
    StoreModule.forFeature(
      fromGuestExperience.guestExperiencesFeatureKey,
      fromGuestExperience.reducer
    ),
    StoreModule.forFeature(
      fromGuestType.guestTypesFeatureKey,
      fromGuestType.reducer
    ),
  ],
  providers: [
    {
      provide: BRANDS_MAP,
      deps: [Store],
      useFactory: (store: State<any>) => {
        return store.pipe(select(selectBrandEntities));
      },
    },
    {
      provide: BRANDS,
      deps: [BRANDS_MAP],
      useFactory: (brandsMap$: Observable<{ [id: string]: Brand }>) => {
        return brandsMap$.pipe(
          map((brands) => {
            return Object.keys(brands).map((key) => brands[key]);
          })
        );
      },
    },
    {
      provide: GUEST_TYPES_MAP,
      deps: [Store],
      useFactory: (store: State<any>) => {
        return store.pipe(
          select(selectGuestTypeEntities),
          map((guestTypes) => {
            return new Map(Object.entries(guestTypes));
          })
        );
      },
    },
    {
      provide: GUEST_TYPES,
      deps: [Store],
      useFactory: (store: State<any>) => {
        return store.pipe(
          select(selectGuestTypeEntities),
          map((guestTypes) => {
            return Object.keys(guestTypes).map((key) => guestTypes[key]);
          })
        );
      },
    },
    {
      provide: GUEST_TYPE_PRESS_UUID,
      deps: [GUEST_TYPES],
      useFactory: (guest$: Observable<GuestType[]>) => {
        return guest$.pipe(
          filter((guests) => !!guests),
          map((guestTypes) => {
            return guestTypes.find((guestType) => guestType.code === 'press');
          }),
          map((guestType) => guestType?.id)
        );
      },
    },
    {
      provide: GUEST_TYPE_TRADE_UUID,
      deps: [GUEST_TYPES],
      useFactory: (guest$: Observable<GuestType[]>) => {
        return guest$.pipe(
          filter((guests) => !!guests),
          map((guestTypes) => {
            return guestTypes.find((guestType) => guestType.code === 'trade');
          }),
          map((guestType) => guestType?.id)
        );
      },
    },
    {
      provide: GUEST_TYPE_PRIVATE_CLIENT_UUID,
      deps: [GUEST_TYPES],
      useFactory: (guest$: Observable<GuestType[]>) => {
        return guest$.pipe(
          filter((guests) => !!guests),
          map((guestTypes) => {
            return guestTypes.find((guestType) => guestType.code === 'hnwi');
          }),
          map((guestType) => guestType?.id)
        );
      },
    },
    {
      provide: GUEST_EXPERIENCES_MAP,
      deps: [Store],
      useFactory: (store: State<any>) => {
        return store.pipe(select(selectGuestExperienceEntities));
      },
    },
    {
      provide: GUEST_EXPERIENCES,
      deps: [GUEST_EXPERIENCES_MAP],
      useFactory: (
        guestExperiencesMap$: Observable<{ [id: string]: GuestExperience }>
      ) => {
        return guestExperiencesMap$.pipe(
          map((guestExperiences) => {
            return Object.keys(guestExperiences).map(
              (key) => guestExperiences[key]
            );
          })
        );
      },
    },
  ],
})
export class BheVocabulariesDataModule {}
