import { DrupalNode } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';

export class GeoZone extends DrupalNode {
  static type = 'node--geo_zone';

  @Transform(({ obj }) => {
    return obj?.attributes?.title;
  })
  @Expose()
  title!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_filter_countries;
  })
  @Expose()
  field_filter_countries!: string[];
}
