import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'bhe-res-overview-field-wrapper',
  template: `
    <ng-container *ngIf="title">
      <small [innerHTML]="title"></small>
    </ng-container>
    <ng-content></ng-content>
  `,
  styleUrls: ['./overview-field-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewFieldWrapperComponent {
  @Input()
  title!: string;
}

@NgModule({
  imports: [CommonModule],
  declarations: [OverviewFieldWrapperComponent],
  exports: [OverviewFieldWrapperComponent],
})
export class OverviewFieldWrapperComponentModule {}
