import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { AuthState } from './bhe-authentication.module';

export const authFeatureKey = 'auth';

export interface State {
  userId: string | null;
  authState: AuthState;
  authenticated: boolean | null;
  error?: object;
}

export const initialState: State = {
  userId: null,
  authState: 'idle',
  authenticated: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.setUserId, (state, { userId }) => {
    let authState: AuthState = state.authState;
    if (userId) {
      authState = 'loaded';
    }
    return {
      ...state,
      userId,
      authState,
      error: undefined,
      authenticated: !!userId,
    };
  }),
  on(AuthActions.setState, (state, { authState }) => {
    return {
      ...state,
      authState,
    };
  }),
  on(AuthActions.setAuthenticated, (state, { authenticated }) => {
    return {
      ...state,
      authenticated,
      userId: authenticated ? state.userId : null,
    };
  }),
  on(AuthActions.setError, (state, { error }) => {
    return {
      ...state,
      error,
      authState: 'error',
    };
  })
);
