import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgModule,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MhProfile } from '@bhe/types';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'bhe-res-mh-profile-renderer',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngIf="mhProfile">
        <strong [innerHTML]="mhProfile.fullName"></strong>
        <dl>
          <ng-template ngFor [ngForOf]="properties" let-property>
            <ng-template
              [ngTemplateOutlet]="labelValueTemplate"
              [ngTemplateOutletContext]="{
                label: 'mh-profile.infos.' + property + '.label',
                value: getProperty(mhProfile, property),
                class: property
              }"
            >
            </ng-template>
          </ng-template>
        </dl>
      </ng-container>
      <ng-template
        #labelValueTemplate
        let-value="value"
        let-label="label"
        let-class="class"
      >
        <ng-container *ngIf="value">
          <div>
            <dt><small [innerHTML]="t(label)"></small></dt>
            <dd [class]="class" [innerHTML]="value"></dd>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./mh-profile-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MhProfileRendererComponent implements OnChanges {
  @Input()
  displayMode: 'grid' | 'column' = 'grid';

  properties: string[] = [
    'mail',
    'service',
    'entity',
    'department',
    'position',
  ];

  @Input()
  mhProfile!: MhProfile;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['displayMode']) {
      const { nativeElement } = this.el;
      if (changes['displayMode'].previousValue) {
        this.renderer.removeClass(
          nativeElement,
          changes['displayMode'].previousValue
        );
      }
      if (changes['displayMode'].currentValue) {
        this.renderer.addClass(
          nativeElement,
          changes['displayMode'].currentValue
        );
      }
    }
  }
}

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [MhProfileRendererComponent],
  exports: [MhProfileRendererComponent],
})
export class MhProfileRendererComponentModule {}
