import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertAction, AlertDialogData, ProcessUi } from '@bhe/types';
import { BheDialogComponent } from '../bhe-dialog/bhe-dialog.component';
import { BheButtonComponentModule } from '../buttons/bhe-button/bhe-button.component';
import { ProcessComponentModule } from '../process/process.component';
import { IconLoadingComponentModule } from '../icon-loading/icon-loading.component';
import { TranslocoModule } from '@ngneat/transloco';
import { map, Observable, ReplaySubject } from 'rxjs';
import { PwaManifestService } from '@madeinlune/ngx-pwa-manifest';
import { ErrorComponentModule } from '../error/error.component';
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-ui-alert',
  template: `
    <ng-container *ngrxLet="iconUrl$; let iconUrl">
      <ng-container *ngIf="iconUrl">
        <img [src]="iconUrl" />
      </ng-container>
    </ng-container>
    <ng-container *ngrxLet="process$; let process">
      <div class="b-content" [class.process]="!!process">
        <div class="b-content__message">
          <div
            [innerHTML]="data.message"
            class="b-content__message__content"
          ></div>
        </div>
        <footer>
          <ng-template ngFor [ngForOf]="data.actions" let-action>
            <bhe-ui-bhe-button
              [size]="'lg'"
              (btnClick)="onClick(action)"
              [variant]="action.color"
            >
              <span [innerHTML]="action.label"></span>
            </bhe-ui-bhe-button>
          </ng-template>
        </footer>
      </div>
    </ng-container>
    <ng-container *transloco="let t">
      <ng-container *ngrxLet="process$; let process">
        <ng-container *ngIf="process">
          <bhe-ui-process class="b-process" [state]="process">
            <ng-template bheUiProcessRunning>
              <bhe-ui-icon-loading></bhe-ui-icon-loading>
              <p [innerHTML]="data.processMessage?.running ?? 'running'"></p>
            </ng-template>
            <ng-template bheUiProcessError>
              <div class="b-process__message">
                <ng-container *ngIf="!data.networkError">
                  <p [innerHTML]="t('process.add-guest.error')"></p>
                </ng-container>
                <ng-container *ngIf="data.networkError as networkError">
                  <bhe-ui-error
                    [message]="data.processMessage?.error ?? 'error'"
                    [error]="networkError.error"
                    [code]="networkError.status"
                  ></bhe-ui-error>
                </ng-container>
              </div>
              <ng-container *ngIf="!data.networkError">
                <div class="b-process__actions">
                  <bhe-ui-bhe-button
                    variant="primary"
                    (btnClick)="close('error')"
                    ><span [innerHTML]="t('actions.ok')"></span
                  ></bhe-ui-bhe-button>
                </div>
              </ng-container>
            </ng-template>
          </bhe-ui-process>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  iconUrl$: Observable<string | undefined> =
    this.pwaManifestService.icons$.pipe(
      map((icons) => {
        return icons.find((icon) => icon.sizes === '192x192')?.src;
      })
    );

  process$: ReplaySubject<ProcessUi> | undefined = this.data.process$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
    public dialogRef: MatDialogRef<BheDialogComponent>,
    private pwaManifestService: PwaManifestService
  ) {
  }

  onClick(action: AlertAction) {
    if (action.close) {
      this.close(action.id);
    } else if (action.emitter) {
      action.emitter.emit();
    }
  }

  close(id: string | null = null): void {
    this.dialogRef.close(id);
  }

  /*get process(): ProcessUi | undefined | null {
    return this.data.process;
  }*/
}

@NgModule({
  imports: [
    CommonModule,
    BheButtonComponentModule,
    ProcessComponentModule,
    IconLoadingComponentModule,
    TranslocoModule,
    ErrorComponentModule,
    LetModule
  ],
  declarations: [AlertComponent],
  exports: [AlertComponent],
})
export class AlertComponentModule {}
