import { Injectable } from '@angular/core';
import { Direction, NgxJsonApi } from '@madeinlune/ngx-json-api';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MhProfile } from '@bhe/types';
import { HttpErrorResponse } from '@angular/common/http';
import { bheClasses, jsonApiResources } from '@nx-agency/bhe/operators';

@Injectable({
  providedIn: 'root',
})
export class MhProfileService {
  constructor(private ngxJsonApi: NgxJsonApi) {}

  searchMhProfile(name: string): Observable<MhProfile[] | HttpErrorResponse> {
    return this.ngxJsonApi
      .find({
        type: MhProfile.type,
        params: {
          page: {
            limit: 10,
            offset: 0,
          },
          sorting: [
            {
              api: 'field_ad_profile_last_name',
              direction: Direction.ASC,
            },
          ],
          filtering: [
            {
              path: 'title',
              operator: 'operator',
              value: 'CONTAINS',
            },
            {
              path: 'title',
              operator: 'value',
              value: name,
            },
            {
              path: 'status',
              value: 1,
            },
          ],
        },
      })
      .pipe(
        jsonApiResources(),
        bheClasses(),
        map((resultMap) => {
          return resultMap[MhProfile.type];
        }),
        catchError((error) => throwError(() => error))
      );
  }
}
