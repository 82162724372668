import { ChangeDetectionStrategy, Component, ElementRef, Inject, NgModule, Renderer2, ViewEncapsulation } from "@angular/core";
import { CommonModule, DOCUMENT } from "@angular/common";
import { ReservationFormService } from "./reservation-form.service";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { MatButtonModule } from "@angular/material/button";
import { ReservationFormEntitiesService } from "@bhe/reservation-data-access";
import { RxState } from "@rx-angular/state";
import { IconLoadingComponentModule } from "@bhe/ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule } from "@ngrx/component";

@UntilDestroy()
@Component({
  selector: 'bhe-reservation-form',
  template: `
    <ng-container *ngIf="form; else loadingTemplate">
      <ng-container *ngrxLet="fields$; let fields">
        <ng-container *ngIf="fields; else loadingTemplate">
          <ng-container
            *ngrxLet="reservationFormModel$; let reservationFormModel"
          >
            <ng-container *ngIf="reservationFormModel; else loadingTemplate">
              <form [formGroup]="form">
                <formly-form
                  [form]="form"
                  [fields]="fields"
                  [model]="reservationFormModel"
                  [options]="options"
                ></formly-form>
              </form>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #loadingTemplate>
      <h1>Loading</h1>
    </ng-template>
  `,
  styleUrls: ['./reservation-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState, ReservationFormService, ReservationFormEntitiesService],
})
export class ReservationFormComponent {
  readonly form = this.reservationFormService.form;
  readonly options = this.reservationFormService.options;
  readonly fields$ = this.reservationFormService.fields$;
  readonly reservationFormModel$ =
    this.reservationFormService.reservationFormModel$;
  readonly formValid$ = this.reservationFormService.formValid$;
  readonly saving$ = this.reservationFormService.saving$;
  readonly hasRequestorRole$ = this.reservationFormService.hasRequestorRole$;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>,
    private reservationFormService: ReservationFormService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.hasRequestorRole$
      .pipe(untilDestroyed(this))
      .subscribe((hasRequestorRole) => {
        const {nativeElement} = this.el;
        this.renderer.removeClass(nativeElement, 'requestor-role');
        if (hasRequestorRole) {
          this.renderer.addClass(nativeElement, 'requestor-role');
        }
      });
    this.saving$.pipe(
      untilDestroyed(this)
    ).subscribe(saving => {
      const datePickerPopUp: HTMLElement | null = this.document.body.getElementsByClassName('mat-datepicker-popup')?.item(0) as HTMLElement | null;
      const {nativeElement} = this.el;
      this.renderer.removeClass(nativeElement, 'saving');
      if (saving) {
        this.renderer.addClass(nativeElement, 'saving');
        if (datePickerPopUp) {
          datePickerPopUp.style.pointerEvents = 'none';
        }
      } else {
        if (datePickerPopUp) {
          datePickerPopUp.style.pointerEvents = 'auto';
        }
      }
    })
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    IconLoadingComponentModule,
    LetModule
  ],
  declarations: [ReservationFormComponent],
  exports: [ReservationFormComponent],
})
export class ReservationFormComponentModule {
}
