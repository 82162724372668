import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGuestType from './guest-type.reducer';
import { guestTypesFeatureKey } from './guest-type.reducer';

export const selectGuestTypeState =
  createFeatureSelector<fromGuestType.State>(guestTypesFeatureKey);

export const selectGuestTypeEntities = createSelector(
  selectGuestTypeState,
  fromGuestType.selectGuestTypeEntities
);
