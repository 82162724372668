import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, Observable } from 'rxjs';
import { PwaManifestService } from '@madeinlune/ngx-pwa-manifest';
import { LetModule } from "@ngrx/component";
import { TranslocoModule } from '@ngneat/transloco';
import { BheButtonComponentModule } from '@bhe/ui';
import { PwaService } from '../pwa.service';

@Component({
  selector: 'bhe-pwa-install-default',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngrxLet="iconUrl$; let iconUrl">
        <ng-container *ngIf="iconUrl">
          <img class="icon" [src]="iconUrl" />
        </ng-container>
      </ng-container>
      <header>
        <h3 [innerHTML]="t('pwa.install.app-title')"></h3>
        <small [innerHTML]="t('pwa.install.publisher')"></small>
      </header>
      <div class="content">
        <p [innerHTML]="t('pwa.install.default.message')"></p>
        <bhe-ui-bhe-button
          variant="soft"
          color="primary"
          (btnClick)="onInstall()"
        >
          <span [innerHTML]="t('actions.install')"></span>
        </bhe-ui-bhe-button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./install-default.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallDefaultComponent {
  iconUrl$: Observable<string | undefined> =
    this.pwaManifestService.icons$.pipe(
      map((icons) => {
        return icons.find((icon) => icon.sizes === '384x384')?.src;
      })
    );

  constructor(
    private pwaManifestService: PwaManifestService,
    private pwaService: PwaService
  ) {}

  onInstall() {
    this.pwaService.install();
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    BheButtonComponentModule,
    LetModule
  ],
  declarations: [InstallDefaultComponent],
  exports: [InstallDefaultComponent],
})
export class InstallDefaultComponentModule {}
