import {ChangeDetectionStrategy, Component, NgModule, TemplateRef, ViewEncapsulation,} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractMatListComponent} from '../abstract-mat-list/abstract-mat-list.component';
import {EditContentForm} from '@bhe/types';
import {MatListModule} from '@angular/material/list';

@Component({
  selector: 'bhe-pro-edit-content-list',
  templateUrl: '../abstract-mat-list/abstract-mat-list.component.html',
  styleUrls: ['../abstract-mat-list/abstract-mat-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditContentListComponent extends AbstractMatListComponent<
  EditContentForm,
  TemplateRef<{ item: EditContentForm }>
> {}

@NgModule({
  imports: [CommonModule, MatListModule],
  declarations: [EditContentListComponent],
  exports: [EditContentListComponent],
})
export class EditContentListComponentModule {}
