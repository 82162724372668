import { Pipe, PipeTransform, NgModule, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BheConfig, DrupalFile } from '@bhe/types';
import { APP_CONFIG } from '@madeinlune/ngx-app-config';

@Pipe({
  name: 'getFileUrl',
})
export class GetFileUrlPipe implements PipeTransform {
  constructor(@Inject(APP_CONFIG) private config: BheConfig) {}

  transform(file: DrupalFile): string {
    let url: string = file.url;
    if (url.indexOf(this.config.backendUrl) == -1) {
      url = `${this.config.backendUrl}${url}`;
    }
    return url;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [GetFileUrlPipe],
  exports: [GetFileUrlPipe],
})
export class GetFileUrlPipeModule {}
