import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent
} from "@angular/material/autocomplete";
import { MhProfileService } from "@bhe/user-data-access";
import { MatFormFieldModule } from "@angular/material/form-field";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ReactiveFormsModule
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { RxState } from "@rx-angular/state";
import { MhProfile } from "@bhe/types";
import { HttpErrorResponse } from "@angular/common/http";
import {
  debounceTime,
  distinctUntilChanged,
  endWith,
  filter,
  map,
  Observable,
  startWith,
  Subscription,
  switchMap,
  tap
} from "rxjs";
import { BheIconComponentModule, IconLoadingComponentModule } from "@bhe/ui";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ReservationFormEntitiesService } from "@bhe/reservation-data-access";
import { GetMhProfilePipeModule } from "./get-mh-profile.pipe";
import { MhProfileRendererComponentModule } from "@bhe/reservation-ui";
import { ResourceIdentifier } from "@madeinlune/ngx-json-api";
import { MatDividerModule } from "@angular/material/divider";
import { TranslocoModule } from "@ngneat/transloco";
import { LetModule } from "@ngrx/component";

@Component({
  selector: "bhe-res-mh-profile-autocomplete",
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngIf="profilesForm">
        <form [formGroup]="profilesForm">
          <mat-form-field appearance="fill">
            <span matPrefix>
              <bhe-ui-bhe-icon icon="search"></bhe-ui-bhe-icon>
            </span>
            <mat-label>{{ label }}</mat-label>
            <mat-chip-list
              #chipList
              [multiple]="multiple"
              formArrayName="profiles"
            >
              <mat-chip
                *ngFor="
                  let control of profilesControls;
                  let i = index;
                  trackBy: identity
                "
                [formGroupName]="i"
                [removable]="!readonly"
                (removed)="remove(profilesForm, i)"
              >
                <ng-container
                  *ngrxLet="control?.value?.id | getMhProfile; let mhProfile"
                >
                  <span>{{ mhProfile.fullName }}</span>
                </ng-container>
                <ng-container *ngIf="!readonly">
                  <button matChipRemove>
                    <bhe-ui-bhe-icon icon="cancel-circle2"></bhe-ui-bhe-icon>
                  </button>
                </ng-container>
              </mat-chip>
              <ng-container *ngIf="profilesControls as controls">
                <input
                  #autoCompleteInput
                  [disabled]="controls.length >= addMax"
                  [formControl]="searchControl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                />
              </ng-container>
            </mat-chip-list>
            <ng-container *ngIf="hint">
              <mat-hint>{{ hint }}</mat-hint>
            </ng-container>

            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onOptionSelected($event)"
            >
              <ng-container *ngrxLet="isLoading$; let isLoading">
                <ng-container *ngIf="isLoading">
                  <mat-option>
                    <bhe-ui-icon-loading></bhe-ui-icon-loading>
                  </mat-option>
                </ng-container>
              </ng-container>
              <ng-container *ngrxLet="mhProfiles$; let mhProfiles">
                <ng-container *ngIf="mhProfiles.length > 0">
                  <ng-template ngFor [ngForOf]="mhProfiles" let-mhProfile>
                    <mat-option [value]="mhProfile" class="ad-profile">
                      <span [innerHTML]="mhProfile.fullName"></span>
                      <small [innerHTML]="mhProfile.mail"></small>
                    </mat-option>
                  </ng-template>
                </ng-container>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </ng-container>
    </ng-container>
    <ng-template
      ngFor
      [ngForOf]="profilesControls"
      let-control
      let-last="last"
      [ngForTrackBy]="identity"
    >
      <ng-container *ngIf="control.value?.id; let mhProfileId">
        <ng-container *ngrxLet="mhProfileId | getMhProfile; let mhProfile">
          <ng-container *ngIf="mhProfile">
            <bhe-res-mh-profile-renderer
              [mhProfile]="mhProfile"
            ></bhe-res-mh-profile-renderer>
            <ng-container
              *ngIf="!last && profilesControls && profilesControls.length > 1"
            >
              <mat-divider></mat-divider>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ["./mh-profile-autocomplete.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class MhProfileAutocompleteComponent implements OnDestroy {
  profilesForm!: UntypedFormGroup;

  @Input()
  label: string | undefined;

  @Input()
  placeholder: string | undefined;

  @Input()
  hint: string | undefined;

  @Input()
  readonly = false;

  @Input()
  addMax = 0;

  @Output()
  valueChanges: EventEmitter<ResourceIdentifier[]> = new EventEmitter<ResourceIdentifier[]>();

  profilesFormSubscription!: Subscription;

  multiple!: boolean;

  searchControl: UntypedFormControl = new UntypedFormControl();

  @Input()
  set value(value: ResourceIdentifier | ResourceIdentifier[]) {
    let values: ResourceIdentifier[];
    this.multiple = Array.isArray(value);
    if (Array.isArray(value)) {
      values = value;
    } else {
      if (value) {
        values = [value];
      } else {
        values = [];
      }
      this.addMax = 1;
    }

    this.profilesForm = this.fb.group({
      profiles: this.fb.array(values)
    });
    this.profilesFormSubscription = this.profilesForm.valueChanges.subscribe(
      (value) => {
        this.valueChanges.emit(value.profiles);
      }
    );
    this.validateValues();
  }

  separatorKeysCodes: number[] = [ENTER, COMMA];

  isLoading$: Observable<boolean> = this.state.select("isLoading");
  mhProfiles$: Observable<MhProfile[]> = this.state.select("mhProfiles");

  @ViewChild("autoCompleteInput")
  autoCompleteInput!: ElementRef<HTMLInputElement>;

  constructor(
    private fb: UntypedFormBuilder,
    private mhProfileService: MhProfileService,
    private state: RxState<{
      isLoading: boolean;
      mhProfiles: MhProfile[];
      error: HttpErrorResponse | null;
    }>,
    private reservationFormService: ReservationFormEntitiesService,
    private cdr: ChangeDetectorRef
  ) {
    this.state.set({
      isLoading: false,
      mhProfiles: [],
      error: null
    });

    const fetchMhProfiles$ = this.searchControl.valueChanges.pipe(
      startWith({ isLoading: true, error: null, mhProfiles: [] }),
      filter((search) => !!search),
      tap((search) => {
        if (search.length < 2) {
          this.state.set({ mhProfiles: [] });
        }
      }),
      filter((search) => search.length >= 2),
      debounceTime(400),
      distinctUntilChanged(),
      tap((search) => {
        this.state.set({ isLoading: true });
      }),
      switchMap((search) => {
        return this.mhProfileService.searchMhProfile(search).pipe(
          map((mhProfiles: MhProfile[] | HttpErrorResponse) => {
            if (mhProfiles instanceof HttpErrorResponse) {
              return {
                error: mhProfiles as HttpErrorResponse,
                isLoading: false
              };
            }
            return { error: null, isLoading: false, mhProfiles };
          })
        );
      }),
      endWith({ isLoading: false })
    );
    this.state.connect(fetchMhProfiles$);
  }

  unsubscribe() {
    if (this.profilesFormSubscription) {
      this.profilesFormSubscription.unsubscribe();
    }
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    const { id, type } = $event.option.value;
    this.reservationFormService.addEntity(
      $event.option.value as MhProfile,
      "IN_SYNC"
    );
    const profilesControl: UntypedFormArray | null = this.profilesControl;
    if (profilesControl) {
      profilesControl.push(new UntypedFormControl({ id, type }));
    }
    this.autoCompleteInput.nativeElement.value = "";
  }

  remove(form: UntypedFormGroup, index: number) {
    const profilesControl: UntypedFormArray | null = this.profilesControl;
    if (profilesControl) {
      profilesControl.removeAt(index);
    }
    this.validateValues();
  }

  validateValues() {
    /*const profilesControl: FormArray | null = this.profilesControls;
    if (profilesControl) {
      console.log(
        'profilesControl.length >= this.addMax',
        profilesControl.length >= this.addMax
      );
      if (profilesControl.length >= this.addMax) {
        this.searchControl.disable();
        console.log('this.searchControl.disable();');
      } else {
        this.searchControl.enable();
        console.log('this.searchControl.enable();');
      }
    }*/
  }

  get profilesControl(): UntypedFormArray | null {
    const profilesControl: UntypedFormArray | null = this.profilesForm?.get(
      "profiles"
    ) as UntypedFormArray;
    return profilesControl;
  }

  get profilesControls(): UntypedFormControl[] | undefined {
    return this.profilesControl?.controls as UntypedFormControl[];
  }

  displayWith = (mhProfile: MhProfile) => {
    return mhProfile?.fullName ?? "";
  };

  identity(index: number, item: UntypedFormControl): string {
    return item.value.id;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    IconLoadingComponentModule,
    MatChipsModule,
    MatIconModule,
    LetModule,
    GetMhProfilePipeModule,
    BheIconComponentModule,
    MhProfileRendererComponentModule,
    MatDividerModule,
    TranslocoModule,
    LetModule
  ],
  declarations: [MhProfileAutocompleteComponent],
  exports: [MhProfileAutocompleteComponent]
})
export class MhProfileAutocompleteComponentModule {
}
