import { Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import {
  GuestForm,
  MhProfile,
  ReservationForm,
  ReservationPartForm,
} from '@bhe/types';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { map, Observable } from 'rxjs';

export type FormEntityState = 'IN_SYNC' | 'UPDATED' | 'NEW';
export type FormEntityType =
  | ReservationForm
  | GuestForm
  | MhProfile
  | ReservationPartForm
  | ResourceIdentifier;

export interface FormEntity {
  state: FormEntityState;
  entity: FormEntityType;
  persistedResource?: FormEntityType;
}

@Injectable()
export class ReservationFormEntitiesService extends RxState<{
  entities: {
    [id: string]: FormEntity;
  };
}> {
  entities$ = this.select('entities');

  constructor() {
    super();
    this.set({ entities: {} });
  }

  addEntity(entity: FormEntityType, state: FormEntityState): void {
    const formEntity: FormEntity = {
      entity,
      state,
    };
    if (state === 'IN_SYNC') {
      formEntity.persistedResource = JSON.parse(JSON.stringify(entity));
    }
    const reduceFn = (oldState: any) => ({
      ...oldState,
      entities: {
        ...oldState.entities,
        [entity.id]: {
          ...oldState.entities[entity.id],
          ...formEntity,
        },
      },
    });
    this.set(reduceFn);
  }

  getEntity(id: string): Observable<FormEntity | undefined> {
    return this.entities$.pipe(
      map((entities) => {
        const foundEntity: FormEntity = entities[id];
        return foundEntity;
      })
    );
  }

  updateEntity(entity: FormEntityType, state: FormEntityState): void {
    const reduceFn = (oldState: any) => ({
      ...oldState,
      entities: {
        ...oldState.entities,
        [entity.id]: {
          ...oldState.entities[entity.id],
          entity,
          state,
        },
      },
    });
    this.set(reduceFn);
  }

  removeEntity(id: string): void {
    const reduceFn = (oldState: any) => {
      delete oldState.entities[id];
      return oldState;
    };
    this.set(reduceFn);
  }

  hasEntity$(id: string): Observable<boolean> {
    return this.entities$.pipe(
      map((entities) => {
        return !!entities[id];
      })
    );
  }
}
