import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { select, Store, StoreModule } from '@ngrx/store';
import * as fromCore from './core.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CoreEffects } from './core.effects';
import { HttpClientModule } from '@angular/common/http';
import { UrlHelperService } from 'angular-oauth2-oidc';
import { OauthUrlHelperService } from './oauth-url-helper-service';
import { map, Observable } from 'rxjs';
import {
  selectApplicationReady,
  selectMainMenu,
  selectSecondaryMenu,
} from './core.selectors';
import { Menu, ReservationStatusInfos, ReservationWorkflow } from '@bhe/types';
import { TranslocoService } from '@ngneat/transloco';

export const APPLICATION_READY = new InjectionToken<Observable<boolean>>(
  'APPLICATION_READY'
);

export const MAIN_MENU = new InjectionToken<Observable<Menu>>('MAIN_MENU');

export const SECONDARY_MENU = new InjectionToken<Observable<Menu>>(
  'SECONDARY_MENU'
);

export const RESERVATION_STATUS_INFOS = new InjectionToken<
  Map<ReservationWorkflow, ReservationStatusInfos>
>('RESERVATION_STATUS_INFOS');

export const RESERVATION_STATUS_INFOS_ARRAY = new InjectionToken<
  ReservationStatusInfos[]
>('RESERVATION_STATUS_INFOS_ARRAY');

export const COUNTRIES = new InjectionToken<
  Observable<{ id: string; label: string }[]>
>('COUNTRIES');

export const COUNTRIES_MAP = new InjectionToken<
  Observable<{ [id: string]: string }[]>
>('COUNTRIES_MAP');

export const SUBJECTS_OF_THE_VISIT_MAP = new InjectionToken<
  Observable<{ [id: string]: any }>
>('SUBJECTS_OF_THE_VISIT_MAP');

export const SUBJECTS_OF_THE_VISIT_FORM = new InjectionToken<
  Observable<{ value: string; label: string }[]>
>('SUBJECTS_OF_THE_VISIT_FORM');

export const BOTTLES_SOLD_MAP = new InjectionToken<
  Observable<{ [id: string]: any }>
>('BOTTLES_SOLD_MAP');

export const BOTTLES_SOLD_FORM = new InjectionToken<
  Observable<{ value: string; label: string }[]>
>('BOTTLES_SOLD_FORM');

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCore.coreFeatureKey, fromCore.reducer),
    EffectsModule.forFeature([CoreEffects]),
    HttpClientModule,
  ],
  providers: [
    {
      provide: UrlHelperService,
      useClass: OauthUrlHelperService,
    },
    {
      provide: MAIN_MENU,
      deps: [Store],
      useFactory: (store: Store<fromCore.State>) => {
        return store.pipe(select(selectMainMenu));
      },
    },
    {
      provide: SECONDARY_MENU,
      deps: [Store],
      useFactory: (store: Store<fromCore.State>) => {
        return store.pipe(select(selectSecondaryMenu));
      },
    },
    {
      provide: APPLICATION_READY,
      deps: [Store],
      useFactory: (store: Store<fromCore.State>) => {
        return store.pipe(select(selectApplicationReady));
      },
    },
    {
      provide: RESERVATION_STATUS_INFOS,
      useFactory: () => {
        const statusMap: Map<ReservationWorkflow, ReservationStatusInfos> =
          new Map();
        statusMap.set('draft', {
          key: 'draft',
          label: 'Draft & Analysis',
          code: 'an',
        });
        statusMap.set('submitted', {
          key: 'submitted',
          label: 'Request Submitted',
          code: 'su',
        });
        statusMap.set('approved', {
          key: 'approved',
          label: 'Approval',
          code: 'ap',
        });
        statusMap.set('ready', {
          key: 'ready',
          label: 'Processing',
          code: 'pr',
        });
        statusMap.set('confirmed', {
          key: 'confirmed',
          label: 'Final Confirmation',
          code: 'co',
        });
        statusMap.set('wns', {
          key: 'wns',
          label: 'Waiting for new Submission',
          code: 'w',
        });
        statusMap.set('closed', {
          key: 'closed',
          label: 'Closed',
          code: 'cl',
        });
        statusMap.set('cancelled', {
          key: 'cancelled',
          label: 'Cancelled',
          code: 'ca',
        });
        return statusMap;
      },
    },
    {
      provide: RESERVATION_STATUS_INFOS_ARRAY,
      deps: [RESERVATION_STATUS_INFOS],
      useFactory: (
        statusMap: Map<ReservationWorkflow, ReservationStatusInfos>
      ) => {
        const array = Array.from(statusMap, ([name, value]) => value);
        return array;
      },
    },
    {
      provide: COUNTRIES_MAP,
      deps: [TranslocoService],
      useFactory: (translocoService: TranslocoService) => {
        return translocoService.selectTranslateObject('countries');
      },
    },
    {
      provide: COUNTRIES,
      deps: [COUNTRIES_MAP],
      useFactory: (countriesMap$: Observable<{ [id: string]: string }>) => {
        return countriesMap$.pipe(
          map((countries) => {
            return Object.keys(countries).map((id) => {
              return {
                id,
                label: countries[id],
              };
            });
          })
        );
      },
    },
    {
      provide: SUBJECTS_OF_THE_VISIT_MAP,
      deps: [TranslocoService],
      useFactory: (translocoService: TranslocoService) => {
        return translocoService.selectTranslateObject(
          'reservation.form.subjects-of-the-visit'
        );
      },
    },
    {
      provide: SUBJECTS_OF_THE_VISIT_FORM,
      deps: [SUBJECTS_OF_THE_VISIT_MAP],
      useFactory: (
        subjectsOfTheVisitMap$: Observable<{ [id: string]: any }>
      ) => {
        return subjectsOfTheVisitMap$.pipe(
          map((subjectsOfTheVisitMap) => {
            return Object.keys(subjectsOfTheVisitMap)
              .map((key) => {
                return { value: key, label: subjectsOfTheVisitMap[key].label };
              })
              .filter((sub) => sub.value !== 'block-title');
          })
        );
      },
    },
    {
      provide: BOTTLES_SOLD_MAP,
      deps: [TranslocoService],
      useFactory: (translocoService: TranslocoService) => {
        return translocoService.selectTranslateObject(
          'reservation.form.bottle-sold-form'
        );
      },
    },
    {
      provide: BOTTLES_SOLD_FORM,
      deps: [BOTTLES_SOLD_MAP],
      useFactory: (BottlesSoldMap$: Observable<{ [id: string]: any }>) => {
        return BottlesSoldMap$.pipe(
          map((BottlesSoldMap) => {
            return Object.keys(BottlesSoldMap).map((key) => {
              return { value: key, label: BottlesSoldMap[key].label };
            });
          })
        );
      },
    },
  ],
})
export class BheShellStateModule {
  static forRoot(): ModuleWithProviders<BheShellStateModule> {
    return {
      ngModule: BheShellStateModule,
      providers: [],
    };
  }
}
