import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { PwaManifestService } from "@madeinlune/ngx-pwa-manifest";
import { map, Observable } from "rxjs";
import { LetModule, PushModule } from "@ngrx/component";
import { IconLoadingComponentModule } from "@bhe/ui";

@Component({
  selector: "bhe-splash",
  template: `
    <div class="content-group">
      <ng-container *ngrxLet="iconUrl$; let iconUrl">
        <ng-container *ngIf="iconUrl">
          <img [src]="iconUrl" />
        </ng-container>
        <h1 [innerHTML]="appTitle$ | ngrxPush"></h1>
        <bhe-ui-icon-loading></bhe-ui-icon-loading>
      </ng-container>
    </div>
  `,
  styleUrls: ["splash.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashComponent {
  iconUrl$: Observable<string | undefined> =
    this.pwaManifestService.icons$.pipe(
      map((icons) => {
        return icons.find((icon) => icon.sizes === "192x192")?.src;
      })
    );

  appTitle$: Observable<string | undefined> = this.pwaManifestService.name$;

  constructor(private pwaManifestService: PwaManifestService) {
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: SplashComponent
      }
    ]),
    IconLoadingComponentModule,
    PushModule,
    LetModule
  ],
  declarations: [SplashComponent],
  exports: [SplashComponent]
})
export class SplashComponentModule {
}
