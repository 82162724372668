import { DrupalCommon } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';

export class GuestExperience extends DrupalCommon {
  static type = 'taxonomy_term--guest_experience';

  @Transform(({ obj }) => {
    return obj?.attributes?.machine_name;
  })
  @Expose()
  code!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.name;
  })
  @Expose()
  name!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.description;
  })
  @Expose()
  description!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description?.value;
  })
  @Expose()
  programDescription!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description_long?.value;
  })
  @Expose()
  programLongDescription!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description_short?.value;
  })
  @Expose()
  programShortDescription!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_template_dress_code;
  })
  @Expose()
  dressCode!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_template_recommendations?.value;
  })
  @Expose()
  recommendations!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.drupal_internal__tid;
  })
  @Expose()
  tid!: number;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_guest_experience_duration;
  })
  @Expose()
  duration!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_enable;
  })
  @Expose()
  enabled!: boolean;
}
