import { Expose, Transform } from 'class-transformer';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';

export class NodeMeta {
  drupal!: {
    entityAccess: EntityAccess;
    fieldAccess: { [fieldName: string]: FieldAccess };
  };
}

export interface EntityAccess {
  view: boolean;
  update: boolean;
  delete: boolean;
}

export interface FieldAccess {
  view: boolean;
  edit: boolean;
}

export class DrupalIdentifier {
  @Expose()
  type!: string;

  @Expose()
  id!: string;
}

export class DrupalCommon extends DrupalIdentifier {
  @Transform(({ obj }) => {
    return obj?.attributes?.changed;
  })
  @Expose()
  changed?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.created;
  })
  @Expose()
  created?: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.uid?.data;
  })
  @Expose()
  userId?: ResourceIdentifier;
}

export class DrupalNode extends DrupalCommon {
  @Expose()
  meta?: NodeMeta;
}
