import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  NgModule,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  combineLatest,
  distinctUntilChanged,
  from,
  map,
  Observable,
  take
} from "rxjs";
import { USER_INFOS } from "@bhe/user-data-access";
import { RouterModule } from "@angular/router";
import { BheDialogData, Menu, User } from "@bhe/types";
import { APPLICATION_READY, MAIN_MENU } from "@bhe/shell-state";
import { HeaderComponentModule } from "../header/header.component";
import { ROUTE_RESERVATION_ID } from "@bhe/router";
import { ReservationListService } from "@bhe/reservation-list-data-access";
import { RxState } from "@rx-angular/state";
import { BheDialogComponent, BheDialogService } from "@bhe/ui";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { TranslocoService } from "@ngneat/transloco";
import { AuthenticationService } from "@bhe/authentication";
import { LetModule } from "@ngrx/component";

@Component({
  selector: "bhe-main-layout",
  template: `
    <header>
      <ng-container *ngrxLet="user$; let user">
        <ng-container *ngrxLet="mainMenu$; let mainMenu">
          <bhe-header
            [user]="user"
            [menu]="mainMenu"
            (newReservation)="onNewReservation()"
            (logOut)="onLogOut()"
          ></bhe-header>
        </ng-container>
      </ng-container>
    </header>
    <main>
      <ng-template #leftTemplate></ng-template>
      <div class="routed-content">
        <router-outlet></router-outlet>
      </div>
    </main>
  `,
  styleUrls: ["main-layout.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState, ReservationListService]
})
export class MainLayoutComponent implements AfterViewInit {
  @ViewChild("leftTemplate", { read: ViewContainerRef })
  leftTemplate!: ViewContainerRef;

  constructor(
    private matDialog: MatDialog,
    @Inject(USER_INFOS) public user$: Observable<User>,
    @Inject(MAIN_MENU) public mainMenu$: Observable<Menu>,
    @Inject(APPLICATION_READY) public applicationReady$: Observable<boolean>,
    @Inject(ROUTE_RESERVATION_ID) public reservationId$: Observable<string>,
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>,
    private translocoService: TranslocoService,
    private bheDialogService: BheDialogService,
    private authenticationService: AuthenticationService
  ) {
  }

  ngAfterViewInit() {
    this.reservationId$
      .pipe(
        map((reservationId) => !!reservationId),
        distinctUntilChanged()
      )
      .subscribe((isReservationContext) => {
        this.renderer.removeClass(this.el.nativeElement, "reservation-context");
        if (isReservationContext) {
          this.renderer.addClass(this.el.nativeElement, "reservation-context");
          from(this.#loadMiniList()).pipe(take(1)).subscribe();
        } else {
          this.#clearLeftTemplate();
        }
      });
    /*const alertData = BheDialogService.buildAlertDialogData("Sorry, we are in maintenance,<br>please come back later", [{
      id: "ok",
      color: "accent",
      label: "OK",
      close: true
    }]);
    this.bheDialogService.openAlert(alertData);*/
  }

  #clearLeftTemplate(): void {
    if (this.leftTemplate) {
      this.leftTemplate.clear();
    }
  }

  async #loadMiniList() {
    const { MiniListComponent } = await import("@bhe/reservation-list-feature");
    if (this.leftTemplate) {
      this.#clearLeftTemplate();
      this.leftTemplate.createComponent(MiniListComponent);
    }
  }

  onNewReservation() {
    const module$: Observable<any> = from(
      import("@bhe/reservation-feature").then(
        (m) => m.NewReservationComponentModule
      )
    );
    const component$: Observable<any> = from(
      import("@bhe/reservation-feature").then((m) => m.NewReservationComponent)
    );
    combineLatest([module$.pipe(take(1)), component$.pipe(take(1))])
      .pipe(take(1))
      .subscribe(([module, component]) => {
        const bheDialogData: BheDialogData = {
          component: component,
          title: "new-reservation.title",
          showHeader: true
        };
        this.matDialog.open(BheDialogComponent, {
          disableClose: true,
          width: "700px",
          minHeight: "50vh",
          maxWidth: "700px",
          maxHeight: "100vh",
          panelClass: ["content", "new-reservation"],
          data: bheDialogData
        });
      });
  }

  onLogOut() {
    this.authenticationService.logOut();
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderComponentModule,
    MatDialogModule,
    LetModule
  ],
  declarations: [MainLayoutComponent],
  exports: [MainLayoutComponent]
})
export class MainLayoutComponentModule {
}
