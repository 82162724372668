import { DrupalNode } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';

export class Guest extends DrupalNode {
  static type = 'node--guest';

  @Transform(({ obj }) => {
    return (
      obj?.attributes?.field_full_name ||
      `${obj?.attributes?.field_first_name} ${obj?.attributes?.field_last_name}`
    );
  })
  @Expose()
  fullName!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_company;
  })
  @Expose()
  company!: string|undefined;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_country;
  })
  @Expose()
  country!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_email;
  })
  @Expose()
  mail!: string|undefined;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_first_name;
  })
  @Expose()
  firstName!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_last_name;
  })
  @Expose()
  lastName!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_job_title;
  })
  @Expose()
  jobTitle!: string|undefined;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_phone;
  })
  @Expose()
  phone!: string|undefined;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_sex;
  })
  @Expose()
  gender!: number;
}
