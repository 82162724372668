import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReservation from './reservation.reducer';

export const selectReservationState =
  createFeatureSelector<fromReservation.State>(
    fromReservation.reservationFeatureKey
  );

export const selectReservation = createSelector(
  selectReservationState,
  (reservationState) => {
    return reservationState.reservation;
  }
);
