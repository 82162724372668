import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

export const selectRouteUrl = createSelector(selectRouter, (router) => {
  return router?.state?.url;
});

export const selectRouteNestedParams = createSelector(
  selectRouter,
  (router) => {
    let currentRoute = router?.state?.root;
    let params: Params = {};
    while (currentRoute?.firstChild) {
      currentRoute = currentRoute.firstChild;
      params = {
        ...params,
        ...currentRoute.params,
      };
    }
    return params;
  }
);

export const selectRouteNestedData = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  let data: any = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    data = {
      ...data,
      ...currentRoute.data,
    };
  }
  return data;
});

export const selectRouteNestedQueryParams = createSelector(
  selectRouter,
  (router) => {
    let currentRoute = router?.state?.root;
    let data: any = {};
    while (currentRoute?.firstChild) {
      currentRoute = currentRoute.firstChild;
      data = {
        ...data,
        ...currentRoute.queryParams,
      };
    }
    return data;
  }
);

export const selectRouteReservationId = createSelector(
  selectRouteNestedParams,
  (params: Params) => {
    return params?.['reservationId'];
  }
);

export const selectRouteSettingsUserFilterType = createSelector(
  selectRouteNestedParams,
  (params: Params) => {
    return params?.['userFilterType'];
  }
);

export const selectRouteSettingsEditContentType = createSelector(
  selectRouteNestedParams,
  (params: Params) => {
    return params?.['editContentType'];
  }
);

export const selectCorporatePageId = createSelector(
  selectRouteNestedParams,
  (params: Params) => {
    return params?.['pageId'];
  }
);

export const selectSearchText = createSelector(
  selectRouteNestedQueryParams,
  (params: any) => {
    return params?.['text'];
  }
);

export const selectRouteReservationContext = createSelector(
  selectRouteNestedData,
  (data: any) => {
    return data?.['reservationContext'];
  }
);

export const selectRouteIsBheProHome = createSelector(
  selectRouteNestedData,
  (data: any) => {
    return data?.['isBheProHome'] === true;
  }
);
