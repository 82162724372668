import { createAction, props } from '@ngrx/store';
import { AuthState } from './bhe-authentication.module';

export const initAuth = createAction('[Auth] Init');
export const tryLogin = createAction('[Auth] Try Login');
export const setUserId = createAction(
  '[Auth] Set User Id',
  props<{ userId: string | null }>()
);
export const setState = createAction(
  '[Auth] Set State',
  props<{ authState: AuthState }>()
);

export const setAuthenticated = createAction(
  '[Auth] Set Authenticated',
  props<{ authenticated: boolean }>()
);
export const setError = createAction(
  '[Auth] Set Error',
  props<{ error: object }>()
);
export const logIn = createAction('[Auth] Login');
export const logOut = createAction('[Auth] Logout');
