import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { map, Observable } from 'rxjs';
import { GuestExperience } from '@bhe/types';
import { State } from './guest-experience.reducer';
import { selectGuestExperienceEntities } from './guest-experience.selectors';

@Pipe({
  name: 'guestExperience',
})
export class GuestExperiencePipe implements PipeTransform {
  constructor(private store: Store<State>) {}

  transform(
    identifier: ResourceIdentifier | undefined
  ): Observable<GuestExperience | undefined> {
    return this.store.pipe(
      select(selectGuestExperienceEntities),
      map((guestExperienceEntities) => {
        if (identifier) {
          return guestExperienceEntities?.[identifier.id];
        }
        return undefined;
      })
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [GuestExperiencePipe],
  exports: [GuestExperiencePipe],
})
export class GuestExperiencePipeModule {}
