import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MhProfile, Role, User } from '@bhe/types';
import { bheClasses, jsonApiResources } from '@nx-agency/bhe/operators';
import { Direction, NgxJsonApi } from '@madeinlune/ngx-json-api';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private ngxJsonApi: NgxJsonApi) {}

  loadUser(uuid: string): Observable<{ user: User; roles: Role[] }> {
    return this.ngxJsonApi
      .find({
        type: User.type,
        id: uuid,
        params: {
          fields: {
            [User.type]: [
              'drupal_internal__uid',
              'field_user_full_name',
              'display_name',
              'field_user_firstname',
              'field_user_lastname',
              'mail',
              'roles',
            ],
            [Role.type]: ['label', 'drupal_internal__id'],
          },
          include: ['roles'],
        },
      })
      .pipe(
        jsonApiResources(),
        bheClasses(),
        map((resultMap) => {
          if (!resultMap[Role.type]) {
            throwError(
              () =>
                'Some resources have been omitted because of insufficient authorization.'
            );
          }
          return {
            user: resultMap[User.type]?.shift(),
            roles: resultMap[Role.type],
          };
        }),
        catchError((error) => {
          console.error('loadUser() error');
          return throwError(() => error);
        })
      );
  }

  searchUser(name: string): Observable<User[] | HttpErrorResponse> {
    return this.ngxJsonApi
      .find({
        type: User.type,
        params: {
          fields: {
            [User.type]: ['display_name', 'mail', 'field_user_full_name'],
          },
          page: {
            limit: 10,
            offset: 0,
          },
          sorting: [
            {
              api: 'field_user_lastname',
              direction: Direction.ASC,
            },
          ],
          filtering: [
            {
              path: 'field_user_full_name',
              operator: 'operator',
              value: 'CONTAINS',
            },
            {
              path: 'field_user_full_name',
              operator: 'value',
              value: name,
            },
          ],
        },
      })
      .pipe(
        jsonApiResources(),
        bheClasses(),
        map((resultMap) => {
          return resultMap[User.type];
        }),
        catchError((error) => throwError(() => error))
      );
  }
}
