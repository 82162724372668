import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BheButtonComponentModule } from '@bhe/ui';
import { TranslocoModule } from '@ngneat/transloco';
import { PwaManifestService } from '@madeinlune/ngx-pwa-manifest';
import { map, Observable } from 'rxjs';
import { LetModule } from "@ngrx/component";
import { MatDialogRef } from '@angular/material/dialog';
import { WINDOW } from '@ng-web-apis/common';

@Component({
  selector: 'bhe-pwa-update',
  template: `
    <ng-container *transloco="let t">
      <div class="content">
        <ng-container *ngrxLet="iconUrl$; let iconUrl">
          <ng-container *ngIf="iconUrl">
            <img [src]="iconUrl" />
          </ng-container>
        </ng-container>
        <p [innerHTML]="t('pwa.update.message')"></p>
      </div>
      <div class="actions-group">
        <bhe-ui-bhe-button size="lg" variant="text" (btnClick)="onClose()">
          <span [innerHTML]="t('actions.cancel')"></span>
        </bhe-ui-bhe-button>
        <bhe-ui-bhe-button size="lg" variant="accent" (btnClick)="onReload()">
          <span [innerHTML]="t('actions.reload')"></span>
        </bhe-ui-bhe-button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./update.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateComponent {
  iconUrl$: Observable<string | undefined> =
    this.pwaManifestService.icons$.pipe(
      map((icons) => {
        return icons.find((icon) => icon.sizes === '384x384')?.src;
      })
    );

  constructor(
    private pwaManifestService: PwaManifestService,
    public dialogRef: MatDialogRef<UpdateComponent>,
    @Inject(WINDOW) private window: Window
  ) {}

  onClose() {
    this.dialogRef.close();
  }

  onReload() {
    this.window.location.reload();
  }
}

@NgModule({
  imports: [
    CommonModule,
    BheButtonComponentModule,
    TranslocoModule,
    LetModule
  ],
  declarations: [UpdateComponent],
  exports: [UpdateComponent],
})
export class UpdateComponentModule {}
