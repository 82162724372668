import { FormlyFieldConfig } from "@ngx-formly/core";

export function guestExperience(): FormlyFieldConfig[] {
  return [
    {
      key: "id"
    },
    {
      key: "type"
    },
    {
      key: "name",
      templateOptions: {
        label: "Title"
      },
      type: "input"
    },
    {
      key: "field_guest_experience_duration",
      templateOptions: {
        label: "Duration"
      },
      type: "input"
    },
    {
      key: "field_program_description",
      templateOptions: {
        label: "Program Description"
      },
      type: "textArea"
    },
    {
      key: "field_program_description_long",
      templateOptions: {
        label: "Long Program Description"
      },
      type: "textArea"
    },
    {
      key: "field_program_description_short",
      templateOptions: {
        label: "Short Program Description"
      },
      type: "textArea"
    },
    {
      key: "field_template_contact_info",
      templateOptions: {
        label: "Contact Infos",
        rows: 3
      },
      type: "textarea"
    },
    {
      key: "field_template_contact_phone",
      templateOptions: {
        label: "Contact Phone"
      },
      type: "input"
    },
    {
      key: "field_template_dress_code",
      templateOptions: {
        label: "Dress Code"
      },
      type: "input"
    },
    {
      key: "field_template_recommendations",
      templateOptions: {
        label: "Recommendations"
      },
      type: "textArea"
    },
    {
      key: "field_enable",
      templateOptions: {
        label: "Enabled"
      },
      type: "boolean"
    }
  ];
}
