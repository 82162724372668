import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FieldType } from "@ngx-formly/material";
import { ReactiveFormsModule } from "@angular/forms";
import { filter, map, Observable, ReplaySubject, startWith, take } from "rxjs";
import { ReservationPartForm } from "@bhe/types";
import { TranslocoModule } from "@ngneat/transloco";
import { FieldTypeConfig, FormlyModule } from "@ngx-formly/core";
import { MatButtonModule } from "@angular/material/button";
import { ReservationPartService } from "./reservation-part.service";
import { GUEST_TYPE_TRADE_UUID } from "@bhe/vocabularies-data";
import { LetModule, PushModule } from "@ngrx/component";

@Component({
  selector: "bhe-reservation-part-form",
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngIf="form">
        <form [formGroup]="reservationPartForm">
          <ng-container *ngrxLet="fields$; let fields">
            <formly-form
              [form]="reservationPartForm"
              [fields]="fields"
              [model]="reservationPartModel$ | ngrxPush"
            ></formly-form>
          </ng-container>
        </form>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ["./reservation-part-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReservationPartService]
})
export class ReservationPartFormComponent extends FieldType<FieldTypeConfig> implements OnInit {
  reservationPartForm = this.reservationPartService.reservationPartForm;
  formValid$: Observable<boolean> = this.reservationPartService.formValid$;
  fields$ = this.reservationPartService.fields$;

  reservationPartModel$: ReplaySubject<ReservationPartForm | undefined> =
    this.reservationPartService.reservationPartModel$;

  constructor(
    @Inject(GUEST_TYPE_TRADE_UUID)
    private guestTradeUuid$: Observable<string>,
    private readonly reservationPartService: ReservationPartService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    const reservationPartId: string =
      this.formControl?.value?.id || this.formControl?.value;
    this.formControl?.valueChanges
      .pipe(
        map(() => {
          return this.formControl?.value?.id || this.formControl?.value;
        }),
        startWith(reservationPartId),
        filter((id) => !!id),
        take(1)
      )
      .subscribe((id) => {
        this.reservationPartService.loadModel(id);
      });
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    PushModule,
    LetModule
  ],
  declarations: [ReservationPartFormComponent],
  exports: [ReservationPartFormComponent]
})
export class ReservationPartFormComponentModule {
}
