import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { GuestType } from '@bhe/types';
import { bheClasses, jsonApiResources } from '@nx-agency/bhe/operators';
import { NgxJsonApi } from '@madeinlune/ngx-json-api';
import { Store } from '@ngrx/store';
import { State } from './guest-type.reducer';
import { loadGuestTypes } from './guest-type.actions';

@Injectable({
  providedIn: 'root',
})
export class GuestTypeService {
  constructor(private ngxJsonApi: NgxJsonApi, private store: Store<State>) {}

  public loadGuestTypes(): Observable<boolean> {
    return this.ngxJsonApi
      .find({
        type: GuestType.type,
        params: {
          filtering: [
            {
              path: 'status',
              value: 1,
            },
          ],
          fields: {
            [GuestType.type]: [
              'machine_name',
              'name',
              'field_short_label',
              'field_description',
              'drupal_internal__tid',
            ],
          },
        },
      })
      .pipe(
        jsonApiResources(),
        bheClasses(),
        map((resultMap) => {
          if (resultMap[GuestType.type]) {
            this.store.dispatch(
              loadGuestTypes({ guestTypes: resultMap[GuestType.type] })
            );
          }
          return true;
        }),
        catchError((error) =>
          throwError(() => 'an error occured while loading guest types')
        )
      );
  }
}
