import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, Observable } from 'rxjs';
import { Brand } from '@bhe/types';
import { select, Store } from '@ngrx/store';
import { State } from './brand.reducer';
import { selectBrandEntities } from './brand.selectors';

@Pipe({
  name: 'codeToBrand',
})
export class CodeToBrandPipe implements PipeTransform {
  constructor(private store: Store<State>) {}

  transform(code: string): Observable<Brand | undefined> {
    return this.store.pipe(
      select(selectBrandEntities),
      map((brandEntities) => {
        return Object.keys(brandEntities).map((id) => {
          const brand: Brand | undefined = brandEntities[id];
          return brand;
        });
      }),
      map((brands) => {
        if (brands) {
          return brands.find((brand) => brand?.code === code);
        }
        return undefined;
      })
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [CodeToBrandPipe],
  exports: [CodeToBrandPipe],
})
export class CodeToBrandPipeModule {}
