import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EditContentType,
  ReservationContext,
  UserFilterType,
} from '@bhe/types';

export const ROUTE_RESERVATION_ID = new InjectionToken<Observable<string>>(
  'ROUTE_RESERVATION_ID'
);

export const ROUTE_CORPORATE_PAGE_ID = new InjectionToken<Observable<string>>(
  'ROUTE_CORPORATE_PAGE_ID'
);

export const ROUTER_URL = new InjectionToken<Observable<string>>('ROUTER_URL');

export const ROUTE_SEARCH_TEXT = new InjectionToken<Observable<string>>(
  'ROUTE_SEARCH_TEXT'
);

export const ROUTE_RESERVATION_CONTEXT = new InjectionToken<
  Observable<ReservationContext>
>('ROUTE_RESERVATION_CONTEXT');

export const ROUTE_SETTINGS_USER_FILTER_TYPE = new InjectionToken<
  Observable<UserFilterType | null>
>('ROUTE_SETTINGS_USER_FILTER_TYPE');

export const ROUTE_SETTINGS_EDIT_CONTENT_TYPE = new InjectionToken<
  Observable<EditContentType | null>
>('ROUTE_SETTINGS_EDIT_CONTENT_TYPE');

export const ROUTE_IS_BHE_PRO_HOME = new InjectionToken<Observable<boolean>>(
  'ROUTE_IS_BHE_PRO_HOME'
);

export const ROUTE_SETTINGS_EDIT_HOME_CONTENTS = new InjectionToken<
  Observable<{
    title: string;
    description: string;
    creationLabel?: string;
  } | null>
>('ROUTE_SETTINGS_EDIT_CONTENT_TYPE');
