import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'bhe-res-radio-wrapper',
  template: `
    <ng-container *ngIf="to.label as label">
      <label class="title" [innerHTML]="label"></label>
    </ng-container>
    <div class="wrapper-fields">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
  styleUrls: ['./radio-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioWrapperComponent extends FieldWrapper {}

@NgModule({
  imports: [CommonModule],
  declarations: [RadioWrapperComponent],
  exports: [RadioWrapperComponent],
})
export class RadioWrapperComponentModule {}
