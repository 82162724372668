import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrupalFile } from '@bhe/types';
import { BheIconComponentModule } from '../../bhe-icon/bhe-icon.component';
import { GetFileUrlPipeModule } from '@bhe/utils';

@Component({
  selector: 'bhe-ui-file-download',
  template: ` <a
    class="b-file"
    [href]="file | getFileUrl"
    download
    target="_blank"
  >
    <bhe-ui-bhe-icon [icon]="'file-pdf'"></bhe-ui-bhe-icon>
    <span [innerHTML]="file.filename" class="file-name truncate"></span
  ></a>`,
  styleUrls: ['./file-download.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDownloadComponent {
  @Input()
  file!: DrupalFile;
}

@NgModule({
  imports: [CommonModule, GetFileUrlPipeModule, BheIconComponentModule],
  declarations: [FileDownloadComponent],
  exports: [FileDownloadComponent],
})
export class FileDownloadComponentModule {}
