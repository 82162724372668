import { AfterViewInit, ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/material";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatQuillModule } from "../mat-quill/mat-quill-module";
import { FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: "bhe-res-text-area",
  template: `
    <mat-form-field appearance="fill">
      <label [innerHTML]="props.label"></label>
      <mat-quill [formControl]="control">
        <div quill-editor-toolbar>
          <span class="ql-formats">
            <button class="ql-bold" [title]="'Bold'"></button>
            <button class="ql-italic" [title]="'Italic'"></button>
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-clean"></button>
          </span>
        </div>
      </mat-quill>
    </mat-form-field>
  `,
  styleUrls: ["./text-area.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  public get control() {
    return this.formControl as UntypedFormControl;
  }

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.field.props?.disabled) {
        if (this.formControl) {
          this.formControl.disable();
        }
      }
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatQuillModule
  ],
  declarations: [TextAreaComponent],
  exports: [TextAreaComponent]
})
export class TextAreaComponentModule {
}
