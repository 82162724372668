import { DrupalNode } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';

export type ImageProfile =
  | 'original_64'
  | 'original_128'
  | 'original_256'
  | 'original_384'
  | 'original_512'
  | 'original_640'
  | 'original_1024'
  | 'original_2048';

export class Image extends DrupalNode {
  @Transform(({ obj }) => {
    const imageDerivatives =
      obj?.relationships?.field_media_image?.data?.meta?.imageDerivatives
        ?.links;
    const profilesMap: Map<ImageProfile, string> = new Map<
      ImageProfile,
      string
    >();
    if (imageDerivatives) {
      Object.keys(imageDerivatives).forEach((key) => {
        const value: any = imageDerivatives[key];
        profilesMap.set(key as ImageProfile, value.href);
      });
    }
    return profilesMap;
  })
  @Expose()
  derivatives!: Map<ImageProfile, string>;
}
