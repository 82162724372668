import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { select, Store, StoreModule } from "@ngrx/store";
import * as fromReservation from "./reservation.reducer";
import { State } from "./reservation.reducer";
import { EffectsModule } from "@ngrx/effects";
import { ReservationEffects } from "./reservation.effects";
import {
  ENTITIES_RELATIONS_SHIPS,
  GUEST_IS_PRESS,
  GUEST_IS_PRIVATE_CLIENT,
  GUEST_IS_TRADE,
  RESERVATION,
  RESERVATION_GUEST_TYPE, RESERVATION_HAS_GUEST_EXPERIENCES, RESERVATION_IS_DRAFT, RESERVATION_MISSING_GUEST_EXPERIENCES,
  SHOW_EDIT_TAB,
  USER_HAS_REQUESTOR_ROLE
} from "./reservation.tokens";
import { selectReservation } from "./reservation.selectors";
import {
  combineLatest,
  filter,
  map,
  Observable,
  shareReplay,
  withLatestFrom
} from "rxjs";
import { Guest, GuestType, Reservation, ReservationPart } from "@bhe/types";
import { USER_IS_BH_TEAM, USER_UUID } from "@bhe/user-data-access";
import { CAN_UPDATE_MAISONS } from "@nx-bhe/bhe/reservation/tokens";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromReservation.reservationFeatureKey,
      fromReservation.reducer
    ),
    EffectsModule.forFeature([ReservationEffects])
  ],
  providers: [
    {
      provide: RESERVATION,
      deps: [Store],
      useFactory: (store: Store<State>) => {
        return store.pipe(select(selectReservation));
      }
    },
    {
      provide: RESERVATION_HAS_GUEST_EXPERIENCES,
      deps: [RESERVATION],
      useFactory: (reservation$: Observable<Reservation>) => {
        return reservation$.pipe(
          filter(reservation => !!reservation),
          map(reservation => {
            const reservationParts: ReservationPart[] = reservation.reservationParts.filter(rp => !!rp);
            return reservationParts.filter(rp => !!rp.guestExperienceRef).length === reservationParts.length;
          })
        );
      }
    },
    {
      provide: RESERVATION_MISSING_GUEST_EXPERIENCES,
      deps: [RESERVATION],
      useFactory: (reservation$: Observable<Reservation>) => {
        return reservation$.pipe(
          filter(reservation => !!reservation),
          map(reservation => {
            const reservationParts: ReservationPart[] = reservation.reservationParts.filter(rp => !!rp);
            return reservationParts.filter(rp => !rp.guestExperienceRef).map(rp => rp.brandRef);
          })
        );
      }
    },
    {
      provide: RESERVATION_GUEST_TYPE,
      deps: [RESERVATION],
      useFactory: (reservation$: Observable<Reservation>) => {
        return reservation$.pipe(
          filter((reservation) => {
            return !!reservation;
          }),
          map((reservation) => {
            return reservation.guestType;
          })
        );
      }
    },
    {
      provide: GUEST_IS_TRADE,
      deps: [RESERVATION_GUEST_TYPE],
      useFactory: (reservationGuestType$: Observable<GuestType>) => {
        return reservationGuestType$.pipe(
          map((reservationGuestType) => {
            return reservationGuestType?.code === "trade";
          })
        );
      }
    },
    {
      provide: GUEST_IS_PRIVATE_CLIENT,
      deps: [RESERVATION_GUEST_TYPE],
      useFactory: (reservationGuestType$: Observable<GuestType>) => {
        return reservationGuestType$.pipe(
          map((reservationGuestType) => {
            return reservationGuestType?.code === "hnwi";
          })
        );
      }
    },
    {
      provide: GUEST_IS_PRESS,
      deps: [RESERVATION_GUEST_TYPE],
      useFactory: (reservationGuestType$: Observable<GuestType>) => {
        return reservationGuestType$.pipe(
          map((reservationGuestType) => {
            return reservationGuestType?.code === "press";
          })
        );
      }
    },
    {
      provide: ENTITIES_RELATIONS_SHIPS,
      useFactory: () => {
        const entitiesRelationships: Map<string, string[]> = new Map<string,
          string[]>();
        entitiesRelationships.set(Reservation.type, [
          "field_reservation_guests",
          "field_invited_by",
          "field_main_guest",
          "field_reservation_parts",
          "field_guest_type",
          "field_mh_accompanying_people",
          "field_new_client_which_houses"
        ]);
        entitiesRelationships.set(Guest.type, []);
        entitiesRelationships.set(ReservationPart.type, [
          "field_brand",
          "field_guest_experience"
        ]);
        return entitiesRelationships;
      }
    },
    {
      provide: USER_HAS_REQUESTOR_ROLE,
      deps: [RESERVATION, USER_IS_BH_TEAM, USER_UUID],
      useFactory: (
        reservation$: Observable<Reservation>,
        isBHTeam$: Observable<boolean>,
        userUuid$: Observable<string>
      ) => {
        return reservation$.pipe(
          filter((reservation) => !!reservation),
          withLatestFrom(isBHTeam$, userUuid$),
          map(([reservation, isBhTeam, userUuid]) => {
            return reservation?.requestor?.id === userUuid && !isBhTeam;
          }),
          shareReplay(1)
        );
      }
    },
    {
      provide: SHOW_EDIT_TAB,
      deps: [USER_HAS_REQUESTOR_ROLE, USER_IS_BH_TEAM],
      useFactory: (
        isRequestor$: Observable<boolean>,
        isBHTeam$: Observable<boolean>
      ) => {
        return combineLatest([isRequestor$, isBHTeam$]).pipe(
          map(([isRequestor, isBHTeam]) => {
            return isRequestor || isBHTeam;
          })
        );
      }
    },
    {
      provide: RESERVATION_IS_DRAFT,
      deps: [RESERVATION],
      useFactory: (reservation$: Observable<Reservation>) => {
        return reservation$.pipe(
          filter(reservation => !!reservation),
          map(reservation => {
            return reservation.workflow === 'draft' || reservation.workflow === 'wns';
          })
        )
      }
    },
    {
      provide: CAN_UPDATE_MAISONS,
      deps: [USER_HAS_REQUESTOR_ROLE, RESERVATION_IS_DRAFT, USER_IS_BH_TEAM],
      useFactory: (isRequestor$: Observable<boolean>, reservationIsDraft$: Observable<boolean>, isBhTeam$: Observable<boolean>) => {
        return combineLatest([isRequestor$, reservationIsDraft$, isBhTeam$]).pipe(
          map(([isRequestor, reservationIsDraft, isBhTeam]) => {
            return isRequestor && reservationIsDraft || isBhTeam;
          })
        )
      }
    }
  ]
})
export class BheReservationDataAccessModule {
}
