import { FormlyFieldConfig } from "@ngx-formly/core";


export function brandFields(): FormlyFieldConfig[] {
  return [
    {
      key: 'id',
    },
    {
      key: 'type',
    },
    {
      key: 'name',
      props: {
        label: 'Title',
      },
      type: 'input',
    },
    {
      key: 'field_default_contact_info',
      props: {
        label: 'Contact Infos',
      },
      type: 'textArea',
    },
    {
      key: 'field_default_contact_phone',
      props: {
        label: 'Contact Phone',
      },
      type: 'input',
    },
  ];
}
