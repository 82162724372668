import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import {
  GetBrandGuestExperiencesPipe,
  GUEST_EXPERIENCES,
} from '@bhe/vocabularies-data';
import { EMPTY, filter, Observable, switchMap, tap } from 'rxjs';
import { GuestExperience } from '@bhe/types';
import { FieldType } from '@ngx-formly/material';
import { MatRadioModule } from '@angular/material/radio';
import { ReservationPartService } from '../reservation-part-form/reservation-part.service';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { RadioGroupCompareWithDirectiveModule } from '@bhe/utils';
import { BheIconComponentModule } from '@bhe/ui';
import { LetModule } from "@ngrx/component";
import { FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: 'bhe-res-guest-experiences',
  template: `
    <mat-radio-group
      class="guest-type-radio-group"
      [formControl]="control"
      [rbCompareWith]="compareWith"
    >
      <ng-container *ngrxLet="guestExperiences$; let guestExperiences">
        <ng-template
          ngFor
          [ngForOf]="guestExperiences"
          let-guestExperience
          [ngForTrackBy]="trackBy"
        >
          <ng-container
            *ngIf="
              guestExperience.enabled ||
              control.value?.id === guestExperience.id
            "
          >
            <mat-radio-button
              [value]="{ type: guestExperience.type, id: guestExperience.id }"
            >
              <strong [innerHTML]="guestExperience.name"></strong>
              <ng-container
                *ngIf="guestExperience.programDescription as description"
              >
                <div [innerHTML]="description"></div>
              </ng-container>
              <div class="b-duration">
                <bhe-ui-bhe-icon [icon]="'duration'"></bhe-ui-bhe-icon>
                <span [innerHTML]="guestExperience.duration"></span>
              </div>
            </mat-radio-button>
          </ng-container>
        </ng-template>
      </ng-container>
    </mat-radio-group>
  `,
  styleUrls: ['./guest-experiences.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GetBrandGuestExperiencesPipe],
})
export class GuestExperiencesComponent extends FieldType<FieldTypeConfig> {
  guestExperiences$: Observable<GuestExperience[]> =
    this.reservationPartService.reservationPartModel$.pipe(
      filter((reservationPartModel) => {
        return !!reservationPartModel;
      }),
      switchMap((reservationPartModel) => {
        if (reservationPartModel) {
          return this.getBrandGuestExperiencesPipe.transform(
            reservationPartModel.field_brand
          );
        }
        return EMPTY;
      })
    );

  public get control() {
    return this.formControl as UntypedFormControl;
  }

  compareWith(o1: ResourceIdentifier, o2: ResourceIdentifier): boolean {
    return o1?.id === o2?.id;
  }

  constructor(
    @Inject(GUEST_EXPERIENCES)
    public allGuestExperiences$: Observable<GuestExperience[]>,
    private getBrandGuestExperiencesPipe: GetBrandGuestExperiencesPipe,
    private readonly reservationPartService: ReservationPartService
  ) {
    super();
  }

  trackBy(index: number, item: GuestExperience) {
    return item.id;
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    ReactiveFormsModule,
    RadioGroupCompareWithDirectiveModule,
    BheIconComponentModule,
    LetModule
  ],
  declarations: [GuestExperiencesComponent],
  exports: [GuestExperiencesComponent],
})
export class GuestExperiencesComponentModule {}
