import { Injectable } from "@angular/core";
import { catchError, EMPTY, map, Observable, startWith, take, tap, throwError, withLatestFrom } from "rxjs";
import { Reservation } from "@bhe/types";
import { ApolloQueryResult } from "@apollo/client";
import { RxState, selectSlice } from "@rx-angular/state";
import { Sort } from "@angular/material/sort";
import { plainToInstance } from "class-transformer";
import { Apollo, QueryRef } from "apollo-angular";
import { reservationListGql } from "./request.gql";
import { BheDialogService } from "@bhe/ui";

export interface ConditionGroupInput {
  conjunction?: "AND" | "OR";
  groups: ConditionGroup[];
}

export interface ConditionGroup {
  conjunction?: "AND" | "OR";
  conditions: Condition[];
}

export interface Condition {
  name: ReservationFacetField;
  value: string;
  operator: string;
}

export type ReservationFacetField =
  | "field_guest_type"
  | "field_workflow_reservation"
  | "field_country"
  | "field_brands_codes_array"
  | "uid"
  | "field_reservation_date";

export interface ReservationFacet {
  name: ReservationFacetField;
  values: ReservationFacetValue[];
}

export interface ReservationFacetValue {
  count: number;
  filter: string;
}

@Injectable({
  providedIn: "root"
})
export class ReservationListService {
  readonly reservations$ = this.state.select("reservations");
  readonly page$ = this.state.select("page");
  readonly limit$ = this.state.select("limit");
  readonly isLoading$ = this.state.select("isLoading");
  readonly loaded$ = this.state.select("loaded");
  readonly count$ = this.state.select("count");
  readonly sort$ = this.state.select("sort");
  readonly conditions$ = this.state.select("conditions");
  readonly fullText$ = this.state.select("fullText");
  readonly reservationsLength$ = this.reservations$.pipe(
    map((reservations) => reservations.length)
  );
  readonly facets$ = this.state.select("facets");
  private reservationsQuery!: QueryRef<any> | null;

  public initialized = false;

  constructor(
    private apollo: Apollo,
    private state: RxState<{
      reservations: Reservation[];
      reset: boolean;
      isLoading: boolean;
      loaded: boolean;
      page: number;
      limit: number;
      count: number;
      sort: Sort;
      fullText: string;
      conditions: ConditionGroupInput | null;
      facets: ReservationFacet[];
    }>,
    private bheDialogService: BheDialogService
  ) {
    this.state.hold(
      this.state.select(
        selectSlice([
          "limit",
          "page",
          "fullText",
          "sort",
          "conditions",
          "reset"
        ])
      ),
      ({ limit, page, fullText, sort, conditions, reset }) => {
        /*console.log({ fullText });
        console.log({ limit });
        console.log({ sort });
        console.log({ conditions });
        console.log({ page });
        console.log({ reset });*/

        if (!reset) {
          if (!this.reservationsQuery) {
            this.#initializeState(limit, fullText, sort, conditions);
          } else {
            this.reservationsQuery.setVariables({
              offset: page * limit,
              limit: limit,
              sortField: sort.active,
              sortDirection: sort.direction === "desc" ? "DESC" : "ASC",
              conditions:
                conditions && conditions?.groups?.length > 0
                  ? conditions
                  : null,
              fullText: {
                keys: fullText
              }
            });
          }
        } else {
          //TODO : this is quick debug, we need to check if can mutualize with line 115 (at least create a shared function);
          if (this.reservationsQuery) {
            this.reservationsQuery.setVariables({
              offset: page * limit,
              limit: limit,
              sortField: sort.active,
              sortDirection: sort.direction === "desc" ? "DESC" : "ASC",
              conditions:
                conditions && conditions?.groups?.length > 0
                  ? conditions
                  : null,
              fullText: {
                keys: fullText
              }
            });
          }
        }
      }
    );
  }

  public init(
    limit: number,
    sort: Sort,
    conditions: ConditionGroupInput | null | undefined,
    freeText: string = ""
  ) {
    if (!this.initialized) {
      this.state.set({
        reservations: [],
        page: 0,
        limit: limit,
        sort,
        fullText: freeText,
        conditions:
          conditions && conditions?.groups?.length > 0 ? conditions : null,
        reset: false
      });
      this.initialized = true;
    }
  }

  #initializeState(
    limit: number,
    fullText: string,
    sort: Sort,
    conditions: ConditionGroupInput | null
  ): void {
    this.reservationsQuery = this.apollo.watchQuery<any>({
      query: reservationListGql,
      fetchPolicy: "network-only",
      variables: {
        offset: 0,
        limit: limit,
        fullText: {
          keys: fullText
        },
        conditions:
          conditions && conditions?.groups?.length > 0 ? conditions : null,
        sortField: sort.active,
        sortDirection: sort.direction === "desc" ? "DESC" : "ASC"
      }
    });

    const apolloSearch$: Observable<ApolloQueryResult<unknown>> =
      this.reservationsQuery.valueChanges;

    const searchReservations$ = apolloSearch$.pipe(
      tap((queryResult: any) => {
        const resultCount: number =
          queryResult?.data?.searchAPISearch?.result_count;
        if (!isNaN(resultCount)) {
          this.state.set({
            count: queryResult?.data?.searchAPISearch?.result_count,
            facets: queryResult?.data?.searchAPISearch?.facets
          });
        }
      }),
      map((queryResult: any) => {
        return queryResult?.data?.searchAPISearch?.documents;
      }),
      /*tap((documents) => console.log({ documents })),*/
      map((documents) => {
        return documents.map((doc: any) => {
          return plainToInstance(Reservation, doc, {
            excludeExtraneousValues: true,
            exposeUnsetFields: false
          });
        });
      }),
      withLatestFrom(this.reservations$),
      map(([reservations, existingReservations]) => {
        const count = this.state.get("count");
        const updatedReservationCollection = [...existingReservations, ...reservations];
        const updatedReservationCollectionLength = updatedReservationCollection.length;
        const loaded = updatedReservationCollectionLength === count;
        return {
          reservations: updatedReservationCollection,
          isLoading: false,
          reset: false,
          loaded
        };
      }),
      startWith({ isLoading: true }),
      catchError((error) => {
        if (error?.message?.indexOf("login?destination=") > -1) {
          this.bheDialogService.openConnectWindow();
        } else {
          this.bheDialogService.openError(
            "errors.reservation-list.message",
            error,
            error.status
          );
        }
        return throwError(
          () => `an error occured while searching reservations, ${error} `
        );
      })
    );
    this.state.connect(searchReservations$);
  }

  public updateFreeText(fullText: string, reset: boolean = false): void {
    this.state
      .select("fullText")
      .pipe(take(1))
      .subscribe((oldFullText) => {
        if (oldFullText !== fullText || reset) {
          this.state.set({
            reservations: [],
            page: 0,
            fullText,
            isLoading: true,
            reset
          });
        }
      });
  }

  public updateSort(sort: Sort): void {
    this.state.set({
      reservations: [],
      page: 0,
      sort,
      isLoading: true,
      reset: false
    });
  }

  public updateConditions(conditions: ConditionGroupInput | null): void {
    //console.log({conditions});
    this.state.set({
      reservations: [],
      page: 0,
      conditions,
      isLoading: true,
      reset: false
    });
  }

  public reset(): void {
    this.initialized = false;
    this.reservationsQuery = null;
    this.state.set({
      reservations: [],
      page: 0,
      fullText: "",
      isLoading: false,
      reset: true,
      conditions: null,
      loaded: false
    });
  }

  public reload(): void {
    this.state.set({
      reservations: [],
      page: 0,
      fullText: "",
      isLoading: true,
      loaded: false
    });
  }

  public loadNextPage(): void {
    this.isLoading$
      .pipe(
      withLatestFrom(this.loaded$),
      take(1)
    )
      .subscribe(([isLoading, loaded]) => {
      if (!isLoading && !loaded) {
        const reduceFn = (oldState: any) => ({
          page: oldState.page + 1,
          reset: false,
          isLoading: true
        });
        this.state.set(reduceFn);
      }
    });
  }

  public loadUserReservations(): Observable<Reservation[]> {
    return EMPTY;
  }

  public loadUserDraftReservations(
    userUuid: string
  ): Observable<Reservation[]> {
    return EMPTY;
  }
}
