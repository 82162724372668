import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Menu, User } from '@bhe/types';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { BheButtonComponentModule, BheIconComponentModule } from '@bhe/ui';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bhe-header',
  template: `
    <ng-container *transloco="let t">
      <div>
        <ng-container *ngIf="menu">
          <ul>
            <ng-template ngFor [ngForOf]="menu.items" let-menuItem>
              <li>
                <ng-template
                  [ngTemplateOutlet]="linkTemplate"
                  [ngTemplateOutletContext]="{ $implicit: menuItem }"
                >
                </ng-template>
              </li>
            </ng-template>
          </ul>
        </ng-container>
      </div>
      <div class="b-right">
        <ng-container *ngIf="user">
          <div class="b-user">
            <button [matMenuTriggerFor]="userMenu" class="user-btn">
              <bhe-ui-bhe-icon icon="user-circle"></bhe-ui-bhe-icon>
              <span
                [innerHTML]="
                (user.firstName ?? user.mail)
              "
              ></span>
            </button>
            <mat-menu #userMenu="matMenu">
              <button mat-menu-item (click)="logOut.emit()">
                <mat-icon svgIcon="icon-logout"></mat-icon>
                <span [innerHTML]="t('actions.logout')"></span>
              </button>
            </mat-menu>
          </div>
        </ng-container>
        <bhe-ui-bhe-button
          variant="accent"
          icon="new-reservation"
          iconPlacement="right"
          (btnClick)="newReservation.emit()"
          class="new-reservation"
        >
          <span>New Reservation</span>
        </bhe-ui-bhe-button>
      </div>
      <div class="logo-group">
        <a [routerLink]="'/welcome'">
          <img class="mh-logo" [src]="'assets/logos/moet-hennessy.svg'" />
        </a>
      </div>
      <ng-template #linkTemplate let-menuItem>
        <ng-container *ngIf="menuItem.path === '/welcome'">
          <a [routerLink]="[menuItem.path]" [routerLinkActive]="'active'">
            <bhe-ui-bhe-icon icon="home"></bhe-ui-bhe-icon>
          </a>
        </ng-container>
        <ng-container *ngIf="menuItem.path !== '/welcome'">
          <a
            [routerLink]="[menuItem.path]"
            [routerLinkActive]="'active'"
            [innerHTML]="t(menuItem.label)"
          ></a>
        </ng-container>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input()
  menu!: Menu;

  @Input()
  user!: User;

  @Output()
  newReservation: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  logOut: EventEmitter<any> = new EventEmitter<any>();
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    MatMenuModule,
    MatButtonModule,
    BheButtonComponentModule,
    BheIconComponentModule,
    MatIconModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderComponentModule {}
