import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as GuestTypeActions from './guest-type.actions';
import { GuestType } from '@bhe/types';

export const guestTypesFeatureKey = 'guestTypes';

export interface State extends EntityState<GuestType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<GuestType> =
  createEntityAdapter<GuestType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(GuestTypeActions.addGuestType, (state, action) =>
    adapter.addOne(action.guestType, state)
  ),
  on(GuestTypeActions.upsertGuestType, (state, action) =>
    adapter.upsertOne(action.guestType, state)
  ),
  on(GuestTypeActions.addGuestTypes, (state, action) =>
    adapter.addMany(action.guestTypes, state)
  ),
  on(GuestTypeActions.upsertGuestTypes, (state, action) =>
    adapter.upsertMany(action.guestTypes, state)
  ),
  on(GuestTypeActions.updateGuestType, (state, action) =>
    adapter.updateOne(action.guestType, state)
  ),
  on(GuestTypeActions.updateGuestTypes, (state, action) =>
    adapter.updateMany(action.guestTypes, state)
  ),
  on(GuestTypeActions.deleteGuestType, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(GuestTypeActions.deleteGuestTypes, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(GuestTypeActions.loadGuestTypes, (state, action) =>
    adapter.setAll(action.guestTypes, state)
  ),
  on(GuestTypeActions.clearGuestTypes, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectGuestTypeEntities = selectEntities;
