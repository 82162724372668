import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Inject,
  NgModule,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { BheDialogData } from '@bhe/types';
import { BheIconComponentModule } from '../bhe-icon/bhe-icon.component';

@Component({
  selector: 'bhe-ui-bhe-dialog',
  template: `
    <ng-container *ngIf="data.showHeader">
      <header>
        <ng-container *ngIf="data.title as title">
          <ng-container *transloco="let t">
            <h3 class="title" [innerHTML]="t(title)"></h3>
          </ng-container>
          <bhe-ui-bhe-icon icon="cross2" (click)="close()"></bhe-ui-bhe-icon>
        </ng-container>
      </header>
    </ng-container>
    <ng-template #target></ng-template>
  `,
  styleUrls: ['./bhe-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BheDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('target', { read: ViewContainerRef }) vcRef!: ViewContainerRef;

  componentRef!: ComponentRef<any>;

  constructor(
    private dialogRef: MatDialogRef<BheDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BheDialogData
  ) {}

  ngAfterViewInit(): void {
    if (this.data.component && this.vcRef) {
      this.componentRef = this.vcRef.createComponent(this.data.component);
      this.componentRef.location.nativeElement.className = 'b-dynamic-content';
      setTimeout(() => {
        this.componentRef.changeDetectorRef.detectChanges();
        this.componentRef.changeDetectorRef.markForCheck();
      });
    } else {
      console.error(
        'cant create component this.data.component',
        this.data.component
      );
      console.error('cant create component this.vcRef', this.vcRef);
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  close() {
    this.dialogRef.close();
  }
}

@NgModule({
  imports: [CommonModule, TranslocoModule, BheIconComponentModule],
  declarations: [BheDialogComponent],
  exports: [BheDialogComponent],
})
export class BheDialogComponentModule {}
