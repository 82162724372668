import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Menu, MenuItem } from '@bhe/types';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'bhe-ui-bhe-pro-menu',
  template: `
    <ng-container *transloco="let t">
      <ng-template
        [ngTemplateOutlet]="menuTemplate"
        [ngTemplateOutletContext]="{ $implicit: menu }"
      >
      </ng-template>

      <ng-template #menuTemplate let-menu>
        <ul>
          <ng-template ngFor [ngForOf]="menu.items" let-menuItem>
            <ng-template
              [ngTemplateOutlet]="menuItemTemplate"
              [ngTemplateOutletContext]="{ $implicit: menuItem }"
            >
            </ng-template>
          </ng-template>
        </ul>
      </ng-template>

      <ng-template #menuItemTemplate let-menuItem>
        <ng-container *ngIf="menuItem.items">
          <span class="menu-group-title" [innerHTML]="t(menuItem.label)"></span>
          <ng-template
            [ngTemplateOutlet]="menuTemplate"
            [ngTemplateOutletContext]="{ $implicit: menuItem }"
          >
          </ng-template>
        </ng-container>
        <ng-container *ngIf="!menuItem.items">
          <li [class]="menuItem.path">
            <a
              [routerLink]="menuItem.path"
              [innerHTML]="t(menuItem.label)"
              [routerLinkActive]="'active'"
              [routerLinkActiveOptions]="{ exact: true }"
            >
            </a>
          </li>
        </ng-container>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./bhe-pro-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BheProMenuComponent {
  @Input()
  menu!: Menu;

  @ViewChild('menuTemplate') menuTemplate!: TemplateRef<Menu | MenuItem>;
}

@NgModule({
  imports: [CommonModule, RouterModule, TranslocoModule],
  declarations: [BheProMenuComponent],
  exports: [BheProMenuComponent],
})
export class BheProMenuComponentModule {}
