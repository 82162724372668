import { Inject, NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { USER_IS_ADMIN, USER_UUID } from '@bhe/user-data-access';
import { combineLatest, map, Observable, take } from 'rxjs';
import { Approbation } from '@bhe/types';

@Pipe({
  name: 'canApprove',
})
export class CanApprovePipe implements PipeTransform {
  constructor(
    @Inject(USER_IS_ADMIN)
    private userIsAdmin$: Observable<Observable<boolean>>,
    @Inject(USER_UUID) private userUuid$: Observable<string>
  ) {}

  transform(approbation: Approbation): Observable<boolean> {
    return combineLatest([this.userIsAdmin$, this.userUuid$]).pipe(
      take(1),
      map(([userIsAdmin, userUuid]) => {
        const approverIndex: number = approbation.possibleApprovers.findIndex(
          (approver) =>
            (approver.id === userUuid || userIsAdmin) &&
            approbation.workflow === 'pending'
        );
        return approverIndex > -1;
      })
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [CanApprovePipe],
  exports: [CanApprovePipe],
})
export class CanApprovePipeModule {}
