import { DrupalCommon } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';

export class Brand extends DrupalCommon {
  static type = 'taxonomy_term--brand';

  @Transform(({ obj }) => {
    return obj?.attributes?.machine_name;
  })
  @Expose()
  code!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.name;
  })
  @Expose()
  name!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.description;
  })
  @Expose()
  description!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_default_contact_info;
  })
  @Expose()
  contactInfos!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_default_contact_phone;
  })
  @Expose()
  contactPhone!: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_guest_experiences?.data;
  })
  @Expose()
  guestExperiences!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    return obj?.relationships?.field_brand_logo?.data;
  })
  @Expose()
  logo!: ResourceIdentifier;
}
