import { Injectable } from '@angular/core';
import { catchError, combineLatest, map, Observable, throwError } from 'rxjs';
import { BrandService } from './brand/brand.service';
import { GuestTypeService } from './guest-type/guest-type.service';

@Injectable({
  providedIn: 'root',
})
export class VocabulariesService {
  constructor(
    private brandService: BrandService,
    private guestTypeService: GuestTypeService
  ) {}

  loadRequired(): Observable<boolean> {
    const brandsLoaded$: Observable<boolean> = this.brandService.loadBrands();
    const guestTypesLoaded$: Observable<boolean> =
      this.guestTypeService.loadGuestTypes();
    return combineLatest([brandsLoaded$, guestTypesLoaded$]).pipe(
      map(([brandsLoaded, guestTypesLoaded]) => {
        return brandsLoaded && guestTypesLoaded;
      }),
      catchError((error) =>
        throwError(() => 'an error occured while loading vocabularies')
      )
    );
  }
}
