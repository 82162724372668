import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap } from 'rxjs/operators';

import * as UserActions from './user.actions';
import { UserService } from './user.service';
import { loadUserSuccess } from './user.actions';
import { catchError, throwError } from 'rxjs';

@Injectable()
export class UserEffects {
  userUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUser),
      switchMap((action) => {
        return this.userService.loadUser(action.uuid).pipe(
          switchMap((result) => {
            const { user, roles } = result;
            return [loadUserSuccess({ user, roles })];
          }),
          catchError((error) => {
            return throwError(
              () => `[USER] an error occured while loading user ${error}`
            );
          })
        );
      })
    );
  });

  constructor(private actions$: Actions, private userService: UserService) {}
}
