import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgModule,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Approbation, GuestExperience, Reservation } from '@bhe/types';
import { CanApprovePipeModule } from '@bhe/reservation-data-access';
import { BheButtonComponentModule, BheIconComponentModule } from '@bhe/ui';
import { BrandPipeModule } from '@bhe/vocabularies-data';
import { TranslocoModule } from '@ngneat/transloco';
import { DialogModule } from '@madeinlune/ngx-dialog';
import { OverviewEventsService } from '../overview-reservation-infos/overview-events-service.service';
import {
  ApprobationGuestExperiencesComponent,
  ApprobationGuestExperiencesComponentModule,
} from './approbation-guest-experiences/approbation-guest-experiences.component';
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-res-overview-approbation',
  template: `
    <ng-container *transloco="let t">
      <header>
        <ng-container *ngrxLet="approbation.brandRef | brand; let brand">
          <ng-container *ngIf="brand">
            <bhe-ui-bhe-icon [icon]="brand.code"></bhe-ui-bhe-icon>
            <h3 [innerHTML]="brand.name"></h3>
          </ng-container>
        </ng-container>
        <span
          [class]="approbation.workflow"
          class="approbation-status"
          [innerHTML]="
            t('reservation-part.approbation.status.' + approbation.workflow)
          "
        ></span>
      </header>

      <div class="b-content">
        <ng-container *ngIf="!approbation.approver; else approvedTemplate">
          <ng-template [ngTemplateOutlet]="notApprovedTemplate"></ng-template>
        </ng-container>
      </div>

      <ng-template #notApprovedTemplate>
        <div>
          <small
            [innerHTML]="t('reservation-part.approbation.to-be-approved-by')"
          ></small>
          <div class="b-content__approvers">
            <ng-template
              ngFor
              [ngForOf]="approbation.possibleApprovers"
              let-approver
            >
              <span [innerHTML]="approver.displayName"></span>
            </ng-template>
          </div>
        </div>
        <ng-container *ngrxLet="approbation | canApprove; let canApprove">
          <ng-container *ngIf="canApprove">
            <div class="approbation-block">
              <bhe-res-approbation-guest-experiences
                #guestExperiencesComponent
                [brandRef]="approbation.brandRef"
                [guestExperienceRef]="approbation.guestExperienceRef"
              ></bhe-res-approbation-guest-experiences>

              <div class="b-content__actions">
                <bhe-ui-bhe-button
                  class="approve-btn"
                  variant="soft"
                  color="accent"
                  icon="approve"
                  (click)="onApprove()"
                  ><span [innerHTML]="t('actions.approve')"></span
                ></bhe-ui-bhe-button>
                <bhe-ui-bhe-button
                  class="reject-btn"
                  variant="soft"
                  color="warn"
                  icon="reject"
                  (click)="onReject()"
                  ><span [innerHTML]="t('actions.reject')"></span
                ></bhe-ui-bhe-button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-template>

      <ng-template #approvedTemplate>
        <ng-container *ngIf="approbation.workflow === 'approved'">
          <small
            [innerHTML]="t('reservation-part.approbation.approved-by')"
          ></small>
        </ng-container>
        <ng-container *ngIf="approbation.workflow === 'rejected'">
          <small
            [innerHTML]="t('reservation-part.approbation.rejected-by')"
          ></small>
        </ng-container>
        <ng-container *ngIf="approbation.approver as approver">
          <span [innerHTML]="approver.displayName"></span>
        </ng-container>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./overview-approbation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewApprobationComponent implements OnChanges {
  @Input()
  approbation!: Approbation;

  @Input()
  reservation!: Reservation;

  @ViewChild('guestExperiencesComponent')
  guestExperiencesComponent!: ApprobationGuestExperiencesComponent;

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef<HTMLElement>,
    private overviewEventsService: OverviewEventsService
  ) {}

  onApprove() {
    const guestExperienceId: string = this.guestExperiencesComponent.formControl
      .value as string;
    this.overviewEventsService.confirmApprobationChoice({
      targetStatus: 'approved',
      approbation: this.approbation,
      message: 'reservation-part.approbation.confirmation.approved.message',
      reservation: this.reservation,
      userGuestExperience: {
        type: GuestExperience.type,
        id: guestExperienceId,
      },
      yesLabel: 'actions.yes-approve',
    });
  }

  onReject() {
    this.overviewEventsService.confirmApprobationChoice({
      targetStatus: 'rejected',
      approbation: this.approbation,
      reservation: this.reservation,
      message: 'reservation-part.approbation.confirmation.rejected.message',
      yesLabel: 'actions.yes-reject',
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const approbationPreviousValue: Approbation =
      changes['approbation']?.previousValue;
    const approbationCurrentValue: Approbation =
      changes['approbation']?.currentValue;
    const { nativeElement } = this.el;
    if (approbationPreviousValue) {
      this.renderer2.removeClass(
        nativeElement,
        approbationPreviousValue.workflow
      );
    }
    if (approbationCurrentValue) {
      this.renderer2.addClass(nativeElement, approbationCurrentValue.workflow);
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    CanApprovePipeModule,
    BheButtonComponentModule,
    BrandPipeModule,
    BheIconComponentModule,
    TranslocoModule,
    DialogModule,
    ApprobationGuestExperiencesComponentModule,
    LetModule
  ],
  declarations: [OverviewApprobationComponent],
  exports: [OverviewApprobationComponent],
})
export class OverviewApprobationComponentModule {}
