import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { AppConfigService } from '@madeinlune/ngx-app-config';

@Component({
  selector: 'bhe-root',
  template: ` <router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  @HostListener('document:keyup', ['$event.ctrlKey', '$event.key'])
  onKeyUp(ctrlKey: boolean, key: string): void {
    if (ctrlKey && key === 'E') {
      this.appConfigService.showAppConfigSwitcher();
    }
  }

  constructor(private appConfigService: AppConfigService) {}

}
