import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { APPLICATION_READY } from '@bhe/shell-state';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    @Inject(APPLICATION_READY) private applicationReady$: Observable<boolean>,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.applicationReady$.pipe(
      map((applicationReady) => {
        if (applicationReady) {
          return true;
        }
        return this.router.parseUrl('');
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationChildGuard implements CanActivateChild {
  constructor(
    @Inject(APPLICATION_READY) private applicationReady$: Observable<boolean>,
    private router: Router
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.applicationReady$.pipe(
      map((applicationReady) => {
        if (applicationReady) {
          return true;
        }
        return this.router.parseUrl('');
      })
    );
  }
}
