import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { GeoZone } from '@bhe/types';

export const loadGeozones = createAction(
  '[Geozone/API] Load Geozones',
  props<{ geozones: GeoZone[] }>()
);

export const addGeozone = createAction(
  '[Geozone/API] Add Geozone',
  props<{ geozone: GeoZone }>()
);

export const upsertGeozone = createAction(
  '[Geozone/API] Upsert Geozone',
  props<{ geozone: GeoZone }>()
);

export const addGeozones = createAction(
  '[Geozone/API] Add Geozones',
  props<{ geozones: GeoZone[] }>()
);

export const upsertGeozones = createAction(
  '[Geozone/API] Upsert Geozones',
  props<{ geozones: GeoZone[] }>()
);

export const updateGeozone = createAction(
  '[Geozone/API] Update Geozone',
  props<{ geozone: Update<GeoZone> }>()
);

export const updateGeozones = createAction(
  '[Geozone/API] Update Geozones',
  props<{ geozones: Update<GeoZone>[] }>()
);

export const deleteGeozone = createAction(
  '[Geozone/API] Delete Geozone',
  props<{ id: string }>()
);

export const deleteGeozones = createAction(
  '[Geozone/API] Delete Geozones',
  props<{ ids: string[] }>()
);

export const clearGeozones = createAction('[Geozone/API] Clear Geozones');
