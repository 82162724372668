import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from './geozone.reducer';
import { catchError, filter, map, Observable, of, tap } from 'rxjs';
import { GeoZone } from '@bhe/types';
import { NgxJsonApi } from '@madeinlune/ngx-json-api';
import { bheClasses, jsonApiResources } from '@nx-agency/bhe/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { addGeozones } from './geozone.actions';

@Injectable({
  providedIn: 'root',
})
export class GeoZoneService {
  constructor(private store: Store<State>, private ngxJsonApi: NgxJsonApi) {}

  loadGeoZones(): Observable<GeoZone[] | HttpErrorResponse> {
    return this.ngxJsonApi
      .find({
        type: GeoZone.type,
        params: {
          fields: {
            [GeoZone.type]: ['field_filter_countries', 'title'],
          },
        },
      })
      .pipe(
        jsonApiResources(),
        bheClasses(),
        map((resultMap) => {
          return resultMap[GeoZone.type];
        }),
        filter((geoZones) => !!geoZones),
        tap((geozones: GeoZone[]) => {
          this.store.dispatch(addGeozones({ geozones }));
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }
}
