import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';
import { State } from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userFeatureKey
);

export const getUserInfos = createSelector(
  selectUserState,
  (state: State) => state.infos
);

export const getUserRoles = createSelector(
  selectUserState,
  (state: State) => state.roles
);
