import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgModule,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'bhe-res-form-items-wrapper',
  template: `
    <ng-container *ngIf="props.label as label">
      <h3 class="title" [innerHTML]="label"></h3>
    </ng-container>
    <ng-container *ngIf="props.description as description">
      <div class="description" [innerHTML]="description"></div>
    </ng-container>
    <div class="wrapper-fields" [class]="props['fieldsWrapperClasses']">
      <ng-container #fieldComponent></ng-container>
    </div>
    <ng-container *ngIf="props['help'] as help">
      <div class="help" [innerHTML]="help"></div>
    </ng-container>
  `,
  styleUrls: ['./form-items-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormItemsWrapperComponent extends FieldWrapper implements OnInit {
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.to['className']) {
      const { nativeElement } = this.elementRef;
      if (nativeElement) {
        this.renderer.addClass(nativeElement, this.to['className']);
      }
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [FormItemsWrapperComponent],
  exports: [FormItemsWrapperComponent],
})
export class FormItemsWrapperComponentModule {}
