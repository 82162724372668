import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import * as Sentry from "@sentry/angular";

@Injectable()
export class JsonApiErrorInterceptor implements HttpInterceptor {

  constructor(
    private zone: NgZone
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response?.error?.jsonapi) {
          this.zone.runOutsideAngular(() => {
            let message = 'JSON:API generic error message';
            if(response.error.errors?.length > 0){
              const firstError = response.error.errors[0];
              if(firstError){
                message = firstError.detail;
              }
            }
            const error = new Error(message);
            error.name = `[${req.method}] JSON:API Error`;
            const extra: any = {
              error: response?.error
            }
            if(req.method === 'PATCH' || req.method === 'POST'){
              extra.body = req.body;
            }
            Sentry.captureException(error, { extra });
          });
        }
        return throwError(() => response);
      })
    );
  }
}
