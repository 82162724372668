import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { GuestForm } from "@bhe/types";
import { filter, map, Observable, shareReplay, startWith } from "rxjs";
import { UntypedFormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { MatButtonModule } from "@angular/material/button";
import { PushModule } from "@rx-angular/template/push";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BheButtonComponentModule } from "@bhe/ui";
import { ResourceIdentifier } from "@madeinlune/ngx-json-api";
import {
  getField,
  getFieldDisabledExpressionProperties
} from "../../reservation.fields";
import { isEqual } from "@madeinlune/ngx-operators";
import { LetModule } from "@ngrx/component";

@UntilDestroy()
@Component({
  selector: "bhe-guest-form",
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngIf="fields">
        <form [formGroup]="guestForm" (ngSubmit)="onSubmit($event)">
          <formly-form
            [form]="guestForm"
            [fields]="fields"
            [model]="formModel"
          ></formly-form>
          <ng-container *ngIf="!autoSave">
            <div class="b-actions">
              <ng-content select=".actions"></ng-content>
              <ng-container *ngrxLet="formValid$; let formValid">
                <bhe-ui-bhe-button
                  [disabled]="!formValid"
                  [variant]="'accent'"
                  (btnClick)="onSubmit(guestForm.value)"
                >
                  <span [innerHTML]="t('actions.save')"></span>
                </bhe-ui-bhe-button>
              </ng-container>
            </div>
          </ng-container>
        </form>
      </ng-container>
      <!--<code [innerHTML]="guestForm.value | json"> </code>-->
    </ng-container>
  `,
  styleUrls: ["./guest-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuestFormComponent implements AfterViewInit {
  @Input()
  formModel!: GuestForm | ResourceIdentifier | undefined;

  @Input()
  autoSave = true;

  // form without mail and phone : accompanying guests
  @Input()
  restricted = false;

  @Output()
  save: EventEmitter<GuestForm> = new EventEmitter<GuestForm>();

  guestForm = new UntypedFormGroup({});
    formValid$: Observable<boolean> = this.guestForm.valueChanges.pipe(
    startWith(false),
    map((value) => this.guestForm.valid)
  );
  fields!: FormlyFieldConfig[];

  @Output()
  formChange = this.guestForm.valueChanges.pipe(
    untilDestroyed(this),
    filter((value) => {
      return value?.id && value?.type && this.guestForm.valid;
    }),
    isEqual(),
    shareReplay(1)
  );

  @Output()
  formValid = this.guestForm.valueChanges.pipe(
    untilDestroyed(this),
    map(() => {
      return this.guestForm.valid;
    }),
    shareReplay(1)
  );

  constructor(
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef
  ) {
  }

  public reset() {
    this.guestForm.reset();
  }

  onSubmit(formValue: GuestForm) {
    this.save.emit(formValue);
  }

  ngAfterViewInit(): void {
    this.fields = [
      { key: "id", className: "id" },
      { key: "type", className: "type" },
      {
        key: "field_sex",
        type: "radio",
        className: "civility",
        expressionProperties: getFieldDisabledExpressionProperties("field_sex"),
        templateOptions: {
          options: [
            {
              value: 2,
              label: this.translocoService.translate("guest.form.civility.mrs")
            },
            {
              value: 1,
              label: this.translocoService.translate("guest.form.civility.mr")
            }
          ],
          required: true
        },
        wrappers: []
      },
      {
        key: "field_full_name",
        className: "full-name"
      },
      {
        ...getField(
          this.translocoService,
          "field_first_name",
          "guest.form.first-name.label",
          {
            required: true
          }
        ),
        type: "input"
      },
      {
        ...getField(
          this.translocoService,
          "field_last_name",
          "guest.form.last-name.label",
          {
            required: true
          }
        ),
        type: "input"
      },
      ...this.getEmailField(),
      {
        ...getField(
          this.translocoService,
          "field_company",
          "guest.form.company.label",
          {
            required: true
          }
        ),
        type: "input"
      },
      {
        ...getField(
          this.translocoService,
          "field_job_title",
          "guest.form.job-title.label",
          {
            required: true
          }
        ),
        type: "input"
      },
      {
        ...getField(
          this.translocoService,
          "field_country",
          "reservation.form.country.label"
        ),
        wrappers: ["form-field"],
        type: "country"
      },
      ...this.getPhoneField(),
    ];
    this.cdr.detectChanges();
  }

  private getEmailField(): FormlyFieldConfig[] {
    if (this.restricted) {
      return [];
    }
    return [{
      ...getField(
        this.translocoService,
        "field_email",
        "guest.form.e-mail.label",
        {
          required: true
        }
      ),
      validators: {
        validation: ["email"]
      },
      type: "input"
    }];
  }

  private getPhoneField(): FormlyFieldConfig[] {
    if (this.restricted) {
      return [];
    }
    return [{
      ...getField(this.translocoService, "field_phone", "guest.form.phone"),
      type: "input"
    }];
  }

}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    PushModule,
    TranslocoModule,
    BheButtonComponentModule,
    LetModule
  ],
  declarations: [GuestFormComponent],
  exports: [GuestFormComponent]
})
export class GuestFormComponentModule {
}
