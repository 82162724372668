import { Expose, Transform } from 'class-transformer';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { NodeMeta } from '../drupal-node.model';

export class ReservationPartForm implements ResourceIdentifier {
  @Expose()
  type!: string;

  @Expose()
  id!: string;
  /******* ATTRIBUTES *******/

  @Transform(({ obj }) => {
    return obj?.attributes?.field_visit_theme;
  })
  @Expose()
  field_visit_theme?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_other_remarks;
  })
  @Expose()
  field_other_remarks?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_bottles_sold;
  })
  @Expose()
  field_bottles_sold?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_bottles_sold_other;
  })
  @Expose()
  field_bottles_sold_other?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_contact_info;
  })
  @Expose()
  field_contact_info?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_contact_phone;
  })
  @Expose()
  field_contact_phone?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description_short;
  })
  @Expose()
  field_program_description_short?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description_short;
  })
  @Expose()
  field_program_description_long?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_dress_code;
  })
  @Expose()
  field_dress_code?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_recommendations;
  })
  @Expose()
  field_recommendations?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_transport_needed;
  })
  @Expose()
  field_transport_needed?: boolean;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_transport_dropoff;
  })
  @Expose()
  field_transport_dropoff?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_transport_pickup;
  })
  @Expose()
  field_transport_pickup?: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_transport_details;
  })
  @Expose()
  field_transport_details?: string;

  /******* RELATIONSHIPS *******/

  @Transform(({ obj }) => {
    return obj?.relationships?.field_guest_experience?.data;
  })
  @Expose()
  field_guest_experience?: ResourceIdentifier;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_brand?.data;
  })
  @Expose()
  field_brand!: ResourceIdentifier;

  @Transform(({ obj }) => {
    return obj?.meta;
  })
  @Expose()
  meta?: NodeMeta;
}
