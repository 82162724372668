import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { map, Observable } from 'rxjs';
import { GuestType } from '@bhe/types';
import { select, Store } from '@ngrx/store';
import { State } from './guest-type.reducer';
import { selectGuestTypeEntities } from './guest-type.selectors';

@Pipe({
  name: 'guestType',
})
export class GuestTypePipe implements PipeTransform {
  constructor(private store: Store<State>) {}

  transform(identifier: ResourceIdentifier): Observable<GuestType | undefined> {
    return this.store.pipe(
      select(selectGuestTypeEntities),
      map((guestTypeEntities) => {
        if (identifier) {
          return guestTypeEntities?.[identifier.id];
        }
        return undefined;
      })
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [GuestTypePipe],
  exports: [GuestTypePipe],
})
export class GuestTypePipeModule {}
