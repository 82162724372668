import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationFormEntitiesService } from './reservation-form-entities.service';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { ReservationPartForm } from '@bhe/types';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'getReservationPartForm',
})
export class GetReservationPartFormPipe implements PipeTransform {
  constructor(
    private reservationFormEntitiesService: ReservationFormEntitiesService
  ) {}

  transform(
    reservationPartRef: ResourceIdentifier
  ): Observable<ReservationPartForm | undefined> {
    return this.reservationFormEntitiesService.entities$.pipe(
      map((entities) => {
        return entities[reservationPartRef?.id]?.entity as ReservationPartForm;
      })
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [GetReservationPartFormPipe],
  exports: [GetReservationPartFormPipe],
})
export class GetReservationPartFormPipeModule {}
