import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnDestroy, Optional,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FieldType } from "@ngx-formly/material";
import {
  GuestFormComponent,
  GuestFormComponentModule
} from "../guest-form/guest-form.component";
import {
  FormEntityState,
  FormEntityType,
  ReservationFormEntitiesService,
  ReservationService
} from "@bhe/reservation-data-access";
import {
  auditTime,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subscription,
  switchMap,
  take,
  tap,
  withLatestFrom
} from "rxjs";
import { GuestForm } from "@bhe/types";
import { ResourceIdentifier } from "@madeinlune/ngx-json-api";
import { RxState, selectSlice } from "@rx-angular/state";
import { AppCursorService } from "@bhe/utils";
import { isEqual } from "@madeinlune/ngx-operators";
import { HttpErrorResponse } from "@angular/common/http";
import { ReservationFormService } from "../reservation-form/reservation-form.service";
import { LetModule } from "@ngrx/component";
import { FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: "bhe-guest-form-ref",
  template: `
    <ng-container *ngrxLet="guestFormModel$; let guestFormModel">
      <bhe-guest-form
        #guestFormComponent
        [restricted]="isRestricted"
        [formModel]="guestFormModel"
        (formValid)="onGuestFormValid($event)"
      ></bhe-guest-form>
    </ng-container>
  `,
  styleUrls: ["./guest-form-ref.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class GuestFormRefComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit, OnDestroy {
  guestFormModel$!: Observable<GuestForm | ResourceIdentifier | undefined>;

  @ViewChild("guestFormComponent")
  guestFormComponent!: GuestFormComponent;

  guestFormChangeSubscription!: Subscription;

  saving$: Observable<boolean> = this.state.select("saving");

  constructor(
    private reservationFormEntitiesService: ReservationFormEntitiesService,
    private reservationService: ReservationService,
    private cdr: ChangeDetectorRef,
    private state: RxState<{ saving: boolean }>,
    private appCursorService: AppCursorService,
    @Optional() private reservationFormService?: ReservationFormService
  ) {
    super();
    this.state.set({ saving: false });

    this.state.hold(
      this.state.select(selectSlice(["saving"])),
      ({ saving }) => {
        if (this.reservationFormService) {
          this.reservationFormService.set({ saving });
        }
      }
    );
  }

  get isCreationMode(): boolean {
    return this.field?.templateOptions?.["isNew"];
  }

  get isRestricted(): boolean {
    return this.field?.templateOptions?.["restricted"];
  }

  ngAfterViewInit() {
    if (this.formControl) {
      this.guestFormModel$ = this.formControl.valueChanges.pipe(
        startWith(this.formControlValue),
        switchMap((value: ResourceIdentifier) => {
          if (value) {
            if (!this.isCreationMode) {
              return this.reservationFormEntitiesService
                .getEntity(value.id)
                .pipe(
                  map((entity) => {
                    return entity?.entity as GuestForm | undefined;
                  })
                );
            } else {
              return of({ ...value });
            }
          }
          return of(undefined);
        }),
        take(1),
        shareReplay(1)
      );
      this.cdr.detectChanges();
    }

    if (this.guestFormComponent) {
      this.guestFormChangeSubscription = combineLatest([
        this.guestFormComponent.formChange,
        this.saving$
      ])
        .pipe(
          filter(([formValue, saving]) => {
            return !saving;
          }),
          auditTime(200),
          isEqual(),
          tap(([formValue, saving]) => {
            const entityState: FormEntityState = this.isCreationMode
              ? "NEW"
              : "UPDATED";
            this.reservationFormEntitiesService.updateEntity(
              formValue,
              entityState
            );
          }),
          filter(() => !this.isCreationMode),
          withLatestFrom(this.reservationFormEntitiesService.entities$),
          switchMap(([[formValue, saving], entities]) => {
            this.state.set({ saving: true });
            console.log({formValue})
            return this.reservationService.saveEntity(entities, formValue.id);
          }),
          tap((result) => {
            this.state.set({ saving: false });
            if (
              !(result instanceof HttpErrorResponse) &&
              result !== "no request needed"
            ) {
              Object.keys(result).forEach((key) => {
                const typeData: FormEntityType[] = result[key];
                if (Array.isArray(typeData)) {
                  typeData.forEach((data) => {
                    this.reservationFormEntitiesService.addEntity(
                      data,
                      "IN_SYNC"
                    );
                  });
                }
              });
            }
          })
        )
        .subscribe();
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.guestFormChangeSubscription) {
      this.guestFormChangeSubscription.unsubscribe();
    }
  }

  get formControlValue(): ResourceIdentifier | null {
    return this.formControl?.value;
  }

  onGuestFormValid(valid: boolean) {
    if (this.field.props["onGuestFormValid"]) {
      this.field.props["onGuestFormValid"](valid);
    }
  }
}

@NgModule({
  imports: [CommonModule, GuestFormComponentModule, LetModule],
  declarations: [GuestFormRefComponent],
  exports: [GuestFormRefComponent]
})
export class GuestFormRefComponentModule {
}
