import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTabsModule } from "@angular/material/tabs";
import { FieldTypeConfig, FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { FieldType } from "@ngx-formly/material";
import { HideTabPipe } from "./hide-tab.pipe";

@Component({
  selector: "bhe-res-tabs",
  template: `
    <mat-tab-group mat-align-tabs="center">
      <ng-template
        ngFor
        [ngForOf]="field.fieldGroup"
        let-tab
        let-i="index"
        [ngForTrackBy]="identity"
      >
        <ng-container *ngIf="tab.hide !== true">
          <mat-tab [label]="tab.props?.label ?? 'tab-' + i">
            <ng-container
              *ngIf="tab.props?.description as description"
            >
              <div [innerHTML]="description"></div>
            </ng-container>
            <formly-field [field]="tab"></formly-field>
          </mat-tab>
        </ng-container>
      </ng-template>
    </mat-tab-group>
  `,
  styleUrls: ["./tabs.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent extends FieldType<FieldTypeConfig> {
  identity(index: number, item: FormlyFieldConfig): any {
    return item.id;
  }
}

@NgModule({
  imports: [CommonModule, MatTabsModule, FormlyModule],
  declarations: [TabsComponent, HideTabPipe],
  exports: [TabsComponent]
})
export class TabsComponentModule {
}
