import { DrupalNode } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';

export interface MessageBoxItem{
  id?: string;
  body?: string;
  userName?: string;
  reservationCountry?: string;
  reservationId?: string;
  reservationUuid?: string;
  changed?: string;
}
export class Message extends DrupalNode implements MessageBoxItem{
  static type = 'comment--reservations_comments';

  @Transform(({ obj }) => {
    return obj?.attributes?.comment_body?.value;
  })
  @Expose()
  body?: string;
  @Transform(({ obj }) => {
    return obj?.relationships?.uid?.data?.reference?.attributes?.display_name;
  })
  @Expose()
  userName?: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.entity_id?.data?.reference?.attributes
      ?.field_country;
  })
  @Expose()
  reservationCountry?: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.entity_id?.data?.reference?.attributes
      ?.field_reservation_id;
  })
  @Expose()
  reservationId?: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.entity_id?.data?.reference?.id;
  })
  @Expose()
  reservationUuid?: string;
}
