import { Expose, Transform } from 'class-transformer';
import { DrupalCommon } from './drupal-node.model';

export class DrupalFile extends DrupalCommon {
  static type = 'file--file';

  @Transform(({ obj }) => {
    return obj?.attributes?.filemime;
  })
  @Expose()
  filemime!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.filename;
  })
  @Expose()
  filename!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.filesize;
  })
  @Expose()
  filesize!: number;

  @Transform(({ obj }) => {
    return obj?.attributes?.uri?.url;
  })
  @Expose()
  url!: string;
}
