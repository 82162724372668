import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconLoadingComponentModule } from '../icon-loading/icon-loading.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'bhe-ui-loading',
  template: `
    <bhe-ui-icon-loading></bhe-ui-icon-loading>
    <div [innerHTML]="message"></div>
  `,
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  @Input()
  message!: string;
}

@NgModule({
  imports: [CommonModule, IconLoadingComponentModule, TranslocoModule],
  declarations: [LoadingComponent],
  exports: [LoadingComponent],
})
export class LoadingComponentModule {}
