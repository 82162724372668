import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { select, State, Store, StoreModule } from '@ngrx/store';
import * as fromGeozone from './geozone/geozone.reducer';
import { map, Observable } from 'rxjs';
import {
  ApproversFilter,
  BhTeamFilter,
  Brand,
  CorporatePage,
  GeoZone,
  GuestExperience,
  GuestType,
  ObserversFilter,
} from '@bhe/types';
import { selectGeozoneEntities } from './geozone/geozone.selectors';

export const GEO_ZONES_MAP = new InjectionToken<
  Observable<{ [id: string]: GeoZone }>
>('GEO_ZONES_MAP');

export const GEO_ZONES = new InjectionToken<Observable<GeoZone[]>>('GEO_ZONES');

export const ENTITIES_RELATIONS_SHIPS = new InjectionToken<
  Map<string, string[]>
>('ENTITIES_RELATIONS_SHIPS');

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromGeozone.geozonesFeatureKey, fromGeozone.reducer),
  ],
  providers: [
    {
      provide: GEO_ZONES_MAP,
      deps: [Store],
      useFactory: (store: State<any>) => {
        return store.pipe(select(selectGeozoneEntities));
      },
    },
    {
      provide: GEO_ZONES,
      deps: [GEO_ZONES_MAP],
      useFactory: (geoZonesMap$: Observable<{ [id: string]: GeoZone }>) => {
        return geoZonesMap$.pipe(
          map((geoZonesMap) => {
            return Object.keys(geoZonesMap).map((key) => geoZonesMap[key]);
          })
        );
      },
    },
    {
      provide: ENTITIES_RELATIONS_SHIPS,
      useFactory: () => {
        const entitiesRelationships: Map<string, string[]> = new Map<
          string,
          string[]
        >();
        entitiesRelationships.set(CorporatePage.type, []);
        entitiesRelationships.set(GuestExperience.type, []);
        entitiesRelationships.set(GuestType.type, []);
        entitiesRelationships.set(Brand.type, []);
        entitiesRelationships.set(GeoZone.type, []);
        const userFiltersRelationships: string[] = [
          'field_filter_geo_zones',
          'field_filter_guest_types',
          'field_guest_experiences',
          'field_filter_brands',
          'field_filter_users',
        ];
        entitiesRelationships.set(
          ApproversFilter.type,
          userFiltersRelationships
        );
        entitiesRelationships.set(BhTeamFilter.type, userFiltersRelationships);
        entitiesRelationships.set(
          ObserversFilter.type,
          userFiltersRelationships
        );
        return entitiesRelationships;
      },
    },
  ],
})
export class BheSettingsDataAccessModule {}
