import {Injectable} from '@angular/core';
import {TemplatePortal} from '@angular/cdk/portal';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  private listPortal: Subject<TemplatePortal | null> =
    new Subject<TemplatePortal | null>();

  readonly listPortal$ = this.listPortal.asObservable();

  constructor() {}

  setListPortal(portal: TemplatePortal | null) {
    this.listPortal.next(portal);
  }
}
