import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationPart } from '@bhe/types';

@Pipe({
  name: 'showApprobationBlocks',
})
export class ShowApprobationBlocksPipe implements PipeTransform {
  transform(reservationParts: ReservationPart[]): boolean {
    return (
      reservationParts
        .filter((reservationPart) => !!reservationPart)
        .map((reservationPart) => {
          return reservationPart.workflow !== 'draft';
        })
        .filter((isNotDraft) => !isNotDraft).length === 0
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ShowApprobationBlocksPipe],
  exports: [ShowApprobationBlocksPipe],
})
export class ShowApprobationBlocksPipeModule {}
