import { Inject, NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BRANDS_MAP,
  GUEST_EXPERIENCES_MAP,
} from '../bhe-vocabularies-data.module';
import {
  catchError,
  filter,
  map,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { Brand, GuestExperience } from '@bhe/types';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';

@Pipe({
  name: 'getBrandGuestExperiences',
})
export class GetBrandGuestExperiencesPipe implements PipeTransform {
  constructor(
    @Inject(GUEST_EXPERIENCES_MAP)
    private guestExperiencesMap$: Observable<{ [id: string]: GuestExperience }>,
    @Inject(BRANDS_MAP)
    private brands$: Observable<{ [id: string]: Brand }>
  ) {}

  transform(brandRef: ResourceIdentifier): Observable<GuestExperience[]> {
    return this.brands$.pipe(
      filter((brands) => !!brands),
      map((brands) => {
        return brands[brandRef?.id];
      }),
      filter((brand) => !!brand),
      switchMap((brand) => {
        return this.guestExperiencesMap$.pipe(
          filter((guestExperiencesMap) => !!guestExperiencesMap),
          map((guestExperiencesMap) => {
            if (brand) {
              return brand.guestExperiences
                .map((ge) => {
                  return guestExperiencesMap[ge?.id];
                })
                .filter((ge) => !!ge);
            }
            return [];
          })
        );
      }),
      catchError((error) => {
        return throwError(
          () => `an error occures in GetBrandGuestExperiencesPipe, ${error}`
        );
      })
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [GetBrandGuestExperiencesPipe],
  exports: [GetBrandGuestExperiencesPipe],
})
export class GetBrandGuestExperiencesPipeModule {}
