import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationPart } from '@bhe/types';
import { GuestExperiencePipeModule } from '@bhe/vocabularies-data';
import { OverviewFieldWrapperComponentModule } from '../overview-field-wrapper/overview-field-wrapper.component';
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-res-overview-reservation-part',
  template: `
    <ng-container *ngIf="reservationPart">
      <ng-container
        *ngrxLet="
          reservationPart.guestExperienceRef | guestExperience;
          let guestExperience
        "
      >
        <ng-container *ngIf="guestExperience">
          <bhe-res-overview-field-wrapper title="Guest Experience">
            <div [innerHTML]="guestExperience.programDescription"></div>
          </bhe-res-overview-field-wrapper>
        </ng-container>
      </ng-container>

      <ng-template
        [ngTemplateOutlet]="fieldTemplate"
        [ngTemplateOutletContext]="{
          title: 'Visit Objectives',
          value: reservationPart.visitTheme
        }"
      >
      </ng-template>
      <ng-template
        [ngTemplateOutlet]="fieldTemplate"
        [ngTemplateOutletContext]="{
          title: 'Program Description, Long',
          value: reservationPart.programLong
        }"
      >
      </ng-template>

      <ng-template
        [ngTemplateOutlet]="fieldTemplate"
        [ngTemplateOutletContext]="{
          title: 'Program Description, Short',
          value: reservationPart.programShort
        }"
      >
      </ng-template>

      <ng-template
        [ngTemplateOutlet]="fieldTemplate"
        [ngTemplateOutletContext]="{
          title: 'Recommendations',
          value: reservationPart.recommendations
        }"
      >
      </ng-template>

      <ng-template
        [ngTemplateOutlet]="fieldTemplate"
        [ngTemplateOutletContext]="{
          title: 'Dress Code',
          value: reservationPart.dressCode
        }"
      >
      </ng-template>

      <ng-container *ngIf="reservationPart.transport">
        <h3>Transport</h3>
        <bhe-res-overview-field-wrapper title="Details">
          <div [innerHTML]="reservationPart.transport.details"></div>
        </bhe-res-overview-field-wrapper>
        <bhe-res-overview-field-wrapper title="Pick-up">
          <div [innerHTML]="reservationPart.transport.pickup"></div>
        </bhe-res-overview-field-wrapper>
        <bhe-res-overview-field-wrapper title="Drop-off">
          <div [innerHTML]="reservationPart.transport.dropoff"></div>
        </bhe-res-overview-field-wrapper>
      </ng-container>
    </ng-container>
    <ng-template #fieldTemplate let-title="title" let-value="value">
      <ng-container *ngIf="value">
        <bhe-res-overview-field-wrapper [title]="title">
          <div [innerHTML]="value"></div>
        </bhe-res-overview-field-wrapper>
      </ng-container>
    </ng-template>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewReservationPartComponent {
  @Input()
  reservationPart!: ReservationPart;
}

@NgModule({
  imports: [
    CommonModule,
    OverviewFieldWrapperComponentModule,
    LetModule,
    GuestExperiencePipeModule
  ],
  declarations: [OverviewReservationPartComponent],
  exports: [OverviewReservationPartComponent],
})
export class OverviewReservationPartComponentModule {}
