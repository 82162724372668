import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Reservation, ReservationWorkflow } from '@bhe/types';
import {
  BrandIconsComponentModule,
  FlagComponentModule,
  IconLoadingComponentModule,
  ReservationWorkflowIconComponentModule,
} from '@bhe/ui';
import { BrandsPipe } from '@bhe/vocabularies-data';
import { map, ReplaySubject, take } from 'rxjs';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { TranslocoModule } from '@ngneat/transloco';
import { OverviewWorkflowComponentModule } from '../overview/overview-workflow/overview-workflow.component';
import { WorkflowSelectComponentModule } from '../workflow-select/workflow-select.component';
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-res-header',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngIf="reservation; else loadingTemplate">
        <div class="id-date">
          <strong [innerHTML]="reservation.bheId"></strong>
        </div>
        <div class="brands justify-center">
          <ng-container *ngrxLet="brandCodes$; let brandCodes">
            <bhe-ui-brand-icons [codes]="brandCodes"></bhe-ui-brand-icons>
          </ng-container>
        </div>
        <div class="status justify-right">
          <bhe-res-workflow-select
            [class.is-bh-team]="isBHTeam"
            [workflow]="reservation.workflow"
            (workflowChanged)="workflowChanged.emit($event)"
          ></bhe-res-workflow-select>
        </div>
        <span
          class="guest-count"
          [innerHTML]="
            t('reservation.header.guest-count', {
              count: guestCount
            })
          "
        ></span>
        <strong
          class="main-guest justify-center"
          [innerHTML]="
            reservation.mainGuest?.fullName +
            '<br> invited by ' +
            (reservation.invitedBy?.fullName ?? '--') +
            ', on ' +
            (reservation.reservationDate
              ? (reservation.reservationDate | date: 'MMM d y':'+00:00')
              : '--')
          "
        ></strong>
        <span
          class="guest-type justify-right"
          [innerHTML]="reservation.guestType.name"
        ></span>
        <div class="b-country">
          <bhe-ui-flag [code]="reservation.country"></bhe-ui-flag>
        </div>
        <span
          class="requestor justify-right"
          [class.is-bh-team]="isBHTeam"
          [innerHTML]="reservation.requestor.displayName"
          (click)="updateRequestor.emit(reservation.id)"
        ></span>
      </ng-container>
    </ng-container>
    <ng-template #loadingTemplate>
      <bhe-ui-icon-loading></bhe-ui-icon-loading>
    </ng-template>
  `,
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BrandsPipe],
})
export class HeaderComponent implements OnChanges {
  @Input()
  reservation!: Reservation;

  @Input()
  isBHTeam!: boolean | undefined;

  brandCodes$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  @Output()
  workflowChanged: EventEmitter<ReservationWorkflow> = new EventEmitter<ReservationWorkflow>();

  @Output()
  updateRequestor: EventEmitter<string> = new EventEmitter<string>();

  protected guestCount = 1;

  constructor(private brandsPipe: BrandsPipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['reservation'].currentValue){
      if(this.reservation){

        if (this.reservation?.reservationParts) {
          const brandRefs: ResourceIdentifier[] = this.reservation.reservationParts
            .filter((rp) => !!rp)
            .map((rp) => rp.brandRef);
          if (brandRefs) {
            this.brandsPipe
              .transform(brandRefs)
              .pipe(
                map((brands) => brands.map((brand) => brand.code)),
                take(1)
              )
              .subscribe((brandCodes) => {
                this.brandCodes$.next(brandCodes);
              });
          }
        }

        if(this.reservation.guests){
          this.guestCount = 1 + this.reservation.guests.length;
        }

        if(this.reservation.field_mh_accompanying_people){
          this.guestCount = this.guestCount + this.reservation.field_mh_accompanying_people.length;
        }

      }
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    BrandIconsComponentModule,
    ReservationWorkflowIconComponentModule,
    FlagComponentModule,
    TranslocoModule,
    IconLoadingComponentModule,
    OverviewWorkflowComponentModule,
    WorkflowSelectComponentModule,
    LetModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderComponentModule {}
