import {ChangeDetectionStrategy, Component, Input, NgModule, ViewEncapsulation,} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Export} from '@bhe/types';
import {BrandPipeModule, GuestTypePipeModule} from '@bhe/vocabularies-data';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslocoModule} from '@ngneat/transloco';
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-pro-export-overview',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngIf="exportNode">
        <dl>
          <dt>Exported on</dt>
          <dd [innerHTML]="exportNode.created"></dd>

          <dt>Exported By</dt>
          <dd [innerHTML]="exportNode.userName"></dd>

          <dt>Period</dt>
          <dd
            [innerHTML]="
              'from ' + exportNode.dateStart + ' to ' + exportNode.dateEnd
            "
          ></dd>

          <dt>Brands</dt>
          <ng-container
            *ngIf="exportNode.brands.length > 0; else allBrandsTemplate"
          >
            <ng-template ngFor [ngForOf]="exportNode.brands" let-brandRef>
              <ng-container *ngrxLet="brandRef | brand; let brand">
                <ng-container *ngIf="brand">
                  <dd [innerHTML]="brand.name"></dd>
                </ng-container>
              </ng-container>
            </ng-template>
          </ng-container>
          <dt>Guest Types</dt>
          <ng-container
            *ngIf="exportNode.guestTypes.length > 0; else allGuestTypesTemplate"
          >
            <ng-template
              ngFor
              [ngForOf]="exportNode.guestTypes"
              let-guestTypeRef
            >
              <ng-container *ngrxLet="guestTypeRef | guestType; let guestType">
                <ng-container *ngIf="guestType">
                  <dd [innerHTML]="guestType.name"></dd>
                </ng-container>
              </ng-container>
            </ng-template>
          </ng-container>
          <dt>Status</dt>
          <ng-container
            *ngIf="exportNode.status.length > 0; else allStatusTemplate"
          >
            <ng-template ngFor [ngForOf]="exportNode.status" let-status>
              <dd [innerHTML]="status"></dd>
            </ng-template>
          </ng-container>
          <dt>Countries</dt>
          <ng-container
            *ngIf="exportNode.countries.length > 0; else allCountriesTemplate"
          >
            <ng-template ngFor [ngForOf]="exportNode.countries" let-country>
              <dd [innerHTML]="country"></dd>
            </ng-template>
          </ng-container>
        </dl>

        <ng-container *ngIf="baseUrl && exportNode.fileUrl">
          <a
            mat-flat-button
            color="primary"
            download
            [href]="baseUrl + exportNode.fileUrl"
            class="download-link"
          >
            <mat-icon svgIcon="icon-download"></mat-icon>
            <span [innerHTML]="t('actions.download-export')"></span>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #allBrandsTemplate>
      <dd [innerHTML]="'All Brands'"></dd>
    </ng-template>

    <ng-template #allGuestTypesTemplate>
      <dd [innerHTML]="'All Guest Types'"></dd>
    </ng-template>

    <ng-template #allStatusTemplate>
      <dd [innerHTML]="'All Status'"></dd>
    </ng-template>

    <ng-template #allCountriesTemplate>
      <dd [innerHTML]="'All Countries'"></dd>
    </ng-template>
  `,
  styleUrls: ['./export-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportOverviewComponent {
  @Input()
  exportNode!: Export;

  @Input()
  baseUrl!: string | undefined;
}

@NgModule({
  imports: [
    CommonModule,
    BrandPipeModule,
    GuestTypePipeModule,
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    LetModule
  ],
  declarations: [ExportOverviewComponent],
  exports: [ExportOverviewComponent],
})
export class ExportOverviewComponentModule {}
