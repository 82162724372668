import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AppCursorService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setWaitingCursor() {
    this.document.body.classList.add('process-saving');
  }

  setDefaultCursor() {
    this.document.body.classList.remove('process-saving');
  }
}
