import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Route, RouterModule } from "@angular/router";
import { MainLayoutComponent, MainLayoutComponentModule } from "@bhe/shell-ui";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { BheShellStateModule, RESERVATION_STATUS_INFOS } from "@bhe/shell-state";
import { LoginGuard } from "./login.guard";
import { AuthenticationChildGuard, AuthenticationGuard } from "./authentication.guard";
import { NGRX_JSON_API_CONFIG, NgxJsonApiModule } from "@madeinlune/ngx-json-api";
import { APP_CONFIG } from "@madeinlune/ngx-app-config";
import {
  ApproversFilter,
  BheConfig,
  BhTeamFilter,
  Brand,
  CorporatePage,
  Export,
  GeoZone,
  GuestExperience,
  GuestType,
  Message,
  MhProfile,
  ObserversFilter,
  Reservation,
  User
} from "@bhe/types";
import { BheVocabulariesDataModule } from "@bhe/vocabularies-data";
import { BheFilesDataModule } from "@bhe/files-data";
import { BheUserDataAccessModule } from "@bhe/user-data-access";
import { APOLLO_OPTIONS, ApolloModule } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client";
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { BheRouterModule } from "@bhe/router";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { BheCorporateDataAccessModule } from "@bhe/corporate-data-access";
import { BheReservationDataAccessModule } from "@bhe/reservation-data-access";
import {
  CountryAutocompleteComponent,
  CountryAutocompleteComponentModule,
  DateRangeComponent,
  DateRangeComponentModule,
  FormItemsWrapperComponent,
  FormItemsWrapperComponentModule,
  GuestTypesComponent,
  GuestTypesComponentModule,
  LanguagesComponent,
  RadioWrapperComponent,
  RadioWrapperComponentModule,
  StepperComponent,
  TextAreaComponent
} from "@bhe/reservation-ui";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule } from "@angular/material-luxon-adapter";
import { AutocompleteComponent, AutocompleteComponentModule, UI_ICON_STATUS_INFOS } from "@bhe/ui";
import {
  BrandSelectionComponent,
  BrandSelectionComponentModule,
  GuestFormComponent,
  GuestFormComponentModule,
  GuestFormRefComponent,
  GuestFormRefComponentModule,
  MhFieldSingleComponent,
  MhFieldSingleComponentModule
} from "@bhe/reservation-feature";
import { FormlyMatDatepickerModule } from "@ngx-formly/material/datepicker";
import { BheSettingsDataAccessModule } from "@bhe/settings-data-access";
import { PwaManifestModule } from "@madeinlune/ngx-pwa-manifest";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { SwBypassInterceptor } from "./sw-bypass-interceptor";
import { AUTH_BACKEND_URL, AUTH_CLIENT_ID, BheAuthenticationModule } from "@bhe/authentication";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { QuillModule } from "ngx-quill";
import { JsonApiErrorInterceptor } from "./json-api-error-interceptor";
import { MaintenanceInterceptor } from "./maintenance-interceptor";

export function minItemsValidationMessage(err: any, field: FormlyFieldConfig) {
  return `should NOT have fewer than ${field?.props?.["minItems"]} items`;
}

export function maxItemsValidationMessage(err: any, field: FormlyFieldConfig) {
  return `should NOT have more than ${field?.props?.["maxItems"]} items`;
}

export function minlengthValidationMessage(err: any, field: FormlyFieldConfig) {
  return `should NOT be shorter than ${field?.props?.minLength} characters`;
}

export function maxlengthValidationMessage(err: any, field: FormlyFieldConfig) {
  return `should NOT be longer than ${field?.props?.maxLength} characters`;
}

export function minValidationMessage(err: any, field: FormlyFieldConfig) {
  return `should be >= ${field?.props?.min}`;
}

export function maxValidationMessage(err: any, field: FormlyFieldConfig) {
  return `should be <= ${field?.props?.max}`;
}

export function multipleOfValidationMessage(
  err: any,
  field: FormlyFieldConfig
) {
  return `should be multiple of ${field?.props?.step}`;
}

export function exclusiveMinimumValidationMessage(
  err: any,
  field: FormlyFieldConfig
) {
  return `should be > ${field?.props?.step}`;
}

export function MailValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (
    control.value &&
    control.value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
  ) {
    return null;
  } else {
    return { invalidEmailAddress: true };
  }
}

export function exclusiveMaximumValidationMessage(
  err: any,
  field: FormlyFieldConfig
) {
  return `should be < ${field?.props?.step}`;
}

export function constValidationMessage(err: any, field: FormlyFieldConfig) {
  return `should be equal to constant "${field?.props?.["const"]}"`;
}

export const bheShellFeatureRoutes: Route[] = [
  {
    path: "",
    pathMatch: "full",
    loadChildren: () =>
      import("@bhe/shell-ui").then((m) => m.SplashComponentModule)
  },
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationChildGuard],
    children: [
      {
        path: "welcome",
        loadChildren: () =>
          import("@bhe/home-feature").then((m) => m.HomeComponentModule)
      },
      {
        path: "reservations",
        pathMatch: "full",
        redirectTo: "search"
      },
      {
        path: "search",
        loadChildren: () =>
          import("@bhe/reservation-list-feature").then(
            (m) => m.SearchResultComponentModule
          )
      },
      {
        path: "reservation/new",
        redirectTo: "welcome"
      },
      {
        path: "reservation/:reservationId",
        loadChildren: () =>
          import("@bhe/reservation-feature").then(
            (m) => m.ReservationComponentModule
          )
      },
      {
        path: "visit",
        loadChildren: () =>
          import("@bhe/corporate-feature").then(
            (m) => m.BheCorporateFeatureModule
          )
      },
      {
        path: "bhe-pro",
        loadChildren: () =>
          import("@bhe/settings-feature").then(
            (m) => m.BheSettingsFeatureModule
          )
      },
      {
        path: "design-system",
        loadChildren: () =>
          import("@madeinlune/design-system").then(
            (m) => m.SharedDesignSystemModule
          )
      }
    ]
  },
  {
    path: "login",
    canActivate: [LoginGuard],
    loadChildren: () =>
      import("@bhe/shell-ui").then((m) => m.LoginComponentModule)
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(bheShellFeatureRoutes, {
      scrollPositionRestoration: "top"
    }),
    BheAuthenticationModule.forRoot(),
    MainLayoutComponentModule,
    StoreModule.forRoot({
      router: routerReducer
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    BheRouterModule,
    BheShellStateModule.forRoot(),
    NgxJsonApiModule.forRoot({
      apiUrl: "TDB",
      resourceDefinitions: []
    }),
    BheVocabulariesDataModule,
    BheFilesDataModule,
    BheUserDataAccessModule,
    BheCorporateDataAccessModule,
    BheReservationDataAccessModule,
    BheSettingsDataAccessModule,
    FormlyMaterialModule,
    AutocompleteComponentModule,
    DateRangeComponentModule,
    FormItemsWrapperComponentModule,
    MatLuxonDateModule,
    BrandSelectionComponentModule,
    GuestTypesComponentModule,
    GuestFormComponentModule,
    RadioWrapperComponentModule,
    FormlyMatDatepickerModule,
    CountryAutocompleteComponentModule,
    MhFieldSingleComponentModule,
    GuestFormRefComponentModule,
    PwaManifestModule.forRoot(),
    ApolloModule,
    QuillModule.forRoot(),
    FormlyModule.forRoot({
      extras: { resetFieldOnHide: false },
      validators: [
        {
          name: "email",
          validation: MailValidator
        }
      ],
      types: [
        { name: "stepper", component: StepperComponent, wrappers: [] },
        {
          name: "autocomplete",
          component: AutocompleteComponent,
          wrappers: ["form-field"]
        },
        {
          name: "brands",
          component: BrandSelectionComponent
        },
        {
          name: "guestTypes",
          component: GuestTypesComponent
        },
        {
          name: "guestForm",
          component: GuestFormComponent
        },
        {
          name: "mhProfileSingle",
          component: MhFieldSingleComponent
        },
        {
          name: "guestFormRef",
          component: GuestFormRefComponent
        },
        {
          name: "country",
          component: CountryAutocompleteComponent
        },
        {
          name: "languages",
          component: LanguagesComponent,
          wrappers: ["form-field"]
        },
        {
          name: "textArea",
          component: TextAreaComponent
        }
      ],
      wrappers: [
        {
          name: "dateRange",
          component: DateRangeComponent
        },
        {
          name: "bheWrapper",
          component: FormItemsWrapperComponent
        },
        {
          name: "radioWrapper",
          component: RadioWrapperComponent
        }
      ],
      validationMessages: [
        { name: "required", message: "This field is required" },
        { name: "null", message: "should be null" },
        { name: "minlength", message: minlengthValidationMessage },
        { name: "maxlength", message: maxlengthValidationMessage },
        { name: "min", message: minValidationMessage },
        { name: "max", message: maxValidationMessage },
        { name: "multipleOf", message: multipleOfValidationMessage },
        {
          name: "exclusiveMinimum",
          message: exclusiveMinimumValidationMessage
        },
        {
          name: "exclusiveMaximum",
          message: exclusiveMaximumValidationMessage
        },
        { name: "minItems", message: minItemsValidationMessage },
        { name: "maxItems", message: maxItemsValidationMessage },
        { name: "uniqueItems", message: "should NOT have duplicate items" },
        { name: "const", message: constValidationMessage },
        {
          name: "invalidEmailAddress",
          message: "this e-mail address is not valid"
        }
      ]
    })
  ],
  exports: [RouterModule],
  providers: [
    AuthenticationGuard,
    LoginGuard,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" }
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: true
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SwBypassInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonApiErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true
    },
    {
      provide: UI_ICON_STATUS_INFOS,
      deps: [RESERVATION_STATUS_INFOS],
      useFactory: (statusInfos: any) => {
        return statusInfos;
      }
    }
  ]
})
export class BheShellFeatureModule {
  static forRoot(): ModuleWithProviders<BheShellFeatureModule> {
    return {
      ngModule: BheShellFeatureModule,
      providers: [
        {
          provide: AUTH_BACKEND_URL,
          deps: [APP_CONFIG],
          useFactory: (appConfig: BheConfig) => {
            return appConfig.backendUrl;
          }
        },
        {
          provide: AUTH_CLIENT_ID,
          deps: [APP_CONFIG],
          useFactory: (appConfig: BheConfig) => {
            return appConfig.oauth.clientId;
          }
        },
        {
          provide: NGRX_JSON_API_CONFIG,
          deps: [APP_CONFIG],
          useFactory: (appConfig: BheConfig) => {
            return {
              apiUrl: `${appConfig.backendUrl}/jsonapi`,
              resourceDefinitions: [
                {
                  type: Reservation.type,
                  collectionPath: Reservation.type.split("--").join("/")
                },
                {
                  type: Brand.type,
                  collectionPath: Brand.type.split("--").join("/")
                },
                {
                  type: GuestType.type,
                  collectionPath: GuestType.type.split("--").join("/")
                },
                {
                  type: User.type,
                  collectionPath: User.type.split("--").join("/")
                },
                {
                  type: Message.type,
                  collectionPath: Message.type.split("--").join("/")
                },
                {
                  type: CorporatePage.type,
                  collectionPath: CorporatePage.type.split("--").join("/")
                },
                {
                  type: MhProfile.type,
                  collectionPath: MhProfile.type.split("--").join("/")
                },
                {
                  type: GeoZone.type,
                  collectionPath: GeoZone.type.split("--").join("/")
                },
                {
                  type: ObserversFilter.type,
                  collectionPath: ObserversFilter.type.split("--").join("/")
                },
                {
                  type: ApproversFilter.type,
                  collectionPath: ApproversFilter.type.split("--").join("/")
                },
                {
                  type: BhTeamFilter.type,
                  collectionPath: BhTeamFilter.type.split("--").join("/")
                },
                {
                  type: GuestExperience.type,
                  collectionPath: GuestExperience.type.split("--").join("/")
                },
                {
                  type: Export.type,
                  collectionPath: Export.type.split("--").join("/")
                }
              ]
            };
          }
        },
        {
          provide: APOLLO_OPTIONS,
          useFactory: (httpLink: HttpLink, appConfig: BheConfig) => {
            return {
              cache: new InMemoryCache(),
              link: httpLink.create({
                uri: `${appConfig.backendUrl}/graphql`
              })
            };
          },
          deps: [HttpLink, APP_CONFIG]
        }
      ]
    };
  }
}
