import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Brand, GuestExperience } from '@bhe/types';
import { BheIconComponentModule } from '@bhe/ui';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'bhe-res-brand-panel-title',
  template: `
    <ng-container *ngIf="brand">
      <bhe-ui-bhe-icon [icon]="brand.code"></bhe-ui-bhe-icon>
      <div class="title-group">
        <span [innerHTML]="brand.name"></span>
        <ng-container *ngIf="guestExperience">
          <small [innerHTML]="guestExperience.name"></small>
        </ng-container>
      </div>
      <ng-container *ngIf="guestExperience">
        <mat-chip-list>
          <!--<mat-chip>{{ guestExperience.name }}</mat-chip>-->
          <ng-container *ngIf="guestExperience.duration">
            <mat-chip
            >
              <bhe-ui-bhe-icon [icon]="'duration'"></bhe-ui-bhe-icon
              >
              <span [innerHTML]="guestExperience.duration"></span
              ></mat-chip>
          </ng-container>
        </mat-chip-list>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!brand">
      <span
        [innerHTML]="
          'There is no associated Brand here, please remove this void Brand Part'
        "
      ></span>
    </ng-container>
  `,
  styleUrls: ['./brand-panel-title.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandPanelTitleComponent {
  @Input()
  brand!: Brand | undefined;

  @Input()
  guestExperience!: GuestExperience | undefined;
}

@NgModule({
  imports: [CommonModule, BheIconComponentModule, MatChipsModule],
  declarations: [BrandPanelTitleComponent],
  exports: [BrandPanelTitleComponent],
})
export class BrandPanelTitleComponentModule {}
