import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@madeinlune/ngx-app-config';
import { BheConfig } from '@bhe/types';
import {uuid} from "@madeinlune/ngx-json-api";

@Injectable()
export class SwBypassInterceptor implements HttpInterceptor {
  protected urlPrefixes: string[] = [
    `${this.appConfig.backendUrl}/jsonapi`,
    `${this.appConfig.backendUrl}/graphql`,
  ];

  constructor(@Inject(APP_CONFIG) protected appConfig: BheConfig) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.urlPrefixes &&
      this.urlPrefixes.filter((prefix) => req.url.startsWith(prefix)).length > 0
    ) {
      if (!req.params.has('ngsw-bypass')) {

        const requestId: string = uuid();
        req = req.clone({
          setHeaders: {
            'X-Request-Id': requestId
          },
          params: req.params.append('ngsw-bypass', '1')
        });

        return next.handle(
          req
        );
      }
    }
    return next.handle(req);
  }
}
