import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGuestExperience from './guest-experience.reducer';
import { guestExperiencesFeatureKey } from './guest-experience.reducer';

export const selectGuestExperienceState =
  createFeatureSelector<fromGuestExperience.State>(guestExperiencesFeatureKey);

export const selectGuestExperienceEntities = createSelector(
  selectGuestExperienceState,
  fromGuestExperience.selectGuestExperienceEntities
);
