import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { Expose, Transform } from 'class-transformer';

export class BrandForm implements ResourceIdentifier {
  @Expose()
  type!: string;

  @Expose()
  id!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.changed;
  })
  @Expose()
  changed!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.name;
  })
  @Expose()
  name!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_default_contact_info;
  })
  @Expose()
  field_default_contact_info!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_default_contact_phone;
  })
  @Expose()
  field_default_contact_phone!: string;
}
