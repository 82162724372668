import {ChangeDetectionStrategy, Component, NgModule, TemplateRef, ViewEncapsulation,} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbstractMatListComponent} from '../abstract-mat-list/abstract-mat-list.component';
import {MatListModule} from '@angular/material/list';

@Component({
  selector: 'bhe-pro-export-list',
  templateUrl: '../abstract-mat-list/abstract-mat-list.component.html',
  styleUrls: ['../abstract-mat-list/abstract-mat-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportListComponent extends AbstractMatListComponent<
  any,
  TemplateRef<{ item: any }>
> {}

@NgModule({
  imports: [CommonModule, MatListModule],
  declarations: [ExportListComponent],
  exports: [ExportListComponent],
})
export class ExportListComponentModule {}
