import { ChangeDetectionStrategy, Component, Inject, NgModule, ViewEncapsulation } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FieldType } from "@ngx-formly/material";
import { GUEST_TYPES } from "@bhe/vocabularies-data";
import { Observable } from "rxjs";
import { GuestType } from "@bhe/types";
import { MatRadioModule } from "@angular/material/radio";
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { ResourceIdentifier } from "@madeinlune/ngx-json-api";
import { RadioGroupCompareWithDirectiveModule } from "@bhe/utils";
import { FieldTypeConfig, FormlyModule } from "@ngx-formly/core";
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-res-guest-types',
  template: `
    <mat-radio-group
      [formlyAttributes]="field"
      aria-label="Select an option"
      [formControl]="control"
      [rbCompareWith]="compareWith"
    >
      <ng-container *ngrxLet="guestTypes$; let guestTypes">
        <ng-template
          ngFor
          [ngForOf]="guestTypes"
          let-guestType
          [ngForTrackBy]="trackBy"
        >
          <mat-radio-button
            [value]="{ type: guestType.type, id: guestType.id }"
          >
            <strong [innerHTML]="guestType.name"></strong>
            <ng-container *ngIf="props['showDescription']">
              <p [innerHTML]="guestType.description"></p>
            </ng-container>
          </mat-radio-button>
        </ng-template>
      </ng-container>
    </mat-radio-group>
  `,
  styleUrls: ['guest-types.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestTypesComponent extends FieldType<FieldTypeConfig> {
  public get control() {
    return this.formControl as UntypedFormControl;
  }

  constructor(
    @Inject(GUEST_TYPES) public guestTypes$: Observable<GuestType[]>
  ) {
    super();
  }

  trackBy(index: number, item: GuestType) {
    return item.id;
  }

  compareWith(o1: ResourceIdentifier, o2: ResourceIdentifier): boolean {
    return o1?.id === o2?.id;
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    ReactiveFormsModule,
    RadioGroupCompareWithDirectiveModule,
    FormlyModule,
    LetModule
  ],
  declarations: [GuestTypesComponent],
  exports: [GuestTypesComponent],
})
export class GuestTypesComponentModule {}
