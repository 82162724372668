import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrupalFile } from '@bhe/types';
import { FileDownloadComponentModule } from '../renderers/file-download/file-download.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'bhe-ui-file-download-list',
  template: `
    <h4 [innerHTML]="title"></h4>
    <div class="b-help" [innerHTML]="help"></div>
    <ul>
      <ng-template ngFor [ngForOf]="files" let-file>
        <li>
          <bhe-ui-file-download [file]="file"></bhe-ui-file-download>
        </li>
      </ng-template>
    </ul>
  `,
  styleUrls: ['./file-download-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDownloadListComponent {
  @Input()
  files!: DrupalFile[];

  @Input()
  title!: string;

  @Input()
  help!: string;
}

@NgModule({
  imports: [CommonModule, FileDownloadComponentModule, TranslocoModule],
  declarations: [FileDownloadListComponent],
  exports: [FileDownloadListComponent],
})
export class FileDownloadListComponentModule {}
