import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  Input,
  NgModule,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { startWith, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatRadioButton } from '@angular/material/radio';
import { UntypedFormControl } from '@angular/forms';

/**
 * Add 'compareWith' to mat-radio-group just like mat-select, because angular team didn't implement it
 * see https://github.com/angular/components/issues/10495
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-radio-group[rbCompareWith]',
})
export class RadioGroupCompareWithDirective<T>
  implements AfterContentInit, OnDestroy
{
  @Input() rbCompareWith!: (o1: T, o2: T) => boolean;
  @Input() formControl!: UntypedFormControl;

  @ContentChildren(MatRadioButton, { descendants: true })
  radioButtons!: QueryList<MatRadioButton>; // List of descendant RadioButtons

  formControlSub!: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    // Use ngAfterContentInit to make sure that radioButtons is initialized
    this.formControlSub = this.formControl.valueChanges
      .pipe(startWith(this.formControl.value))
      .subscribe((value) => {
        const foundRadioButton = this.radioButtons
          .toArray()
          .find((radioButton) => {
            // Find a radio button whose value compares to ngModel
            return this.rbCompareWith(radioButton.value, value);
          });
        if (foundRadioButton) {
          foundRadioButton.checked = true;
          this.cdr.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.formControlSub) {
      this.formControlSub.unsubscribe();
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [RadioGroupCompareWithDirective],
  exports: [RadioGroupCompareWithDirective],
})
export class RadioGroupCompareWithDirectiveModule {}
