import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Reservation } from '@bhe/types';
import { RxState } from '@rx-angular/state';
import { map, Observable } from 'rxjs';
import {
  OverviewBlockComponentModule,
  OverviewField,
} from '../overview-block/overview-block.component';
import { PushModule } from '@rx-angular/template/push';

@Component({
  selector: 'bhe-res-overview-new-client',
  template: `
    <ng-container *transloco="let t">
      <h3 [innerHTML]="t('reservation.tabs.client.label')"></h3>
      <bhe-res-overview-block
        [fields]="newClientFields$ | push"
      ></bhe-res-overview-block>
    </ng-container>
  `,
  styleUrls: ['./overview-new-client.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class OverviewNewClientComponent {
  reservation$ = this.state.select('reservation');

  newClientFields$: Observable<OverviewField[]> = this.reservation$.pipe(
    map((reservation) => {
      if (reservation) {
        const {
          field_new_client_quality_ordered,
          field_new_client_champagne,
          field_new_client_establishment,
          field_new_client_property,
          field_new_client_house_event,
          field_new_client_which_event,
          field_new_client_private_member,
          field_new_client_met_ambassador,
          field_new_client_ordered_months,
          field_new_client_date_of_last,
          field_new_client_which_houses,
        } = reservation;
        return [
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.label'
            ),
            value: field_new_client_property,
            type: 'boolean',
            class: 'span-3',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.private-member.label'
            ),
            value: field_new_client_private_member,
            type: 'boolean-idk',
            class: 'span-3',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.house-event.label'
            ),
            value: field_new_client_house_event,
            type: 'boolean-idk',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.which-event.label'
            ),
            value: field_new_client_which_event,
            class: 'span-2',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.visit-champagne.label'
            ),
            value: field_new_client_champagne,
            type: 'boolean-idk',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.which-houses.label'
            ),
            value: field_new_client_which_houses,
            type: 'brand-refs',
            class: 'span-2',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.ordered-brands.label'
            ),
            value: field_new_client_ordered_months,
            type: 'boolean-idk',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.last-order-date.label'
            ),
            value: field_new_client_date_of_last,
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.quality-ordered.label'
            ),
            value: field_new_client_quality_ordered,
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.ambassador.label'
            ),
            value: field_new_client_met_ambassador,
            type: 'boolean-idk',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.new-client.starred-establishment.label'
            ),
            value: field_new_client_establishment,
            type: 'boolean-idk',
          },
        ];
      }
      return [];
    })
  );

  @Input()
  set reservation(reservation: Reservation) {
    this.state.set({ reservation });
  }

  constructor(
    private state: RxState<{ reservation: Reservation }>,
    private translocoService: TranslocoService
  ) {}
}

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    OverviewBlockComponentModule,
    PushModule,
  ],
  declarations: [OverviewNewClientComponent],
  exports: [OverviewNewClientComponent],
})
export class OverviewNewClientComponentModule {}
