import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Brand, GuestType, Reservation } from "@bhe/types";
import { ResourceIdentifier } from "@madeinlune/ngx-json-api";

export const RESERVATION = new InjectionToken<Observable<Reservation>>(
  'RESERVATION'
);

export const RESERVATION_HAS_GUEST_EXPERIENCES = new InjectionToken<Observable<boolean>>(
  'RESERVATION_HAS_GUEST_EXPERIENCES'
);

export const RESERVATION_MISSING_GUEST_EXPERIENCES = new InjectionToken<Observable<ResourceIdentifier[]>>(
  'RESERVATION_MISSING_GUEST_EXPERIENCES'
);

export const RESERVATION_GUEST_TYPE = new InjectionToken<Observable<GuestType>>(
  'RESERVATION_GUEST_TYPE'
);

export const RESERVATION_IS_DRAFT = new InjectionToken<Observable<boolean>>(
  'RESERVATION_IS_DRAFT'
);

export const GUEST_IS_TRADE = new InjectionToken<Observable<boolean>>(
  'GUEST_IS_TRADE'
);

export const GUEST_IS_PRESS = new InjectionToken<Observable<boolean>>(
  'GUEST_IS_PRESS'
);

export const GUEST_IS_PRIVATE_CLIENT = new InjectionToken<Observable<boolean>>(
  'GUEST_IS_PRIVATE_CLIENT'
);

export const ENTITIES_RELATIONS_SHIPS = new InjectionToken<
  Map<string, string[]>
>('ENTITIES_RELATIONS_SHIPS');

export const USER_HAS_REQUESTOR_ROLE = new InjectionToken<Observable<boolean>>(
  'USER_HAS_REQUESTOR_ROLE'
);

export const SHOW_EDIT_TAB = new InjectionToken<Observable<boolean>>(
  'SHOW_EDIT_TAB'
);
