import { Inject, Injectable } from "@angular/core";
import { NgxJsonApi, Resource } from "@madeinlune/ngx-json-api";
import { BheConfig, JsonApiOperation, Reservation, ReservationWorkflow, User } from "@bhe/types";
import { catchError, map, Observable, of, switchMap } from "rxjs";
import { bheClasses, jsonApiResources } from "@nx-agency/bhe/operators";
import { BheDialogService } from "@bhe/ui";
import { Apollo } from "apollo-angular";
import { ConditionGroup, ConditionGroupInput, reservationListGql } from "@bhe/reservation-list-data-access";
import { plainToInstance } from "class-transformer";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { APP_CONFIG } from "@madeinlune/ngx-app-config";

@Injectable({
  providedIn: "root"
})
export class RequestorsService {
  constructor(
    private ngxJsonApi: NgxJsonApi,
    private bheDialogService: BheDialogService,
    private apollo: Apollo,
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: BheConfig
  ) {
  }

  replaceRequestor(
    reservations: Reservation[],
    newRequestor: User
  ): Observable<boolean> {
    const operations: JsonApiOperation[] = reservations.map((reservation) => {
      const { searchUuid } = reservation;
      return {
        op: "update",
        data: {
          type: Reservation.type,
          id: searchUuid,
          relationships: {
            uid: {
              data: { type: newRequestor.type, id: newRequestor.id }
            }
          }
        },
        ref: {
          type: Reservation.type,
          id: searchUuid
        }
      };
    });
    return this.#sendOperations(operations);
  }

  searchUser(mail: string): Observable<User> {
    return this.ngxJsonApi
      .find({
        type: User.type,
        params: {
          page: {
            limit: 10,
            offset: 0
          },
          filtering: [
            {
              path: "mail",
              value: "name",
              operator: "path"
            },
            {
              path: "mail",
              operator: "value",
              value: mail
            },
            {
              path: "mail",
              value: "=",
              operator: "operator"
            }
          ]
        }
      })
      .pipe(
        jsonApiResources(),
        bheClasses(),
        map((result) => {
          return result[User.type]?.shift();
        })
      );
  }

  loadRequestorReservations(
    mail: string
  ): Observable<Reservation[] | "no user found"> {
    return this.searchUser(mail).pipe(
      switchMap((user) => {
        if (user) {
          const conditions: ConditionGroupInput = {
            conjunction: "AND",
            groups: []
          };

          const mailGroup: ConditionGroup = {
            conjunction: "AND",
            conditions: [
              {
                name: "uid",
                operator: "=",
                value: `${user.uid}`
              }
            ]
          };
          conditions.groups.push(mailGroup);

          const statusGroup: ConditionGroup = {
            conjunction: "OR",
            conditions: []
          };
          const searchStatus: ReservationWorkflow[] = [
            "draft",
            "submitted",
            "approved",
            "ready",
            "wns",
            "confirmed"
          ];
          searchStatus.forEach((statusKey: string) => {
            statusGroup.conditions.push({
              name: "field_workflow_reservation",
              operator: "=",
              value: statusKey
            });
          });
          conditions.groups.push(statusGroup);
          console.log({ conditions });
          return this.apollo
            .query<any>({
              query: reservationListGql,
              fetchPolicy: "no-cache",
              variables: {
                offset: 0,
                limit: 50,
                conditions
              }
            })
            .pipe(
              map((queryResult: any) => {
                return queryResult?.data?.searchAPISearch?.documents;
              }),
              /*tap((documents) => console.log({ documents })),*/
              map((documents) => {
                return documents.map((doc: any) => {
                  return plainToInstance(Reservation, doc, {
                    excludeExtraneousValues: true,
                    exposeUnsetFields: false
                  });
                });
              })
            );
        }
        return of("no user found");
      })
    );
  }

  #sendOperations(operations: JsonApiOperation[]): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json"
    });
    const url = `${this.appConfig.backendUrl}/jsonapi/operations`;

    return this.httpClient
      .patch(
        url,
        {
          operations
        },
        {
          observe: "response",
          headers
        }
      )
      .pipe(
        map((operationsResult: HttpResponse<any>) => {
          const operations = operationsResult?.body?.operations;
          const data: Resource[] = operations
            .map((op: any) => {
              return op?.data;
            })
            .filter((d: Resource) => !!d);
          return data;
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            this.bheDialogService.openError(
              "Sorry, an error occured",
              error,
              error.status
            );
          }
          return of(error);
        })
      );
  }
}
