import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { GuestType } from '@bhe/types';

export const loadGuestTypes = createAction(
  '[GuestType/API] Load GuestTypes',
  props<{ guestTypes: GuestType[] }>()
);

export const addGuestType = createAction(
  '[GuestType/API] Add GuestType',
  props<{ guestType: GuestType }>()
);

export const upsertGuestType = createAction(
  '[GuestType/API] Upsert GuestType',
  props<{ guestType: GuestType }>()
);

export const addGuestTypes = createAction(
  '[GuestType/API] Add GuestTypes',
  props<{ guestTypes: GuestType[] }>()
);

export const upsertGuestTypes = createAction(
  '[GuestType/API] Upsert GuestTypes',
  props<{ guestTypes: GuestType[] }>()
);

export const updateGuestType = createAction(
  '[GuestType/API] Update GuestType',
  props<{ guestType: Update<GuestType> }>()
);

export const updateGuestTypes = createAction(
  '[GuestType/API] Update GuestTypes',
  props<{ guestTypes: Update<GuestType>[] }>()
);

export const deleteGuestType = createAction(
  '[GuestType/API] Delete GuestType',
  props<{ id: string }>()
);

export const deleteGuestTypes = createAction(
  '[GuestType/API] Delete GuestTypes',
  props<{ ids: string[] }>()
);

export const clearGuestTypes = createAction('[GuestType/API] Clear GuestTypes');
