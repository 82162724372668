import { createAction, props } from '@ngrx/store';
import { Reservation } from '@bhe/types';

export const clearReservation = createAction('[Reservation] Clear Reservation');

export const loadReservation = createAction(
  '[Reservation] Load Reservation',
  props<{ reservationId: string }>()
);

export const loadReservationSuccess = createAction(
  '[Reservation] Load Reservation Success',
  props<{ reservation: Reservation }>()
);

export const loadReservationError = createAction(
  '[Reservation] Load Reservation Error'
);
