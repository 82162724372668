import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Brand, DrupalFile, GuestExperience } from '@bhe/types';
import { NgxJsonApi } from '@madeinlune/ngx-json-api';
import { bheClasses, jsonApiResources } from '@nx-agency/bhe/operators';
import { loadBrands } from './brand.actions';
import { State } from './brand.reducer';
import { Store } from '@ngrx/store';
import { addFiles } from '@bhe/files-data';
import { addGuestExperiences } from '../guest-experience/guest-experience.actions';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  constructor(private ngxJsonApi: NgxJsonApi, private store: Store<State>) {}

  public loadBrands(): Observable<boolean> {
    return this.ngxJsonApi
      .find({
        type: Brand.type,
        params: {
          filtering: [
            {
              path: 'status',
              value: 1,
            },
          ],
          fields: {
            [Brand.type]: [
              'machine_name',
              'name',
              'changed',
              'description',
              'field_default_contact_info',
              'field_default_contact_phone',
              'field_guest_experiences',
              'field_brand_logo',
            ],
            [GuestExperience.type]: [
              'machine_name',
              'name',
              'field_program_description',
              'field_program_description_long',
              'field_program_description_short',
              'field_template_dress_code',
              'field_template_recommendations',
              'drupal_internal__tid',
              'field_guest_experience_duration',
              'field_enable',
            ],
            [DrupalFile.type]: ['filemime', 'filename', 'filesize', 'uri'],
          },
          include: ['field_brand_logo', 'field_guest_experiences'],
        },
      })
      .pipe(
        jsonApiResources(),
        bheClasses(),
        map((resultMap) => {
          if (resultMap[Brand.type]) {
            this.store.dispatch(loadBrands({ brands: resultMap[Brand.type] }));
          }
          if (resultMap[DrupalFile.type]) {
            this.store.dispatch(
              addFiles({ files: resultMap[DrupalFile.type] })
            );
          }
          if (resultMap[GuestExperience.type]) {
            this.store.dispatch(
              addGuestExperiences({
                guestExperiences: resultMap[GuestExperience.type],
              })
            );
          }
          return true;
        }),
        catchError((error) => {
          return throwError(
            () => `an error occured while loading brands ${error}`
          );
        })
      );
  }
}
