
import { Observable } from 'rxjs';
import { FormlyFieldConfig } from "@ngx-formly/core";

export function teamFilters(
  brands$: Observable<any[]>,
  guestTypes$: Observable<any[]>,
  geoZones$: Observable<any[]>
): FormlyFieldConfig[] {
  return [
    {
      key: 'id',
    },
    {
      key: 'type',
    },
    {
      key: 'title',
      templateOptions: {
        label: 'Filter title',
      },
      type: 'input',
    },
    {
      className: 'section-label',
      template: '<h3>Brands</h3>',
    },
    {
      key: 'field_filter_brands',
      type: 'multiGuestTypes',
      templateOptions: {
        options: brands$,
        optionLabelKey: 'name',
      },
    },
    {
      className: 'section-label',
      template: '<h3>Guest Types</h3>',
    },
    {
      key: 'field_filter_guest_types',
      type: 'multiGuestTypes',
      templateOptions: {
        options: guestTypes$,
        optionLabelKey: 'name',
      },
    },
    {
      className: 'section-label',
      template: '<h3>Geo-Zones</h3>',
    },
    {
      key: 'field_filter_geo_zones',
      type: 'multiGuestTypes',
      templateOptions: {
        options: geoZones$,
        optionLabelKey: 'title',
      },
    },
    {
      className: 'section-label',
      template: '<h3>Assigned Users</h3>',
    },
    {
      key: 'field_filter_users',
      type: 'searchUsers',
    },
  ];
}

export function approverFilters(
  brands$: Observable<any[]>,
  guestTypes$: Observable<any[]>,
  geoZones$: Observable<any[]>
): FormlyFieldConfig[] {
  return [
    ...teamFilters(brands$, guestTypes$, geoZones$).slice(0, 5),
    {
      className: 'section-label',
      template: '<h3>Guest Experiences</h3>',
    },
    {
      key: 'field_guest_experiences',
      type: 'multiGuestExperiences',
    },
    ...teamFilters(brands$, guestTypes$, geoZones$).slice(5),
  ];
}
