import { DrupalNode } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';

/*
changed: "2021-04-14T15:27:20+00:00"
content_translation_outdated: false
content_translation_source: "und"
created: "2021-04-14T15:08:32+00:00"
default_langcode: true
drupal_internal__nid: 3140749
drupal_internal__vid: 3290032
field_export_data: "{\"list\":[\"entity:node\\/10000:en\",\"entity:no
field_export_error: null
field_export_filter_country: []
field_export_filter_date_end: "2021-04-14"
field_export_filter_date_start: "2016-01-01"
field_export_filter_status: []
field_export_progression: 1
field_workflow_export: "finished"
langcode: "en"
promote: false
revision_log: null
revision_timestamp: "2021-04-14T15:08:32+00:00"
revision_translation_affected: true
status: true
sticky: false
title: "Export-2021-04-14-admin"
 */

export class Export extends DrupalNode {
  static type = 'node--export';
  @Transform(({ obj }) => {
    return obj?.attributes?.title;
  })
  @Expose()
  title!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_export_filter_country;
  })
  @Expose()
  countries!: string[];

  @Transform(({ obj }) => {
    return obj?.attributes?.field_export_filter_date_start;
  })
  @Expose()
  dateStart!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_export_filter_date_end;
  })
  @Expose()
  dateEnd!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_export_filter_status;
  })
  @Expose()
  status!: string[];

  @Transform(({ obj }) => {
    return obj?.attributes?.field_export_progression;
  })
  @Expose()
  exportProgression!: number;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_workflow_export;
  })
  @Expose()
  exportStatus!: string;

  /****** RELATIONSHIPS ******/

  @Transform(({ obj }) => {
    return obj?.relationships?.field_export_filter_brand?.data;
  })
  @Expose()
  brands!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    return obj?.relationships?.field_export_filter_guest_type?.data;
  })
  @Expose()
  guestTypes!: ResourceIdentifier[];

  /****** DENORMALIZED ******/
  @Transform(({ obj }) => {
    return obj?.relationships?.uid?.data?.reference?.attributes?.display_name;
  })
  @Expose()
  userName!: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_export_file?.data?.reference?.attributes
      ?.uri?.url;
  })
  @Expose()
  fileUrl!: string;
}
