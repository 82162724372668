import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ROUTE_RESERVATION_ID } from '@bhe/router';
import {
  clearReservation,
  loadReservation,
  loadReservationSuccess,
} from './reservation.actions';
import { ReservationService } from './reservation.service';
import { Action } from '@ngrx/store';

@Injectable()
export class ReservationEffects {
  reservationNavigated$ = createEffect(() => {
    return this.reservationId$.pipe(
      distinctUntilChanged(),
      switchMap((reservationId) => {
        const actions: Action[] = [clearReservation()];
        if (reservationId) {
          actions.push(loadReservation({ reservationId }));
        }
        return actions;
      })
    );
  });

  loadReservation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadReservation),
      switchMap((action) => {
        return this.reservationService
          .loadReservation(action.reservationId)
          .pipe(
            switchMap((reservation) => {
              return [loadReservationSuccess({ reservation })];
            }),
            catchError((error) => throwError(error))
          );
      }),
      catchError((error) => throwError(error))
    );
  });

  constructor(
    private actions$: Actions,
    private reservationService: ReservationService,
    @Inject(ROUTE_RESERVATION_ID) public reservationId$: Observable<string>
  ) {}
}
