import { DrupalCommon } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';

export class GuestType extends DrupalCommon {
  static type = 'taxonomy_term--guest_type';

  @Transform(({ obj }) => {
    return obj?.attributes?.machine_name;
  })
  @Expose()
  code!: 'corporate' | 'gtr' | 'hnwi' | 'press' | 'trade';

  @Transform(({ obj }) => {
    return obj?.attributes?.name;
  })
  @Expose()
  name!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_short_label;
  })
  @Expose()
  shortName!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_description;
  })
  @Expose()
  description!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.drupal_internal__tid;
  })
  @Expose()
  tid!: number;
}
