import { EventEmitter, Inject, Injectable } from '@angular/core';
import { BheDialogService } from '@bhe/ui';
import { Brand, ProcessUi, Reservation, ReservationWorkflow } from '@bhe/types';
import { TranslocoService } from '@ngneat/transloco';
import { ReservationService } from '@bhe/reservation-data-access';
import {
  catchError,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { BRANDS_MAP } from '@bhe/vocabularies-data';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  constructor(
    private bheDialogService: BheDialogService,
    private transloco: TranslocoService,
    private reservationService: ReservationService,
    @Inject(BRANDS_MAP) private brandsMap$: Observable<{ [id: string]: Brand }>
  ) {}

  update(
    reservationWorkflow: ReservationWorkflow,
    reservation: Reservation
  ): void {
    if (reservationWorkflow === 'submitted') {
      const reservationReadyForSubmission: boolean =
        reservation.reservationParts
          .map((rp) => rp.guestExperienceRef?.id)
          .filter((ge) => !!ge).length === reservation.reservationParts.length;
      if (!reservationReadyForSubmission) {
        this.brandsMap$.pipe(take(1)).subscribe((brandsMap) => {
          const missingBrands: Brand[] = reservation.reservationParts
            .filter((reservationPart) => !reservationPart.guestExperienceRef)
            .map(
              (reservationPart) => brandsMap?.[reservationPart?.brandRef?.id]
            )
            .filter((brand) => !!brand);
          const missingBrandNames: string[] = missingBrands.map(
            (brand) => brand.name
          );
          const bheAlertData = BheDialogService.buildAlertDialogData(
            this.transloco.translate(
              'reservation.workflow.missing-guest-experiences-message',
              { brands: missingBrandNames.join(', ') }
            ),
            [
              {
                id: 'ok',
                label: this.transloco.translate('actions.ok'),
                color: 'accent',
                close: true,
              },
            ]
          );
          this.bheDialogService.openAlert<'ok'>(bheAlertData);
        });
      } else {
        this.#openConfirmationDialog(reservationWorkflow, reservation);
      }
    } else {
      this.#openConfirmationDialog(reservationWorkflow, reservation);
    }
  }

  #openConfirmationDialog(
    reservationWorkflow: ReservationWorkflow,
    reservation: Reservation
  ) {
    const confirmEmitter: EventEmitter<any> = new EventEmitter<any>();
    const bheAlertData = BheDialogService.buildAlertDialogData(
      this.transloco.translate('reservation.workflow.update-message', {
        value: reservationWorkflow,
      }),
      [
        {
          id: 'no',
          label: this.transloco.translate('actions.no'),
          color: 'text',
          close: true,
        },
        {
          id: 'yes',
          label: this.transloco.translate('actions.yes'),
          color: 'accent',
          emitter: confirmEmitter,
        },
      ]
    );

    bheAlertData.process$ = new ReplaySubject<ProcessUi>(1);
    bheAlertData.process$?.next(null);

    bheAlertData.processMessage = {
      running: this.transloco.translate('process.workflow.running'),
      error: this.transloco.translate('process.workflow.error'),
    };

    const dialogRef = this.bheDialogService.openAlert<'yes' | 'no'>(
      bheAlertData
    );
    const confirmEmitterSubscription = confirmEmitter
      .pipe(
        take(1),
        tap(() => {
          bheAlertData.process$?.next('running');
        }),
        switchMap((event) => {
          return this.reservationService.updateWorkflow(
            reservation,
            reservationWorkflow
          );
        }),
        catchError((error) => throwError(() => error))
      )
      .subscribe((result) => {
        if (!(result instanceof HttpErrorResponse)) {
          dialogRef.close();
        } else {
          bheAlertData.process$?.next('error');
          bheAlertData.networkError = result;
        }
      });
    dialogRef.afterClosed().subscribe((result) => {
      confirmEmitterSubscription.unsubscribe();
    });
  }
}
