import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { DOCUMENT, NgForOf, NgIf, NgTemplateOutlet } from "@angular/common";
import { Route } from "@angular/router";
import { MessageBoxService } from "@bhe/message-box-data-access";
import { ROUTE_RESERVATION_ID } from "@bhe/router";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  endWith,
  filter,
  map,
  Observable,
  startWith,
  switchMap,
  take,
  tap,
  throwError,
  withLatestFrom
} from "rxjs";
import { RxState } from "@rx-angular/state";
import { Message, Reservation } from "@bhe/types";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { HomeMessageComponentModule } from "@bhe/message-box-ui";
import { IconLoadingComponentModule } from "@bhe/ui";
import { MessageBoxInputComponent, MessageBoxInputComponentModule } from "./message-box-input/message-box-input.component";
import { plainToClass } from "class-transformer";
import { Resource } from "@madeinlune/ngx-json-api";
import { WINDOW } from "@ng-web-apis/common";
import { USER_DISPLAY_NAME, USER_UUID } from "@bhe/user-data-access";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule } from "@ngrx/component";
import { TranslocoModule } from "@ngneat/transloco";

@UntilDestroy()
@Component({
  selector: "bhe-message-box",
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngIf="standAlone">
        <h2>Message-Box</h2>
      </ng-container>
      <ng-container *ngrxLet="userUuid$; let userUuid">
        <ng-container *ngrxLet="messages$; let messages">
          <ng-container *ngrxLet="isLoading$; let isLoading">
            <ng-container *ngrxLet="isCreatingMessage$; let isCreatingMessage">
              <ng-container *ngIf="isLoading || isCreatingMessage">
                <ng-template
                  [ngTemplateOutlet]="loadingTemplate"
                  [ngTemplateOutletContext]="{
                    $implicit: isCreatingMessage
                      ? 'message-box.creating'
                      : 'message-box.loading'
                  }"
                ></ng-template>
              </ng-container>
              <ng-container *ngIf="!isLoading || messages">
                <ng-container *ngIf="messages; else noMessageTemplate">
                  <ng-container
                    *ngIf="messages.length > 0; else noMessageTemplate"
                  >
                    <ul>
                      <ng-template
                        ngFor
                        [ngForOf]="messages"
                        let-message
                        [ngForTrackBy]="identify"
                      >
                        <li [class.me]="message?.userId?.id === userUuid">
                          <bhe-home-message
                            [message]="message"
                          ></bhe-home-message>
                        </li>
                      </ng-template>
                    </ul>
                  </ng-container>
                  <bhe-message-box-input
                    #inputComponent
                    (sendMessage)="onSendMessage($event)"
                    (sizeChanged)="onInputSizeChanged($event)"
                  ></bhe-message-box-input>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #loadingTemplate let-message>
        <div class="message-layer">
          <bhe-ui-icon-loading></bhe-ui-icon-loading>
          <span [innerHTML]="t(message)"></span>
        </div>
      </ng-template>
      <ng-template #noMessageTemplate>
        <ng-container *ngrxLet="isCreatingMessage$; let isCreatingMessage">
          <ng-container *ngIf="!isCreatingMessage">
            <div class="message-layer">
              <span [innerHTML]="t('message-box.no-messages')"></span>
            </div>
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ["./message-box.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
  imports: [
    IconLoadingComponentModule,
    MessageBoxInputComponentModule,
    HomeMessageComponentModule,
    NgForOf,
    NgTemplateOutlet,
    NgIf,
    LetModule,
    TranslocoModule
  ],
  standalone: true
})
export class MessageBoxComponent implements OnChanges, OnDestroy {
  observer!: ResizeObserver;

  @Input()
  standAlone!: boolean;

  readonly messages$ = this.state.select("messages");
  readonly isLoading$ = this.state.select("isLoading");
  readonly isCreatingMessage$ = this.state.select("isCreatingMessage");

  @ViewChild("inputComponent") inputComponent!: MessageBoxInputComponent;

  readonly refreshMessages$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ROUTE_RESERVATION_ID) private reservationId$: Observable<string>,
    @Inject(USER_DISPLAY_NAME) private userName$: Observable<string>,
    @Inject(USER_UUID) public userUuid$: Observable<string>,
    private messageBoxService: MessageBoxService,
    private state: RxState<{
      messages: Message[] | null;
      isLoading: boolean;
      isCreatingMessage: boolean;
      error: { message: string; error: number } | null;
    }>,
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>,
    private ngZone: NgZone
  ) {
    this.observer = new ResizeObserver((entries) => {
      this.ngZone.run(() => {
        const { nativeElement } = this.el;
        const box = nativeElement.getBoundingClientRect();
        const { left, width, top, height } = box;
        nativeElement.style.setProperty("--input-position-left", `${left}px`);
        nativeElement.style.setProperty("--input-size-width", `${width}px`);
        nativeElement.style.setProperty("--input-position-top", `${top}px`);
        nativeElement.style.setProperty("--input-size-height", `${height}px`);
      });
    });

    this.reservationId$
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.state.set({ messages: null });
      });

    this.observer.observe(this.el.nativeElement);

    const fetchMessages$ = combineLatest([
      this.reservationId$,
      this.refreshMessages$
    ]).pipe(
      map(([reservationId, refreshMessages]) => reservationId),
      filter((reservationId) => !!reservationId),
      switchMap((reservationId) => {
        return this.messageBoxService
          .loadReservationMessages(reservationId)
          .pipe(
            map((messages) => ({ messages: messages ?? [] })),
            startWith({
              isLoading: true,
              error: null,
              isCreatingMessage: false
            }),
            endWith({ isLoading: false }),
            tap(() => {
              this.#scrollToBottom();
            }),
            catchError((error: HttpErrorResponse) => {
              this.state.set({
                error: {
                  message:
                    "An error prevents us from loading the reservation messages",
                  error: error.status
                }
              });
              return throwError(() => error);
            })
          );
      })
    );
    this.state.connect(fetchMessages$);
  }

  #scrollToBottom(time = 400) {
    setTimeout(() => {
      const computedStyle = this.window.getComputedStyle(this.el.nativeElement);
      console.log("computedStyle.overflowY", computedStyle.overflowY);
      if (computedStyle.overflowY === "auto") {
        this.el.nativeElement.scrollTo({ top: this.el.nativeElement.scrollHeight, behavior: "smooth" });
      } else {
        this.window.scrollTo(0, this.document.body.scrollHeight);
      }
    }, time);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["standAlone"].firstChange) {
      const { nativeElement } = this.el;
      this.renderer.removeClass(nativeElement, "stand-alone");
      if (this.standAlone) {
        this.renderer.addClass(nativeElement, "stand-alone");
      }
    }
  }

  identify(index: number, item: Message) {
    return item.id;
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.unobserve(this.el.nativeElement);
    }
  }

  onInputSizeChanged(inputSize: { width: number; height: number }) {
    const { nativeElement } = this.el;
    nativeElement.style.setProperty(
      "--message-list-padding-bottom",
      `${inputSize.height}px`
    );
  }

  onSendMessage(message: string) {
    if (!message) {
      return;
    }
    this.reservationId$
      .pipe(
        take(1),
        tap(() => {
          this.state.set({ isLoading: true, isCreatingMessage: true });
        }),
        switchMap((id) => {
          return this.messageBoxService.postMessage(message, {
            id,
            type: Reservation.type
          });
        }),
        withLatestFrom(this.userName$),
        tap(([result, userName]: [HttpResponse<any>, string]) => {
          this.refreshMessages$.next(true);
          this.state.set({ isLoading: false, isCreatingMessage: false });
          const messageResource: Resource | undefined = result?.body?.data;
          if (messageResource) {
            const message: Message = plainToClass(Message, messageResource, {
              excludeExtraneousValues: true,
              exposeUnsetFields: false
            });
            message.userName = userName;
            const reduceFn = (oldState: any) => [message, ...(oldState.messages ?? [])];
            this.state.set("messages", reduceFn);
          }
        }),
        catchError((error) => {
          this.state.set({ isLoading: false, isCreatingMessage: false });
          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.#scrollToBottom(0);
        if (this.inputComponent) {
          this.inputComponent.clearInput();
        }
      });
  }
}

export const MESSAGE_BOX_ROUTES: Route[] = [
  {
    path: "",
    component: MessageBoxComponent
  }
];
