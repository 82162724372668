import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
  Direction,
  NgxJsonApi,
  Resource,
  ResourceIdentifier,
  uuid,
} from '@madeinlune/ngx-json-api';
import { BheConfig, Message, MessageBoxItem, Reservation, User } from "@bhe/types";
import { bheClasses, jsonApiResources } from '@nx-agency/bhe/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { APP_CONFIG } from '@madeinlune/ngx-app-config';
import { BheDialogService } from '@bhe/ui';

interface MessageBoxServiceItem{
  comment: {
    uuid: string;
    body: string;
    by: string;
    timestamp: string;
  },
  reservation: {
    id: string;
    uuid: string;
    country: string;
    status: string;
    brands: string[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class MessageBoxService {
  constructor(
    private ngxJsonApi: NgxJsonApi,
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: BheConfig,
    private bheDialogService: BheDialogService
  ) {}

  loadUserMessages(): Observable<MessageBoxItem[]> {
    const backendUrl = this.appConfig.backendUrl;
    return this.httpClient
      .get<MessageBoxServiceItem[]>(
        `${backendUrl}/last-comments`,
      )
      .pipe(
        map((messageBoxItems) => {
          return messageBoxItems.map(m => {
            const {comment, reservation} = m;
            let body = comment.body.replace(/<p><br><\/p>/g, '');
            body = `<p>${body.replace(/<p>/g, '').replace(/<\/p>/g, '<br>')}</p>`;
            const messageBoxItem:MessageBoxItem = {
              body,
              id: comment.timestamp,
              changed: (parseInt(comment.timestamp)*1000).toString(),
              reservationId: reservation.id,
              reservationCountry: reservation.country,
              reservationUuid: reservation.uuid,
              userName: comment.by
            }
            return messageBoxItem;
          });
        }),
        catchError((error) =>
          throwError(() => `an error occured while loading messages, ${error}`)
        )
      );
  }

  loadReservationMessages(reservationId: string): Observable<Message[]> {
    return this.ngxJsonApi
      .find({
        type: Message.type,
        params: {
          page: {
            limit: 50,
            offset: 0,
          },
          filtering: [
            {
              path: 'reservationParent',
              value: 'entity_id.id',
              operator: 'path',
            },
            {
              path: 'reservationParent',
              operator: 'value',
              value: reservationId,
            },
            {
              path: 'reservationParent',
              value: '=',
              operator: 'operator',
            },
          ],
          sorting: [
            {
              api: 'changed',
              direction: Direction.DESC,
            },
          ],
          include: ['uid'],
          fields: {
            [Message.type]: ['changed', 'comment_body', 'uid', 'entity_id'],
            [User.type]: ['display_name'],
          },
        },
      })
      .pipe(
        jsonApiResources(true),
        bheClasses(),
        map((resultMap) => {
          return resultMap[Message.type];
        }),
        catchError((error) => throwError(() => error))
      );
  }

  postMessage(
    message: string,
    reservation: ResourceIdentifier
  ): Observable<any> {
    const url = `${this.appConfig.backendUrl}/jsonapi/${Message.type
      .split('--')
      .join('/')}`;

    const commentResource: Resource = {
      type: Message.type,
      id: uuid(),
      attributes: {
        field_name: 'field_comments',
        entity_type: 'node',
        comment_body: {
          value: message,
        },
      },
      relationships: {
        entity_id: {
          data: reservation,
        },
      },
    };

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    });

    return this.httpClient
      .post(
        url,
        { data: commentResource },
        {
          observe: 'response',
          headers,
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.bheDialogService.openError(
            'errors.message-box.message',
            error.error,
            error.status
          );
          return throwError(() => error);
        })
      );
  }
}
