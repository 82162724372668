import { DrupalCommon } from './drupal-node.model';
import {
  Expose,
  plainToClass,
  plainToInstance,
  Transform,
} from 'class-transformer';
import { Resource, ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { Image } from './image.model';
import { DrupalFile } from './file.model';

export class CorporatePage extends DrupalCommon {
  static type = 'node--brand_corporate_information';

  @Transform(({ obj }) => {
    return obj?.attributes?.title;
  })
  @Expose()
  title!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_brand_description?.value;
  })
  @Expose()
  description!: string;

  @Transform(({ obj }) => {
    const image: Resource | undefined =
      obj?.relationships?.field_brand_banner?.data?.reference;
    if (image) {
      return plainToClass(Image, image, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false,
      });
    }
    return null;
  })
  @Expose()
  banner!: Image;

  @Transform(({ obj }) => {
    const fieldBrandDownloads: any[] | undefined =
      obj?.relationships?.field_brand_downloads?.data;
    if (fieldBrandDownloads) {
      const mediaFiles: DrupalFile[] = fieldBrandDownloads
        .map((bd) => {
          return bd.reference?.relationships?.field_media_file?.data?.reference;
        })
        .filter((r) => !!r)
        .map((r) => {
          return plainToInstance(DrupalFile, r, {
            excludeExtraneousValues: true,
            exposeUnsetFields: false,
          });
        });
      return mediaFiles;
    }

    return [];
  })
  @Expose()
  downloads!: DrupalFile[];

  @Transform(({ obj }) => {
    const images: Resource[] | undefined =
      obj?.relationships?.field_brand_images?.data?.map((ref: any) => {
        return ref.reference;
      });
    if (images) {
      return images.map((image) => {
        return plainToClass(Image, image, {
          excludeExtraneousValues: true,
          exposeUnsetFields: false,
        });
      });
    }
    return [];
  })
  @Expose()
  images!: Image[];

  @Transform(({ obj }) => {
    return obj?.relationships?.field_brand_reference?.data;
  })
  @Expose()
  brand!: ResourceIdentifier;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_brand_video?.data;
  })
  @Expose()
  video!: any;
}
