import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GuestFormComponent,
  GuestFormComponentModule,
} from '../guest-form/guest-form.component';
import {
  AddGuestDialogData,
  Guest,
  GuestForm,
  ProcessUi,
  ReservationForm,
} from '@bhe/types';
import {
  FormEntityType,
  ReservationFormEntitiesService,
  ReservationService,
} from '@bhe/reservation-data-access';
import { uuid } from '@madeinlune/ngx-json-api';
import { BehaviorSubject, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BheButtonComponentModule,
  BheDialogComponent,
  IconLoadingComponentModule,
  ProcessComponentModule,
} from '@bhe/ui';
import { ReservationFormService } from '../reservation-form/reservation-form.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslocoModule } from '@ngneat/transloco';
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-add-guest',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngrxLet="process$; let process">
        <bhe-guest-form
          #guestFormComponent
          [class.saving]="!!process"
          (save)="onSave($event)"
          [autoSave]="false"
          [restricted]="true"
        >
          <bhe-ui-bhe-button
            class="actions"
            variant="primary"
            (btnClick)="close()"
            ><span [innerHTML]="t('actions.cancel')"></span
          ></bhe-ui-bhe-button>
        </bhe-guest-form>
        <ng-container *ngIf="process">
          <bhe-ui-process class="b-process" [state]="process">
            <ng-template bheUiProcessRunning>
              <bhe-ui-icon-loading></bhe-ui-icon-loading>
              <p [innerHTML]="t('process.add-guest.running')"></p>
            </ng-template>
            <ng-template bheUiProcessSuccess>
              <div class="b-process__message">
                <p [innerHTML]="t('process.add-guest.success')"></p>
              </div>
              <div class="b-process__actions">
                <bhe-ui-bhe-button variant="primary" (btnClick)="close()"
                  ><span [innerHTML]="t('actions.ok')"></span
                ></bhe-ui-bhe-button>
                <bhe-ui-bhe-button
                  variant="primary"
                  (btnClick)="createNewGuest()"
                  ><span [innerHTML]="t('actions.add-guest')"></span
                ></bhe-ui-bhe-button>
              </div>
            </ng-template>
            <ng-template bheUiProcessError>
              <div class="b-process__message">
                <p [innerHTML]="t('process.add-guest.error')"></p>
              </div>
              <div class="b-process__actions">
                <bhe-ui-bhe-button variant="primary" (btnClick)="close()"
                  ><span [innerHTML]="t('actions.ok')"></span
                ></bhe-ui-bhe-button>
              </div>
            </ng-template>
          </bhe-ui-process>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./add-guest.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddGuestComponent {
  @ViewChild('guestFormComponent') guestFormComponent!: GuestFormComponent;

  process$: BehaviorSubject<ProcessUi | null> =
    new BehaviorSubject<ProcessUi | null>(null);

  constructor(
    public dialogRef: MatDialogRef<BheDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: AddGuestDialogData,
    private reservationService: ReservationService,
    private reservationFormService: ReservationFormService,
    private reservationFormEntitiesService: ReservationFormEntitiesService
  ) {}

  createNewGuest() {
    this.process$.next(null);
  }

  close(): void {
    this.dialogRef.close();
  }

  onSave(guestForm: GuestForm) {
    guestForm.id = uuid();
    guestForm.type = Guest.type;
    const reservationForm: ReservationForm =
      this.reservationFormService.form.value;
    this.process$.next('running');
    this.reservationService
      .addRelationshipsFieldMulti(reservationForm, 'field_reservation_guests', [
        guestForm,
      ])
      .pipe(
        tap(
          (
            entities: { [type: string]: FormEntityType[] } | HttpErrorResponse
          ) => {
            if (!(entities instanceof HttpErrorResponse)) {
              Object.keys(entities).forEach((key) => {
                const typeData: FormEntityType[] = entities[key];
                if (typeData) {
                  typeData.forEach((data) => {
                    this.reservationFormEntitiesService.addEntity(
                      data,
                      'IN_SYNC'
                    );
                  });
                }
              });
              if (this.guestFormComponent) {
                this.guestFormComponent.reset();
              }
              if (guestForm) {
                const { id, type } = guestForm;
                this.data.addGuestEmitter.emit({ id, type });
              }
              this.process$.next('success');
            } else {
              this.process$.next('error');
            }
          }
        )
      )
      .subscribe();
  }
}

@NgModule({
  imports: [
    CommonModule,
    GuestFormComponentModule,
    IconLoadingComponentModule,
    ProcessComponentModule,
    TranslocoModule,
    BheButtonComponentModule,
    LetModule
  ],
  declarations: [AddGuestComponent],
  exports: [AddGuestComponent],
})
export class AddGuestComponentModule {}
