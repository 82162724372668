import { DrupalNode } from './drupal-node.model';
import { Expose, Transform } from 'class-transformer';

/*
changed: "2022-01-16T05:03:15+00:00"
content_translation_outdated: false
content_translation_source: "und"
created: "2022-01-16T05:03:15+00:00"
default_langcode: true
drupal_internal__nid: 3184615
drupal_internal__vid: 3353265
field_ad_profile_address: "68-70 av de champagne"
field_ad_profile_area_service: "MHCS"
field_ad_profile_city: " REIMS"
field_ad_profile_department: "Communication & RP & Presse / Communication & PR & Media"
field_ad_profile_dn: "CN=fraysses,OU=Users,OU=MC,OU=MHCS,DC=mh,DC=lvmh"
field_ad_profile_email: "sfraysse-nouvel@moethennessy.com"
field_ad_profile_entity: "Moët & Chandon"
field_ad_profile_first_name: "Sabine"
field_ad_profile_hash: "4e77af2cf3f23066b8b9ce3eb90777e6"
field_ad_profile_last_name: "FRAYSSE NOUVEL "
field_ad_profile_mobile: "+33 6 07 63 08 91"
field_ad_profile_office: null
field_ad_profile_phone: "-"
field_ad_profile_phone_ip: "112237"
field_ad_profile_position: null
field_ad_profile_title: null
field_ad_profile_zip: "51200"
langcode: "en"
promote: true
revision_log: null
revision_timestamp: "2022-01-16T05:03:15+00:00"
revision_translation_affected: true
status: true
sticky: false
title: "Sabine FRAYSSE NOUVEL "
*/

export class MhProfile extends DrupalNode {
  static type = 'node--mh_ad_profile';

  [key: string]: any;

  @Transform(({ obj }) => {
    return obj?.attributes?.title;
  })
  @Expose()
  fullName!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_ad_profile_first_name;
  })
  @Expose()
  firstName!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_ad_profile_last_name;
  })
  @Expose()
  lastName!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_ad_profile_email;
  })
  @Expose()
  mail!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_ad_profile_area_service;
  })
  @Expose()
  service!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_ad_profile_entity;
  })
  @Expose()
  entity!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_ad_profile_position;
  })
  @Expose()
  position!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_ad_profile_department;
  })
  @Expose()
  department!: string;
}
