import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReservationPressInfos } from '@bhe/types';
import { RxState } from '@rx-angular/state';
import { filter, map, Observable } from 'rxjs';
import { PushModule } from '@rx-angular/template/push';
import { LetModule } from "@ngrx/component";
import {
  OverviewBlockComponentModule,
  OverviewField,
} from '../overview-block/overview-block.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'bhe-res-overview-reservation-press-infos',
  template: `
    <ng-container *transloco="let t">
      <h3 [innerHTML]="t('reservation.tabs.press.label')"></h3>
      <bhe-res-overview-block
        [title]="'reservation.form.subjects-of-the-visit.block-title'"
        [fields]="subjectsFields$ | push"
      ></bhe-res-overview-block>
      <ng-container *ngrxLet="articleFields$; let articleFields">
        <ng-container *ngIf="articleFields">
          <bhe-res-overview-block
            [title]="'reservation.form.press.article.before-title'"
            [fields]="articleFields"
          ></bhe-res-overview-block>
        </ng-container>
      </ng-container>
      <bhe-res-overview-block
        [title]="'reservation.form.press.media.block-title'"
        [fields]="mediaFields$ | push"
      ></bhe-res-overview-block>
      <bhe-res-overview-block
        [fields]="partnerFields$ | push"
      ></bhe-res-overview-block>
    </ng-container>
  `,
  styleUrls: ['./overview-reservation-press-infos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState, DatePipe],
})
export class OverviewReservationPressInfosComponent {
  pressInfos$ = this.state.select('pressInfos');

  subjectsFields$: Observable<OverviewField[]> = this.pressInfos$.pipe(
    map((pressInfos) => {
      if (pressInfos) {
        const {
          subjectsOfTheVisit,
          visitSpecialOccasion,
          visitFocusOnVintage,
        } = pressInfos;
        return [
          {
            label: this.translocoService.translate(
              'reservation.form.subjects-of-the-visit.block-title'
            ),
            value: subjectsOfTheVisit,
            type: 'subjectsOfTheVisit',
          },
          {
            label: this.translocoService.translate(
              'reservation.form.subjects-of-the-visit.vs_so.label'
            ),
            value: visitSpecialOccasion,
          },
          {
            label: this.translocoService.translate(
              'reservation.form.subjects-of-the-visit.vs_fpv.label'
            ),
            value: visitFocusOnVintage,
          },
        ];
      }
      return [];
    })
  );

  articleFields$: Observable<OverviewField[]> = this.pressInfos$.pipe(
    map((pressInfos) => {
      return pressInfos.articleInfos;
    }),
    filter((articleInfos) => !!articleInfos),
    map((articleInfos) => {
      if (articleInfos) {
        const { date, name } = articleInfos;
        return [
          {
            label: 'Date',
            value: this.datePipe.transform(date, 'MMM d y', '+00:00'),
          },
          {
            label: 'Name',
            value: name,
          },
        ];
      }
      return [];
    })
  );

  mediaFields$: Observable<OverviewField[]> = this.pressInfos$.pipe(
    map((pressInfos) => {
      if (pressInfos) {
        const {
          mediaTitle,
          nextPublicationDate,
          circulations,
          interviewTypes,
          publicationFrequency,
          target,
          website,
        } = pressInfos;
        return [
          {
            label: this.translocoService.translate(
              'reservation.form.press.media.title'
            ),
            value: mediaTitle,
          },
          {
            label: this.translocoService.translate(
              'reservation.form.press.media.publication-date'
            ),
            value: this.datePipe.transform(
              nextPublicationDate,
              'MMM d y',
              '+00:00'
            ),
          },
          {
            label: this.translocoService.translate(
              'reservation.form.press.media.circulations'
            ),
            value: circulations,
          },
          {
            label: this.translocoService.translate(
              'reservation.form.press.media.interview-types'
            ),
            value: interviewTypes,
          },
          {
            label: this.translocoService.translate(
              'reservation.form.press.media.publication-frequency'
            ),
            value: publicationFrequency,
          },
          {
            label: this.translocoService.translate(
              'reservation.form.press.media.target'
            ),
            value: target,
          },
          {
            label: this.translocoService.translate(
              'reservation.form.press.media.website'
            ),
            value: website,
          },
        ];
      }
      return [];
    })
  );

  partnerFields$: Observable<OverviewField[]> = this.pressInfos$.pipe(
    map((pressInfos) => {
      if (pressInfos) {
        const { otherMediaPartner } = pressInfos;
        return [
          {
            label: this.translocoService.translate(
              'reservation.form.press.partners.block-title'
            ),
            value: otherMediaPartner,
            type: 'boolean',
          },
        ];
      }
      return [];
    })
  );

  @Input()
  set pressInfos(pressInfos: ReservationPressInfos) {
    this.state.set({ pressInfos });
  }

  constructor(
    private state: RxState<{ pressInfos: ReservationPressInfos }>,
    private datePipe: DatePipe,
    private translocoService: TranslocoService
  ) {}
}

@NgModule({
  imports: [
    CommonModule,
    OverviewBlockComponentModule,
    PushModule,
    TranslocoModule,
    LetModule
  ],
  declarations: [OverviewReservationPressInfosComponent],
  exports: [OverviewReservationPressInfosComponent],
})
export class OverviewReservationPressInfosComponentModule {}
