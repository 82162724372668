import { DrupalNode } from './drupal-node.model';
import { Expose, plainToInstance, Transform } from 'class-transformer';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { User } from './user.model';
import { ReservationPartWorkflow } from './reservation-part-workflow';

/*changed: "2022-01-07T17:20:36+00:00"
content_translation_outdated: false
content_translation_source: "und"
created: "2022-01-04T14:49:12+00:00"
default_langcode: true
drupal_internal__nid: 3214605
drupal_internal__vid: 3494844
field_approval_auto: false
field_approval_deadline: "2022-01-11T00:00:00+00:00"
field_bottles_sold: null
field_bottles_sold_other: null
field_contact_info: null
field_contact_phone: null
field_dress_code: null
field_other_remarks: null
field_program_description_long: null
field_program_description_short: null
field_recommendations: null
field_transport_details: null
field_transport_dropoff: null
field_transport_needed: false
field_transport_pickup: null
field_visit_theme: "<p>JOAO FREIRE IS A MOET BRAND LOVERS AND HE IS AN INFLUENCER IN BRAZIL.&nbsp;</p><p>HE HAS A WEBSITE WWW.JOAOFREIRE.COM.BR AND WNAT TO PUBLISH THE VISIT</p><p>OBJETIVE IN RELATIONSHIP WITH INFLUENCER</p>"
field_workflow_reservation_part: "approved"
langcode: "en"
promote: false
revision_log: null
revision_timestamp: "2022-01-07T17:20:36+00:00"
revision_translation_affected: true
status: true
sticky: false
title: "Reservation Part #3214605"*/

export class Approbation {
  @Transform(({ obj }) => {
    return obj?.relationships?.field_approvers?.data?.map((userRef: any) => {
      return plainToInstance(User, userRef.reference, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false,
      });
    });
  })
  @Expose()
  possibleApprovers!: User[];

  @Transform(({ obj }) => {
    return plainToInstance(
      User,
      obj?.relationships?.field_approval_approver?.data?.reference,
      {
        excludeExtraneousValues: true,
        exposeUnsetFields: false,
      }
    );
  })
  @Expose()
  approver!: User;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_brand?.data;
  })
  @Expose()
  brandRef!: ResourceIdentifier;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_workflow_reservation_part;
  })
  @Expose()
  workflow!: ReservationPartWorkflow;

  @Transform(({ obj }) => {
    return { id: obj?.id, type: obj?.type };
  })
  @Expose()
  reservationPartRef!: ResourceIdentifier;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_guest_experience?.data;
  })
  @Expose()
  guestExperienceRef!: ResourceIdentifier;
}

export class Transport {
  @Transform(({ obj }) => {
    return obj?.attributes?.field_transport_details;
  })
  @Expose()
  details!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_transport_dropoff;
  })
  @Expose()
  dropoff!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_transport_pickup;
  })
  @Expose()
  pickup!: string;
}

export class ReservationPart extends DrupalNode {
  static type = 'node--reservation_part';

  @Transform(({ obj }) => {
    return obj?.attributes?.field_workflow_reservation_part;
  })
  @Expose()
  workflow!: ReservationPartWorkflow;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_visit_theme;
  })
  @Expose()
  visitTheme!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_other_remarks;
  })
  @Expose()
  otherRemarks!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_dress_code;
  })
  @Expose()
  dressCode!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_recommendations;
  })
  @Expose()
  recommendations!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description_short;
  })
  @Expose()
  programShort!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description_long;
  })
  @Expose()
  programLong!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_contact_info;
  })
  @Expose()
  contactInfo!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_contact_phone;
  })
  @Expose()
  contactPhone!: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_brand?.data;
  })
  @Expose()
  brandRef!: ResourceIdentifier;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_bottles_sold;
  })
  @Expose()
  bottlesSold!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_bottles_other;
  })
  @Expose()
  bottlesOther!: string;

  @Transform(({ obj }) => {
    return obj?.relationships?.field_guest_experience?.data;
  })
  @Expose()
  guestExperienceRef!: ResourceIdentifier;

  @Transform(({ obj }) => {
    if (obj?.attributes?.field_transport_needed) {
      return plainToInstance(Transport, obj, {
        excludeExtraneousValues: true,
        exposeUnsetFields: false,
      });
    }
    return null;
  })
  @Expose()
  transport!: Transport;

  @Transform(({ obj }) => {
    return plainToInstance(Approbation, obj, {
      excludeExtraneousValues: true,
      exposeUnsetFields: false,
    });
  })
  @Expose()
  approbation!: Approbation;
}
