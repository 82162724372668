import { Inject, Injectable, Optional } from "@angular/core";
import { Actions, createEffect, ofType, OnInitEffects } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { initAuth, logIn, logOut, setAuthenticated, setError, setState, setUserId, tryLogin } from "./auth.actions";
import { catchError, filter, from, Observable, switchMap, take, tap } from "rxjs";
import { AuthConfig, NullValidationHandler, OAuthErrorEvent, OAuthService } from "angular-oauth2-oidc";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AUTH_BACKEND_URL, AUTH_CLIENT_ID, AUTH_SECRET_ID, AUTHENTICATION_USER_ID, JSON_API_END_POINT } from "./auth.token";
import { State } from "./auth.reducer";
import { getAuthConfig } from "./auth.config";
import { LOCATION } from "@ng-web-apis/common";

@Injectable()
export class AuthEffects implements OnInitEffects {
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initAuth),
      filter(() => {
        const urlObj: URL = new URL(this.location.href);
        const searchParams = new URLSearchParams(urlObj.search);
        const noauth = !!searchParams.get("noauth");
        return !noauth;
      }),
      switchMap(() => {
        const authConfig: AuthConfig = getAuthConfig(
          this.backendUrl,
          this.clientId
        );
        if (this.secretId) {
          authConfig.dummyClientSecret = this.secretId;
        }
        this.oauthService.configure(authConfig);
        this.oauthService.tokenValidationHandler = new NullValidationHandler();
        const originalFn = this.oauthService.processIdToken;
        this.oauthService.processIdToken = (
          idToken,
          accessToken,
          skipNonceCheck
        ) => {
          return originalFn.apply(this.oauthService, [
            idToken,
            accessToken,
            true
          ]);
        };
        this.oauthService.setupAutomaticSilentRefresh();
        return [tryLogin()];
      })
    );
  });

  tryLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(tryLogin),
      switchMap(() => {
        return from(this.oauthService.tryLoginCodeFlow()).pipe(
          switchMap(() => {
            if (!this.oauthService.hasValidAccessToken()) {
              return [logIn()];
            } else {
              return this.httpClient.get(this.jsonApiEndPoint).pipe(
                switchMap((result: any) => {
                  const userId: string | null =
                    result?.meta?.links?.me?.meta?.id;
                  return [setUserId({ userId })];
                }),
                catchError((error) => {
                  return [setState({ authState: "error" })];
                })
              );
            }
          })
        );
      })
    );
  });

  oauthEvents$ = createEffect(
    () => {
      return this.oauthService.events.pipe(
        tap((event) => {
          if (event instanceof OAuthErrorEvent) {
            this.store.dispatch(setError({ error: event.reason }));
            if (event.reason instanceof HttpErrorResponse) {
              if (event.reason.status === 401) {
                this.store.dispatch(setAuthenticated({ authenticated: false }));
              }
            }
          }
        })
      );
    },
    { dispatch: false }
  );

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logIn),
      tap(() => {
        this.#login();
      }),
      switchMap(() => {
        return [setState({ authState: "pending" })];
      })
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logOut),
      tap(() => {
        this.#logout();
      }),
      switchMap(() => {
        return [setAuthenticated({ authenticated: false })];
      })
    );
  });

  ngrxOnInitEffects(): Action {
    return initAuth();
  }

  constructor(
    @Inject(JSON_API_END_POINT) private jsonApiEndPoint: string,
    @Inject(AUTH_BACKEND_URL) private backendUrl: string,
    @Inject(AUTH_CLIENT_ID) private clientId: string,
    @Optional() @Inject(AUTH_SECRET_ID) private secretId: string,
    @Inject(AUTHENTICATION_USER_ID) private userId$: Observable<string>,
    @Inject(LOCATION) private location: Location,
    private actions$: Actions,
    private oauthService: OAuthService,
    private httpClient: HttpClient,
    private store: Store<State>
  ) {
  }

  #login() {
    this.oauthService.initCodeFlow();
  }

  #logout() {
    this.oauthService.logOut();
    this.userId$
      .pipe(
        take(1),
        switchMap((userId) => {
          return this.httpClient.post(
            `${this.backendUrl}/oauth/logout?_format=json&user_json=${userId}`,
            null
          );
        })
      )
      .subscribe((result) => {
      });
  }

}
