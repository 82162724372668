import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewFieldWrapperComponentModule } from '../overview-field-wrapper/overview-field-wrapper.component';
import { TranslocoModule } from '@ngneat/transloco';
import {
  BheIconComponentModule,
  BrandIconsComponentModule,
  FlagComponentModule,
} from '@bhe/ui';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { BrandPipeModule } from '@bhe/vocabularies-data';
import { LetModule } from "@ngrx/component";

export interface OverviewField {
  label: string;
  value: string | string[] | number | boolean | ResourceIdentifier[] | null;
  class?: string;
  type?:
    | 'country'
    | 'language'
    | 'subjectsOfTheVisit'
    | 'boolean'
    | 'boolean-idk'
    | 'brand-refs';
}

@Component({
  selector: 'bhe-res-overview-block',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngIf="title">
        <h4 [innerHTML]="t(title)"></h4>
      </ng-container>

      <ng-container *ngIf="fields">
        <div class="b-content">
          <ng-template
            ngFor
            [ngForOf]="fields"
            let-field
            [ngForTrackBy]="identify"
          >
            <bhe-res-overview-field-wrapper
              [title]="field.label"
              [class]="field.class"
            >
              <ng-container [ngSwitch]="field.type">
                <ng-container *ngSwitchCase="'country'">
                  <div class="b-country">
                    <ng-container *ngIf="field.value as countryCode">
                      <bhe-ui-flag
                        [code]="getCountryCode(countryCode)"
                      ></bhe-ui-flag>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'language'">
                  <span
                    [innerHTML]="field.value ? t('languages.' + field.value + '.label') : '--'"
                  ></span>
                </ng-container>
                <ng-container *ngSwitchCase="'subjectsOfTheVisit'">
                  <ul class="subjects-of-the-visit">
                    <ng-template
                      ngFor
                      [ngForOf]="getArrayValue(field.value)"
                      let-subjectKey
                    >
                      <li>
                        <span
                          [innerHTML]="
                            t(
                              'reservation.form.subjects-of-the-visit.' +
                                subjectKey +
                                '.label'
                            )
                          "
                        ></span>
                      </li>
                    </ng-template>
                  </ul>
                </ng-container>
                <ng-container *ngSwitchCase="'boolean'">
                  <span
                    [innerHTML]="
                      field.value === true
                        ? t('actions.yes')
                        : field.value === false
                        ? t('actions.no')
                        : '--'
                    "
                  ></span>
                </ng-container>
                <ng-container *ngSwitchCase="'boolean-idk'">
                  <ng-container [ngSwitch]="field.value">
                    <ng-container *ngSwitchCase="1">
                      <span [innerHTML]="t('actions.yes')"></span>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                      <span [innerHTML]="t('actions.no')"></span>
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                      <span [innerHTML]="t('actions.i-dont-know')"></span>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <span [innerHTML]="'--'"></span>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'brand-refs'">
                  <ng-container *ngIf="getRefsValue(field.value).length > 0">
                    <div class="b-ordered-brands">
                      <ng-template
                        ngFor
                        [ngForOf]="getRefsValue(field.value)"
                        let-brandRef
                      >
                        <ng-container *ngrxLet="brandRef | brand; let brand">
                          <ng-container *ngIf="brand">
                            <bhe-ui-bhe-icon
                              [icon]="brand.code"
                            ></bhe-ui-bhe-icon>
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <span [innerHTML]="field.value ?? '--'"></span>
                </ng-container>
              </ng-container>
            </bhe-res-overview-field-wrapper>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./overview-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewBlockComponent {
  @Input()
  title!: string;

  @Input()
  fields!: OverviewField[] | undefined;

  identify(index: number, item: any) {
    return index;
  }

  getRefsValue(
    value: string | string[] | number | boolean | ResourceIdentifier[] | null
  ): ResourceIdentifier[] {
    return value as ResourceIdentifier[];
  }

  getArrayValue(
    value: string | string[] | number | boolean | ResourceIdentifier[] | null
  ): string[] {
    return value as string[];
  }

  getCountryCode(
    value: string | string[] | number | boolean | ResourceIdentifier[] | null
  ): string {
    return value as string;
  }
}

@NgModule({
  imports: [
    CommonModule,
    OverviewFieldWrapperComponentModule,
    TranslocoModule,
    FlagComponentModule,
    BrandIconsComponentModule,
    BheIconComponentModule,
    BrandPipeModule,
    LetModule
  ],
  declarations: [OverviewBlockComponent],
  exports: [OverviewBlockComponent],
})
export class OverviewBlockComponentModule {}
