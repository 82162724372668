import { createAction, props } from '@ngrx/store';
import { Menu } from '@bhe/types';

export const initCore = createAction('[Core] Init');

export const loadVocabularies = createAction(
  '[Core] Load Required Vocabularies'
);

export const applicationReady = createAction('[Core] Application Ready');

export const setMenuMain = createAction(
  '[Core] Set MenuMain',
  props<{ mainMenu: Menu }>()
);

export const setMenuSecondary = createAction(
  '[Core] Set MenuSecondary',
  props<{ secondaryMenu: Menu }>()
);
