import { ResourceIdentifier } from '@madeinlune/ngx-json-api';
import { Expose, Transform } from 'class-transformer';

/*
changed: "2020-01-24T10:31:59+00:00"
content_translation_created: "2019-10-08T12:26:43+00:00"
content_translation_outdated: false
content_translation_source: "und"
default_langcode: true
description: null
drupal_internal__revision_id: 5
drupal_internal__tid: 5
field_guest_experience_duration: null
field_program_description: {,…}
field_program_description_long: {,…}
field_program_description_short: {,…}
field_template_contact_info: "Brand Home Team contact : "
field_template_contact_phone: "00 33 3 26 5"
field_template_dress_code: "Casual Chic"
field_template_recommendations: {,…}
langcode: "en"
machine_name: "premiere_rencontre"
name: "First Experience "
revision_created: "2019-10-08T12:26:43+00:00"
revision_log_message: null
revision_translation_affected: true
status: true
weight: 0
 */

export class GuestExperienceForm implements ResourceIdentifier {
  @Expose()
  type!: string;

  @Expose()
  id!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.changed;
  })
  @Expose()
  changed!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.name;
  })
  @Expose()
  name!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_guest_experience_duration;
  })
  @Expose()
  field_guest_experience_duration!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description?.value;
  })
  @Expose()
  field_program_description!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description_long?.value;
  })
  @Expose()
  field_program_description_long!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_program_description_short?.value;
  })
  @Expose()
  field_program_description_short!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_template_contact_info;
  })
  @Expose()
  field_template_contact_info!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_template_contact_phone;
  })
  @Expose()
  field_template_contact_phone!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_template_dress_code;
  })
  @Expose()
  field_template_dress_code!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_template_recommendations?.value;
  })
  @Expose()
  field_template_recommendations!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.field_enable;
  })
  @Expose()
  field_enable!: string;
}
