import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, tap, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { BheDialogService } from "@bhe/ui";

//https://github.com/manfredsteyer/angular-oauth2-oidc/issues/414
@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private bheDialogService: BheDialogService
  ) {
  }

  private getAuthRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: "Bearer " + this.oauthService.getAccessToken()
      }
    });
  }

  private initImplicitFlow(): void {
    const encodedUri = encodeURIComponent(this.router.url);
    this.oauthService.initCodeFlow(encodedUri);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /*if (!accessTokenUrlValidator(req.url)) {
      return next.handle(req);
    }*/

    return next.handle(req).pipe(
      tap((response) => {
        console.log({response});
      }),
      catchError((response: HttpResponse<any>) => {
        if (response?.status === 403 || response?.status === 401) {
          this.oauthService.logOut();
          if (this.router.url !== "/login") {
            this.bheDialogService.openConnectWindow();
          }
        }

        /*if (response?.status === 401) {
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until token is silently refreshed
            // which means the new token is ready and we can retry the request
            return this.oauthService.events.pipe(
              filter((result) => {
                return result.type === 'token_refreshed';
              }),
              take(1),
              switchMap(() => next.handle(this.getAuthRequest(req)))
            );
          } else {
            this.refreshTokenInProgress = true;
            return from(this.oauthService.refreshToken()).pipe(
              tap((result) => console.log({ result })),
              switchMap((result) => {
                console.log({ result });
                return next.handle(this.getAuthRequest(req));
              }),
              catchError((error) => {
                console.error({error});
                this.oauthService.logOut();
                this.initImplicitFlow();
                return throwError(error);
              }),
              // When the call to silentRefresh completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => {
                this.refreshTokenInProgress = false;
              })
            );
          }
        }*/

        return throwError(() => response);
      })
    );
  }
}
