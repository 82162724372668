import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UpdateComponent } from './update/update.component';
import { BheDialogComponent } from '@bhe/ui';
import { MatDialog } from '@angular/material/dialog';
import { WINDOW } from '@ng-web-apis/common';
import { fromEvent, Observable, ReplaySubject } from 'rxjs';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  #deferredPwaPromptEvent!: BeforeInstallPromptEvent | null;
  displayInstallComponent$: ReplaySubject<boolean> = new ReplaySubject<boolean>(
    1
  );

  constructor(
    private swUpdate: SwUpdate,
    private matDialog: MatDialog,
    @Inject(WINDOW) private window: Window
  ) {
    swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`VERSION_READY`);
          this.openUpdateWindow();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });

    swUpdate.unrecoverable.subscribe((unrecoverable) => {
      console.log('swUpdate.unrecoverable', { unrecoverable });
    });

    console.log('swUpdate.isEnabled', swUpdate.isEnabled);

    fromEvent(this.window, 'beforeinstallprompt').subscribe((event: Event) => {
      this.openInstallWindow(event as BeforeInstallPromptEvent);
    });
  }

  openInstallWindow(event: BeforeInstallPromptEvent): void {
    event.preventDefault();
    console.log('openInstallWindow()');
    this.#deferredPwaPromptEvent = event;
    this.displayInstallComponent$.next(true);
  }

  async install() {
    if (this.#deferredPwaPromptEvent) {
      // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
      this.#deferredPwaPromptEvent.prompt();
      // Find out whether the user confirmed the installation or not
      const { outcome } = await this.#deferredPwaPromptEvent.userChoice;
      // The deferredPrompt can only be used once.
      this.#deferredPwaPromptEvent = null;
      // Act on the user's choice
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt.');
        this.displayInstallComponent$.next(false);
      } else if (outcome === 'dismissed') {
        console.log('User dismissed the install prompt');
      }
    }
  }

  openUpdateWindow(): void {
    this.matDialog.open(BheDialogComponent, {
      disableClose: true,
      panelClass: 'alert',
      data: {
        component: UpdateComponent,
      },
    });
  }
}
