import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as CorporatePageActions from './corporate-page.actions';
import { CorporatePage } from '@bhe/types';

export const corporatePagesFeatureKey = 'corporatePages';

export interface State extends EntityState<CorporatePage> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CorporatePage> =
  createEntityAdapter<CorporatePage>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(CorporatePageActions.addCorporatePage, (state, action) =>
    adapter.addOne(action.corporatePage, state)
  ),
  on(CorporatePageActions.upsertCorporatePage, (state, action) =>
    adapter.upsertOne(action.corporatePage, state)
  ),
  on(CorporatePageActions.addCorporatePages, (state, action) =>
    adapter.addMany(action.corporatePages, state)
  ),
  on(CorporatePageActions.upsertCorporatePages, (state, action) =>
    adapter.upsertMany(action.corporatePages, state)
  ),
  on(CorporatePageActions.updateCorporatePage, (state, action) =>
    adapter.updateOne(action.corporatePage, state)
  ),
  on(CorporatePageActions.updateCorporatePages, (state, action) =>
    adapter.updateMany(action.corporatePages, state)
  ),
  on(CorporatePageActions.deleteCorporatePage, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(CorporatePageActions.deleteCorporatePages, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(CorporatePageActions.loadCorporatePages, (state, action) =>
    adapter.setAll(action.corporatePages, state)
  ),
  on(CorporatePageActions.clearCorporatePages, (state) =>
    adapter.removeAll(state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectCorporatePageIds = selectIds;

// select the dictionary of user entities
export const selectCorporatePageEntities = selectEntities;
