import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { State } from './guest-type.reducer';
import { map, Observable } from 'rxjs';
import { GuestType } from '@bhe/types';
import { selectGuestTypeEntities } from './guest-type.selectors';

@Pipe({
  name: 'tidToGuestType',
})
export class TidToGuestTypePipe implements PipeTransform {
  constructor(private store: Store<State>) {}

  transform(tid: string): Observable<GuestType | undefined> {
    return this.store.pipe(
      select(selectGuestTypeEntities),
      map((guestTypeEntities) => {
        return Object.keys(guestTypeEntities).map((id) => {
          const guestType: GuestType | undefined = guestTypeEntities[id];
          return guestType;
        });
      }),
      map((guestTypes) => {
        if (guestTypes) {
          return guestTypes.find(
            (guestType) => guestType?.tid === parseInt(tid)
          );
        }
        return undefined;
      })
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [TidToGuestTypePipe],
  exports: [TidToGuestTypePipe],
})
export class TidToGuestTypePipeModule {}
