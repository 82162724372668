import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey
);

export const selectAuthenticated = createSelector(
  selectAuthState,
  (authState: fromAuth.State) => {
    return authState.authenticated;
  }
);

export const selectAuthenticationState = createSelector(
  selectAuthState,
  (authState: fromAuth.State) => {
    return authState.authState;
  }
);

export const selectAuthenticationUserId = createSelector(
  selectAuthState,
  (authState: fromAuth.State) => {
    return authState.userId;
  }
);
