import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { CorporatePage } from '@bhe/types';

export const loadCorporatePages = createAction(
  '[CorporatePage/API] Load CorporatePages',
  props<{ corporatePages: CorporatePage[] }>()
);

export const addCorporatePage = createAction(
  '[CorporatePage/API] Add CorporatePage',
  props<{ corporatePage: CorporatePage }>()
);

export const upsertCorporatePage = createAction(
  '[CorporatePage/API] Upsert CorporatePage',
  props<{ corporatePage: CorporatePage }>()
);

export const addCorporatePages = createAction(
  '[CorporatePage/API] Add CorporatePages',
  props<{ corporatePages: CorporatePage[] }>()
);

export const upsertCorporatePages = createAction(
  '[CorporatePage/API] Upsert CorporatePages',
  props<{ corporatePages: CorporatePage[] }>()
);

export const updateCorporatePage = createAction(
  '[CorporatePage/API] Update CorporatePage',
  props<{ corporatePage: Update<CorporatePage> }>()
);

export const updateCorporatePages = createAction(
  '[CorporatePage/API] Update CorporatePages',
  props<{ corporatePages: Update<CorporatePage>[] }>()
);

export const deleteCorporatePage = createAction(
  '[CorporatePage/API] Delete CorporatePage',
  props<{ id: string }>()
);

export const deleteCorporatePages = createAction(
  '[CorporatePage/API] Delete CorporatePages',
  props<{ ids: string[] }>()
);

export const clearCorporatePages = createAction(
  '[CorporatePage/API] Clear CorporatePages'
);
