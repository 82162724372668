import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgModule,
  OnChanges,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BheButtonType,
  typeAttributeToken,
} from '../bhe-button/bhe-button.component';
import { MatButtonModule } from '@angular/material/button';
import { BheIconComponentModule } from '../../bhe-icon/bhe-icon.component';
import { ButtonTemplate } from '../button-template';
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'bhe-ui-soft-button',
  template: `
    <button
      #target
      mat-flat-button
      [color]="color"
      [type]="type"
      [disabled]="isDisabled"
    >
      <ng-container *ngIf="icon">
        <bhe-ui-bhe-icon [icon]="icon"></bhe-ui-bhe-icon>
      </ng-container>
      <ng-content></ng-content>
    </button>
  `,
  styleUrls: ['./soft-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftButtonComponent implements ButtonTemplate, OnChanges {
  @Input()
  icon!: string;

  @Input()
  color: ThemePalette;

  @Input()
  isDisabled = false;

  @ViewChild('target', { static: true, read: ElementRef })
  target!: ElementRef<HTMLButtonElement>;

  constructor(
    @Inject(typeAttributeToken) public type: BheButtonType,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef<HTMLElement>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
    const iconChange: SimpleChange = changes['icon'];
    const { nativeElement } = this.el;
    this.renderer.removeClass(nativeElement, 'has-icon');
    if (iconChange?.currentValue) {
      this.renderer.addClass(nativeElement, 'has-icon');
    }
  }
}

@NgModule({
  imports: [CommonModule, MatButtonModule, BheIconComponentModule],
  declarations: [SoftButtonComponent],
  exports: [SoftButtonComponent],
})
export class SoftButtonComponentModule {}
