import { Resource, ResourceIdentifier } from '@madeinlune/ngx-json-api';

export function buildResourceFromEntity(
  formEntity: ResourceIdentifier,
  entitiesRelationships: Map<string, string[]>
): Resource | null {
  const resource: Resource = {
    id: formEntity.id,
    type: formEntity.type,
  };
  const resourcesRelationships: string[] | undefined =
    entitiesRelationships.get(resource.type);
  if (resourcesRelationships) {
    Object.keys(formEntity).forEach((fieldName: string) => {
      if (fieldName !== 'id' && fieldName !== 'type') {
        const fieldValue: any = (formEntity as any)[fieldName];
        if (resourcesRelationships.indexOf(fieldName) > -1) {
          if (!resource.relationships) {
            resource.relationships = {};
          }
          if (resource.relationships) {
            if (fieldValue) {
              resource.relationships[fieldName] = {
                data: fieldValue,
              };
            } else {
              resource.relationships[fieldName] = { data: {} };
            }
          }
        } else {
          if (!resource.attributes) {
            resource.attributes = {};
          }
          if (resource.attributes) {
            resource.attributes[fieldName] = fieldValue;
          }
        }
      }
    });
    return resource;
  } else {
    console.error(
      `no relationships defined for ${resource.type} cf ENTITIES_RELATIONS_SHIPS token`
    );
  }
  return null;
}
