import { Inject, Injectable } from '@angular/core';
import { catchError, EMPTY, map, Observable, of, tap } from 'rxjs';
import {
  BheConfig,
  CorporatePageForm,
  EditContentType,
  JsonApiOperation,
} from '@bhe/types';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import {
  Direction,
  NgxJsonApi,
  QueryParams,
  Resource,
  uuid,
} from '@madeinlune/ngx-json-api';
import { bheClasses, jsonApiResources } from '@nx-agency/bhe/operators';
import { RxState } from '@rx-angular/state';
import { buildResourceFromEntity } from '@bhe/utils';
import { APP_CONFIG } from '@madeinlune/ngx-app-config';
import { FormEntityType } from '@bhe/reservation-data-access';
import { BheDialogService } from '@bhe/ui';

@Injectable({
  providedIn: 'root',
})
export class EditContentService extends RxState<{ resultMap: any }> {
  readonly resultMap$: Observable<any> = this.select('resultMap');

  constructor(
    private ngxJsonApi: NgxJsonApi,
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: BheConfig,
    private bheDialogService: BheDialogService
  ) {
    super();
  }

  loadContentList(
    type: EditContentType,
    params: QueryParams = {},
    denormalize: boolean = false
  ): Observable<CorporatePageForm[] | HttpErrorResponse> {
    this.set({ resultMap: null });
    return this.ngxJsonApi
      .find({
        type,
        params: {
          filtering: [
            {
              path: 'status',
              value: 1,
            },
          ],
          sorting: [
            {
              api: 'changed',
              direction: Direction.DESC,
            },
          ],
          ...params,
        },
      })
      .pipe(
        jsonApiResources(denormalize),
        bheClasses(true),
        tap((resultMap) => {
          this.set({ resultMap });
        }),
        map((resultMap) => {
          return resultMap[type];
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  save(
    formValue: any,
    entitiesRelationships: Map<string, string[]>
  ): Observable<{ [type: string]: FormEntityType[] } | HttpErrorResponse> {
    const resource: Resource | null = buildResourceFromEntity(
      formValue,
      entitiesRelationships
    );
    if (resource) {
      const operations: JsonApiOperation[] = [
        {
          op: 'update',
          data: resource,
          ref: {
            id: resource.id,
            type: resource.type,
          },
        },
      ];

      return this.sendOperations(operations);
    }
    return EMPTY;
  }

  createNewContent(creationResource: Resource) {
    const resource: Resource = {
      ...creationResource,
      id: uuid(),
    };
    const operations: JsonApiOperation[] = [
      {
        op: 'add',
        data: resource,
        ref: {
          id: resource.id,
          type: resource.type,
        },
      },
    ];
    return this.sendOperations(operations);
  }

  sendOperations(
    operations: JsonApiOperation[]
  ): Observable<{ [type: string]: FormEntityType[] } | HttpErrorResponse> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    });
    const url = `${this.appConfig.backendUrl}/jsonapi/operations`;

    return this.httpClient
      .patch(
        url,
        {
          operations,
        },
        {
          observe: 'response',
          headers,
        }
      )
      .pipe(
        map((operationsResult: HttpResponse<any>) => {
          const operations = operationsResult?.body?.operations;
          const data: Resource[] = operations
            .map((op: any) => {
              return op?.data;
            })
            .filter((d: Resource) => !!d);
          return data;
        }),
        bheClasses(true),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            this.bheDialogService.openError(
              'Sorry, an error occured',
              error,
              error.status
            );
          }
          return of(error);
        })
      );
  }
}
