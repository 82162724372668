import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, Observable } from 'rxjs';
import { PwaManifestService } from '@madeinlune/ngx-pwa-manifest';
import { PwaService } from '../pwa.service';
import { BheButtonComponentModule } from '@bhe/ui';
import { TranslocoModule } from '@ngneat/transloco';
import { LetModule } from "@ngrx/component";

@Component({
  selector: 'bhe-pwa-install-ios',
  template: `
    <ng-container *transloco="let t">
      <ng-container *ngrxLet="iconUrl$; let iconUrl">
        <ng-container *ngIf="iconUrl">
          <img class="icon" [src]="iconUrl" />
        </ng-container>
      </ng-container>
      <header>
        <h3 [innerHTML]="t('pwa.install.app-title')"></h3>
        <small [innerHTML]="t('pwa.install.publisher')"></small>
      </header>
      <div class="content">
        <p [innerHTML]="t('pwa.install.ios.message', { device: 'iPhone' })"></p>
      </div>
    </ng-container>
  `,
  styleUrls: ['./install-ios.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallIosComponent {
  iconUrl$: Observable<string | undefined> =
    this.pwaManifestService.icons$.pipe(
      map((icons) => {
        return icons.find((icon) => icon.sizes === '384x384')?.src;
      })
    );

  constructor(
    private pwaManifestService: PwaManifestService,
    private pwaService: PwaService
  ) {}

  onInstall() {
    this.pwaService.install();
  }
}

@NgModule({
  imports: [
    CommonModule,
    BheButtonComponentModule,
    TranslocoModule,
    LetModule
  ],
  declarations: [InstallIosComponent],
  exports: [InstallIosComponent],
})
export class InstallIosComponentModule {}
