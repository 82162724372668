import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCorporate from './corporate-page.reducer';
import { corporatePagesFeatureKey } from './corporate-page.reducer';

export const selectBrandState = createFeatureSelector<fromCorporate.State>(
  corporatePagesFeatureKey
);

export const selectCorporatePageEntities = createSelector(
  selectBrandState,
  fromCorporate.selectCorporatePageEntities
);
