import { Expose, Transform } from 'class-transformer';
import { ResourceIdentifier } from '@madeinlune/ngx-json-api';

export type UserFilterType =
  | 'node--observer_filter'
  | 'node--approver_filter'
  | 'node--team_filter';

export class UsersFilterFormModel {
  @Expose()
  type!: string;

  @Expose()
  id!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.title;
  })
  @Expose()
  title!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.changed;
  })
  @Expose()
  changed!: string;

  @Transform(({ obj }) => {
    return obj?.attributes?.drupal_internal__nid;
  })
  @Expose()
  nid!: string;

  /******* RELATIONSHIPS *******/

  @Transform(({ obj }) => {
    return obj?.relationships?.field_filter_users?.data;
  })
  @Expose()
  field_filter_users!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    return obj?.relationships?.field_filter_brands?.data;
  })
  @Expose()
  field_filter_brands!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    return obj?.relationships?.field_filter_geo_zones?.data;
  })
  @Expose()
  field_filter_geo_zones!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    return obj?.relationships?.field_guest_experiences?.data;
  })
  @Expose()
  field_guest_experiences!: ResourceIdentifier[];

  @Transform(({ obj }) => {
    return obj?.relationships?.field_filter_guest_types?.data;
  })
  @Expose()
  field_filter_guest_types!: ResourceIdentifier[];
}

export class ObserversFilter extends UsersFilterFormModel {
  static type = 'node--observer_filter';
}

export class BhTeamFilter extends UsersFilterFormModel {
  static type = 'node--team_filter';
}

export class ApproversFilter extends UsersFilterFormModel {
  static type = 'node--approver_filter';
}
