import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';

import { filter, skip, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as CoreActions from './core.actions';
import {
  applicationReady,
  initCore,
  loadVocabularies,
  setMenuMain,
  setMenuSecondary,
} from './core.actions';
import { Action } from '@ngrx/store';
import { APP_CONFIG } from '@madeinlune/ngx-app-config';
import { BheConfig, Menu } from '@bhe/types';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { VocabulariesService } from '@bhe/vocabularies-data';
import {
  loadUser,
  loadUserSuccess,
  USER_IS_SETTINGS_MANAGER,
  UserService,
} from '@bhe/user-data-access';
import { DOCUMENT, Location } from '@angular/common';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AUTHENTICATED,
  AUTHENTICATION_ERROR,
  AUTHENTICATION_STATE,
  AUTHENTICATION_USER_ID,
  AuthState,
} from '@bhe/authentication';
import { Observable } from 'rxjs';
import { PwaService } from '@bhe/pwa';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import {setUser} from '@sentry/angular';

@Injectable()
export class CoreEffects implements OnInitEffects {
  init$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.initCore),
        tap((action) => {
          if (!this.localStorage.getItem('redirect')) {
            this.localStorage.setItem('redirect', this.location.path());
          }
        })
      );
    },
    { dispatch: false }
  );

  /***** BHE BOOTS *****/

  loadVocabularies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.loadVocabularies),
      withLatestFrom(this.userIsSettingsManager$),
      switchMap(([action, isUserManager]) => {
        return this.vocabulariesService.loadRequired().pipe(
          switchMap((vocabulariesLoaded) => {
            const mainMenu: Menu = {
              items: [
                {
                  label: 'header.welcome',
                  path: '/welcome',
                },
                {
                  label: 'header.reservation-list',
                  path: '/search',
                },
                ...(isUserManager
                  ? [
                      {
                        label: 'bhe-pro.title',
                        path: '/bhe-pro',
                      },
                    ]
                  : []),
                /*{
                  label: 'design-system.title',
                  path: '/design-system',
                },*/
              ],
            };
            const secondaryMenu: Menu = {
              items: [
                /*{
                  id: 'contact',
                  label: 'contact.title',
                },*/
                {
                  id: 'support',
                  label: 'support.title',
                },
              ],
            };
            return [
              setMenuMain({ mainMenu }),
              setMenuSecondary({ secondaryMenu }),
              applicationReady(),
            ];
          })
        );
      })
    );
  });

  applicationReady$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.applicationReady),
        tap((action) => {
          console.log('********** APPLICATION READY **********');
          const redirect: string | null = this.localStorage.getItem('redirect');
          this.localStorage.removeItem('redirect');
          if (redirect) {
            this.router
              .navigateByUrl(redirect, {
                replaceUrl: true,
              })
              .then((result) => {
                //console.log('redirect result', result)
              });
          } else {
            this.router.navigateByUrl('/welcome', {
              replaceUrl: true,
            });
          }
          /***** REGISTER ICOMOON ICONS ******/
          this.matIconRegistry.addSvgIconSet(
            this.sanitizer.bypassSecurityTrustResourceUrl(
              './assets/icomoon/sprite.svg'
            )
          );
        })
      );
    },
    { dispatch: false }
  );

  authenticatedEffect$ = createEffect(
    () => {
      return this.authenticated$.pipe(
        filter((authenticated) => authenticated === false),
        tap((authenticated) => {
          this.router.navigateByUrl('/login');
        })
      );
    },
    { dispatch: false }
  );

  authenticationErrorEffect$ = createEffect(
    () => {
      return this.authenticationError$.pipe(
        filter((authenticationError) => authenticationError),
        tap((authenticationError) => {
          this.router.navigateByUrl('/login');
        })
      );
    },
    { dispatch: false }
  );

  authenticationUserIdEffect$ = createEffect(() => {
    return this.authenticationUserId$.pipe(
      filter((userId) => !!userId),
      switchMap((userId) => {
        return [loadUser({ uuid: userId })];
      })
    );
  });

  userLoadedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserSuccess),
      tap(action => {
        const {user} = action;
        if (user) {
          setUser({
            id: user.uid,
            username: user.displayName,
            email: user.mail,
          });
        }
      }),
      switchMap(() => {
        return [loadVocabularies()];
      })
    );
  });

  routerNavigatedEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        skip(1),
        tap((action: RouterNavigatedAction) => {
          const {
            payload: {
              event: { url },
            },
          } = action;
          if (url !== '/login') {
            // this.localStorage.setItem('redirect', url);
          }
        })
      );
    },
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return initCore();
  }

  constructor(
    private actions$: Actions,
    @Inject(AUTHENTICATED) private authenticated$: Observable<boolean | null>,
    @Inject(AUTHENTICATION_STATE)
    private authenticationState$: Observable<AuthState>,
    @Inject(AUTHENTICATION_USER_ID)
    private authenticationUserId$: Observable<string>,
    @Inject(AUTHENTICATION_ERROR)
    private authenticationError$: Observable<boolean>,
    @Inject(USER_IS_SETTINGS_MANAGER)
    private userIsSettingsManager$: Observable<boolean>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) private appConfig: BheConfig,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private location: Location,
    private oAuthService: OAuthService,
    private router: Router,
    private vocabulariesService: VocabulariesService,
    private userService: UserService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private pwaService: PwaService
  ) {}
}
